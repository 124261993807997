import { Injectable } from '@angular/core';
import { Platform, AlertController, ToastController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { 
    Firestore, 
    doc,
    docData,
    // DocumentData, 
} from '@angular/fire/firestore';
import { TypeUser } from 'src/app/models/type-user';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
//import 'rxjs/add/operator/catch';
// import { Container } from 'src/app/models/container';
import { TypeLogin } from 'src/app/enums/type-login';
// import { AuthService } from 'src/app/providers/authentication/auth.service';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { DaoModulesService } from 'src/app/providers/db/dao-modules.service';
import { NameGroup } from 'src/app/enums/name-group';
import { Subscription, BehaviorSubject, Observable, share, takeUntil, Subject, take, firstValueFrom } from 'rxjs';
// import { Store } from '@ngrx/store';
// import { AppState } from '../../reducers';
// import { GetUser } from '../../actions/user.actions';
// import { EventColors } from 'src/app/models/event-colors';
// import { LogoutService } from '../logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    subscriptionUser: Subscription;
    // public userAuthStatus = null;
    public previousPage: string = null;
    // public displayName: string = null;
    // public email: string = null;
    // public emailRecovery: string = null;
    // public company: string = null;
    // public title: string = null;
    // public photoUrl: string = null;
    // public userType: number;
    // public userId: string = null;
    // public clientId: string = null;
    // public userEditProfile: boolean;
    // public eventId: string = null;
    // public userModuleId: string = null;
    // public language: string = environment.platform.defaultLanguage;
    // public deviceLanguage: string;
    // public userPlatform: string = null;
    // public userNotificationTime: number = null;
    // public userTotalAccess: number = null;
    public notificationBadge: number = 0;
    public chatBadge: number = 0;

    public modulesMenu: any
    public modulesEvent: any
    public groupsAttendees: any = []; 
    // private eventColors: EventColors = null;
    // public eventHomePage: string = null;
    // public event: any = null;
    // public eventTimezone: string = null;
    // public user: any = null;
    // public userLoaded: boolean = false;
    // public userEvents: Array<string> = null;
    public isBrowser: boolean = false;
    public isMobile: boolean = false;
    // public allow_language: boolean = null; //multi language 
    // public container: Container = null;
    public cantGoBackFromProfile: boolean = false;
    public hamburgerActivated: BehaviorSubject<boolean> = new BehaviorSubject(true);

    // private eventSharedObs: Observable<any> = null;
    // private userDataSharedObs: Observable<any> = null;

    constructor(
        // private auth: AuthService,
        private daoGeral: DaoGeralService,
        // private platform: Platform,
        // private daoModules: DaoModulesService,
        private firestore: Firestore,
        private events: Events,
        private alertCtrl: AlertController,
        private translateService: TranslateService,
        private toastController: ToastController,
        // private store: Store<AppState>,
        // private Slogout: LogoutService
    ) {
        this.getReferences();
    }

    // async loadService(onResolve) {
    //     this.getReferences();
    //     this.auth.getUser().subscribe((userFromUser) => {
    //         // this.getActiveEvent();
    //         if (userFromUser) {
    //             console.log(`userFromUser: ${JSON.stringify(userFromUser)}`)
    //             this.userType = userFromUser['type'];
    //             this.userEvents = userFromUser['events'];
    //             this.userId = userFromUser['uid'];
    //             // this.userType = userFromUser['type']
    //             if (this.eventId) {
    //                 if (this.subscriptionUser && !this.subscriptionUser.closed) {
    //                     this.subscriptionUser.unsubscribe();
    //                 }
    //                 this.subscriptionUser = this.daoGeral.loadUser(this.userId, this.userType, this.eventId).subscribe((user) => {
    //                     if (user) {
    //                         // this.userLoaded = true;
    //                         // this.user = user;
    //                         if (user['name']) { this.displayName = user['name']; }
    //                         if (user['email']) { this.email = user['email']; }
    //                         if (user['emailRecovery']) { this.emailRecovery = user['emailRecovery']; }
    //                         if (user['photoUrl']) { this.photoUrl = user['photoUrl']; } else { this.photoUrl = '' }
    //                         if (user['moduleId']) { this.userModuleId = user['moduleId']; }
    //                         if (user['company']) { this.company = user['company']; }
    //                         if (user['title']) { this.title = user['title']; }
    //                         //if (user['language']) { this.language = user['language']; }
    //                         if (user['edited_profile']) { this.userEditProfile = user['edited_profile']; }
    //                         if (user['notification_last_time']) { this.userNotificationTime = user['notification_last_time']; }
    //                         if (user['type']) { this.userType = user['type']; }
    //                         if (user['total_access']) { this.userTotalAccess = user['total_access']; }
    //                         if (user['eventId']) { this.loadGroups(this.userId, this.userType, user['eventId']); }
    //                         this.events.publish('globalUserLoaded');
    //                         // if (this.userNotificationTime !== null) { this.events.publish('reloadNotification'); }
    //                         // if (this.userType == TypeUser.EMPLOYEE) this.clientId = user['clientId'];

    //                         this.store.dispatch(new GetUser(user));
    //                         onResolve(true);
    //                     } else {
    //                         onResolve(false);
    //                     }
    //                 });
    //             } else {
    //                 onResolve(false);
    //             }
    //         } else {
    //             onResolve(false);
    //         }
    //     }, (error) => {
    //         onResolve((false));
    //     })
    // }

    getReferences() {
        // this.userId = localStorage.getItem('userIdentifier');
        // this.eventId = localStorage.getItem('eventId');
        // this.eventHomePage = localStorage.getItem('homePage');
        // this.eventTimezone = localStorage.getItem('timezone');
    }

    async presentAlertController(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    updateNotificationBadge(number: number, badgeUpdate: string) {
        if (badgeUpdate == 'chat') {
            this.chatBadge = number;
        } else if (badgeUpdate == 'notify') {
            this.notificationBadge = number;
        }

        if (this.notificationBadge < 0) {
            this.notificationBadge = 0;
        }

        if (this.chatBadge < 0) {
            this.chatBadge = 0;
        }
        this.notificationBadge = this.notificationBadge + this.chatBadge;
        if (this.notificationBadge >= 3) { this.notificationBadge = 2; }
        this.events.publish('menuBadge');
    }

    resetService() {
        // this.userId = null;
        // this.userType = null;
        // this.user = null;
        // this.displayName = null;
        // this.email = null;
        // this.event = null;
        // this.emailRecovery = null;
        // this.photoUrl = null;
        // this.userModuleId = null;
        // this.company = null;
        // this.title = null;
        // this.language = null;
        // this.eventId = null;
        // this.userPlatform = null;
        // this.allow_language = null

        this.groupsAttendees = []
        this.modulesEvent = []
        this.modulesMenu = []
    }

    // getActiveEvent() {
    //     return new Promise((resolve, reject) => {
    //         this.eventId = localStorage.getItem('eventId');
    //         this.eventHomePage = localStorage.getItem('homePage');
    //         this.eventTimezone = localStorage.getItem('timezone');
    //         if (this.eventId !== null) {
    //             this.getEvent(this.eventId, (result) => {
    //                 resolve(true);
    //             });
    //         } else {
    //             resolve(false);
    //         }
    //     })
    // }

    // getPlatform() {
    //     let aux = this.platform.platforms().join();
    //     if (aux.includes("desktop")) {
    //         this.userPlatform = 'web';
    //     } else if (aux.includes("hybrid")) {
    //         this.userPlatform = 'web';
    //     } else if (aux.includes("android")) {
    //         this.userPlatform = 'android';
    //     } else if (aux.includes("ios")) {
    //         this.userPlatform = 'ios';
    //     }
    // }

    // public getEvent(eventId: string, callback = (data: DocumentData[]) => {}) {
    //     this.getEventSharedObs(eventId).subscribe((data) => {
    //         if (data !== undefined && data !== null) {
    //             // this.event = data;
                
    //             // this.eventHomePage = data.homePage;
    //             localStorage.setItem('homePage', data.homePage);

    //             // this.eventTimezone = data.timezone;
    //             localStorage.setItem('timezone', data.timezone);

    //             // this.allow_language = data.allow_language
    //             this.language = data.language;
    //             this.translateService.use(data.language);
    //             localStorage.setItem('usedLanguage', data.language);

    //             this.eventColors = data.colors;

    //             // if (this.event.allowProfileQR == undefined || this.event.allowProfileQR == null) {
    //             //     this.event.allowProfileQR = true;
    //             // }

    //             // if (this.event.publicOptions == undefined) {
    //             //     this.event.publicOptions = {
    //             //         google_btn: false,
    //             //         facebook_btn: false,
    //             //         twitter_btn: false,
    //             //         microsoft_btn: false,
    //             //         yahoo_btn: false
    //             //     }
    //             // } else {
    //             //     this.event.publicOptions = {
    //             //         google_btn: this.event.publicOptions.google_btn ? this.event.publicOptions.google_btn : false,
    //             //         facebook_btn: this.event.publicOptions.facebook_btn ? this.event.publicOptions.facebook_btn : false,
    //             //         twitter_btn: this.event.publicOptions.twitter_btn ? this.event.publicOptions.twitter_btn : false,
    //             //         microsoft_btn: this.event.publicOptions.microsoft_btn ? this.event.publicOptions.microsoft_btn : false,
    //             //         yahoo_btn: this.event.publicOptions.yahoo_btn ? this.event.publicOptions.yahoo_btn : false
    //             //     }
    //             // }

    //             this.events.publish('allowLanguage')
    //             this.events.publish('loadColors');

    //             // if (this.eventColors !== null) onResolve(true);
    //             callback(data);
    //         } 
    //         else {
    //             callback(null);
    //         }
    //     })
    // }

    // /**
    //  * @description using the idea of singletons, this method returns an Observable that can be shared 
    //  * among subscribers, i.e. anywhere in the application the firestore DocumentData of the event can be 
    //  * retrived with a single observable
    //  */
    // public getEventSharedObs(eventId: string): Observable<any> {
    //     if (this.eventSharedObs == null) {
    //         const ref = doc(this.firestore, `events/${eventId}`);
    //         this.eventSharedObs = docData(ref).pipe(share(), takeUntil(this.Slogout.logoutSubject));
    //         // this.eventSharedObs = this.aFirestore.collection('events').doc(eventId).valueChanges().pipe(
    //         //     share(), 
    //         //     takeUntil(this.Slogout.logoutSubject)
    //         // );
    //         this.getEvent(eventId);
    //     }
    //     return this.eventSharedObs;
    // }
        
    // /**
    //  * @description	load the full user data and return it to the callback param (obs.: all callbacks passed 
    //  * to this method will be called every time the shared observable emits a value)
    //  * @param eventId 
    //  * @param callback 
    //  */
    // public loadUser(eventId: string, callback = (userData: DocumentData) => {}): void {
    //     const getUserData = (eventId: string, userId: string, userType: number): Observable<any> => {
    //         let ref: any;
    //         switch (userType) {
    //             case TypeUser.ATTENDEE:
    //                 ref = this.aFirestore.collection('events').doc(eventId).collection('attendees').doc(userId);
    //                 break;
    //             case TypeUser.SPEAKER:
    //                 ref = this.aFirestore.collection('events').doc(eventId).collection('speakers').doc(userId);
    //                 break;
    //             case (TypeUser.SUPERGOD || TypeUser.GOD || TypeUser.CLIENT || TypeUser.EMPLOYEE):
    //                 ref = this.aFirestore.collection('users').doc(userId);
    //                 break; 
    //         }
    //         return ref.valueChanges().pipe(share(), takeUntil(this.Slogout.logoutSubject));
    //     }

    //     this.auth.getUser().subscribe((userData) => {
    //         if (this.userDataSharedObs == null) {
    //             this.userDataSharedObs = getUserData(eventId, userData.uid, userData.type);
    //         }

    //         this.userDataSharedObs.subscribe((fullUserData) => {
    //             this.user = fullUserData;

    //             // those properies should be removed in the future, in favor of using the UserDataService
    //             // they are here only for support of all the methods in the application that dont use the 
    //             // UserDataService yet
    //             this.userId = fullUserData.uid;
    //             this.userType = fullUserData.type
                
    //             callback(fullUserData);
    //         })
    //     });
    // }

    // /**
    //  * @description get the colors of a event, then return it as a param to the callback
    //  * @param eventId 
    //  * @param callback
    //  * @returns 
    //  */
    // public getEventColors(eventId: string, callback: (eventColors: EventColors) => void): void {
    //         if (this.eventColors == null) {
    //             this.getEventSharedObs(eventId).subscribe((data) => {
    //                 this.eventColors = data.colors
    //                 callback(data.colors);
    //             });
    //         }
    //         else {
    //             callback(this.eventColors);
    //         }
    // }

    // loads the attendeees groups
    loadGroups(attendeeId: string, typeUser: number, eventId: string) {
        return new Promise((resolve) => {
            if (attendeeId && typeUser && eventId) {
                // let db = this.aFirestore.firestore;
                let ref = null;

                if (typeUser == TypeUser.SPEAKER) {
                    // ref = db.collection('events').doc(eventId).collection('speakers').doc(attendeeId);
                    ref = doc(this.firestore, `events/${eventId}/speakers/${attendeeId}`)
                } else if (typeUser == TypeUser.ATTENDEE) {
                    // ref = db.collection('events').doc(eventId).collection('attendees').doc(attendeeId);
                    ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`)
                }

                if (ref != null) { 
                    docData(ref).pipe(take(1)).subscribe((attendee) => {
                        this.groupsAttendees = [];

                        if (typeof attendee.groups !== 'undefined') {
                            const groups = attendee.groups
    
                            for (let index in groups) {
                                this.groupsAttendees.push(groups[index])
                            }
                        }

                        resolve(this.groupsAttendees);
                    });
                }
            } else {
                this.groupsAttendees = [];
                resolve(this.groupsAttendees);
            }
        })
    }

    // loadUserId(): Promise<string> {
    //     return new Promise<string>((resolve) => {
    //         this.auth.current().then((userAuth) => {
    //             if (userAuth != null) {
    //                 this.userId = userAuth['uid'];
    //                 resolve(this.userId)
    //             } else {
    //                 resolve(null)
    //             }
    //         })
    //             .catch((e) => {
    //                 console.log(e);
    //             })
    //     })
    // }

    // loads the type of user.
    // loadUserType() {
    //     return new Promise((resolve: any) => {
    //         this.auth.current().then((userAuth) => {
    //             if (userAuth != null) {
    //                 this.auth.claimsUser(async (claims) => {
    //                     this.userType = claims['result']['type']
    //                     resolve(this.userType)
    //                 })
    //             } else {
    //                 resolve(null)
    //             }
    //         })
    //             .catch((e) => {
    //                 console.log(e);
    //             })
    //     })
    // }

    // loadModulesEvent() {
    //     return new Promise((resolve) => {
    //         this.daoModules.getModulesEvent(this.eventId, (modules) => {
    //             this.modulesEvent = []
    //             this.modulesEvent = modules
    //             resolve(this.modulesEvent)
    //         })
    //     })
    // }

    // userLogged() {
    //     let logged = false;
    //     if (this.userId !== null) {
    //         logged = true;
    //     } else {
    //         logged = false;
    //         this.needLoginToUse();
    //     }
    //     return logged;
    // }

    async needLoginToUse() {
        const alert = await this.alertCtrl.create({
            // header: 'Confirm!',
            message: this.translateService.instant('global.texts.need_login_to_use'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (blah) => {
                        console.log();
                    }
                }
            ]
        });

        await alert.present();
    }

    public isAppOrBrowser() {
        this.isBrowser =
            document.URL.indexOf('http://localhost:8100') === 0 ||
            document.URL.indexOf('ionic') === 0 ||
            document.URL.indexOf('https://localhost') === 0 ||
            document.URL.indexOf('https://app') === 0 ||
            document.URL.indexOf('http://app') === 0 ||
            document.URL.indexOf('app') === 0 ||
            document.URL.indexOf('event') === 0 ||
            document.URL.indexOf('webapp') === 0 ||
            document.URL.indexOf('web') === 0 ||
            document.URL.indexOf('fair') === 0 ||
            document.URL.indexOf('market') === 0 ||
            document.URL.indexOf('com') === 0 ||
            document.URL.indexOf('br') === 0 ||
            document.URL.indexOf('net') === 0 ||
            document.URL.indexOf('symposium') === 0 ||
            document.URL.indexOf('ch') === 0 ||
            document.URL.indexOf('fr') === 0 ||
            document.URL.indexOf('firebaseapp') === 0 ||
            document.URL.indexOf('web.app') === 0 ||
            document.URL.indexOf(environment.platform.appBaseUrl) === 0;
    }

    // remove o grupo do usuário ao trocar o idioma.
    public removeGroup(lang) {
        switch (lang) {
            case 'de_DE':
                lang = NameGroup.DeDE
                break;
            case 'en_US':
                lang = NameGroup.EnUS
                break;
            case 'es_ES':
                lang = NameGroup.EsES
                break;
            case 'fr_FR':
                lang = NameGroup.FrFR
                break;
            default:
                lang = NameGroup.PtBR
                break;
        }


        const index = this.groupsAttendees.map(function (e) { return e.identifier; }).indexOf(lang);

        if (index > -1) {
            this.groupsAttendees.splice(index, 1)
        }
    }

}
