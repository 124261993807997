import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/shared/services';
import { MenuController, AlertController } from '@ionic/angular';
// import { Events } from 'src/app/shared/services/global/events.service';
import { DaoWordCloudService } from 'src/app/providers/db/dao-word-cloud.service';
import { TranslateService } from '@ngx-translate/core';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-word-cloud',
    templateUrl: './word-cloud.page.html',
    styleUrls: ['./word-cloud.page.scss'],
})
export class WordCloudPage implements OnInit, OnDestroy {

    eventId: string = null;
    moduleId: string = null;
    cloudId: string = null;
    module;
    form: UntypedFormGroup;

    allowButtonsHeader: boolean = false;

    eventColors: EventColors = new EventColors();

    loader: boolean = false;
    moduleName: string = null;

    cloud = null;
    wordRequired = false;
    wordMax = false;
    wordTitle: string;
    wordSubtitle: string;
    wordMaxChars: number;

    language: string;
    subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public global: GlobalService,
        private menuCtrl: MenuController,
        // private events: Events,
        private daoWordCloud: DaoWordCloudService,
        public alertController: AlertController,
        private translateService: TranslateService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        const params = this.route.snapshot.params;

        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        this.cloudId = params.cloudId;
        
        this.menuCtrl.enable(true);

        this.loadColors();
        this.language = this.SEventData.getLanguage();

        this.daoWordCloud.getCloud(this.moduleId, this.cloudId, (cloud) => {
            this.cloud = cloud;

            this.wordTitle = cloud['title'];
            this.wordSubtitle = cloud['subtitle'];
            this.wordMaxChars = cloud['max_characters'];

            this.form = fb.group({
                'wordAnswer': [null, Validators.compose([Validators.required, Validators.maxLength(this.cloud.wordMaxChars), Validators.pattern('^[A-Za-z]+$')])],
            });
        })

        this.form = fb.group({
            'wordAnswer': [null, Validators.compose([Validators.required, Validators.pattern('^[A-Za-z]+$')])],
        });
    }

    ngOnInit() {
        this.daoWordCloud.getModule(this.moduleId, (module) => {
            this.module = module;
            this.moduleName = module.name;
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    sendWord() {
        let valid = !this.form.get('wordAnswer').invalid;

        if (valid) {
            const answer = this.form.value.wordAnswer;
            if (answer == '' || answer == null) {
                this.wordMax = false;
                this.wordRequired = true;
            } else if (answer !== '' && answer.length < this.wordMaxChars) {
                this.loader = true;
                this.daoWordCloud.setUserWordCloud(this.moduleId, this.cloudId, this.SUserData.userId, answer, (data) => {
                    if (data) {
                        this.wordMax = false;
                        this.wordRequired = false;
                        this.presentAlertConfirm();
                    }
                });
            } else {
                this.wordRequired = false;
                this.wordMax = true;
            }
        }
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('pages.word_cloud.confirm_alert'),
            message: this.translateService.instant('pages.word_cloud.confirm_alert_message'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: () => {
                        this.loader = false;
                        this.form.reset();
                    }
                }
            ],
            backdropDismiss: false
        });

        await alert.present();
    }

}
