export class Answer {
    uid: string;
    answer: Array<string>;
    weight: Array<string>;

    constructor(){
        this.answer = new Array<string>();
        this.weight = new Array<string>();
    }


}