import { Action } from '@ngrx/store';
import { IIntegration } from '../interfaces/integrations.interface';

export enum IntegrationsActionsTypes {
    GetIntegrations = "[Integrations Service] Get integrations"
}

export class GetIntegrations implements Action {
    readonly type = IntegrationsActionsTypes.GetIntegrations;

    constructor(public payload: IIntegration) { }
}

export type IntegrationsActionsUnion = GetIntegrations;