import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
import { 
    Firestore, 
    DocumentData, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    updateDoc, 
    doc 
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs/operators';
// import { DateTimeService } from './date-time/date-time.service';
import { GlobalService } from '../shared/services';
import { TypeModule } from '../enums/type-module';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { LogoutService } from '../shared/services/logout/logout.service';
import { UserDataService } from '../shared/services/user-data/user-data.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    headers: HttpHeaders;
    apiUrl: string;

    constructor(
        private firestore: Firestore,
        // private http: HttpClient,
        // private dt: DateTimeService,
        private global: GlobalService,
        private Slogout: LogoutService,
        private SUserData: UserDataService
    ) { }

    // async sendNotification(
    //     eventId: string,
    //     message: string,
    //     sender_name: string,
    //     userIds: string[]
    // ) {
    //     const promises = userIds.map((uid) => this.getPlayerId(eventId, uid));
    //     const include_player_ids = (await Promise.all(promises)).filter(
    //         (p) => !!p
    //     );

    //     if (!include_player_ids.length) return;

    //     return this.http
    //         .post(
    //             this.apiUrl,
    //             {
    //                 include_player_ids,
    //                 headings: {
    //                     en: sender_name
    //                 },
    //                 contents: {
    //                     en: message
    //                 },
    //                 priority: 10,
    //                 api_id: environment.onesignal.notification_api_id,
    //                 app_id: environment.onesignal.onesignal_appid
    //             },
    //             {
    //                 headers: this.headers,
    //                 observe: 'response'
    //             }
    //         )
    //         .toPromise();
    // }

    // getPlayerId(eventId: string, uid: string) {
    //     return this.afs
    //         .collection('events')
    //         .doc(eventId)
    //         .collection('attendees')
    //         .doc(uid)
    //         .valueChanges()
    //         .pipe(
    //             first(),
    //             map((a: any) => (a.notification ? a.notification.userId : null))
    //         )
    //         .toPromise();
    // }


    /**
     * @description get the notifications and the quantity of notifications available for the user 
     * @param eventId 
     */
    public getNotifications(eventId: string, callback = (notifications, notificationCount: number) => { }) {
        const getBadgeValue = (data: DocumentData[]) => {
            return new Promise((resolve) => {
                let auxBadge = [];
                let cont = 0

                if (data.length == 0) { resolve(0); }
                data.forEach(async (notification) => {
                    if (this.SUserData.userData !== null) {
                        if (notification.send_to == 'all') {
                            if (this.SUserData.userData.notification_last_time !== undefined) {
                                if (
                                    notification.delivery_date >
                                    this.SUserData.userData.notification_last_time
                                ) {
                                    if (notification.forModule === -1 || notification.forModule === undefined || notification.forModule === 0) {
                                        auxBadge.push(notification);
                                    } else if (notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(this.SUserData.userId)) {
                                        auxBadge.push(notification);
                                    } else if (notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(this.SUserData.userId)) {
                                        auxBadge.push(notification);
                                    }
                                }
                            } else {
                                auxBadge.push(notification);
                            }
                            cont++;
                        } else {
                            await this.global.loadGroups(
                                this.SUserData.userId,
                                this.SUserData.userData.userType,
                                eventId
                            );
                            for (let index in notification.groups_ids) {
                                const pos = this.global.groupsAttendees
                                    .map(function (e) {
                                        return e.uid;
                                    })
                                    .indexOf(notification.groups_ids[index]);
                                if (pos >= 0) {
                                    if (
                                        this.SUserData.userData.notification_last_time !==
                                        undefined
                                    ) {
                                        if (
                                            notification.delivery_date >
                                            this.SUserData.userData.notification_last_time
                                        ) {
                                            if (notification.forModule === -1 || notification.forModule === undefined || notification.forModule === 0) {
                                                auxBadge.push(notification);
                                            } else if (notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(this.SUserData.userId)) {
                                                auxBadge.push(notification);
                                            } else if (notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(this.SUserData.userId)) {
                                                auxBadge.push(notification);
                                            }
                                            // auxBadge.push(notification);
                                        }
                                    } else {
                                        auxBadge.push(notification);
                                    }
                                    cont++;
                                } else {
                                    cont++;
                                }
                            }
                        }
                    } else {
                        let noUserNotificationDate = localStorage.getItem(
                            'notificationTime'
                        );
                        if (notification.send_to == 'all') {
                            if (noUserNotificationDate !== undefined) {
                                if (
                                    notification.delivery_date >
                                    noUserNotificationDate
                                ) {
                                    auxBadge.push(notification);
                                }
                            } else {
                                auxBadge.push(notification);
                            }
                            cont++;
                        } else {
                            cont++;
                        }
                    }
                    if (cont == data.length) {
                        resolve(auxBadge.length);
                    }
                });
            });
        }

        const ref = collection(this.firestore, `events/${eventId}/notifications`);
        const refQ = query(ref, orderBy('delivery_date', 'desc'));

        collectionData(refQ)
            .pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((data: DocumentData[]) => {
                getBadgeValue(data).then((response: number) => {
                    // if (response >= 1 && this.global.notificationBadge <= 1) {
                    //     this.global.updateNotificationBadge(1, 'notify');
                    // }

                    callback(data, response);
                });
            })


    }

    /**
     * @description register the app for push notifications and subscripe to the FCM (Firebase Cloud Messaging) 
     * topic equal to the eventId passed as param
     * @param eventId 
     */
    public async setupPushNotifications(eventId: string, userId: string) {
        const addListeners = async () => {
            await PushNotifications.addListener('registration', token => {
                console.log(`Registration token:  ${token.value}`);
                const ref = doc(this.firestore, `users/${userId}`)
                updateDoc(ref, {"fcmToken": token});
            });

            await PushNotifications.addListener('registrationError', err => {
                console.log(`Registration error: ${err.error}`);
            });

            await PushNotifications.addListener('pushNotificationReceived', notification => {
                console.log(`Push notification received: ${notification}`);
            });

            await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
                console.log(`Push notification action performed: ${notification.actionId}, ${notification.inputValue}`);
            });
        }

        const registerNotifications = async () => {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                console.log('(push notification) User denied permissions!');
            }

            await PushNotifications.register();
        }

        const getDeliveredNotifications = async () => {
            const notificationList = await PushNotifications.getDeliveredNotifications();
            console.log(`delivered notifications: ${notificationList}`);
        }

        await addListeners();
        await registerNotifications();
        await getDeliveredNotifications();

        FCM.subscribeTo({ topic: eventId })
            .then(() => { console.log(`subscribed to topic (eventId): ${eventId}`) })
            .catch((error) => { console.log(error); })
    }
}
