import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { Filesystem, Directory, DownloadFileResult } from '@capacitor/filesystem';
import { FileOpener } from "@capacitor-community/file-opener";


// interface FileServiceDownloadOptions {
//     name?: string;
//     directory?: string;
// }

@Injectable({
    providedIn: 'root'
})
export class FileService {

    fileFormats: string[] = [
        "pdf", "docx", "doc", "docm", "txt", "rtf", "csv", "odt", "pptx", "ppt", "ppsx",
        "pps", "ppsm", "odp", "xlsx", "xls", "xlsm", "xlt", "xlsb", "xltx", "xltm", "ods",
        "jpg", "jpeg", "png", "gif", "bmp"
    ];

    constructor(
        private platform: Platform,
    ) { }

    /**
     * @param {string} filePath
     * @param {string} fileType file extension
     * @description Open a file using the capacitor-community/file-opener plugin
     */
    openFile(filePath: string, fileType?: string): Promise<void> {
        return FileOpener.open({
            filePath: filePath,
            contentType: fileType ? this.getMIMEtype(fileType) : null
        });
    }

    /**
     * @param dirPath
     * @description Create a subdirectory inside Documents directory of the device. Is recursive, therefore,
     * if nested folders are provided in dirPath param, all of the directorys in the path will be created.
     * @returns 
     */
    createDir(dirPath: string): Promise<void> {
        return Filesystem.mkdir({
            path: dirPath,
            directory: Directory.Documents,
            recursive: true
        });
    }

    /**
     * @description Download a file using the capacitor-community/http plugin and store it within the directory
     * provided in filePath param and in the case that no argument is provided to filePath, the file is downloaded
     * directly to Documents directory
     */
    downloadFile(fileName: string, fileType: string, url: string, filePath: string = "", directory: Directory = null): Promise<DownloadFileResult> {
        if (fileName === "") { fileName = uuidv4(); }
        const options = {
            url: url,
            path: (fileName.includes(".")) ? `${filePath}/${fileName}` : `${filePath}/${fileName}.${fileType}`,
            directory: directory == null ? Directory.Cache : directory,
            recursive: true
        };
        return Filesystem.downloadFile(options);
    }

    /**
     * Get mime type of a file
     * @param extn
     */
    getMIMEtype(extn): string {
        let ext = extn.toLowerCase();
        let MIMETypes = {
            // texts
            txt: "text/plain",
            rtf: "application/rtf",
            csv: "text/csv",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            doc: "application/msword",
            docm: "application/vnd.ms-word.document.macroEnabled.12",
            odt: "application/vnd.oasis.opendocument.text",
            // pdf
            pdf: "application/pdf",
            // images
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            bmp: "image/bmp",
            png: "image/png",
            gif: "image/gif",
            // spreadsheets
            xls: "application/vnd.ms-excel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
            xlt: "application/vnd.ms-excel",
            xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
            xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
            xltm: "application/vnd.ms-excel.template.macroEnabled.12",
            ods: "application/vnd.oasis.opendocument.spreadsheet",
            // presentations (ppt)
            ppt: "application/vnd.ms-powerpoint",
            pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
            pps: "application/vnd.ms-powerpoint",
            ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
            odp: "application/vnd.oasis.opendocument.presentation"
        };
        return MIMETypes[ext];
    }
}
