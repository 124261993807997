import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable()

export class PathApi {
    // BASE URL FROM ENVIRONMENT IN USE
    static baseUrl = environment.platform.apiBaseUrl;

    // authentication
    static authDeleteUser = 'authDeleteUser';
    static authResetPass = 'authResetPass';
    static authSupergodDefaultCreateUser = 'authSupergodDefaultCreateUser';
    static authChangeUserType = 'authChangeUserType';
    static authVerifyEmailExists = 'authVerifyEmailExists';
    static authClaimsUsers = 'authClaimsUsers';
    static authSetUserClaims = 'authSetUserClaims';
    static emailsSendCodeToUser = 'emailsSendCodeToUser'; 

    // database
    static dbScheduleDatesOfTheSessions = 'dbScheduleDatesOfTheSessions'
    static dbScheduleFilterSessionsByName = 'dbScheduleFilterSessionsByName'
    static dbPersonalScheduleDatesOfTheSessions = 'dbPersonalScheduleDatesOfTheSessions'
    static dbPersonalScheduleFilterSessionsByName = 'dbPersonalScheduleFilterSessionsByName'
    static dbGetUserByEmail = 'dbUserGetByEmail';
    static dbCreateUser = 'dbUserCreateUser';
    static dbCreateUserSpeaker = 'dbUserCreateUserSpeaker';
    static dbUserSendCodeNumberEmail = 'dbUserSendCodeNumber';
    static dbUserVerifyCodeNumber = 'dbUserVerifyCodeNumber';
    static dbEventGetAllEvents = 'dbEventGetAllEvents';
    static dbEventGetClientEvents = 'dbEventGetClientEvents?clientId=';
    static dbEventGetEvent = 'dbEventGetEvent?eventId=';
    // static dbAttendeegetAttendeeCheckinStatus = 'dbAttendeesCheckinStatus';
    static dbScheduleGetAllSessions = 'dbScheduleGetAllSessions';
}
