import { Injectable } from '@angular/core';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { StorageService } from '../storage/storage.service';
import { CeuSpeaker } from 'src/app/models/ceu-speakers';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { Firestore, QueryConstraint, collection, collectionData, doc, docData, limit, orderBy, query, startAfter, updateDoc, writeBatch } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class DaoSpeakersService {
    refGetModule = null

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private storage: StorageService
    ) { }

    // get the id module passed in the parameter.
    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        this.refGetModule = docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((module) => {
            onResolve(module);
        })
    }

    // close get module
    closeRefGetModule() {
        if (this.refGetModule)
            this.refGetModule.unsubscribe()
    }

    getSpeakerByEvent(eventId: string, speakerId: string) {
        const ref = doc(this.firestore, `events/${eventId}/speakers/${speakerId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    getCustomFields(eventId: string, speakerId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/speakers/${speakerId}/customFields`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    getCustomFieldOptions(moduleId: string, customId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/customFields/${customId}/options`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    getFieldOptionsCustom(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        firstValueFrom(docData(ref)).then((doc) => {
            onResolve(doc['fieldsCustom']);
        });
    }

    updateSpeaker(eventId: string, moduleId: string, speaker, customFields, file: any, onResolve) {
        const batch = writeBatch(this.firestore);
        const refSpeakerEvent = doc(this.firestore, `events/${eventId}/speakers/${speaker.uid}`);
        const refSpeakerModule = doc(this.firestore, `modules/${moduleId}/speakers/${speaker.uid}`);

        batch.update(refSpeakerEvent, Object.assign({}, speaker));
        batch.update(refSpeakerModule, Object.assign({}, speaker));

        for (let custom of customFields) {
            const refCustomEvent = doc(this.firestore, `events/${eventId}/speakers/${speaker.uid}/customFields/${custom.uid}`);
            const refCustomModule = doc(this.firestore, `modules/${moduleId}/speakers/${speaker.uid}/customFields/${custom.uid}`);

            batch.update(refCustomEvent, { value: custom.value, textValue: custom.textValue });
            batch.update(refCustomModule, { value: custom.value, textValue: custom.textValue });
        }

        batch.commit()
            .then((data) => {
                if (file !== null && file !== undefined && file !== '') {
                    this.storage.uploadSpeakerProfile(eventId, speaker.uid, file, (url) => {
                        Promise.all([
                            updateDoc(refSpeakerEvent, { photoUrl: url }),
                            updateDoc(refSpeakerModule, { photoUrl: url })
                        ]).then(() => {
                                let result = {
                                    code: 200,
                                    message: 'Dados alterados com sucesso',
                                    result: data
                                }

                                onResolve(result)
                            })
                            .catch((error) => {
                                let result = {
                                    code: 500,
                                    message: 'Erro ao alterar foto de perfil',
                                    result: data
                                }

                                onResolve(result)
                            })
                    })
                } else {
                    let result = {
                        code: 200,
                        message: 'Dados alterados com sucesso',
                        result: data
                    }

                    onResolve(result)
                }

            })
            .catch((error) => {
                let result = {
                    code: 500,
                    message: 'Erro ao alterar dados',
                    result: error
                }

                onResolve(result)
            })

    }

    checkeditProfileActive(moduleId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        firstValueFrom(docData(ref)).then((doc) => {
            if (doc.allowedEditProfile) {
                onResolve(true);
            } else {
                onResolve(false);
            }
        })
    }

    getOrder(moduleId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        firstValueFrom(docData(ref)).then((doc) => {
            onResolve(doc.orderUsers);
        });
    }

    // getFirstSpeakersPageByModule(moduleId: string, typeOrder: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     let firstPage;

    //     switch (typeOrder) {
    //         case 'asc': //a-z
    //             firstPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('name', 'asc')
    //                 .limit(100)
    //             break;

    //         case 'desc': //z-a
    //             firstPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('name', 'desc')
    //                 .limit(100)
    //             break;

    //         case 'oldest'://antiho-recente
    //             firstPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('createdAt', 'asc')
    //                 .limit(100)
    //             break;

    //         case 'recent': //recente-antigo
    //             firstPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('createdAt', 'desc')
    //                 .limit(100)
    //             break;

    //         case 'id': //by identifier
    //             firstPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('identifier', 'desc')
    //                 .limit(100)
    //             break;
    //     }

    //     firstPage
    //         .onSnapshot((snapshot) => {
    //             if (snapshot.size >= 1) {
    //                 let nextPage = snapshot.docs[snapshot.docs.length - 1];
    //                 let auxArray = [];

    //                 snapshot.forEach(element => {
    //                     auxArray.push(element.data());
    //                 });
    //                 onResolve({
    //                     speakers: auxArray,
    //                     next: nextPage.data()
    //                 });
    //             } else {
    //                 onResolve({
    //                     speakers: [],
    //                     next: null
    //                 });
    //             }
    //         })
    // }


    // getNextSpeakersPageByModule(moduleId: string, nextPage, typeOrder: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     let lastPage;

    //     switch (typeOrder) {
    //         case 'asc': //a-z
    //             lastPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('name', 'asc')
    //                 .startAfter(nextPage['name'])
    //                 .limit(100);
    //             break;

    //         case 'desc': //z-a
    //             lastPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('name', 'desc')
    //                 .startAfter(nextPage['name'])
    //                 .limit(100);
    //             break;

    //         case 'oldest'://antiho-recente
    //             lastPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('createdAt', 'asc')
    //                 .startAfter(nextPage['createdAt'])
    //                 .limit(100);
    //             break;

    //         case 'recent': //recente-antigo
    //             lastPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('createdAt', 'desc')
    //                 .startAfter(nextPage['createdAt'])
    //                 .limit(100);
    //             break;

    //         case 'id': //by identifier
    //             lastPage = db.collection('modules')
    //                 .doc(moduleId)
    //                 .collection('speakers')
    //                 .orderBy('identifier', 'desc')
    //                 .startAfter(nextPage['identifier'])
    //                 .limit(100);
    //             break;
    //     }
    //     lastPage
    //         .onSnapshot((snapshot) => {
    //             let morePage = snapshot.docs[snapshot.docs.length - 1];
    //             let auxArray = [];
    //             if (snapshot.size == 0) {
    //                 onResolve(false);
    //             } else {
    //                 snapshot.forEach(element => {
    //                     auxArray.push(element.data());
    //                 });
    //                 onResolve({
    //                     speakers: auxArray,
    //                     next: morePage.data().name
    //                 });
    //             }
    //         })
    // }

    getFieldOptions(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        firstValueFrom(docData(ref)).then((doc) => {
            onResolve(doc['fields']);
        });
    }

    // searchSpeakersByName(moduleId: string, name: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     name = this.convertLowerCaseUpperCase(name);
    //     let speakers = [];
    //     db
    //         .collection('modules')
    //         .doc(moduleId)
    //         .collection('speakers')
    //         .orderBy('name')
    //         .startAt(name)
    //         .endAt(name + '\uf8ff')
    //         .onSnapshot((values) => {
    //             values.forEach(el => {
    //                 speakers.push(el.data());
    //             });
    //             onResolve(speakers);
    //         });
    // }

    convertLowerCaseUpperCase(name) {
        var words = name.trim().toLowerCase().split(" ");
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
            words[a] = w[0].toUpperCase() + w.slice(1);
        }
        words.join(" ");
        return words[0];
    }

    // lists the speaker's sessions.
    getSessionsOfSpeaker(speakerId: string, eventId: string) {
        const speakers = `speakers.${speakerId}`;
        const ref = collection(this.firestore, `events/${eventId}/sessions`);
        const refQ = query(ref, orderBy(speakers));

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((sessions) => {
                sessions.sort((a, b) => {
                    return a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0;
                });
                return (of(sessions));
            })
        );
    }

    /******************************************************************************** methods of global vision *************************************************************************************** */
    // loads the first 100 speakers of the module. global vision
    getFirstASpeakersGlobalVision(typeOrder, moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        let qc: QueryConstraint[];

        // checks the order that speakers are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), limit(100)];
                break;
            case 'id': //by identifier
                qc = [orderBy('identifier'), limit(100)];
                break;
            default:
                return;
        }
        
        const refQ = query(ref, ...qc);
        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }


    // get all speakers of the module
    getSpeakersAllGlobalVision(moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // get plus 100 speakers module.
    getNextPageSpeakersGlobalVision(moduleId, lastSpeaker, typeOrder, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), startAfter(lastSpeaker.queryName), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), startAfter(lastSpeaker.queryName), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), startAfter(lastSpeaker.createdAt), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), startAfter(lastSpeaker.createdAt), limit(100)];
                break;
            case 'id': //by identifier
                qc = [orderBy('identifier'), startAfter(lastSpeaker.identifier), limit(100)];
                break;
        }

        const refQ = query(ref, ...qc);
        collectionData(refQ).subscribe((data) => {
            onResolve(data)
        });
    }

    /******************************************************************************** methods of divided by groups *************************************************************************************** */
    // loads the group speakers.
    getSpeakersGroupGlobalVision(groupId: string, typeOrder: string, moduleId: string, onResolve) {
        const group = `groups.${groupId}`
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        const refQ = query(ref, orderBy(group));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((data) => {
                let ref = null

                // checks the order that speakers are to be loaded.
                switch (typeOrder) {
                    case 'asc': //a-z
                        data.sort(function (a, b) {
                            return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
                        });
                        break;

                    case 'desc': //z-a
                        data.sort(function (a, b) {
                            return a.queryName > b.queryName ? -1 : a.queryName < b.queryName ? 1 : 0;
                        });
                        break;

                    case 'oldest'://antiho-recente
                        data.sort(function (a, b) {
                            return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
                        });
                        break;

                    case 'recent': //recente-antigo
                        data.sort(function (a, b) {
                            return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                        });
                        break;

                    case 'id': //by identifier
                        data.sort(function (a, b) {
                            return a.identifier > b.identifier ? -1 : a.identifier < b.identifier ? 1 : 0;
                        });
                        break;
                }

                onResolve(data)
            })
    }

    /******************************************************************************** groups vision *************************************************************************************** */
    // loads the speaker group speakers.
    getSpeakersGroupsVision(groups: Array<any>, typeOrder: string, moduleId: string, onResolve) {
        if (groups.length > 0) {
            const total = groups.length
            let i = 0
            const list = []
            const ref = collection(this.firestore, `modules/${moduleId}/speakers`);


            // get the speakers of each group
            for (const group of groups) {
                const groupId = `groups.${group.uid}`
                const refQ = query(ref, orderBy(groupId));

                collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                    .subscribe((snapshot) => {
                        i++

                        // withdraws replay of speakers
                        snapshot.forEach((speaker) => {
                            const pos = list.map(function (e) { return e.uid; }).indexOf(speaker.uid);
                            if (pos === -1) { list.push(speaker) }
                        })

                        // if all groups have already been processed.
                        if (i >= total) {
                            if (list.length <= 0) { onResolve([]) }


                            // checks the order that participants are to be loaded.
                            switch (typeOrder) {
                                case 'asc': //a-z
                                    list.sort(function (a, b) {
                                        return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
                                    });
                                    break;

                                case 'desc': //z-a
                                    list.sort(function (a, b) {
                                        return a.queryName > b.queryName ? -1 : a.queryName < b.queryName ? 1 : 0;
                                    });
                                    break;

                                case 'oldest'://antiho-recente
                                    list.sort(function (a, b) {
                                        return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
                                    });
                                    break;

                                case 'recent': //recente-antigo
                                    list.sort(function (a, b) {
                                        return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                                    });
                                    break;

                                case 'id': //by identifier
                                    list.sort(function (a, b) {
                                        return a.identifier > b.identifier ? -1 : a.identifier < b.identifier ? 1 : 0;
                                    });
                                    break;
                            }

                            onResolve(list)
                        }
                    })
            }
        } else {
            onResolve([])
        }
    }

    /******************************************************************************** methods of limited access by groups *************************************************************************************** */
    // loads the first 100 speakers of the module. global vision
    getFirstASpeakersLimitedAccessByGroups(typeOrder, moduleId, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        let qc: QueryConstraint[];

        // checks the order that speakers are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), limit(100)];
                break;
            case 'id': //by identifier
                qc = [orderBy('identifier'), limit(100)];
                break;
            default:
                return;
        }
        
        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data) => {
            onResolve(data)
        });
    }

    // get all speakers of the module
    getSpeakersAllLimitedAccessByGroup(moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // get plus 100 speakers module.
    getNextPageSpeakersLimitedAccessByGroup(moduleId, lastSpeaker, typeOrder, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/speakers`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), startAfter(lastSpeaker.queryName), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), startAfter(lastSpeaker.queryName), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), startAfter(lastSpeaker.createdAt), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), startAfter(lastSpeaker.createdAt), limit(100)];
                break;
            case 'id': //by identifier
                qc = [orderBy('identifier'), startAfter(lastSpeaker.identifier), limit(100)];
                break;
            default:
                return;
        }
        
        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data) => {
            onResolve(data);
        });
    }

}
