import { UserActionsTypes, UserActionsUnion } from '../actions/user.actions';
import { IUserState } from '../interfaces/user.interfaces';

export const initialState: IUserState = {
    userData: null
}

export function userReducer(state = initialState, action: UserActionsUnion): IUserState {
    switch (action.type) {
        case UserActionsTypes.GetUser: {
            return ({
                ...state,
                userData: action.payload
            })
        }

        default: {
            return (state);
        }
    }
}