import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { GroupDiscussionsService } from 'src/app/shared/services/group-discussions/group-discussions.service';
import { Observable, Subscription } from 'rxjs';
import { GroupDiscussion } from 'src/app/models/group-discussion';
import { DocumentData } from '@angular/fire/firestore';

@Component({
    selector: 'app-chat-settings-popup',
    templateUrl: './chat-settings-popup.component.html',
    styleUrls: ['./chat-settings-popup.component.scss']
})
export class ChatSettingsPopupComponent implements OnInit {
    eventId: string;
    moduleId: string;
    groupId: string;
    userId: string;
    uidSub: Subscription;
    url: string;
    groupDiscussion$: Observable<DocumentData>;

    constructor(
        private popoverController: PopoverController,
        private gdService: GroupDiscussionsService,
        private navParams: NavParams
    ) {
        const params = this.navParams.get('params');

        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        this.groupId = params.groupId;
        this.url = `event/${this.eventId}/list-group-discussions/${this.moduleId}/group-chat/${this.groupId}/detail`;
    }

    async onNotificationToggle(evt) {
        try {
            const uid = await this.gdService.getUserId();

            await this.gdService.updateMutedParticipants(this.groupId, uid);
        } catch (e) {
            console.log(e);
        }
    }

    ngOnInit() {
        this.uidSub = this.gdService
            .userId()
            .subscribe((uid) => (this.userId = uid));
        console.log(this.groupId);
        this.groupDiscussion$ = this.gdService.groupDiscussion(this.groupId);
    }

    ngOnDestroy() {
        this.uidSub.unsubscribe();
    }

    isMuted(mutedParticipants: string[], uid: string) {
        return (mutedParticipants || []).includes(uid);
    }

    onDismiss() {
        this.popoverController.dismiss();
    }
}
