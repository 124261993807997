import { Action } from '@ngrx/store';

export enum SchedulesActionsTypes {
    GetScheduleSessions = "[Schedules Service] Get schedule sessions"
}

export class GetScheduleSessions implements Action {
    readonly type = SchedulesActionsTypes.GetScheduleSessions;

    constructor(public payload: any[]) { }
}

export type SchedulesActionsUnion = GetScheduleSessions;