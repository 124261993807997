import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Subscription, firstValueFrom, of } from 'rxjs';
import { getUserData } from '../../selectors/user.selectors';
import { switchMap, map, take } from 'rxjs/operators';
import { TypeTracking, TypeNameTracking } from 'src/app/enums/type-tracking';
import * as moment from 'moment';
import { TypeModule } from 'src/app/enums/type-module';
import { ChatMessage } from 'src/app/models/chat-message';
import { Post } from 'src/app/models/ceu-post';
import { Platform } from '@ionic/angular';
import { Firestore, addDoc, collection, collectionData, doc, docData, updateDoc } from '@angular/fire/firestore';
import { UserDataService } from '../user-data/user-data.service';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { TypeUser } from 'src/app/models/type-user';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventDataService } from '../eventData/event-data.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    subscriptions: Subscription[] = [];

    constructor(
        private firestore: Firestore,
        private store: Store<AppState>,
        private platform: Platform,
        private userData: UserDataService,
        private daoGeneral: DaoGeralService,
        private dt: DateTimeService,
        private eventData: EventDataService
    ) {
        this.store.select(getUserData).subscribe((userData) => {
            // this.userData = userData;

            let platform = "";
            if (this.platform.is('ios')) {
                platform = "ios";
            } else if (this.platform.is('android')) {
                platform = "android";
            } else {
                platform = "web";
            }
            // Check platform
            // this.updateAnalyticsPlatforms(this.eventData.eventId, platform);
        })
    }

    /*********************************************************/
    /******************* Global analytics ********************/
    /*********************************************************/

    /**
     * Track leaving application
     */
    goOutOfApp(eventId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            this.updateConnectedStatusUser(eventId, false);
            const ref = collection(this.firestore, `analytics/${this.eventData.eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.GO_OUT_OF_APP,
                    typeName: TypeNameTracking[TypeTracking.GO_OUT_OF_APP],
                    idOfTrack: null,
                    nameOfTrack: null,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    /**
     * Update connected status for user
     * @param status 
     */
    updateConnectedStatusUser(eventId: string, status: boolean) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(
                this.firestore,
                `events/${this.eventData.eventId}/${(userData.type == 4) ? "speakers" : "attendees"}/${userData.uid}`
            );
            updateDoc(ref, { connected: status });
        });
    }

    /**
     * Track going back on application
     */
    goBackOnApp(eventId: string) {
        if (!eventId) { return; }
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            this.updateConnectedStatusUser(eventId, true);
            const ref = collection(this.firestore, `analytics/${this.eventData.eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.GO_BACK_ON_APP,
                    typeName: TypeNameTracking[TypeTracking.GO_BACK_ON_APP],
                    idOfTrack: null,
                    nameOfTrack: null,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    updateAnalyticsPlatforms(eventId: string, platform: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `events/${eventId}/attendees/${userData.uid}`);
            if (!userData.platforms) {
                let newPlatforms = {
                    "android": false,
                    "ios": false,
                    "web": false
                }

                newPlatforms[platform] = true;
                updateDoc(ref, { platforms: newPlatforms });
            } else {
                docData(ref).pipe(
                    take(1),
                    switchMap((doc) => {
                        if (doc) {
                            let oldPlatforms = doc.platforms;
                            if (!oldPlatforms[platform]) {
                                oldPlatforms[platform] = true;
                                updateDoc(ref, { platforms: oldPlatforms })
                            }
                        }
                        return (of(true));
                    })
                )
            }
        });
    }

    /*********************************************************/
    /******************* Events analytics ********************/
    /*********************************************************/

    /**
     * Track access to event
     * @param eventId 
     */
    moduleAccessEvent(eventId: string) {
        if (eventId && this.userData) {
            const ref = collection(this.firestore, `events/${eventId}/modules`);
            firstValueFrom(collectionData(ref).pipe(
                switchMap((modules) => {
                    modules.forEach((module) => {
                        if (module.type == TypeModule.EVENT) {
                            this.moduleAccess(eventId, module.uid);
                        }
                    })
                    return (of(true));
                })
            ))
        }
    }


    /*********************************************************/
    /****************** Modules analytics ********************/
    /*********************************************************/


    /**
     * Update module total access
     * @param eventId 
     * @param moduleId 
     */
    moduleAccess(eventId: string, moduleId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `events/${eventId}/modules/${moduleId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((doc) => {
                    if (doc) {
                        let increment = doc.total_access + 1;
                        let usersAccessIds = (doc.usersAccessIds) ? doc.usersAccessIds : [];

                        if (this.userData && !usersAccessIds.includes(userData.uid)) {
                            usersAccessIds.push(userData.uid);
                            updateDoc(ref, { total_access: increment, usersAccessIds: usersAccessIds });
                        } else {
                            updateDoc(ref, { total_access: increment });
                        }

                    }
                    return (of(true));
                })
            ))
        });
    }

    /**
     * Track access to a module
     * @param eventId 
     * @param moduleId 
     * @param typeTracking 
     */
    createTrackRoadToModule(eventId: string, moduleId: string, typeTracking: number) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((module: any) => {
                    if (!module) { return (of(false)); }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: typeTracking,
                            typeName: TypeNameTracking[typeTracking],
                            idOfTrack: module.uid,
                            nameOfTrack: module.name,
                            trackAt: moment().toISOString()
                        }
                    )

                    return (of(true));
                })
            ));
        });
    }



    /*********************************************************/
    /******************* Chats analytics *********************/
    /*********************************************************/

    /**
     * Update chat history access
     * @param eventId 
     * @param moduleId 
     */
    chatHistoryAccess(eventId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.ACCESS_TO_CHATS_MODULE,
                    typeName: TypeNameTracking[TypeTracking.ACCESS_TO_CHATS_MODULE],
                    idOfTrack: null,
                    nameOfTrack: null,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    /**
     * Update chat access
     * @param eventId 
     * @param moduleId 
     */
    chatAccess(eventId: string, chatId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.ACCESS_TO_CHAT,
                    typeName: TypeNameTracking[TypeTracking.ACCESS_TO_CHAT],
                    idOfTrack: chatId,
                    nameOfTrack: null,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    /**
    * Track send message on chat or discussion group
    * @param eventId 
    * @param moduleId 
    */
    sendMessageOnChatOrDiscussionGroup(eventId: string, chatOrGroupId: string, message: ChatMessage, typeTracking: number) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: typeTracking,
                    typeName: TypeNameTracking[typeTracking],
                    idOfTrack: chatOrGroupId,
                    nameOfTrack: (message.message) ? message.message : null,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    /**
    * Track send visio message on chat or discussion group
    * @param eventId 
    * @param moduleId 
    */
    sendVisioMessageOnChatOrDiscussionGroup(eventId: string, chatOrGroupId: string, typeTracking: number) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: typeTracking,
                    typeName: TypeNameTracking[typeTracking],
                    idOfTrack: chatOrGroupId,
                    nameOfTrack: (typeTracking == TypeTracking.SEND_VISIO_MESSAGE_ON_CHAT) ? "Visio created on chat" : "Visio created on group",
                    trackAt: moment().toISOString()
                }
            );
        });
    }



    /*********************************************************/
    /************** Discussion groups analytics **************/
    /*********************************************************/

    /**
     * Update group access
     * @param eventId 
     * @param groupId
     */
    groupAccess(eventId: string, groupId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `groupDiscussions/${groupId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((module: any) => {
                    if (!module) {
                        return (of(false));
                    }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_DISCUSSION_GROUP,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_DISCUSSION_GROUP],
                            idOfTrack: groupId,
                            nameOfTrack: module.title,
                            trackAt: moment().toISOString()
                        }
                    );
                    return (of(true));
                })
            ));
        });
    }

    /*********************************************************/
    /******************* Users analytics *********************/
    /*********************************************************/

    /**
     * Track user access
     * @param eventId 
     */
    userAccess(eventId: string, userId: string) {
        // Create analytics user access
        if (eventId && userId) {
            const ref = collection(this.firestore, `analytics/${eventId}/total-user-access`);
            addDoc(
                ref,
                {
                    userId: userId,
                    accessAt: moment().toISOString()
                }
            );
        }
    }

    /**
     * Track access to profile
     * @param eventId 
     * @param profileId 
     * @param typeUser
     * @param typeTracking 
     */
    accessToProfile(eventId: string, profileId: string, typeUser: number, typeTracking: number) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `events/${eventId}/${(typeUser == 4) ? 'speakers' : 'attendees'}/${profileId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((profileModule) => {
                    if (!profileModule) { return (of(false)); }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: typeTracking,
                            typeName: TypeNameTracking[typeTracking],
                            idOfTrack: profileId,
                            nameOfTrack: profileModule.name,
                            trackAt: moment().toISOString()
                        }
                    );
                    return (of(true));
                })
            ));
        });
    }


    /*********************************************************/
    /***************** Documents analytics *******************/
    /*********************************************************/

    /**
     * Track access to documents folder
     * @param eventId 
     * @param docModuleId
     * @param folderId
     */
    goToDocumentsFolder(eventId: string, docModuleId: string, folderId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${docModuleId}/folders/${folderId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((folder) => {
                    if (!folder) { return (of(false)); }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER],
                            idOfTrack: folder.uid,
                            nameOfTrack: folder.name,
                            trackAt: moment().toISOString()
                        }
                    );
                    return (of(true));
                })
            ))
        });
    }

    /**
     * Update document total access and create tracking
     * @param eventId 
     * @param docId 
     */
    documentAccess(eventId: string, document: any) {
        if (!document) { return; }

        const ref = doc(this.firestore, `events/${eventId}/documents/${document.id}`);
        firstValueFrom(docData(ref).pipe(
            take(1),
            switchMap((doc) => {
                if (doc.exists) {
                    let increment = doc.total_access + 1;
                    updateDoc(ref, { total_access: increment });
                }
                return (of(true));
            })
        ));
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref1,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.OPEN_DOCUMENT,
                    typeName: TypeNameTracking[TypeTracking.OPEN_DOCUMENT],
                    idOfTrack: document.uid,
                    nameOfTrack: document.name,
                    trackAt: moment().toISOString()
                }
            );
        });
    }




    /*********************************************************/
    /******************* Gallery analytics *******************/
    /*********************************************************/

    /**
     * Track access to images folder
     * @param eventId 
     * @param imageModuleId
     * @param folderId
     */
    goToImagesFolder(eventId: string, imageModuleId: string, folderId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${imageModuleId}/folders/${folderId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((folder) => {
                    if (!folder) { return (of(false)); }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_IMAGES_FOLDER,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_IMAGES_FOLDER],
                            idOfTrack: folder.uid,
                            nameOfTrack: folder.name,
                            trackAt: moment().toISOString()
                        }
                    );
                    return (of(true));
                })
            ));
        });
    }

    /**
    * Update image total access and create tracking
    * @param eventId 
    * @param imageId 
    */
    imageAccess(eventId: string, image: any) {
        if (!image) { return; }

        const ref = doc(this.firestore, `events/${eventId}/gallery-images/${image.uid}`);
        firstValueFrom(docData(ref).pipe(
            take(1),
            switchMap((doc) => {
                if (doc) {
                    let increment = doc.total_access + 1;
                    updateDoc(ref, { total_access: increment });
                }
                return (of(true));
            })
        ));

        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref1,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: TypeTracking.OPEN_DOCUMENT,
                    typeName: TypeNameTracking[TypeTracking.OPEN_DOCUMENT],
                    idOfTrack: image.uid,
                    nameOfTrack: image.name,
                    trackAt: moment().toISOString()
                }
            );
        });
    }

    /*********************************************************/
    /**************** Ask question analytics *****************/
    /*********************************************************/

    /**
     * Track access to ask question item or session
     * @param eventId 
     * @param moduleId 
     * @param askQuestionType 
     * @param askQuestionId 
     */
    accessToAskQuestion(eventId: string, moduleId: string, askQuestionType: string, askQuestionId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/${(askQuestionType == 'session') ? 'sessions' : 'items'}/${askQuestionId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((item) => {
                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_ASK_QUESTION,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_ASK_QUESTION],
                            idOfTrack: item.uid,
                            nameOfTrack: item.name,
                            trackAt: moment().toISOString()
                        }
                    );
                    return (of(true));
                })
            ))
        });
    }

    /**
     * Track question sent on item or session
     * @param eventId 
     * @param moduleId 
     * @param askQuestionType 
     * @param askQuestionId 
     */
    sendQuestion(eventId: string, moduleId: string, askQuestionType: string, askQuestionId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/${(askQuestionType == 'session') ? 'sessions' : 'items'}/${askQuestionId}`);
            firstValueFrom(docData(ref).pipe(
                switchMap((item) => {
                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.SEND_QUESTION,
                            typeName: TypeNameTracking[TypeTracking.SEND_QUESTION],
                            idOfTrack: item.uid,
                            nameOfTrack: item.name,
                            trackAt: moment().toISOString()
                        }
                    )
                    return (of(true));
                })
            ));
        });
    }

    /*********************************************************/
    /******************* Surveys analytics *******************/
    /*********************************************************/

    /**
         * Track access to survey
         * @param eventId 
         * @param moduleId 
         * @param surveyId
         */
    accessToSurvey(eventId: string, moduleId: string, surveyId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/surveys/${surveyId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((survey) => {
                    if (survey) {
                        const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                        addDoc(
                            ref1,
                            {
                                userId: (userData.uid) ? userData.uid : null,
                                userIdentifier: (userData.identifier) ? userData.identifier : null,
                                userEmail: (userData.email) ? userData.email : null,
                                userName: (userData.name) ? userData.name : null,
                                type: TypeTracking.ACCESS_TO_SURVEY,
                                typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SURVEY],
                                idOfTrack: survey.uid,
                                nameOfTrack: survey.name,
                                trackAt: moment().toISOString()
                            }
                        )
                    }
                    return (of(true));
                })
            ))
        });
    }

    /**
     * Track send survey
     * @param eventId 
     * @param moduleId 
     * @param suveyId
     */
    sendSurvey(eventId: string, moduleId: string, surveyId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/surveys/${surveyId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((survey) => {
                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.SEND_SURVEY,
                            typeName: TypeNameTracking[TypeTracking.SEND_SURVEY],
                            idOfTrack: (survey && survey.uid) ? survey.uid : null,
                            nameOfTrack: (survey && survey.name) ? survey.name : null,
                            trackAt: moment().toISOString()
                        }
                    )
                    return (of(true));
                })
            ))
        });
    }

    /*********************************************************/
    /********************* Quizs analytics *******************/
    /*********************************************************/

    /**
         * Track access to quiz
         * @param eventId 
         * @param moduleId 
         * @param quizId
         */
    accessToQuiz(eventId: string, moduleId: string, quizId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/quizs/${quizId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((quiz) => {
                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_QUIZ,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_QUIZ],
                            idOfTrack: quiz.uid,
                            nameOfTrack: quiz.name,
                            trackAt: moment().toISOString()
                        }
                    )
                    return (of(true));
                })
            ))
        })
    }

    /**
     * Track send quiz
     * @param eventId 
     * @param moduleId 
     * @param quizId
     */
    sendQuiz(eventId: string, moduleId: string, quizId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `modules/${moduleId}/quizs/${quizId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((quiz) => {
                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.SEND_QUIZ,
                            typeName: TypeNameTracking[TypeTracking.SEND_QUIZ],
                            idOfTrack: quiz.uid,
                            nameOfTrack: quiz.name,
                            trackAt: moment().toISOString()
                        }
                    )
                    return (of(true));
                })
            ))
        });
    }

    /*********************************************************/
    /******************** Feeds analytics ********************/
    /*********************************************************/

    /**
     * Track send or remove like on feed
     * @param eventId 
     * @param feed 
     * @param type 
     * @param typeTracking 
     */
    sendOrRemoveLikeOnFeed(eventId: string, post: Post, type: string, typeTracking: number) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: typeTracking,
                    typeName: TypeNameTracking[typeTracking],
                    idOfTrack: post.uid,
                    nameOfTrack: null,
                    trackAt: moment().toISOString()
                }
            )
        });
    }

    /**
     * Track send comment on feed
     * @param eventId 
     * @param comment 
     */
    sendOrDeleteCommentOnFeed(eventId: string, comment: any, typeTracking: number) {
        if (!comment) { return; }

        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
            addDoc(
                ref,
                {
                    userId: (userData.uid) ? userData.uid : null,
                    userIdentifier: (userData.identifier) ? userData.identifier : null,
                    userEmail: (userData.email) ? userData.email : null,
                    userName: (userData.name) ? userData.name : null,
                    type: typeTracking,
                    typeName: TypeNameTracking[typeTracking],
                    idOfTrack: comment.id,
                    nameOfTrack: comment.comment,
                    trackAt: moment().toISOString()
                }
            )
        });
    }

    /*********************************************************/
    /******************* Schedules analytics *****************/
    /*********************************************************/

    /**
     * Track access to schedule session
     * @param eventId 
     * @param moduleId 
     * @param sessionId 
     */
    accessToSession(eventId: string, moduleId: string, sessionId: string) {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            const ref = doc(this.firestore, `events/${eventId}/sessions/${sessionId}`);
            firstValueFrom(docData(ref).pipe(
                take(1),
                switchMap((doc) => {
                    let usersAccessIds = (doc.usersAccessIds) ? doc.usersAccessIds : [];

                    if (this.userData && !usersAccessIds.includes(userData.uid)) {
                        usersAccessIds.push(userData.uid);

                        updateDoc(
                            ref,
                            { total_access: ((doc.total_access) ? doc.total_access + 1 : 1), usersAccessIds: usersAccessIds }
                        )
                    } else {
                        updateDoc(
                            ref,
                            { total_access: ((doc.total_access) ? doc.total_access + 1 : 1) }
                        )
                    }

                    const ref1 = collection(this.firestore, `analytics/${eventId}/tracking-road-user`);
                    addDoc(
                        ref1,
                        {
                            userId: (userData.uid) ? userData.uid : null,
                            userIdentifier: (userData.identifier) ? userData.identifier : null,
                            userEmail: (userData.email) ? userData.email : null,
                            userName: (userData.name) ? userData.name : null,
                            type: TypeTracking.ACCESS_TO_SCHEDULE_SESSION,
                            typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SCHEDULE_SESSION],
                            idOfTrack: doc.uid,
                            nameOfTrack: doc.name,
                            trackAt: moment().toISOString()
                        }
                    )
                    return (of(true));
                })
            )) 
        });
    }

    /**
     * @description updates the access count of an user in to a event 
     * @param eventId 
     */
    public updateUserAccessCount(eventId: string): void {
        this.userData.getUserDataSnapshot(eventId).then((userData) => {
            if (
                sessionStorage.getItem('access_count_updated') || 
                userData.type < TypeUser.SPEAKER    // the user inst a speaker or attendee
            ) { return; }

            const totalAccess = (!userData.total_access) ? 0 : userData.total_access;
            const lastAccess = this.dt.dbTime();
            sessionStorage.setItem('access_count_updated', lastAccess);

            this.userAccess(eventId, userData.uid);
            this.daoGeneral.updateUserGeneral({
                    total_access: totalAccess + 1,
                    last_access: lastAccess
                },
                userData.uid,
                eventId,
                userData.type
            );
        });
    }
}
