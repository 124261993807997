import { Injectable } from '@angular/core';
import { DocumentData, Firestore, QueryConstraint, collection, collectionData, doc, docData, orderBy, query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoDocumentsService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    /**
    * get module. 
    * @param moduleId 
    */
    getModule(moduleId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
    * Get folder. 
    * @param moduleId 
    * @param folderId 
    */
    getFolder(moduleId: string, folderId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `modules/${moduleId}/folders/${folderId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * Getting all folders of document module
     * @param moduleId 
     * @param order 
     */
    getFolders(moduleId: string, order: any) {
        const ref = collection(this.firestore, `modules/${moduleId}/folders`);
        const qc: QueryConstraint = (order == "custom") ? orderBy('order') : orderBy('createdAt', order);
        const refQ = query(ref, qc);

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
    * get documents. 
    * @param moduleId 
    * @param folderId 
    * @param orderDocuments
    * @param orderName 
    * @returns onResolve
    */
    getDocuments(moduleId: string, folderId: string, orderDocuments: string, orderName: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/folders/${folderId}/documents`);
        let qc: QueryConstraint;

        switch (orderDocuments) {
            case 'asc':
                qc = orderBy(orderName, 'asc');
                break
            case 'desc':
                qc = orderBy(orderName, 'desc');
                break
            case 'oldest':
                qc = orderBy('createdAt', 'asc');
                break
            default: // recent'
                qc = orderBy('createdAt', 'desc');
                break
        }

        const refQ = query(ref, qc);
        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}