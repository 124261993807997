import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MenuController, LoadingController, ModalController, AlertController, NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../providers/authentication/auth.service';
import { TypeUser } from '../../models/type-user';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService, GlobalService, UtilityService } from 'src/app/shared/services';
import { DaoEventsService } from '../../providers/db/dao-events.service';
import { TermsOfUseComponent } from '../../components/terms-of-use/terms-of-use.component';
import { PrivacyTermsComponent } from '../../components/privacy-terms/privacy-terms.component';
import { DaoGeralService } from '../../providers/db/dao-geral.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Container } from 'src/app/models/container';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/reducers';
import { getConnectionStatus } from 'src/app/shared/selectors/utility.selectors';
import { take } from 'rxjs/operators';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-login-with-code-confirm',
    templateUrl: './login-with-code-confirm.component.html',
    styleUrls: ['./login-with-code-confirm.component.scss'],
})
export class LoginWithCodeConfirmComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    @Input() containerApp: Container;
    displayPublicEventsBtn: boolean = environment.platform.isClientApp;
    loginLogo: string = environment.platform.loginLogo;
    loginLogoSize: string = environment.platform.loginLogoClass;
    @ViewChild('passwordInput') passwordInput: ElementRef;
    insertPasswordView: boolean = false;
    normalView: boolean = true;
    firstAccessView: boolean = false;
    createPassView: boolean = false;

    msgEmail: string = null;
    msgPassword: string = null;
    msgCode: string = null;
    msgCreateAccount: string = null;
    msgEmailAlreadyUse: string = null;
    msgTermRequired: string = null;
    msgTimeRemaining: string = null;

    count: number;
    interval: any;
    seconds: number = 180;
    timeRemaining;

    email: string = null;
    password: string = null;
    userType: number;

    createPass: string = null;
    confirmPass: string = null;
    userUid: string = null;
    userTempId: string = null;
    codeNumber: string;
    codeNumberTyped: string = null;
    termCheck: boolean;

    container: any;
    clientId: string = null;
    user = null;
    eventId: string = null;
    activeLoader: boolean = false;
    activeLoader2: boolean = false;
    firstAccess: boolean = true;
    event = null;
    emailDisabled: boolean = false;
    navParams: any;

    networkStatus: string;
    showPwd = false;
    @Output() nextScreenEmit = new EventEmitter<any>();

    constructor(
        public global: GlobalService,
        private route: ActivatedRoute,
        private menu: MenuController,
        private translateService: TranslateService,
        private auth: AuthService,
        public loadingCtrl: LoadingController,
        public modalCtrl: ModalController,
        private daoGeral: DaoGeralService,
        private eventsA: Events,
        private daoEvent: DaoEventsService,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        private titleService: Title,
        private store: Store<AppState>,
        private SUtility: UtilityService,
        private SAnalytics: AnalyticsService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.menu.enable(false);
        this.eventId = this.route.snapshot.params['eventId'];
    }

    ngOnInit() {
        if (this.eventId !== null && this.eventId !== undefined && this.eventId !== '') {
            this.subscriptions.push(this.daoEvent.getEvent(this.eventId).subscribe((event) => {
                this.event = event;
                this.titleService.setTitle(this.event['title']);
            }));
        }

        // Check network status
        this.subscriptions.push(this.store.select(getConnectionStatus).subscribe((networkStatus) => {
            this.networkStatus = networkStatus;
        }))
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /* The first step of login is check e-mail in database to find 
    user (and verify if is first access) */
    verifyEmail() {
        if (this.networkStatus == "ONLINE") {

            this.msgEmail = null;
            this.msgPassword = null;
            if (this.email !== null) {
                this.activeLoader = true;
                this.email = this.email.toLowerCase();
                this.auth.verifyEmailDb(this.email, (user) => {
                    if (user['code'] !== 404) {
                        this.user = user['result'];
                        this.userType = user['result']['type'];
                        this.emailDisabled = true;
                        // verify if type is client or employee to get client id and display correct events later
                        if (this.userType == TypeUser.CLIENT) {
                            this.clientId = user['result']['uid'];
                        } else if (this.userType == TypeUser.EMPLOYEE) {
                            this.clientId = user['result']['clientId'];
                        }
                        // case e-mail exists and is first access, display verify code view;
                        if (user['result']['firstAccess'] == true) {
                            this.normalView = false;
                            this.firstAccessView = true;
                            this.createCodeNumber();
                            this.activeLoader = false;
                        } else if (user['result'] == 'email-not-found') {
                            this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                            this.emailDisabled = false;
                            this.activeLoader = false;
                        } else {
                            this.firstAccessView = false;
                            this.insertPasswordView = true;
                            this.activeLoader = false;
                            this.nextScreenEmit.emit('showPasswordScreen')
                        }
                    } else {
                        this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                        this.activeLoader = false;
                    }
                });
            } else {
                this.msgEmail = this.translateService.instant('pages.login.blank_email');
            }
        } else {
            this.SUtility.presentToast(this.translateService.instant('global.texts.network_offline'), 2500, 'bottom', 'danger');
        }

    }

    // case user exists and is not first access, user enter password and make login
    makeLogin() {
        this.msgPassword = null;
        if (this.password !== null && this.password !== undefined) {
            if (this.termCheck == true) {
                this.activeLoader = true;
                this.msgTermRequired = null;
                this.email = this.email.toLowerCase();
                this.auth.login(this.email, this.password, async (data) => {
                    if (data.code == 'auth/wrong-password') {
                        this.activeLoader = false;
                        this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
                    } else if (data.user['uid'] !== null) {
                        localStorage.setItem('userIdentifier', data.user['uid']);
                        if (this.eventId) {
                            this.SUserData.getUserDataSnapshot(this.eventId).then(() => {
                                    let eventInterval = setInterval(() => {
                                        if (this.user.events && this.user.events.length > 0) {
                                            clearInterval(eventInterval);
                                            const haveEvent = this.user.events.map(function (e) { return e; }).indexOf(this.eventId);
                                            if (haveEvent <= -1) {
                                                // alert, user not have access to event
                                                this.activeLoader = false;
                                                this.auth.removeStorageReferences();
                                                this.msgPassword = this.translateService.instant('pages.login.not_have_access_to_event');
                                            } else if (haveEvent >= 0) {
                                                // this.global.loadService((_) => { });
                                                this.redirectUser(data)
                                            }
                                        } else {
                                            this.activeLoader = false;
                                            this.auth.removeStorageReferences();
                                            this.msgPassword = this.translateService.instant('pages.login.not_have_access_to_event');
                                        }
                                    }, 500);
                            }).catch(() => {
                                this.activeLoader == false;
                                this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                            });
                        } else {
                            // this.global.loadService((_) => { });
                            this.redirectUser(data);
                        }
                    } else if (data.code == 'auth/too-many-requests') {
                        this.activeLoader == false;
                        this.msgPassword = this.translateService.instant('pages.login.many_pass_errors');
                    } else if (data.code == 'auth/argument-error') {
                        this.activeLoader == false;
                        this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                    } else {
                        this.activeLoader == false;
                        this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                    }
                });
            } else {
                this.activeLoader = false;
                this.msgTermRequired = this.translateService.instant('pages.login.term_check_required');
            }
        } else {
            this.activeLoader == false;
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
        }

    }

    redirectUser(data) {
        if (this.userType == TypeUser.SUPERGOD || this.userType == TypeUser.GOD) {
            this.navCtrl.navigateRoot(['/admin-events']);
        } else if (this.userType == TypeUser.CLIENT || this.userType == TypeUser.EMPLOYEE) {
            this.navCtrl.navigateRoot(['/c-events', this.clientId]);
        } else if (this.userType == TypeUser.SPEAKER || this.userType == TypeUser.ATTENDEE) {
            if (this.user.events.length == 1) {
                this.daoGeral.loadUser(this.user['uid'], this.userType, this.user.events[0]).pipe(
                    take(1)
                ).subscribe((user) => {
                    this.redirectUserToEvent(this.user.events[0]);
                });
            } else {
                this.navCtrl.navigateRoot(['/user-events', data.user['uid']]);
            }
        }
    }

    createCodeNumber() {
        // call function to create code, insert in user database and send e-mail to user;
        // this.auth.sendCodeNumberToEmail(this.user, (data) => {

        //     if (data !== false) {
        //         this.codeNumber = data;
        //         this.userTempId = this.user['uid'];

        //         this.count = this.seconds;
        //         this.interval = setInterval(() => {
        //             this.count--;

        //             var date = new Date(null);
        //             date.setSeconds(this.count); // specify value for SECONDS here
        //             this.timeRemaining = date.toISOString().substr(11, 8);

        //             if (this.count == 0) {
        //                 clearInterval(this.interval);
        //                 this.msgTimeRemaining = this.translateService.instant('pages.login.resend_code_number');
        //             }
        //         }, 1000);
        //         this.activeLoader2 = false;
        //     } else {
        //         this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
        //     }
        // })
        this.codeNumber = String(Math.floor(Math.random() * 899999 + 100000)); // generate 6 digits random number 
        this.auth.sendVerificationCodeToEmail(this.email, this.codeNumber, (res) => {
            if(!res) {
                this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
            }
            this.userTempId = this.user['uid'];
        })

        // Remaining time 
        this.count = this.seconds;
        this.interval = setInterval(() => {
            this.count--;
            const date = new Date(null);
            date.setSeconds(this.count); // specify value for SECONDS here
            this.timeRemaining = date.toISOString().substr(11, 8);

            if (this.count <= 0) {
                clearInterval(this.interval);
                this.msgTimeRemaining = this.translateService.instant('pages.login.resend_code_number');
            }
        }, 1000);
    }

    /* in case of first access, the user needs to verify your identity 
    by code number sended to e-mail and create your account */
    verifyCodeNumber() {
        // if (this.codeNumberTyped !== null) {
        //     this.activeLoader = true;
        //     this.auth.verifyCodeNumber(this.codeNumberTyped, this.userTempId, (data) => {
        //         if (data['result'] == true) {
        //             clearInterval(this.interval);
        //             this.firstAccessView = false;
        //             this.createPassView = true;
        //             this.activeLoader = false;
        //         } else if (data['result'] == false) {
        //             this.msgCode = this.translateService.instant('pages.login.invalid_code');
        //             this.activeLoader = false;
        //         } else {
        //             // not ok
        //             this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
        //             this.activeLoader = false;
        //         }
        //     });
        // } else {
        //     this.msgCode = this.translateService.instant('pages.login.invalid_code');
        // }
        this.activeLoader = true;
        if(String(this.codeNumberTyped) === String(this.codeNumber)) {
            clearInterval(this.interval);
            this.firstAccessView = false;
            this.createPassView = true;
        } else {
            this.msgCode = this.translateService.instant('pages.login.invalid_code');
        }
        this.activeLoader = false;
    }

    /* to create account, the user enter your password two times, check both 
    is equal and add to Firebase Authentication and update in Firestore */
    createAccount() {
        this.msgPassword = null;
        if (this.termCheck) {
            if (this.createPass.length >= 6) {
                if (this.createPass == this.confirmPass) {
                    this.activeLoader = true;
                    this.email = this.email.toLowerCase();
                    this.auth.createAccount(this.email, this.createPass, this.userTempId, this.userType).then((success) => {
                        if (success['message'] == 'error') {
                            if (success['code'] == "auth/email-already-in-use") {
                                this.activeLoader = false;
                                this.msgEmailAlreadyUse = this.translateService.instant('pages.login.email_already_use');
                            } else {
                                this.activeLoader = false;
                                this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                            }
                        } else if (success['message'] == 'success') {
                            // call API sending old uid and new uid; 
                            this.password = this.createPass;
                            this.makeLogin();
                        }
                    }, (error) => {
                        this.activeLoader = false;
                        this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                    })
                } else {
                    this.activeLoader = false;
                    this.msgPassword = this.translateService.instant('pages.login.password_not_match');
                }
            } else {
                this.activeLoader = false;
                this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
            }
        } else {
            this.activeLoader = false;
            this.msgTermRequired = this.translateService.instant('pages.login.term_check_required');
        }
    }

    async openLoadingContainer() {
        if (!this.container) {
            this.container = await this.loadingCtrl.create({
                spinner: 'crescent'
            });
            this.container.present();
        }
    }

    async closeLoadingContainer() {
        if (this.container) {
            await this.container.dismiss();
            this.container = null;
        }
    }

    async openTermsOfUse() {
        const modal = await this.modalCtrl.create({
            component: TermsOfUseComponent,
            componentProps: {
                terms: this.containerApp.termsOfUse
            }
        });
        return await modal.present();
    }

    async openPrivacy() {
        const modal = await this.modalCtrl.create({
            component: PrivacyTermsComponent,
            componentProps: {
                privacy: this.containerApp.privacyTerms
            }
        });
        return await modal.present();
    }

    redirectUserToEvent(eventId: string) {
        localStorage.setItem('eventId', eventId);
        this.global.previousPage = 'container';
        this.SAnalytics.updateConnectedStatusUser(eventId, true);
        
        Promise.all([
            this.SEventData.getEventDataSnapshot(eventId),
            this.SUserData.getUserDataSnapshot(eventId)
        ]).then(([eventData, userData]) => {
            localStorage.setItem('homePage', eventData.homePage);

            if (!this.firstAccess) { return; }
            this.firstAccess = false;

            if (eventData.required_edit_profile == false) {
                this.navCtrl.navigateRoot([eventData.homePage]).then((_) => {
                    if (_ == true) {
                        this.eventsA.publish('updateScreen');
                    }
                });
            }
            else if (eventData.required_edit_profile == true && userData.edited_profile == true) {
                this.navCtrl.navigateRoot([eventData.homePage]).then((_) => {
                    if (_ == true) {
                        this.eventsA.publish('updateScreen');
                    }
                });
            }
            else if (
                eventData.required_edit_profile == true &&
                (userData.edited_profile == false || userData.edited_profile == null)
            ) {
                this.global.cantGoBackFromProfile = true;
                this.navCtrl.navigateRoot(
                    [`/event/${eventId}/edit-profile/${userData.moduleId}/${userData.type}/${userData.uid}`]
                ).then((_) => {
                    if (_ == true) {
                        this.eventsA.publish('updateScreen');
                    }
                });
            }
        });
    }

    async recoveryPassword() {
        const prompt = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: this.translateService.instant('pages.login.recovery_pass_txt'),
            inputs: [
                {
                    name: 'email',
                    placeholder: this.translateService.instant('pages.login.your_email_plc'),
                    value: this.email,
                    type: 'email'
                },
            ],
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.cancel'),
                    handler: data => {
                        // cancel operation
                    }
                },
                {
                    text: this.translateService.instant('pages.login.recovery_btn'),
                    handler: data => {
                        // check e-mail and recovery password
                        if (data.email !== null && data.email !== undefined && data.email !== null) {
                            this.auth.checkAuthEmail(data.email, (status) => {
                                if (status) {
                                    this.auth.recoveryPassword(data.email, (status) => {
                                        if (status == true) {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_success'))
                                        } else {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_fail'))
                                        }
                                    });
                                } else {
                                    // e-mail não encontrado
                                    this.alertRecoveryPass(this.translateService.instant('pages.login.email_not_found'))
                                }
                            })
                        } else {
                            // blank email error
                            this.alertRecoveryPass(this.translateService.instant('pages.login.blank_email'));
                        }
                    }
                }
            ]
        });
        prompt.present();
    }

    async alertRecoveryPass(message) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: message,
            buttons: [this.translateService.instant('global.buttons.ok')]
        });

        await alert.present();
    }

    shortcode: string = '';
    async openShortcodeCtrl() {
        if (this.networkStatus == "ONLINE") {

            const prompt = await this.alertCtrl.create({
                header: this.translateService.instant('global.buttons.acess_by_shortcode'),
                message: this.translateService.instant('global.alerts.type_event_shortcode'),
                inputs: [
                    {
                        name: 'shortcode',
                        placeholder: this.translateService.instant('global.texts.code'),
                        value: this.shortcode,
                        type: 'text'
                    },
                ],
                buttons: [
                    {
                        text: this.translateService.instant('global.buttons.cancel'),
                        handler: data => {
                            // cancel operation
                        }
                    },
                    {
                        text: this.translateService.instant('global.buttons.btn_verify'),
                        handler: data => {
                            // check shortcode input is filled
                            if (data.shortcode !== '' && data.shortcode !== null && data.shortcode !== undefined) {
                                this.shortcode = data.shortcode.toLowerCase();
                                this.daoEvent.getEventByShortcode(this.shortcode, (event) => {
                                    if (event == null) {
                                        // case event doesn't exist
                                        this.blankShortcodeInput();
                                        this.shortcode = '';
                                    } else {
                                        this.navCtrl.navigateRoot([`/${this.shortcode}`]);
                                        this.shortcode = '';
                                    }
                                })
                            } else {
                                // case blank, show error
                                this.blankShortcodeInput();
                            }
                        }
                    }
                ]
            });
            prompt.present();
        } else {
            this.SUtility.presentToast(this.translateService.instant('global.texts.network_offline'), 2500, 'bottom', 'danger');
        }
    }

    async blankShortcodeInput() {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('global.alerts.error'),
            message: this.translateService.instant('global.alerts.event_shortcode_blank'),
            buttons: [{
                text: this.translateService.instant('global.buttons.ok'),
                handler: _ => this.openShortcodeCtrl()
            }]
        });

        await alert.present();
    }

    backToAppLogin() {
        this.eventId = null;
        this.event = null;
        this.navCtrl.navigateRoot(['/'])
    }

    ionViewWillLeave() {
        this.eventId = null;
        this.event = null;
    }

    showHidePwd() {
        this.showPwd = !this.showPwd;
    }
}
