import { Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DaoMapsService {
    constructor(
        private firestore: Firestore
    ) { }

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(take(1)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    getMap(moduleId: string, mapId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/maps/${mapId}`);
        docData(ref).pipe(take(1)).subscribe((doc) => {
            onResolve(doc);
        });
    }
}