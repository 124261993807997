import { Injectable } from '@angular/core';
import { 
    Firestore, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    doc, 
    docData,
    where,
    QueryConstraint
} from '@angular/fire/firestore';
import { auditTime, takeUntil } from 'rxjs/operators';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoModulesService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    getMenuModules(eventId: string) {
        const ref = collection(this.firestore, `modules`);
        const qc: QueryConstraint[] = [
            where("eventId", "==", eventId),
            where("habilitedApp", "==", true),
            where("order", ">=", 0),
            orderBy("order")
        ];
        const refQ = query(ref, ...qc);

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject), auditTime(500));
    }

    getModulesEvent(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /**
     * Get module
     * @param moduleId 
     */
    getModule(moduleId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}
