import { Component, OnInit, NgZone } from '@angular/core';
import { AnalyticsService, GlobalService } from 'src/app/shared/services';
import { DaoEventsService } from '../providers/db/dao-events.service';
import { AuthService } from '../providers/authentication/auth.service';
import { MenuController, NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { LuxonService } from '../providers/luxon/luxon.service';
import { UserDataService } from '../shared/services/user-data/user-data.service';
import { EventDataService } from '../shared/services/eventData/event-data.service';

@Component({
    selector: 'app-admin-events',
    templateUrl: './admin-events.page.html',
    styleUrls: ['./admin-events.page.scss'],
    providers: [DaoEventsService]
})
export class AdminEventsPage implements OnInit {
    events;

    searchOpen = false;
    container: any;
    loader: boolean = true;
    constructor(
        public global: GlobalService,
        private daoEvents: DaoEventsService,
        private auth: AuthService,
        private eventsA: Events,
        private menuCtrl: MenuController,
        private luxon: LuxonService,
        private navCtrl: NavController,
        private zone: NgZone,
        // private SAnalytics: AnalyticsService
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(false);
    }

    ngOnInit() {
        this.loadEvents();
    }

    loadEvents() {
        this.daoEvents.getAllEvents((data) => {
            this.zone.run(() => {
                // makes the treatment of the startDate of the event.
                for (const event of data['result']) {
                    if (typeof event.startDate == 'number') {
                        const sDate = this.luxon.convertTimestampToDate(event.startDate)
                        event.startDate = this.luxon.convertDateToFormatDDMM(sDate)
                    }
                    if (event.banner == '' || event.banner == null || event.banner == undefined) {
                        event.banner = {
                            uid: '',
                            url: ''
                        }
                    }
                }

                this.events = data['result'];
                this.loader = false;
            })
        });
    }

    logOut() {
        // Update connected analytics
        // this.SAnalytics.updateConnectedStatusUser(false);

        this.auth.logout();
    }

    openEvent(event) {
        this.loader = true;
        this.menuCtrl.enable(true);
        this.global.previousPage = 'container';
        localStorage.setItem('eventId', event.uid);
        localStorage.setItem('homePage', event.homePage);

        this.SEventData.eventData = event;
        this.SEventData.eventId = event.uid;
        this.SEventData.getEventDataListener(event.uid).subscribe();

        this.SUserData.getUserDataSnapshot(event.uid).then(() => {
            this.navCtrl.navigateRoot([`${event.homePage}`])
                .then((_) => {
                    this.eventsA.publish('updateScreen');
                    this.loader = false;
                });
        });
    }

}
