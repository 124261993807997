import { Component, OnInit, Input } from "@angular/core";
import { DaoEventsService } from "src/app/providers/db/dao-events.service";
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Container } from "src/app/models/container";

@Component({
	selector: "app-login-with-shortcode",
	templateUrl: "./login-with-shortcode.component.html",
	styleUrls: ["./login-with-shortcode.component.scss"]
})
export class LoginWithShortcodeComponent implements OnInit {
	@Input() containerApp: Container;
	activeLoader: boolean = false;
	shortcode: string = null;
	constructor(
		private daoEvent: DaoEventsService,
		private alertCtrl: AlertController,
		private translateService: TranslateService,
		private navCtrl: NavController
	) {}

	ngOnInit() {
		console.log("Shortcode !");
	}

	checkShortcode() {
		// check shortcode input is filled
		if (
			this.shortcode !== "" &&
			this.shortcode !== null &&
			this.shortcode !== undefined
		) {
			this.shortcode = this.shortcode.toLowerCase();
			this.activeLoader = true;
			this.daoEvent.getEventByShortcode(this.shortcode, (event) => {
				if (event == null) {
					// case event doesn't exist
					this.blankShortcodeInput();
					this.shortcode = "";
					this.activeLoader = false;
				} else {
					this.navCtrl.navigateRoot([`/${this.shortcode}`]);
					this.shortcode = "";
					this.activeLoader = false;
				}
			});
		} else {
			// case blank, show error
			this.blankShortcodeInput();
			this.activeLoader = false;
		}
	}

	async blankShortcodeInput() {
		const alert = await this.alertCtrl.create({
			header: this.translateService.instant("global.alerts.error"),
			message: this.translateService.instant(
				"global.alerts.event_shortcode_blank"
			),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.ok")
				}
			]
		});

		await alert.present();
	}
}
