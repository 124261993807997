import { Component, OnInit, NgZone, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import {
    MenuController,
    ModalController,
    NavParams,
    PickerController,
    Platform,
    AlertController
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { DaoGeralService } from "src/app/providers/db/dao-geral.service";
//import { Globalization } from "@ionic-native/globalization/ngx";  // todo: update plugin globalization
import { PathComponent } from "src/app/models/path/path-components";
import { DaoEventsService } from "src/app/providers/db/dao-events.service";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";


@Component({
    selector: 'app-personal-settings',
    templateUrl: './personal-settings.page.html',
    styleUrls: ['./personal-settings.page.scss'],
})
export class PersonalSettingsPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    @Input() userId: string = null;
    @Input() eventId: string = null;
    @Input() moduleId: string = null;
    public typeUser: number = null;
    public loader: boolean = true;
    public company: string = null;
    public title: string = null;
    public name: string = null;
    public site: string = null;
    public facebook: string = null;
    public instagram: string = null;
    public linkedin: string = null;
    public twitter: string = null;
    public identifier: string = null;
    public phone: string = null;
    public description: string = null;
    public photoUrl: string = null;


    public editProfileActive: boolean = false;

    public listCustomFields: Array<any> = [];
    public listCustomFieldOptions: Array<any> = [];
    public fieldsCustom: any;
    showCardCustomField: boolean = false;

    public documents = [];
    allowQR: boolean = false;

    allow_language: boolean = null; //multi language

    languages;
    term;
    privacy;
    cookiePolicy;
    termsOfUseLabel;
    privacyLabel;
    cookiePolicyLabel;
    termsOfUseVisibility;
    privacyVisibility;
    cookiePolicyVisibility;

    showAll: boolean = true;
    showTerms: boolean = false;
    showPrivacy: boolean = false;
    showCookie: boolean = false;
    backBtn: boolean = true;

    menu_color1: string = null;
    menu_color2: string = null;
    eventColors: EventColors = new EventColors();;
    menuBadge: number = 0;
    language: string;
    type: string = null;

    doc;
    loaderToast;
    isMobile = window.innerWidth < 768;

    handlerMessage = "";
    roleMessage = "";
    constructor(
        private route: ActivatedRoute,
        private dbAttendees: DaoAttendeesService,
        private dbSpeakers: DaoSpeakersService,
        public global: GlobalService,
        private modalCtrlWriteMessages: ModalController,
        private events: Events,
        private menuCtrl: MenuController,

        private zone: NgZone,
        private pickerCtrl: PickerController,
        private translateService: TranslateService,
        private dbGeral: DaoGeralService,
        private platform: Platform,
        //public globalization: Globalization,  // todo: update plugin globalization
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private dbEvents: DaoEventsService,
        private alertController: AlertController,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(true);
        const params = this.route.snapshot.params;

        this.eventId = params.eventId;
            
        this.userId = params.userId;
        this.typeUser = params.type;
        this.moduleId = params.moduleId;
        this.loadColors();
        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe("menuBadge", () => {
            this.zone.run(() => {
                this.menuBadge = this.global.notificationBadge;
            });
        });

        this.setLanguageToUse();

        // evento que observa o multi-idioma
        // this.events.subscribe('allowLanguage', () => {
        //     this.allow_language = this.SEventData.eventData.allow_language;
        // });

        // evento que observa se deve atualizar o idioma do aplicativo
        this.events.subscribe('languageUpdate', (language) => {
            this.setLanguageUse(language);
        });

        if (this.navParams.get('type')) {
            this.type = this.navParams.get('type');
            if (this.type == 'termsOfUse') {
                this.showAll = false;
                this.showPrivacy = false;
                this.showCookie = false;
                this.showTerms = true;

            } else if (this.type == 'privacy') {
                this.showAll = false;
                this.showTerms = false;
                this.showCookie = false;
                this.showPrivacy = true;
            } else {
                this.showAll = false;
                this.showTerms = false;
                this.showPrivacy = false;
                this.showCookie = true;
            }
        }
    }

    getEventData() {
        this.SEventData.getEventDataListener(this.eventId).subscribe((eventData) => {

        })
    }


    async getColumnOptions() {
        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        let options = [];

        if (eventData) {
            if (
                eventData.description['de_DE'] !== null &&
                eventData.description['de_DE'] !== undefined
            ) {
                options.push({
                    text: this.translateService.instant('global.texts.de_DE'),
                    value: 'de_DE'
                });
            }

            if (
                eventData.description['es_ES'] !== null &&
                eventData.description['es_ES'] !== undefined
            ) {
                options.push({
                    text: this.translateService.instant('global.texts.es_ES'),
                    value: 'es_ES'
                });
            }

            if (
                eventData.description['fr_FR'] !== null &&
                eventData.description['fr_FR'] !== undefined
            ) {
                options.push({
                    text: this.translateService.instant('global.texts.fr_FR'),
                    value: 'fr_FR'
                });
            }

            if (
                eventData.description['en_US'] !== null &&
                eventData.description['en_US'] !== undefined
            ) {
                options.push({
                    text: this.translateService.instant('global.texts.en_US'),
                    value: 'en_US'
                });
            }

            if (
                eventData.description['pt_BR'] !== null &&
                eventData.description['pt_BR'] !== undefined
            ) {
                options.push({
                    text: this.translateService.instant('global.texts.pt_BR'),
                    value: 'pt_BR'
                });
            }
        }
        return options;
    }

    async getLanguageColumns() {
        const options = await this.getColumnOptions()
        let columns = [];
        columns.push({
            name: 'lang',
            options: options
        });
        return columns;
    }

    async changeLanguage() {
        const columns = await this.getLanguageColumns()
        const picker = await this.pickerCtrl.create({
            columns: columns,
            buttons: [
                {
                    text: this.translateService.instant(
                        'global.buttons.cancel'
                    ),
                    role: 'cancel'
                },
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (v) => {
                        let lang = v['lang']['value'];
                        if (this.userId) {
                            this.dbGeral.updateUserGeneral(
                                { language: lang },
                                this.userId,
                                this.eventId,
                                this.typeUser
                            );
                        } else {
                            localStorage.setItem('loggedLang', 'true');
                        }
                        this.events.publish('languageUpdate', lang);
                    }
                }
            ]
        });
        // picker.columns[0].selectedIndex = this.selectedLangIndex();
        await picker.present();
    }

    // seta a linguagem para uso do app
    async setLanguageUse(language: string) {
        if (!language) {
            language = this.checkLanguage(environment.platform.defaultLanguage);
        }
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
        this.SEventData.language = language;
        localStorage.setItem('usedLanguage', language);
    }

    // checa a linguagem do usuário e retorna a string do idioma no formato necessário
    checkLanguage(language: string) {
        if (language == 'PtBR') {
            return 'pt_BR';
        } else if (language == 'EnUS') {
            return 'en_US';
        } else if (language == 'FrFR') {
            return 'fr_FR';
        } else if (language == 'EsES') {
            return 'es_ES';
        } else if (language == 'DeDE') {
            return 'de_DE';
        } else {
            return this.checkLanguage(environment.platform.defaultLanguage);
        }
    }

    async setLanguageToUse() {
        let storageLang = localStorage.getItem('usedLanguage');
        if (storageLang) {
            this.setLanguageUse(storageLang);
        } else {
            if (this.platform.is('ios') || this.platform.is('android')) {
                // this.globalization
                //     .getPreferredLanguage()
                //     .then((lang) => {
                //         this.global.deviceLanguage = lang.value;
                //         this.setLanguageUse(this.checkLanguage(lang.value));
                //     })
                //     .catch((e) => {
                //         this.checkLanguage(
                //             environment.platform.defaultLanguage
                //         );
                //     });  // todo: update plugin globalization
            } else {
                this.setLanguageUse(this.checkLanguage(environment.platform.defaultLanguage));
            }
        }
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;

            if (this.eventColors.menu_color.includes("linear-gradient")) {
                const colors = this.eventColors.menu_color.split(",");
                this.menu_color1 = colors[1];
                this.menu_color2 = colors[2].substr(0, colors[2].length - 1);
            } else {
                this.menu_color1 = this.menu_color2 = this.eventColors.menu_color;
            }
            
            this.allow_language = eventData.allow_language;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.global.previousPage == "container") {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        if (this.typeUser == 5) {
            this.checkeditProfileActiveAttendee();
        } else if (this.typeUser == 4) {
            this.checkeditProfileActiveSpeaker();
        } else {
            // invalid view profile
        }

        this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
            this.allowQR = eventData.allowProfileQR;
        });

        this.subscriptions.push(this.dbEvents.getEvent(this.eventId).subscribe((event) => {
            this.term = event['terms_of_use'];
            this.privacy = event['privacy'];
            this.languages = event['languages'];
            this.cookiePolicy = event['cookiePolicy'];
            this.termsOfUseLabel = event['termsOfUseLabel'] && event['termsOfUseLabel'][this.SEventData.getLanguage(true)] ? 
                event['termsOfUseLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.terms_of_use');

            this.privacyLabel = event['privacyLabel'] && event['privacyLabel'][this.SEventData.getLanguage(true)] ? 
                event['privacyLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.privacy');

            this.cookiePolicyLabel = event['cookiePolicyLabel'] && event['cookiePolicyLabel'][this.SEventData.getLanguage(true)] ? 
                event['cookiePolicyLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.cookie');

            this.termsOfUseVisibility = event['termsOfUseVisibility'] !== undefined ? event['termsOfUseVisibility'] : true;
            this.privacyVisibility = event['privacyVisibility'] !== undefined ? event['privacyVisibility'] : true;
            this.cookiePolicyVisibility = event['cookiePolicyVisibility'] !== undefined ? event['cookiePolicyVisibility'] : true;
        }));
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    close() {
        this.modalCtrl.dismiss();
        this.type = null;
        this.eventId = null;
    }

    // getLabel(type: string) {
    //     let selectedLabel;
    //     switch (type) {
    //         case 'terms-of-use':
    //             selectedLabel = this.termsOfUseLabel
    //             break;
    //         case 'privacy':
    //             selectedLabel = this.privacyLabel
    //             break;
    //         case 'cookie':
    //             selectedLabel = this.cookiePolicyLabel
    //             break;
    //     }
    //     return selectedLabel ? selectedLabel[this.SEventData.getLanguage()] : ''
    // }

    showRightBlock(type: string) {
        this.showAll = false;
        this.showTerms = false;
        this.showPrivacy = false;
        this.showCookie = false;
        switch (type) {
            case 'terms-of-use':
                this.showTerms = true;
                break;
            case 'privacy':
                this.showPrivacy = true;
                break;
            case 'cookie':
                this.showCookie = true;
                break;
        }
    }

    goBack() {
        this.showAll = true;
        this.showTerms = false;
        this.showPrivacy = false;
        this.showCookie = false;
    }

    checkeditProfileActiveAttendee() {
        this.dbAttendees.checkeditProfileActive(this.moduleId, (result) => {
            this.editProfileActive = result;
        });
    }

    checkeditProfileActiveSpeaker() {
        this.dbSpeakers.checkeditProfileActive(this.moduleId, (result) => {
            this.editProfileActive = result;
        });
    }

    getSpeakerAttendeePrincipalTitle(title, description) {
        return {
            title: title[this.language],
            description: description[this.language]
        };
    }

    async startEventValues() {
        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        this.allow_language = (eventData) ? eventData.allow_language : null;
    }

    setReferences() {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            // obtém as informações do usuário, via global service
            this.typeUser = userData.type;
            this.photoUrl = userData.photoUrl;
            this.userId = userData.uid;
            this.eventId = localStorage.getItem('eventId');
            this.moduleId = userData.moduleId;


            // após logado, utiliza o idioma preferido do usuário, definido na conta
            this.setLanguageUse(this.SEventData.getLanguage(true));

            if (
                this.userId == null &&
                !localStorage.getItem('loggedLang')
            ) {
                localStorage.setItem('loggedLang', 'false');

                // start event values
                this.startEventValues();
            }
        });
    }


    closeModal() {
        this.modalCtrlWriteMessages.dismiss()
    }
    // abre o modal de termos de uso
    async openLegalTerms() {
        const modal = await this.modalCtrlWriteMessages.create({
            component: PathComponent.terms_n_privacy,
            componentProps: {
                eventId: this.eventId
            }
        });
        return await modal.present();
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant("global.alerts.confirm_delete_account"),
            buttons: [
                {
                    text: this.translateService.instant("global.buttons.cancel"),
                    role: 'cancel',
                    handler: () => {
                        this.handlerMessage = 'Alert canceled';
                    },
                },
                {
                    text: this.translateService.instant("global.buttons.ok"),
                    role: 'confirm',
                    handler: () => {
                        this.handlerMessage = 'Alert confirmed';
                    },
                },
            ],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        this.roleMessage = `Dismissed with role: ${role}`;
        console.log(this.roleMessage);
    }

}
