import { waitForAsync } from '@angular/core/testing';
import { Component, NgZone, Inject, HostListener, ViewChild, OnInit, SimpleChanges } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import {
    Platform,
    ModalController,
    MenuController,
    AlertController,
    PickerController,
    NavController,
    ToastController
} from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';  // todo: update plugin splash-screen
import { StatusBar, Style } from '@capacitor/status-bar';
import { environment } from '../environments/environment';
import { GlobalService, UtilityService, AnalyticsService, VideoService, WherebyService } from 'src/app/shared/services';
import { DaoModulesService } from './providers/db/dao-modules.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthService } from './providers/authentication/auth.service';
import { TypeUser } from './models/type-user';
import { TypeVisionGroup } from './enums/type-vision-group';
import { DaoGeralService } from './providers/db/dao-geral.service';
import { PathComponent } from './models/path/path-components';
import { 
    Firestore, 
    collection,
    query,
    orderBy,
    collectionData,
    QueryConstraint,
    where,
} from '@angular/fire/firestore';
import { NotificationDateService } from './providers/date/notification-date.service';
//import { Globalization } from '@ionic-native/globalization/ngx';  // todo: update plugin globalization
import { LoginpPage } from './loginp/loginp.page';
import { TypeModule } from './enums/type-module';
//import 'rxjs/add/operator/catch';
import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from './shared/reducers';
import { getConnectionStatus } from './shared/selectors/utility.selectors';
import {
    take,
    mergeMap,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DaoEventsService } from './providers/db/dao-events.service';
import { getUserData } from './shared/selectors/user.selectors';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TypeVisionModule } from './models/type-vision-module';
import { DaoScheduleService } from './providers/db/dao-schedule.service';
import { DaoSessionFeedbackService } from './providers/db/dao-session-feedback.service';
import { DaoSpeakersService } from './providers/db/dao-speakers.service';
import { DaoAttendeesService } from './providers/db/dao-attendees.service';
import { VideoPlayerComponent } from './content/reusable_components/video-player/video-player.component';
import { getVideoPlayerState } from './shared/selectors/video-player.selectors';
import { DaoFeedNewsService } from './providers/db/dao-feed-news.service';
import { GroupDiscussionsService } from "src/app/shared/services/group-discussions/group-discussions.service";
import * as moment from 'moment';
import { AppointmentTimeSlotStatus } from "src/app/models/appointments";
import { InAppBrowserService } from 'src/app/providers/in-app-browser/in-app-browser.service';
import { BarcodeScannerService } from './providers/barcode-scanner/barcode-scanner.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from '@capacitor/device';
import { LogoutService } from './shared/services/logout/logout.service';
import { UserDataService } from './shared/services/user-data/user-data.service';
import { EventDataService } from './shared/services/eventData/event-data.service';

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
    inApp: boolean = false;
    browserName: string;
    dataBrowser: any = [
        { string: navigator.userAgent, subString: "Edge", identity: "MS Edge" },
        { string: navigator.userAgent, subString: "MSIE", identity: "Explorer" },
        { string: navigator.userAgent, subString: "Trident", identity: "Explorer" },
        { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
        { string: navigator.userAgent, subString: "Opera", identity: "Opera" },
        { string: navigator.userAgent, subString: "OPR", identity: "Opera" },
        { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
        { string: navigator.userAgent, subString: "Safari", identity: "Safari" },
        { string: navigator.userAgent, subString: "Android", identity: "Mobile" }
    ];
    eventsArray: any;
    @HostListener('document:visibilitychange', ['$event'])
    changingTabs(evt) {
        if (this.inApp && document.hidden) {
            // Track leave app analytics
            this.SAnalytics.goOutOfApp(this.eventId);
        } else if (!this.inApp && !document.hidden) {
            this.SAnalytics.goBackOnApp(this.eventId);
        }

        this.inApp = !document.hidden;
        return (true);
    }
    /* Some functions for brwoser detection */
    getBrowserName() {
        this.browserName = this.searchString(this.dataBrowser) || 'Other';
    }

    searchString(data: any): string {
        for (let i = 0; i < data.length; i++) {
            const dataString = data[i].string;
            console.log(dataString)
            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload(evt) {
        // Track leave app analytics
        this.SAnalytics.goOutOfApp(this.eventId);
        return (true);
    }

    subscriptions: Subscription[] = [];

    // Mini video player
    @ViewChild('videoComponent') videoComponent: VideoPlayerComponent;
    floating: boolean = false;
    start: number = 0;
    src: string = null;
    type: string;
    playerCurrentTime: number = 0;

    authenticated: boolean = false;
    showSplashScreenWeb: boolean = false;
    public requestOptions;
    public headers;
    allow_language: boolean = null; //multi language
    displayName: string = '';
    eventId: string = null;
    userId: any = null;
    typeUser: number = null;
    moduleId: string = null;
    photoUrl: string = null;
    // menuModules$: Observable<any[]>;
    menuModules: any[] = [];
    tabBarModules: any[] = [];
    interval: any;
    groupsUser: any;
    allowChat: boolean = false;
    contAccess: number = 0;

    refAllNotif = null;
    removedNotif = [];

    notificationBadge: number = 0;
    refChat: any = null;
    chatBadge: number = 0;
    bannerUrl: string = null;
    bannerVis: boolean = null;
    notificationPlayerIds = null;
    allowPublicRegister: boolean = false;
    allowReloadModules: boolean = true;
    onlyCallOne: boolean = true;
    allowLogin: boolean = false;
    allowRegistration: boolean = false;
    static loginCounter: number = 0;

    connectionStatus: string = 'PENDING';
    networkInit: boolean = false;

    resumeAppInterval: any;
    appLoadedBeforeResume: boolean = false;
    totalEvents: number = 0;

    isMobile: boolean = false;
    hamburgerActivated: boolean = true;
    personalPageActive = false;

    videoPlayerActivated: boolean = false;
    activeModuleUid: string = null;
    OS = (navigator.appVersion.indexOf('Win') != -1) ? "Windows" : "Other";

    customFont;
    showNotifPopup = false;
    showChatPopup = false;

    constructor(
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private route: ActivatedRoute,
        //public globalization: Globalization,  // todo: update plugin globalization
        public alertCtrl: AlertController,
        private auth: AuthService,
        private platform: Platform,
        //private splashScreen: SplashScreen,  // todo: update plugin splash-screen
        private translateService: TranslateService,
        public global: GlobalService,
        private daoModules: DaoModulesService,
        private router: Router,
        public events: Events,
        private zone: NgZone,
        private daoGeral: DaoGeralService,
        private modalCtrl: ModalController,
        private firestore: Firestore,
        private notificationDate: NotificationDateService,
        private SAnalytics: AnalyticsService,
        private dbGeral: DaoGeralService,
        private luxon: NotificationDateService,
        private menuCtrl: MenuController,
        private pickerCtrl: PickerController,
        private navCtrl: NavController,
        // private connection: ConnectionService,
        private store: Store<AppState>,
        public SUtility: UtilityService,
        private SVideo: VideoService,
        private daoEvent: DaoEventsService,
        private title: Title,
        private dbSchedule: DaoScheduleService,
        private daoFeedback: DaoSessionFeedbackService,
        private dbSpeakers: DaoSpeakersService,
        private dbAttendees: DaoAttendeesService,
        private SWhereby: WherebyService,
        private sanitizer: DomSanitizer,
        private SFeedNews: DaoFeedNewsService,
        public toastController: ToastController,
        public alertController: AlertController,
        private gdService: GroupDiscussionsService,
        private inAppBrowser: InAppBrowserService,
        private scan: BarcodeScannerService,
        private Slogout: LogoutService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
    ) {
        // set the default language in which the app will be translated. use the user device language if supported, 
        // else uses the app default language
        const setDefaultLanguage = async () => {
            const supportedLangs: Array<string> = ["en-US", "pt-BR", "es-ES", "de-DE"];
            this.translateService.setDefaultLang(environment.platform.oldDefaultLanguage);
            const tag = await Device.getLanguageTag();
            
            if (supportedLangs.includes(tag.value)) {
                const lang = tag.value.replace("-", "_");
                this.translateService.use(lang);
            }
        }
        setDefaultLanguage();

        // this.showSplashscreen();

        // initialize app, padrão do ionic para iniciar a plataforma
        this.initializeApp();

        // evento que observa se deve recarregar alguma função, nesse caso a load general
        this.events.subscribe('updateScreen', () => {
            // this.allowReloadModules = true;
            // this.showSplashscreen();

            // this.loadGeneral();
            // this.global.getEvent(this.eventId, () => {});
            this.SEventData.getEventDataSnapshot().then().catch(error => console.error(error));
            if (
                this.router.url.includes('start') ||
                this.router.url.includes('login') ||
                this.router.url.includes('select-login') ||
                this.router.url.includes('admin-events') ||
                this.router.url.includes('c-events') ||
                this.router.url.includes('user-events') ||
                this.router.url.includes('plogin')
            ) {
                // this.hideSplashScreen();
            } else {
                // this.hideSplashScreen();
            }
        });
        this.events.subscribe('allowReloadModules', () => {
            this.allowReloadModules = true;
        });
        // this.events.subscribe('reloadNotification', () => {
        //     if (this.eventId) this.startNotificationsValues();
        // });
        // evento que observa o multi-idioma
        // this.events.subscribe('allowLanguage', () => {
        //     this.allow_language = this.global.allow_language;
        // });

        // evento que observa se deve atualizar as badges (chat,notificação)
        this.events.subscribe('updateChatBadge', () => {
            // let badge = this.global.notificationBadge;
            // badge--;
            // this.global.updateNotificationBadge(badge, 'chat');
            let badge = this.global.notificationBadge - 1;
            this.global.notificationBadge = badge;
            this.events.publish('menuBadge');
        });

        // evento que observa se deve atualizar o idioma do aplicativo
        this.events.subscribe('languageUpdate', (language) => {
            this.setLanguageUse(language);
        });

        this.events.subscribe('clearNotificationBadge', () => {
            this.global.updateNotificationBadge(0, 'notify');
        });

        // Subscription on connection status
        this.store.select(getConnectionStatus).subscribe((networkStatus) => {
            if (
                this.connectionStatus == 'OFFLINE' &&
                networkStatus == 'ONLINE' &&
                this.networkInit
            ) {
                this.events.publish('updateScreen');
            }

            if (
                (this.connectionStatus == 'PENDING' &&
                    networkStatus == 'OFFLINE') ||
                this.connectionStatus === 'OFFLINE'
            ) {
                this.connectionStatus = networkStatus;
                if (networkStatus == 'ONLINE') {
                    setTimeout(() => {
                        this.connectionStatus = 'PENDING';
                    }, 5000);
                }
            }

            if (!this.networkInit) {
                this.networkInit = true;
            }
        });

        this.subscriptions.push(
            this.store.select(getVideoPlayerState).subscribe((videoPlayerState) => {
                this.videoPlayerActivated = (!videoPlayerState || !videoPlayerState.src || !videoPlayerState.type || videoPlayerState.inSession) ? false : true;
            })
        )
    }

    async ngOnInit() {
        await SplashScreen.hide();
        
        this.getBrowserName();
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    // async setLanguageToUse() {
    //     let storageLang = localStorage.getItem('usedLanguage');
    //     if (storageLang) {
    //         this.setLanguageUse(storageLang);
    //     } else {
    //         if (this.platform.is('ios') || this.platform.is('android')) {
    //             // this.globalization
    //             //     .getPreferredLanguage()
    //             //     .then((lang) => {
    //             //         this.global.deviceLanguage = lang.value;
    //             //         this.setLanguageUse(this.checkLanguage(lang.value));
    //             //     })
    //             //     .catch((e) => {
    //             //         this.checkLanguage(
    //             //             environment.platform.defaultLanguage
    //             //         );
    //             //     });  // todo: update plugin globalization
    //         } else {
    //             this.setLanguageUse(this.checkLanguage(environment.platform.defaultLanguage));
    //         }
    //     }
    // }

    setIntervalAppResume: any;
    // isCordova: boolean = false;

    initializeApp() {
        this.platform.ready().then(() => {
            // if (this.platform.is('ios') || this.platform.is('android') || window.innerWidth < 768) {
            //     this.isMobile = true;
            //     this.hamburgerActivated = false;
            // }
            // this.global.isAppOrBrowser();
            // this.global.getPlatform();
            // this.setLanguageToUse();
            // this.loadGeneral();
            if (this.platform.is('mobile')) {
                // this.isCordova = true;
                StatusBar.setStyle({ style: Style.Default });
                // this.setupPush();
            }

            this.platform.pause.subscribe(() => {
                // this.global.userLoaded = false;
            });
            this.platform.resume.subscribe(() => {
                this.events.publish('updateScreen');
            });

            this.SUtility.manageNetworkStatus();
            // this.connectionStatusNet();

            this.wakeUpFunctions();
        });
    }

    async notificationAlert(title, msg) {
        const alert = await this.alertCtrl.create({
            header: title,
            subHeader: msg,
            buttons: [
                {
                    text: 'OK',
                    handler: () => { }
                }
            ]
        });
        alert.present();
    }

    dateNow;
    groupIntervalIf: any;
    groupIntervalElse: any;
    // loadGeneral() {
    //     console.log("loadGeneral");
    //     this.auth.isAuthenticated().subscribe((authState) => {
    //         this.authenticated = authState;
    //         this.eventId = localStorage.getItem('eventId');

    //         // CHECK HAVE EVENT ID AND LOAD MODULES OR REDIRECT TO LOGIN
    //         console.log(`this.eventId: ${this.eventId}`)
    //         console.log(`this.authenticated: ${this.authenticated}`)
    //         if (this.eventId) {
    //             console.log(`conditional 01`)
    //             this.global.getEvent(this.eventId, async (status) => {
    //                 if (status && this.SEventData.eventData && this.SEventData.eventData.title) {
    //                     this.title.setTitle(this.SEventData.eventData.title);
    //                     this._document.getElementById('appFavicon').setAttribute('href', this.SEventData.eventData.webApp.touchIcon.url);
    //                     this._document.getElementById('appAppleFavicon').setAttribute('href', this.SEventData.eventData.webApp.touchIcon.url);
    //                     this._document
    //                         .getElementById("appAppleStartupIcon")
    //                         .setAttribute("href", this.SEventData.eventData.webApp.touchIcon.url);
    //                 }

    //                 console.log(`this.SEventData.eventData: ${this.SEventData.eventData}`);
    //                 console.log(`this.SEventData.eventData.visibility: ${this.SEventData.eventData.visibility}`)
    //                 console.log(`this.authenticated: ${this.authenticated}`)

    //                 if (this.SEventData.eventData && (this.SEventData.eventData.visibility || (!this.SEventData.eventData.visibility && this.authenticated))) {
    //                     console.log(`conditional 02`)
    //                     //this.loadModules();
    //                     //this.setReferences();
    //                 } else if (!this.SEventData.eventData || (!this.SEventData.eventData.visibility && !this.authenticated)) {
    //                     this.auth.removeStorageReferences();
    //                     this.navCtrl.navigateRoot(['/'])
    //                         .then(() => {
    //                             this.hideSplashScreen()
    //                         });
    //                 }
    //             });

    //         } else if (this.authenticated && !this.eventId) {
    //             this.auth.getUser().pipe(
    //                 take(1)
    //             ).subscribe((user: any) => {
    //                 if (user) {
    //                     this.global.loadService((_) => {
    //                         this.hideSplashScreen();
    //                         if (
    //                             this.global.userEvents &&
    //                             this.global.userEvents.length > 1
    //                         ) {
    //                             this.navCtrl.navigateRoot([
    //                                 '/user-events',
    //                                 user.uid
    //                             ]);
    //                         } else if (
    //                             this.global.userEvents &&
    //                             this.global.userEvents.length == 1
    //                         ) {
    //                             this.redirectUserToEvent(
    //                                 this.global.userEvents[0]
    //                             );
    //                         }
    //                     });
    //                 } else {
    //                     // Update connected analytics
    //                     this.SAnalytics.updateConnectedStatusUser(false);

    //                     this.auth.logout();
    //                 }
    //             });
    //         } else {
    //             // CASE DO NOT HAVE EVENT ID, CLEAR STORAGE REFERENCES AND REDIRECT TO LOGIN
    //             this.auth.removeStorageReferences();
    //             this.navCtrl.navigateRoot(['/']).then(() => {
    //                 this.hideSplashScreen();
    //             });
    //         }
    //     });
    // }

    // updateAccessAnalytics() {
    //     if (this.typeUser != TypeUser.ATTENDEE || this.contAccess !== 0) return;

    //     this.SAnalytics.userAccess(
    //         this.eventId,
    //         this.userId
    //     );
    //     const totalAccess = this.SUserData.userData.total_access ? this.SUserData.userData.total_access + 1 : 1;

    //     this.dbGeral.updateUserGeneral(
    //         {
    //             total_access: totalAccess,
    //             last_access: this.luxon.getTimeStampFromDateNow(
    //                 new Date(),
    //                 this.SEventData.eventData.timezone
    //             )
    //         },
    //         this.userId,
    //         this.eventId,
    //         this.typeUser
    //     );
    //     this.contAccess = 1;
    // }

    // redirectUserToEvent(eventId: string) {
    //     localStorage.setItem('eventId', eventId);
    //     this.global.previousPage = 'container';

    //     this.daoEvent.getEvent(eventId).subscribe((event) => {

    //         if (event) {
    //             this.updateAccessAnalytics();
    //         }

    //         localStorage.setItem('homePage', event.homePage);
    //         // this.SEventData.eventDataHomePage = event.homePage;     // TODO
    //         this.SUserData.getUserDataSnapshot(eventId).then((_) => {
    //             if (event.required_edit_profile == false) {
    //                 this.navCtrl.navigateRoot([event.homePage]).then((_) => {
    //                     if (_ == true) {
    //                         this.events.publish('updateScreen');
    //                     }
    //                 });
    //             } else if (
    //                 event.required_edit_profile == true &&
    //                 this.SUserData.userData.edited_profile == true
    //             ) {
    //                 this.navCtrl.navigateRoot([event.homePage]).then((_) => {
    //                     if (_ == true) {
    //                         this.events.publish('updateScreen');
    //                     }
    //                 });
    //             } else if (
    //                 event.required_edit_profile == true &&
    //                 (this.SUserData.userData.edited_profile == false ||
    //                     this.SUserData.userData.edited_profile == null)
    //             ) {
    //                 this.global.cantGoBackFromProfile = true;
    //                 this.navCtrl
    //                     .navigateRoot([
    //                         `/event/${eventId}/edit-profile/${this.SUserData.userData.moduleId}/${this.SUserData.userData.type}/${this.SUserData.userId}`
    //                     ])
    //                     .then((_) => {
    //                         if (_ == true) {
    //                             this.events.publish('updateScreen');
    //                         }
    //                     });
    //             }
    //         });
    //     });
    // }

    // setReferences() {
    //     console.log(`setReference`)
    //     this.global.loadService(async (status) => {
    //         if (status) {
    //             // obtém as informações do usuário, via global service
    //             this.typeUser = this.global.userType;
    //             this.photoUrl = this.global.photoUrl;
    //             this.displayName = this.global.displayName;
    //             this.userId = this.SUserData.userId;
    //             this.eventId = localStorage.getItem('eventId');
    //             this.moduleId = this.global.userModuleId;
    //             // await this.getEventOfTheAttendee(this.userId);



    //             // após logado, utiliza o idioma preferido do usuário, definido na conta
    //             if (
    //                 this.global.language !== null &&
    //                 this.global.language !== undefined &&
    //                 this.global.language !== ''
    //             ) {
    //                 this.setLanguageUse(this.global.language);
    //             }

    //             if (
    //                 this.userId == null &&
    //                 !localStorage.getItem('loggedLang')
    //             ) {
    //                 localStorage.setItem('loggedLang', 'false');
    //             }

    //             if (this.userId !== null && this.eventId !== null) {
    //                 this.daoEvent
    //                     .getEvent(this.eventId)
    //                     .subscribe((eventRes) => {
    //                         if (this.typeUser == TypeUser.ATTENDEE) {
    //                             this.allowChat = eventRes['allow_chat'];
    //                             console.log(`chat is allowed: ${this.allowChat}`)
    //                             if (this.allowChat) {
    //                                 this.startChatValues();
    //                             }
    //                         }
    //                         else { console.log(`chat inst allowed`) }
    //                     });

    //                 // Update analytics access
    //                 this.updateAccessAnalytics();

    //                 // start notification badges
    //                 this.startNotificationsValues();

    //                 this.subscribeNotification()

    //                 // start event values
    //                 this.startEventValues();
    //                 // redirect user
    //                 this.redirectUser();
    //                 // start user groups

    //                 //user must fill his profile almost once a time
    //                 this.firstEditProfile();

    //                 if (this.typeUser >= TypeUser.SPEAKER)
    //                     this.global.loadGroups(
    //                         this.userId,
    //                         this.typeUser,
    //                         this.eventId
    //                     );

    //                 this.observeNotifications();
    //                 this.global.loadModulesEvent();

    //                 if (this.typeUser <= 3 || this.typeUser == null) {
    //                     this.totalEvents = 0;
    //                 } else {
    //                     this.totalEvents = this.global.userEvents.length;
    //                 }
    //                 // this.hideSplashScreen();
    //             }
    //         } else {
    //             if (this.eventId !== null) {
    //                 if (this.SEventData.eventData.visibility) {
    //                     this.loadModules();
    //                     this.startEventValues();
    //                     // this.hideSplashScreen();
    //                 } else {
    //                     this.eventId = null;
    //                     this.auth.removeStorageReferences();
    //                     this.router.navigateByUrl('/').then(() => {
    //                         // this.hideSplashScreen();
    //                     });
    //                 }
    //             } else {
    //                 // this.hideSplashScreen();
    //             }
    //         }
    //     });
    // }

    // subscribeNotification() {
    //     this.notificationBadge = 0;

    //     if (!this.refAllNotif) {
    //         const ref = collection(this.firestore, `events/${this.eventId}/notifications`);
    //         const refQ = query(ref, orderBy('delivery_date', 'desc'));

    //         this.refAllNotif = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe(data => {
    //             const notifs = [...data].map(n => {
    //                 if (n.send_to == 'all') {
    //                     const userId = this.SUserData.userId;
    //                     if (n.forModule === -1 || n.forModule === undefined || n.forModule === TypeModule.SCHEDULE) {
    //                         return n
    //                     } else if (n.forModule === TypeModule.PERSONALSCHEDULE && n.users_ids.includes(userId)) {
    //                         return n
    //                     } else if (n.forModule === TypeModule.APPOINTMENTS && n.users_ids.includes(userId)) {
    //                         return n
    //                     } else {
    //                         return null
    //                     }
    //                 } else {
    //                     return (this.global.groupsAttendees.some(gr => n.groups_ids.includes(gr.uid))) ? n : null
    //                 }
    //             }).filter(_ => _ !== null)
    //             const filteredNotifs = [...notifs].filter(n => {
    //                 if (n.scheduled) {
    //                     return n.delivery_date >= this.SUserData.userData.notification_last_time && n.delivery_date <= (moment().valueOf() + 1500)
    //                 } else {
    //                     return n.delivery_date >= this.SUserData.userData.notification_last_time
    //                 }
    //             })
    //             const lastNotif = filteredNotifs[0]

    //             if (lastNotif !== undefined) {
    //                 const { titre, content } = this.getNotificationData(lastNotif)

    //                 !this.removedNotif.includes(lastNotif.uid) &&
    //                     !this.isMobile && this.showNotifPopup && this.presentNotifToast(titre, content, lastNotif.uid).then(x => {
    //                         const els = document.querySelectorAll('ion-toast');
    //                         els.forEach(item => {
    //                             if (item.id !== lastNotif.uid) {
    //                                 if (!item.id.includes('_globalMsg')) {
    //                                     item.style.top = '180px';
    //                                     setTimeout(() => item.remove(), 600);
    //                                 }
    //                             }
    //                         })
    //                     })
    //             }

    //             notifs.forEach(notif => {
    //                 const time = moment(notif.delivery_date).diff(moment(), 'milliseconds')
    //                 const { titre, content } = this.getNotificationData(notif)
    //                 if (notif.scheduled) {
    //                     time / 1000 >= -1 && time <= 86_400_000 &&
    //                         setTimeout(() => {
    //                             if (!this.isMobile && this.showNotifPopup) {
    //                                 this.presentNotifToast(titre, content, notif.uid).then(item => {
    //                                     const els = document.querySelectorAll('ion-toast');
    //                                     let c = 0;
    //                                     els.forEach(item => {
    //                                         if (item.id !== notif.uid) {
    //                                             if (!item.id.includes('_globalMsg')) {
    //                                                 item.style.top = '180px';
    //                                                 setTimeout(() => { item.remove() }, 600);
    //                                             }
    //                                         } else {
    //                                             if (c > 1) {
    //                                                 !item.id.includes('_globalMsg') && item.remove()
    //                                             }
    //                                             c++
    //                                         }
    //                                     })
    //                                 })
    //                             }
    //                         }, time);
    //                 }
    //             })
    //         })
    //     }
    // }
    /**
     * Init badges
     */
    // startNotificationsValues() {
    //     this.notificationBadge = 0;
    //     this.dateNow = this.notificationDate.getTimeStampFromDateNow(
    //         new Date(),
    //         this.SEventData.eventData.timezone
    //     );

    //     const ref = collection(this.firestore, `events/${this.eventId}/notifications`);
    //     const qc: QueryConstraint[] = [where('delivery_date', '<=', this.dateNow), orderBy('delivery_date', 'desc')]
    //     const refQ = query(ref, ...qc);

    //     collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data) => {
    //         this.getBadgeValue(data).then((response: number) => {
    //             // this.zone.run(() => {
    //             this.notificationBadge = response;
    //             if (this.notificationBadge >= 1 && this.global.notificationBadge <= 1) {
    //                 this.global.updateNotificationBadge(1, 'notify');
    //             }
    //             // });
    //         });
    //     })
    // }

    // /**
    //  * Init badges chat
    //  */
    // startChatValues() {
    //     if (
    //         this.typeUser >= TypeUser.SPEAKER &&
    //         this.typeUser <= TypeUser.ATTENDEE
    //     ) {
    //         if (!this.refChat) {
    //             const ref = collection(this.firestore, `events/${this.eventId}/chats`);
    //             const refQ = query(ref, where(`members.${this.SUserData.userId}.uid`, '==', this.SUserData.userId));

    //             this.refChat = collectionData(refQ).subscribe((data: any) => {
    //                     let auxBadgeChat = [];
    //                     data.forEach((element) => {
    //                         let last_access = (element['last_access']) ? element['last_access'][this.userId] : null;
    //                         let uid = element['uid'];

    //                         const ref = collection(this.firestore, `events/${this.eventId}/chats/${uid}/messages`);
    //                         const refQ = query(ref, orderBy('send_at', 'desc'));

    //                         collectionData(refQ)
    //                             .subscribe((dataT: any) => {
    //                                 dataT.forEach((elMsg) => {
    //                                     let msg = elMsg;
    //                                     if (!last_access || msg.send_at > last_access) {
    //                                         if (msg.from_user !== this.userId) {
    //                                             auxBadgeChat.push(element);
    //                                         }
    //                                     }
    //                                 });
    //                                 this.chatBadge = this.removeChatBadgeDouble(
    //                                     auxBadgeChat
    //                                 );
    //                                 if (
    //                                     this.chatBadge >= 1 &&
    //                                     this.global.chatBadge < 1
    //                                 ) {
    //                                     this.global.updateNotificationBadge(
    //                                         1,
    //                                         'chat'
    //                                     );
    //                                 }
    //                                 console.log('chatBadge', this.chatBadge)
    //                                 if (this.chatBadge > 0 && this.showChatPopup) {
    //                                     // const lastMsg = lastMsgs[0].lastMessage
    //                                     const lastMsg = [...dataT].filter(m => (m.send_at > last_access || !last_access) && (m.from_user !== this.userId))
    //                                         .sort((a, b) => b.send_at - a.send_at)[0]
    //                                     const title = lastMsg.send_from_user_name
    //                                     let content = '';

    //                                     if (lastMsg.appointment !== undefined) {
    //                                         switch (lastMsg.appointment.status) {
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.accepted", {
    //                                                     subject: lastMsg.appointment.subject
    //                                                 });
    //                                                 break;
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_REJECTED:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.rejected", {
    //                                                     subject: lastMsg.appointment.subject
    //                                                 });
    //                                                 break;
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_PENDING:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.pending", {
    //                                                     subject: lastMsg.appointment.subject,
    //                                                     date: lastMsg.appointment.startDateTime.toLocaleString({
    //                                                         locale: this.global.language.replace("_", "-"),
    //                                                         weekday: "short",
    //                                                         month: "2-digit",
    //                                                         day: "2-digit",
    //                                                         hour: "2-digit",
    //                                                         minute: "2-digit"
    //                                                     })
    //                                                 });
    //                                                 break;
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.cancelled", {
    //                                                     subject: lastMsg.appointment.subject
    //                                                 });
    //                                                 break;
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.rejected_automatically", {
    //                                                     subject: lastMsg.appointment.subject
    //                                                 });
    //                                                 break;
    //                                             case AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY:
    //                                                 content = '🔈 ' + this.translateService.instant("appointments.notification.cancelled_automatically", {
    //                                                     subject: lastMsg.appointment.subject
    //                                                 });
    //                                                 break;
    //                                         }
    //                                     } else {
    //                                         content = '🔈 ' + lastMsg.message
    //                                     }
    //                                     // const id = lastMsg.message.substr(0, 5).split(' ').join('') + '_' + uid + '_' + lastMsg.send_at;
    //                                     const id = 'chat_' + uid + '_' + lastMsg.send_at;
    //                                     const now = moment();
    //                                     const delivery_date = moment(lastMsg.send_at);
    //                                     let c = 0;
    //                                     if (now.diff(delivery_date, 'minutes') <= 2 && now.diff(delivery_date, 'minutes') > -1)
    //                                         if (!this.router.url.includes(`/chat/${uid}`) && !this.isMobile && this.showChatPopup) {
    //                                             this.presentNotifToast(title, content, id, 'chat').then(item => {
    //                                                 const els = document.querySelectorAll('ion-toast');
    //                                                 console.log('c ', c)
    //                                                 els.forEach(item => {
    //                                                     if (item.id !== id) {
    //                                                         if (!item.id.includes('_globalMsg')) {
    //                                                             item.style.top = '180px';
    //                                                             setTimeout(() => {
    //                                                                 item.remove();
    //                                                                 !item.id.includes('_') && this.removedNotif.push(item.id);
    //                                                             }, 600);
    //                                                         }
    //                                                     } else {
    //                                                         if (c > 1) {
    //                                                             item.remove()
    //                                                         }
    //                                                         c++;
    //                                                     }
    //                                                 })
    //                                             })
    //                                         }
    //                                 }
    //                             });
    //                     });
    //                 });
    //         }
    //     }
    // }

    // async startEventValues() {
    //     if (
    //         this.SEventData.eventData !== null &&
    //         !this.SEventData.eventData.visibility &&
    //         this.userId == null
    //     ) {
    //         this.callLogin();
    //     }

    //     if (this.SEventData.eventData.visibility && this.userId == null) {
    //         localStorage.setItem('eventVisible', 'true');
    //         if (
    //             localStorage.getItem('loggedLang')
    //             && localStorage.getItem('loggedLang') == 'false'
    //         ) {
    //             this.setLanguageUse(this.SEventData.eventData.language);
    //             this.navCtrl.navigateRoot([this.SEventData.eventDataHomePage]);
    //             // this.hideSplashScreen();
    //         }

    //         this.global.language = localStorage.getItem('usedLanguage');
    //     }
    //     // carrega as cores do evento
    //     if (
    //         this.global.language == '' ||
    //         this.global.language == null ||
    //         this.global.language == undefined
    //     ) {
    //         this.global.language = environment.platform.defaultLanguage;
    //     }
    //     localStorage.setItem('usedLanguage', this.global.language);
    //     this.translateService.setDefaultLang(this.global.language);
    //     this.translateService.use(this.global.language);

    //     this.showNotifPopup = this.SEventData.eventData.showNotifPopup !== undefined ? this.SEventData.eventData.showNotifPopup : true;
    //     this.showChatPopup = this.SEventData.eventData.showChatPopup !== undefined ? this.SEventData.eventData.showChatPopup : true;

    //     // this.menu_color = this.SEventData.eventDataColors.menu_color;
    //     // this.menu_text_color = this.SEventData.eventDataColors.menu_text_color;
    //     // this.menu_text_color2 = this.SEventData.eventDataColors.menu_text_color2;
    //     // this.active_menu_item = this.SEventData.eventDataColors.active_menu_item;
    //     // this.title_color = this.SEventData.eventDataColors.title_color;
    //     // this.text_content_color = this.SEventData.eventDataColors.text_content_color;
    //     // this.link_color = this.SEventData.eventDataColors.link_color;
    //     // this.bg_content_color = this.SEventData.eventDataColors.bg_content_color;
    //     // document.body.style.setProperty(`--active-menu-item`, this.active_menu_item)

    //     // document.body.style.setProperty('--tab-bar-color', this.menu_color);

    //     let toast = null
    //     const id = this.SEventData.eventData.uid + '_globalMsg'
    //     if (this.SEventData.eventData.globalMsg !== undefined && this.SEventData.eventData.globalMsg !== null && this.SEventData.eventData.globalMsg !== '') {
    //         // document.body.style.setProperty('--toast-bg', this.menu_color)
    //         // document.body.style.setProperty('--toast-color', this.menu_text_color)
    //         toast = await this.presentToast(this.SEventData.eventData.globalMsg, id)
    //         // console.log(toast)
    //     } else {
    //         if (toast !== null)
    //             (document.getElementById(id) as HTMLIonToastElement).remove();
    //         // this.toastController.dismiss()
    //     }
    //     if (this.SEventData.eventData.font !== undefined && this.SEventData.eventData.font !== null) {
    //         this.customFont = this.sanitizer.bypassSecurityTrustResourceUrl(`https://fonts.googleapis.com/css?family=${this.SEventData.eventData.font}:300,400,600,700`)
    //         // document.body.style.fontFamily = this.SEventData.eventData.font.split('+').join(' ') + ' !important'
    //         document.body.style.setProperty('--font', `'${this.SEventData.eventData.font.split('+').join(' ')}'`)
    //         // document.body.style.setProperty('--bg-img',`center / contain no-repeat url(${this.SEventData.eventDataColors.img_bg})`)
    //     }
    //     // const bg = this.SEventData.eventDataColors.img_bg !== '' ? `center / 100% 100% no-repeat url(${this.SEventData.eventDataColors.img_bg})` : this.bg_content_color
    //     // document.body.style.setProperty(`--bg-color-content`, bg);
    //     // const bgColor = this.bg_content_color.includes('rgba') ?
    //     //     this.bg_content_color.split(',').filter((item, i, tab) => i !== tab.length - 1).join(',') + ',1)' :
    //     //     this.bg_content_color;
    //     // document.body.style.setProperty(`--bg-color`, bgColor);

    //     // const bgModal = this.bg_content_color.includes('rgba') ? this.bg_content_color : `${bgColor.substr(0, bgColor.length - 1)},0.1)`.replace('rgb', 'rgba')
    //     // const style = document.createElement('style')
    //     // style.setAttribute('type', 'text/css')
    //     // style.setAttribute('type', 'text/css')
    //     // style.innerHTML = `.modal-order-by { --background: ${bgModal}; }`;

    //     // document.body.style.setProperty(`--bg-color-modal`, bgModal);


    //     this.global.getContainerData().then(container => {
    //         document.body.style.setProperty(`--valid-btn-bg-color`, container.valid_btn_bg_color);
    //         document.body.style.setProperty(`--btn-bg-color-hover`, `${container.valid_btn_bg_color}d9`);
    //         document.body.style.setProperty(`--text-color-1st-login`, container.text_color_1st_login);
    //         document.body.style.setProperty(`--text-color-2nd-login-desktop`, container.text_color_2nd_login_desktop);
    //         document.body.style.setProperty(`--text-color-2nd-login-mobile`, container.text_color_2nd_login_mobile);
    //         document.body.style.setProperty(`--label-login-color`, container.label_login_color);
    //         document.body.style.setProperty(`--ion-color-blue-default`, container.color_blue_default);
    //         document.body.style.setProperty(`--ion-color-blue-default-contrast`, container.color_blue_default_contrast);
    //         document.body.style.setProperty(`--ion-color-blue-default-shade`, container.color_blue_default_shade);
    //         document.body.style.setProperty(`--ion-color-blue-default-tint`, container.color_blue_default_tint);
    //     })

    //     this.bannerUrl = this.SEventData.eventData.banner.url;
    //     this.bannerVis = this.SEventData.eventData.visibiltyBanner
    //     this.allow_language = this.global.allow_language;

    //     if (this.typeUser >= TypeUser.SPEAKER)
    //         await this.global
    //             .loadGroups(this.userId, this.typeUser, this.eventId)
    //             .then((a) => { });
    //     if (this.SEventData.eventData['default_attendee_module'] !== '') {
    //         this.allowPublicRegister = true;
    //     } else {
    //         this.allowPublicRegister = false;
    //     }
    //     this.allowLogin = this.SEventData.eventData.allowLogin;
    //     this.allowRegistration = this.SEventData.eventData.allowRegistration;

    //     if (this.userId === null && this.allowRegistration
    //         && this.SEventData.eventData.firstRegistration !== false) {
    //         this.openPublicRegister();
    //     }


    // }

    // redirectUser(eventId: string) {
    //     // se o usuário estiver logado e a página que ele está fizer parte do login, redireciona para inicial do evento
    //     if (
    //         this.router.url == '/' ||
    //         this.router.url == '/start' ||
    //         this.router.url == '/login' ||
    //         this.router.url == '/select-login'
    //     ) {
    //         // this.allow_language = this.SEventData.eventData.allow_language;
    //         /**
    //          *  caso o evento não seja obrigatório editar o perfil no primeiro acesso
    //          *  redireciona para home do evento
    //          */
    //         this.SEventData.getEventDataSnapshot(eventId).then((eventData) => {
    //             if (eventData.required_edit_profile == false) {
    //                 this.navCtrl
    //                     .navigateRoot([eventData.homePage])
    //                     .then((_) => {
    //                         if (_ == true) {
    //                             // this.hideSplashScreen();
    //                         }
    //                     });
    //                 /**
    //                  * caso o evento seja obrigatório editar o perfil no primeiro acesso e o usuário já tenha editado
    //                  * redireciona para a home do evento
    //                  */
    //             } else if (
    //                 eventData.required_edit_profile == true &&
    //                 this.SUserData.userData.edited_profile == true
    //             ) {
    //                 this.navCtrl
    //                     .navigateRoot([eventData.homePage])
    //                     .then((_) => {
    //                         if (_ == true) {
    //                             // this.hideSplashScreen();
    //                         }
    //                     });
    //                 /**
    //                  * caso o evento seja obrigatório editar o perfil no primeiro acesso e o usuário ainda não editou
    //                  * redireciona para a página de editar perfil
    //                  */
    //             } else if (
    //                 eventData.required_edit_profile == true &&
    //                 this.SUserData.userData.edited_profile == false
    //             ) {
    //                 this.global.cantGoBackFromProfile = true;
    //                 this.navCtrl
    //                     .navigateRoot([
    //                         `/event/${eventId}/edit-profile/${this.SUserData.userData.moduleId}/${this.SUserData.userData.type}/${this.SUserData.userId}`
    //                     ])
    //                     .then((_) => {
    //                         // fecha a splash screen
    //                         if (_ == true) {
    //                             // this.hideSplashScreen();
    //                         }
    //                     });
    //             }
    //         });
    //     } else {
    //         // fecha a splash screen
    //         // this.hideSplashScreen();
    //     }
    // }

    // firstEditProfile(eventId: string, userId: string) {
    //     if (this.userId != null &&
    //         this.SEventData.eventData.required_edit_profile == true &&
    //         (this.SUserData.userData.edited_profile == undefined ||
    //             this.SUserData.userData.edited_profile == false)) {
    //         this.global.cantGoBackFromProfile = true;
    //         this.navCtrl
    //             .navigateRoot([
    //                 `/event/${this.SEventData.eventData.uid}/edit-profile/${this.SUserData.userData.moduleId}/${this.SUserData.userData.type}/${this.SUserData.userId}`
    //             ])
    //             .then((_) => {
    //                 // fecha a splash screen
    //                 if (_ == true) {
    //                     // this.hideSplashScreen();
    //                 }
    //             });
    //     }
    // }


    // getBadgeValue(data) {
    //     return new Promise((resolve) => {
    //         let auxBadge = [];
    //         let cont = 0;
    //         data.forEach(async (notification) => {
    //             if (this.SUserData.userLogged()) {
    //                 if (notification.send_to == 'all') {
    //                     if (this.SUserData.userData.notification_last_time !== undefined) {
    //                         if (
    //                             notification.delivery_date >
    //                             this.SUserData.userData.notification_last_time
    //                         ) {
    //                             if (notification.forModule === -1 || notification.forModule === undefined || notification.forModule === 0) {
    //                                 auxBadge.push(notification);
    //                             } else if (notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(this.SUserData.userId)) {
    //                                 auxBadge.push(notification);
    //                             } else if (notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(this.SUserData.userId)) {
    //                                 auxBadge.push(notification);
    //                             }
    //                         }
    //                     } else {
    //                         auxBadge.push(notification);
    //                     }
    //                     cont++;
    //                 } else {
    //                     await this.global.loadGroups(
    //                         this.SUserData.userId,
    //                         this.SUserData.userData.type,
    //                         this.SEventData.eventId
    //                     );
    //                     for (let index in notification.groups_ids) {
    //                         const pos = this.global.groupsAttendees
    //                             .map(function (e) {
    //                                 return e.uid;
    //                             })
    //                             .indexOf(notification.groups_ids[index]);
    //                         if (pos >= 0) {
    //                             if (
    //                                 this.SUserData.userData.notification_last_time !==
    //                                 undefined
    //                             ) {
    //                                 if (
    //                                     notification.delivery_date >
    //                                     this.SUserData.userData.notification_last_time
    //                                 ) {
    //                                     if (notification.forModule === -1 || notification.forModule === undefined || notification.forModule === 0) {
    //                                         auxBadge.push(notification);
    //                                     } else if (notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(this.SUserData.userId)) {
    //                                         auxBadge.push(notification);
    //                                     } else if (notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(this.SUserData.userId)) {
    //                                         auxBadge.push(notification);
    //                                     }
    //                                     // auxBadge.push(notification);
    //                                 }
    //                             } else {
    //                                 auxBadge.push(notification);
    //                             }
    //                             cont++;
    //                         } else {
    //                             cont++;
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 let noUserNotificationDate = localStorage.getItem(
    //                     'notificationTime'
    //                 );
    //                 if (notification.send_to == 'all') {
    //                     if (noUserNotificationDate !== undefined) {
    //                         if (
    //                             notification.delivery_date >
    //                             noUserNotificationDate
    //                         ) {
    //                             auxBadge.push(notification);
    //                         }
    //                     } else {
    //                         auxBadge.push(notification);
    //                     }
    //                     cont++;
    //                 } else {
    //                     cont++;
    //                 }
    //             }
    //             if (cont == data.length) {
    //                 resolve(auxBadge.length);
    //             }
    //         });
    //     });
    // }

    // loads the modules in the event menu
    // async loadModules() {
    //     // check if user is logged in. seeking the userId in the global service.
    //     // if (typeof this.typeUser === 'undefined' || this.typeUser === null) {
    //     //     this.userId = await this.global.loadUserId();
    //     // }
    //     this.userId = this.SUserData.userId;

    //     let actualEvent;
    //     this.daoEvent.getEvent(this.eventId).subscribe(event => {
    //         actualEvent = event;
    //         // if (this.allowReloadModules == true) {
    //         //     this.allowReloadModules = false;
    //         this.daoModules
    //             .getMenuModules(this.eventId)
    //             .pipe(
    //                 switchMap((modules) => {
    //                     let menuModules: any[] = [];

    //                     // Logged
    //                     if (this.userId) {
    //                         return this.store.select(getUserData).pipe(
    //                             mergeMap(async (userData) => {
    //                                 if (userData) {
    //                                     let menuModules: any[] = [];
    //                                     for (let module of modules) {
    //                                         // if the type of vision is limited by groups
    //                                         if (
    //                                             this.SUserData.userData.type >= TypeUser.SUPERGOD &&
    //                                             this.SUserData.userData.type <= TypeUser.EMPLOYEE
    //                                         ) {
    //                                             menuModules.push(module);
    //                                         } else if (
    //                                             module.typeVision ===
    //                                             TypeVisionGroup.GROUP_ACCESS_PERMISSION
    //                                         ) {
    //                                             if (
    //                                                 this.global.groupsAttendees ==
    //                                                 undefined
    //                                             ) {
    //                                                 await this.global.loadGroups(
    //                                                     this.userId,
    //                                                     this.SUserData.userData.type,
    //                                                     this.eventId
    //                                                 );
    //                                             }
    //                                             // tslint:disable-next-line: forin
    //                                             for (let index in module.access_groups) {
    //                                                 // checks whether the group belongs to the user groups
    //                                                 const pos = this.global.groupsAttendees
    //                                                     .map(function (e) {
    //                                                         return e.uid;
    //                                                     })
    //                                                     .indexOf(
    //                                                         module.access_groups[
    //                                                             index
    //                                                         ].uid
    //                                                     );

    //                                                 if (pos >= 0) {
    //                                                     menuModules.push(module);
    //                                                     break;
    //                                                 }
    //                                             }
    //                                         } else {
    //                                             menuModules.push(module);
    //                                         }
    //                                     }
    //                                     return of(menuModules).toPromise();
    //                                 } else {
    //                                     return of([]).toPromise();
    //                                 }
    //                             })
    //                         );
    //                     }
    //                     // not logged in
    //                     else {
    //                         for (let module of modules) {
    //                             if (actualEvent.visibility) {
    //                                 if (
    //                                     module.typeVision !== TypeVisionGroup.GROUP_ACCESS_PERMISSION &&
    //                                     module.visOnlyForLoggedUser !== undefined &&
    //                                     !module.visOnlyForLoggedUser
    //                                 ) {
    //                                     menuModules.push(module);
    //                                 }
    //                             } else {
    //                                 if (
    //                                     module.typeVision !== TypeVisionGroup.GROUP_ACCESS_PERMISSION
    //                                 ) {
    //                                     menuModules.push(module);
    //                                 }
    //                             }

    //                         }

    //                         return of(menuModules);
    //                     }
    //                 })
    //             )
    //             .subscribe((menuModules) => {

    //                 // First check for active menu when navigating
    //                 let check = false;
    //                 this.menuModules = [...menuModules].map(m => {

    //                     if (m && this.activeModuleUid == m.uid) {
    //                         m.active = true;
    //                         check = true;
    //                     } else {
    //                         m.active = false;
    //                     }
    //                     return m;
    //                 });

    //                 // If no menu navigated fall back to menu order 0
    //                 if (!check) {
    //                     this.menuModules.map(m => {
    //                         m.active = m.order === 0 && true;
    //                         return m;
    //                     });
    //                 }

    //                 //recebe os cinco primeiros modulos do array menuModules que contem todos os modulos disponiveis para o evento e disponibiliza na tab bar para a versao mobile
    //                 this.tabBarModules = this.menuModules.slice(0, 5);

    //                 // Get all feed news posts for new posts badges
    //                 this.menuModules.forEach((menuModule) => {
    //                     menuModule.newPosts = 0;
    //                     if (
    //                         menuModule.type == TypeModule.NEWS_FEED && 
    //                         this.SUserData.userLogged() && 
    //                         this.SUserData.userData.access && 
    //                         this.SUserData.userData.access['feedNews']
    //                     ) {
    //                         const user = this.SUserData.userData;
    //                         this.subscriptions.push(
    //                             this.SFeedNews.getNewsFeedsPost(this.SEventData.eventId, menuModule.uid).subscribe((posts: any[]) => {
    //                                 if (posts && posts.length > 0) {
    //                                     menuModule.newPosts = posts.filter((post) => post.active && (post.date * 1000) >= moment(user.access['feedNews']).valueOf()).length;
    //                                 }
    //                             })
    //                         )
    //                     }
    //                 })
    //             });
    //         // }
    //     })
    // }

    basicModuleRouting(module) {
        this.router.navigate([module.viewApp]);
    }

    routingToSessionDirectly(moduleId, sessionId, sessionFeedbackModule) {
        let navigationExtras: NavigationExtras = {
            state: {
                sessionFeedbackModule: sessionFeedbackModule
            }
        }

        this.router.navigate([`/event/${this.eventId}/schedule-detail/${moduleId}/${sessionId}`], navigationExtras);
    }

    routingToSpeakerDirectly(moduleId, speaker) {
        let navigationExtras: NavigationExtras = {
            state: {
                user: speaker
            }
        };
        this.router.navigate([`/event/${this.eventId}/profile/${moduleId}/4/${speaker.uid}`], navigationExtras);
    }

    routingToAttendeeDirectly(moduleId, attendee) {
        let navigationExtras: NavigationExtras = {
            state: {
                user: attendee
            }
        };
        this.router.navigate([`/event/${this.eventId}/profile/${moduleId}/${attendee.type}/${attendee.uid}`], navigationExtras);
    }

    /**
     * Check number of sesions/speaker/orators
     * @param module 
     */
    // checkNumberOfSub(module) {
    //     this.SWhereby.stopVisio.next(true);
    //     setTimeout(async () => {
    //         if (module.type == TypeModule.SCHEDULE) {
    //             this.dbSchedule.closeRefGetSessionsGroupsVision();

    //             this.daoFeedback.getFeedbackModule(this.SEventData.eventId, async (modules) => {
    //                 let sessionFeedbackModule = null;
    //                 if (modules.length >= 1) {
    //                     sessionFeedbackModule = modules[0].uid;
    //                 }

    //                 // verifies the type of vision of the module. global vision
    //                 if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
    //                     this.dbSchedule.getAllSessionsVisionGlobal(module.uid).pipe(take(1)).subscribe((sessions) => {
    //                         if (sessions.length == 1) {
    //                             this.routingToSessionDirectly(module.uid, sessions[0].uid, sessionFeedbackModule);
    //                         } else {
    //                             this.basicModuleRouting(module);
    //                         }
    //                     })
    //                 }

    //                 // group vision
    //                 if (module.typeVision === TypeVisionModule.GROUP_VISION) {
    //                     let groups = (this.global.groupsAttendees) ? this.global.groupsAttendees : await this.global.loadGroups(this.SUserData.userId, this.SUserData.userData.type, this.eventId);
    //                     this.dbSchedule.getSessionsGroupsVision(groups, module.uid).pipe(take(1)).subscribe((data) => {
    //                         if (data && data['sessions'] && data['sessions'].length == 1) {
    //                             this.routingToSessionDirectly(module.uid, data['sessions'][0].uid, sessionFeedbackModule);
    //                         } else {
    //                             this.basicModuleRouting(module);
    //                         }
    //                     })
    //                 }

    //                 //limited access by groups
    //                 if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
    //                     this.dbSchedule.getAllSessionsLimitedAccessByGroup(module.uid).pipe(take(1)).subscribe((sessions) => {
    //                         if (sessions.length == 1) {
    //                             this.routingToSessionDirectly(module.uid, sessions[0].uid, sessionFeedbackModule);
    //                         } else {
    //                             this.basicModuleRouting(module);
    //                         }
    //                     })
    //                 }
    //             })
    //         } else if (module.type == TypeModule.SPEAKER) {
    //             // verifies the type of vision of the module. global vision
    //             if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
    //                 this.dbSpeakers.getSpeakersAllGlobalVision(module.uid).pipe(take(1)).subscribe((speakers) => {
    //                     if (speakers.length == 1) {
    //                         this.routingToSpeakerDirectly(module.uid, speakers[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }

    //             // group vision
    //             if (module.typeVision === TypeVisionModule.GROUP_VISION) {
    //                 let groups = (this.global.groupsAttendees) ? this.global.groupsAttendees : await this.global.loadGroups(this.SUserData.userId, this.SUserData.userData.type, this.eventId);
    //                 this.dbSpeakers.getSpeakersGroupsVision(groups, 'asc', module.uid, (speakers) => {
    //                     if (speakers.length == 1) {
    //                         this.routingToSpeakerDirectly(module.uid, speakers[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }

    //             //limited access by groups
    //             if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
    //                 this.dbSpeakers.getSpeakersAllLimitedAccessByGroup(module.uid).pipe(take(1)).subscribe((speakers) => {
    //                     if (speakers.length == 1) {
    //                         this.routingToSpeakerDirectly(module.uid, speakers[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }
    //         } else if (module.type == TypeModule.ATTENDEE) {
    //             // verifies the type of vision of the module. global vision
    //             if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
    //                 this.dbAttendees.getAttendeesAllGlobalVision(module.uid).pipe(take(1)).subscribe((attendees) => {
    //                     if (attendees.length == 1) {
    //                         this.routingToAttendeeDirectly(module.uid, attendees[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }

    //             // group vision
    //             if (module.typeVision === TypeVisionModule.GROUP_VISION) {
    //                 let groups = (this.global.groupsAttendees) ? this.global.groupsAttendees : await this.global.loadGroups(this.SUserData.userId, this.SUserData.userData.type, this.eventId);
    //                 this.dbAttendees.getAttendeesGroupsVision(groups, 'asc', module.uid, (attendees) => {
    //                     if (attendees.length == 1) {
    //                         this.routingToAttendeeDirectly(module.uid, attendees[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }

    //             //limited access by groups
    //             if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
    //                 this.dbAttendees.getAttendeesAllLimitedAccessByGroup(module.uid).pipe(take(1)).subscribe((attendees) => {
    //                     if (attendees.length == 1) {
    //                         this.routingToAttendeeDirectly(module.uid, attendees[0]);
    //                     } else {
    //                         this.basicModuleRouting(module);
    //                     }
    //                 })
    //             }
    //         } else {
    //             this.basicModuleRouting(module);
    //         }
    //     }, 200)
    // }


    // getEventOfTheAttendee(userId: string): any {
    //     const userRef = this
    //         .afs
    //         .collection('users')
    //         .doc(userId);

    //     userRef.get().subscribe((user: any) => {
    //         if (user.exists) {
    //             this.eventsArray = user.data().events;
    //         }
    //     });
    // }

    // async openModule(module) {
    //     this.personalPageActive = false;
    //     this.menuModules = [...this.menuModules].map(m => {
    //         m.active = m.uid === module.uid ? true : false;
    //         this.activeModuleUid = module.uid;
    //         return m;
    //     })
    //     if (module.type !== TypeModule.EXTERNAL_LINK) {
    //         this.checkNumberOfSub(module);
    //     } else {
    //         this.inAppBrowser.openLink(module.link);
    //     }
    // }

    async callLogin() {
        if (this.onlyCallOne) {
            if (
                this.eventId == null ||
                this.eventId == undefined ||
                this.eventId == ''
            ) {
                if (this.route.snapshot.children.length >= 1) {
                    this.eventId = this.route.snapshot.children[0].params[
                        'eventId'
                    ];
                    localStorage.setItem('eventId', this.eventId);
                    // this.global.getActiveEvent();
                }
            }
            this.onlyCallOne = false;
            this.auth.removeStorageReferences();

            const modal = await this.modalCtrl.create({
                component: LoginpPage,
                componentProps: {
                    redirectUrl: this.router.url,
                    eventId: this.eventId
                },
                backdropDismiss: false
            });
            return await modal.present();
        }
    }

    // remove as conversas duplicadas do chat, para o número da badge ficar correto
    removeChatBadgeDouble(array) {
        let badgeArray = array.filter(function (el, i) {
            return array.indexOf(el) == i;
        });

        return badgeArray.length;
    }

    logout() {
        // Update connected analytics
        this.SAnalytics.updateConnectedStatusUser(this.eventId, false);

        this.auth.logout();
        this.global.resetService();
        this.eventId = null;
        this.userId = null;
        this.typeUser = null;
    }

    // volta para a lista de eventos do usuário
    // async confirmRedirectToContainer() {
    //     const alert = await this.alertCtrl.create({
    //         header: this.translateService.instant('global.alerts.list_events'),
    //         message: this.translateService.instant(
    //             'global.alerts.go_to_container_confirm'
    //         ),
    //         buttons: [
    //             {
    //                 text: this.translateService.instant(
    //                     'global.buttons.cancel'
    //                 ),
    //                 handler: () => { }
    //             },
    //             {
    //                 text: this.translateService.instant('global.buttons.go'),
    //                 cssClass: 'red-text',
    //                 handler: (blah) => {
    //                     this.redirectToContainer();
    //                 }
    //             }
    //         ]
    //     });

    //     await alert.present();
    // }
    // volta para o login
    async confirmBackToLogin() {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('global.alerts.exit_event'),
            message: this.translateService.instant(
                'global.alerts.confirm_exit_event'
            ),
            buttons: [
                {
                    text: this.translateService.instant(
                        'global.buttons.cancel'
                    ),
                    handler: () => { }
                },
                {
                    text: this.translateService.instant('global.buttons.exit'),
                    cssClass: 'red-text',
                    handler: (_) => {
                        // this.showSplashscreen();
                        this.auth.removeStorageReferences();
                        this.navCtrl.navigateRoot(['/']).then(() => {
                            window.location.reload();
                            // this.hideSplashScreen();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    // /**
    //  * Showing splashscreen dependent on platform
    //  */
    // async showSplashscreen() {
    //     if (this.platform.is('mobile')) {
    //         //this.splashScreen.show();  // todo: update plugin splash-screen
    //         await SplashScreen.show({ autoHide: false });
    //     } else {
    //         this.showSplashScreenWeb = true;
    //         //this.splashScreen.hide();  // todo: update plugin splash-screen
    //     }
    // }

    // /**
    //  * Hiding splashscreen dependent on platform
    //  */
    // async hideSplashScreen() {
    //     if (this.platform.is('mobile')) {
    //         //navigator.splashscreen.show()
    //         //this.splashScreen.hide();  // todo: update plugin splash-screen
    //         await SplashScreen.hide();
    //     } else {
    //         this.showSplashScreenWeb = false;
    //     }
    // }

    // redirectToContainer() {
    //     // Update connected analytics
    //     this.SAnalytics.updateConnectedStatusUser(this.eventId, false);

    //     this.allowReloadModules = true;
    //     localStorage.removeItem('eventId');
    //     // localStorage.removeItem('usedLanguage');
    //     localStorage.removeItem('timezone');
    //     localStorage.removeItem('homePage');

    //     this.events.publish('reloadContainerEvents');
    //     if (this.userId == null) {
    //         // if (!this.global.isBrowser)
    //         // this.showSplashscreen();
    //         // this.router.navigate
    //         this.navCtrl.navigateRoot(['/public']).then((_) => {
    //             window.location.reload();
    //             // if (!this.global.isBrowser)
    //             // this.hideSplashScreen();
    //         });
    //     } else {
    //         if (this.typeUser <= TypeUser.GOD) {
    //             // this.router.navigate
    //             this.navCtrl.navigateRoot(['/admin-events']);
    //             this.menuCtrl.close();
    //         } else if (this.typeUser == TypeUser.EMPLOYEE) {
    //             // this.router.navigate
    //             this.navCtrl.navigateRoot(['/c-events', this.SUserData.userData.clientId]);
    //             this.menuCtrl.close();
    //         } else if (this.typeUser == TypeUser.CLIENT) {
    //             // this.router.navigate
    //             this.navCtrl.navigateRoot(['/c-events', this.userId]);
    //             this.menuCtrl.close();
    //         } else if (
    //             this.typeUser >= TypeUser.SPEAKER &&
    //             this.typeUser <= TypeUser.ATTENDEE
    //         ) {
    //             // this.router.navigate
    //             this.navCtrl.navigateRoot(['/user-events', this.userId]);
    //             this.menuCtrl.close();
    //         }
    //     }
    // }

    // checa a linguagem do usuário e retorna a string do idioma no formato necessário
    checkLanguage(language: string) {
        if (language == 'PtBR') {
            return 'pt_BR';
        } else if (language == 'EnUS') {
            return 'en_US';
        } else if (language == 'FrFR') {
            return 'fr_FR';
        } else if (language == 'EsES') {
            return 'es_ES';
        } else if (language == 'DeDE') {
            return 'de_DE';
        } else {
            return this.checkLanguage(environment.platform.defaultLanguage);
        }
    }

    // seta a linguagem para uso do app
    async setLanguageUse(language: string) {
        if (!language) {
            language = this.checkLanguage(environment.platform.defaultLanguage);
        }
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
        // this.global.language = language;
        localStorage.setItem('usedLanguage', language);
    }

    // abre o modal para exibir notificações
    async openNotifications() {
        this.toastController.dismiss()
        const modal = await this.modalCtrl.create({
            component: PathComponent.notifications,
            componentProps: {
                eventId: this.eventId
            }
        });
        this.menuCtrl.close();
        return await modal.present();
    }

    // abre o modal de termos de uso
    async openLegalTerms() {
        const modal = await this.modalCtrl.create({
            component: PathComponent.terms_n_privacy,
            componentProps: {
                eventId: this.eventId
            }
        });
        return await modal.present();
    }

    // // login evento público
    // async openPublicLogin() {
    //     const modal = await this.modalCtrl.create({
    //         component: PathComponent.public_login,
    //         componentProps: {
    //             eventId: this.eventId,
    //             logoUrl: this.SEventData.eventData.logo.url,
    //             // menuColor: this.menu_color,
    //             // menuTxtColor: this.menu_text_color
    //         }
    //     });
    //     return await modal.present();
    // }

    // registro evento público
    // async openPublicRegister() {
    //     const modal = await this.modalCtrl.create({
    //         component: PathComponent.public_register,
    //         componentProps: {
    //             eventId: this.eventId,
    //             logoUrl: this.SEventData.eventData.logo.url,
    //             moduleId: this.moduleId,
    //             typeUser: this.typeUser,
    //             registerFirstTime: this.SEventData.eventData.firstRegistration
    //         }
    //     });
    //     // modal.onDidDismiss().then(data => 
    //     //     console.log('modal dissmed ',data)
    //     // );
    //     return await modal.present();
    // }

    // async openFillProfil() {
    //     const modal = await this.modalCtrl.create({
    //         component: PathComponent.profile_edit,
    //         componentProps: {
    //             eventId: this.eventId,
    //             userId: this.userId,
    //             logoUrl: this.SEventData.eventData.logo.url,
    //             // menuColor: this.menu_color,
    //             // menuTxtColor: this.menu_text_color,
    //             registerFirstTime: this.SEventData.eventData.firstRegistration
    //         }
    //     });
    //     // modal.onDidDismiss().then(data => 
    //     //     console.log('modal dissmed ',data)
    //     // );
    //     return await modal.present();
    // }

    // alterar idioma do app, baseado nos idiomas disponíveis no evento
    // async changeLanguage() {
    //     const picker = await this.pickerCtrl.create({
    //         columns: this.getLanguageColumns(),
    //         buttons: [
    //             {
    //                 text: this.translateService.instant(
    //                     'global.buttons.cancel'
    //                 ),
    //                 role: 'cancel'
    //             },
    //             {
    //                 text: this.translateService.instant('global.buttons.ok'),
    //                 handler: (v) => {
    //                     let lang = v['lang']['value'];
    //                     if (this.userId) {
    //                         this.dbGeral.updateUserGeneral(
    //                             { language: lang },
    //                             this.userId,
    //                             this.eventId,
    //                             this.typeUser
    //                         );
    //                     } else {
    //                         localStorage.setItem('loggedLang', 'true');
    //                     }
    //                     this.events.publish('languageUpdate', lang);
    //                 }
    //             }
    //         ]
    //     });
    //     // picker.columns[0].selectedIndex = this.selectedLangIndex();
    //     await picker.present();
    // }

    // // obtém as colunas da seleção de idioma
    // getLanguageColumns() {
    //     let columns = [];
    //     columns.push({
    //         name: 'lang',
    //         options: this.getColumnOptions()
    //     });
    //     return columns;
    // }

    // // retorna as colunas de seleção de idioma, com base nos idiomas disponíveis no evento
    // getColumnOptions() {
    //     let options = [];

    //     if (this.SEventData.eventData !== null) {
    //         if (
    //             this.SEventData.eventData.description['de_DE'] !== null &&
    //             this.SEventData.eventData.description['de_DE'] !== undefined
    //         ) {
    //             options.push({
    //                 text: this.translateService.instant('global.texts.de_DE'),
    //                 value: 'de_DE'
    //             });
    //         }

    //         if (
    //             this.SEventData.eventData.description['es_ES'] !== null &&
    //             this.SEventData.eventData.description['es_ES'] !== undefined
    //         ) {
    //             options.push({
    //                 text: this.translateService.instant('global.texts.es_ES'),
    //                 value: 'es_ES'
    //             });
    //         }

    //         if (
    //             this.SEventData.eventData.description['fr_FR'] !== null &&
    //             this.SEventData.eventData.description['fr_FR'] !== undefined
    //         ) {
    //             options.push({
    //                 text: this.translateService.instant('global.texts.fr_FR'),
    //                 value: 'fr_FR'
    //             });
    //         }

    //         if (
    //             this.SEventData.eventData.description['en_US'] !== null &&
    //             this.SEventData.eventData.description['en_US'] !== undefined
    //         ) {
    //             options.push({
    //                 text: this.translateService.instant('global.texts.en_US'),
    //                 value: 'en_US'
    //             });
    //         }

    //         if (
    //             this.SEventData.eventData.description['pt_BR'] !== null &&
    //             this.SEventData.eventData.description['pt_BR'] !== undefined
    //         ) {
    //             options.push({
    //                 text: this.translateService.instant('global.texts.pt_BR'),
    //                 value: 'pt_BR'
    //             });
    //         }
    //     }
    //     return options;
    // }

    // observeNotifications() {
    //     this.notificationsCollectionObserver = this.afs
    //         .collection('events')
    //         .doc(this.eventId)
    //         .collection('notifications');
    //     this.refNotificationsObserver = this.notificationsCollectionObserver
    //         .valueChanges()
    //         .subscribe((data: any) => {
    //             this.events.publish('reloadNotification');
    //         });
    // }

    wakeUpFunctions() {
        this.auth.verifyEmailDb('test@test.com', () => { });
        this.auth.verifyCodeNumber('123456', '12345678901234567890', () => { });
        this.auth.createAccount(null, null, null, null).then(() => { });
        let body = { eventId: null };
        this.requestOptions = { headers: this.headers, body: {} };
        this.headers = new HttpHeaders();
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
    }

    /**
     * Event for hamburger click
     */
    hamburgerClicked() {
        this.hamburgerActivated = !this.hamburgerActivated;
        if ((window.innerWidth < 768 || this.isMobile) && this.hamburgerActivated) {
            this.menuCtrl.close()
            this.hamburgerActivated = false;
        }
        this.global.hamburgerActivated.next(this.hamburgerActivated);
    }

    mouseEnter() {
        if (!this.isMobile) {
            const menu = document.querySelector('ion-menu');
            // menu.style.minWidth = 270 + 'px !important';
            // menu.style.maxWidth = '28% !important';
            menu.style.width = '295px !important';
            menu.style.minWidth = '295px !important';
            menu.style.transition = 'all 0.3s ease-in-out 0s';
            setTimeout(() => {
                this.hamburgerActivated = false
                this.global.hamburgerActivated.next(this.hamburgerActivated)
            }, 100);

        }
    }
    mouseLeave() {
        if (!this.isMobile) {
            const menu = document.querySelector('ion-menu');
            menu.style.minWidth = '80px !important';
            menu.style.maxWidth = '80px !important';
            menu.style.transition = 'all 0.3s ease-out 0s';
            this.hamburgerActivated = true
            this.global.hamburgerActivated.next(this.hamburgerActivated)
        }
    }
    // Mini video player part


    ionViewDidEnter() {
        this.SVideo.publish({ 'floating': false });
    }

    onClosed() {
        this.floating = false;
        this.src = null;
        this.type = null;
        this.playerCurrentTime = 0;
    }

    onExpanded(value: boolean) {
        console.log(value);
    }

    async presentToast(msg: string, id) {
        const toast = await this.toastController.create({
            message: msg,
            //   cssClass: 'toast-gloabl-msg'
        });
        // toast.style.setProperty('--background', this.menu_color);
        // toast.style.setProperty('--color', this.menu_text_color);
        // toast.style.background = this.menu_color;
        // toast.style.color = this.menu_text_color;
        toast.id = id
        // const length = document.querySelectorAll(`#${id}`).length
        // if(length > 1){
        //     document.querySelectorAll(`#${id}`).forEach((item,i) => {
        //         i !== 0 && item.remove()
        //     })
        // }
        toast.present();
        return toast;
    }


    toast: HTMLIonToastElement;

    // async presentNotifToast(title: string, msg: string, id?: string, type?: string) {
    //     const text = msg.trim().length > 195 ? msg.substr(0, 192) + '...' : msg
    //     const chatID = id.split('_')[1]

    //     let toast = await this.toastController.create({
    //         header: title,
    //         message: text,
    //         position: 'top',
    //         buttons: [
    //             {
    //                 side: 'end',
    //                 icon: 'paper-plane',
    //                 cssClass: 'btn-notif-toast',
    //                 handler: () => {
    //                     toast.remove()
    //                     type === 'chat' ? this.router.navigate([`/event/${this.eventId}/chat/${chatID}`]) : this.openNotifications()
    //                 }
    //             },
    //             {
    //                 side: 'end',
    //                 icon: 'close',
    //                 cssClass: 'btn-notif-toast',
    //                 // role: 'cancel',
    //                 handler: () => {
    //                     if (type == 'chat') {
    //                         this.global.updateNotificationBadge(0, 'chat');
    //                         this.chatBadge = this.global.chatBadge
    //                         const timestamp = this.luxon.getTimeStampFromDateNow(new Date(), this.SEventData.eventData.timezone);
    //                         this.gdService.updateLastAccess(this.eventId, chatID, timestamp);
    //                     } else {
    //                         this.dateNow = this.notificationDate.getTimeStampFromDateNow(
    //                             new Date(),
    //                             this.SEventData.eventData.timezone
    //                         );
    //                         if (this.SUserData.userLogged() && this.SUserData.userId !== null) {
    //                             this.daoGeral.updateUserGeneral(
    //                                 { notification_last_time: this.dateNow }, 
    //                                 this.SUserData.userId, 
    //                                 this.eventId, 
    //                                 this.SUserData.userData.type
    //                             );
    //                         }
                            
    //                         this.global.updateNotificationBadge(0, 'notify');
    //                         this.notificationBadge = this.global.notificationBadge
    //                         this.events.publish('clearNotificationBadge');
    //                         this.events.publish('clearBadgePropertie');
    //                     }
    //                 }
    //             }
    //         ]
    //     });
    //     toast.id = id
    //     toast.style.top = '60px'
    //     toast.style.marginRight = '0';
    //     toast.style.maxWidth = '660px';
    //     toast.style.width = '40%';
    //     toast.style.maxHeight = '200px';
    //     toast.style.left = 'calc(100vw - 40%)';
    //     toast.style.setProperty('--button-color', '#fff'); // pour changer la couleur des boutons

    //     const length = document.querySelectorAll(`#${id}`).length
    //     if (length > 1) {
    //         document.querySelectorAll(`#${id}`).forEach((item, i) => {
    //             i !== 0 && item.remove()
    //         })
    //     }
    //     toast.present();
    //     return toast;
    // }

    // getNotificationData(notif) {
    //     const result = { titre: '', content: '' }
    //     switch (this.global.language) {
    //         case 'pt_BR':
    //             result.titre = notif.headings.pt;
    //             result.content = notif.contents.pt;
    //             break;

    //         case 'en_US':
    //             result.titre = notif.headings.en;
    //             result.content = notif.contents.en;
    //             break;

    //         case 'es_ES':
    //             result.titre = notif.headings.es;
    //             result.content = notif.contents.es;
    //             break;

    //         case 'fr_FR':
    //             result.titre = notif.headings.fr;
    //             result.content = notif.contents.fr;
    //             break;

    //         case 'de_DE':
    //             result.titre = notif.headings.de;
    //             result.content = notif.contents.de;
    //             break;
    //     }
    //     return result;
    // }

    /**
     * @description Stop Scanner
     */
    stopScanner() {
        this.scan.stopScanner();
    }
}
