import { sessionFeedbackAnswer } from './sessionFeedbackAnswer';

export class sessionFeedbackQuestion {
    uid: string;
    title: string;
    infobooth: string;
    type: string;
    graphic: string;
    points: number;
    answers: Array<sessionFeedbackAnswer> = [];
    answered: boolean = false;
    createdAt: number;
    visibility: boolean;
    // maxResponses: number;

    constructor() {
        this.uid = null;
        this.title = null;
        this.type = null;
        this.graphic = null;
        this.answers =[];
    }
}