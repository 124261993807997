import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavController, NavParams } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService } from 'src/app/shared/services';
import { TypeUser } from 'src/app/models/type-user';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/providers/authentication/auth.service';
import { RegexProvider } from 'src/app/providers/regex/regex.service';
import { TermsNPrivacyComponent } from '../terms-n-privacy/terms-n-privacy.component';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';  // todo: update plugin splash-screen
import { ViewController } from '@ionic/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { PathComponent } from 'src/app/models/path/path-components';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { ActiveMenuIconsService, icons } from 'src/app/shared/services/active-menu-icons/active-menu-icons.service';

@Component({
    selector: 'app-public-register',
    templateUrl: './public-register.component.html',
    styleUrls: ['./public-register.component.scss'],
    providers: [RegexProvider, NavParams]
})
export class PublicRegisterComponent implements OnInit {
    activeLoader: boolean = false;
    logoUrl: string = null;
    menuColor: string = null;
    menuTxtColor: string = null;
    user;
    email: string = null;
    password: any = null;
    name: string = null;
    userType: number = 5;
    clientId: string = null;
    msgEmail;
    msgEmailExisted: boolean = false;
    attendeeFirstAccess: boolean;
    firstAccessView: boolean = false;
    switchLogin: boolean = false;
    msgPassword;
    msgName;
    msgGeral: any = null;
    eventId: string = null;
    termCheck: boolean;
    showPwd = false;
    firstTime: boolean;
    subscriptions: Subscription[] = [];
    customField_up: string = null;
    customField_down: string = null;
    createPass: any;
    confirmPass: any;

    authLogo = '';
    desktopBg = ''
    mobileBg =  ''
    isMobile = window.innerWidth < 768;
    isModal: boolean = false;

    constructor(
        private modalCtrl: ModalController,
        private events: Events,
        private auth: AuthService,
        public global: GlobalService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private regex: RegexProvider,
        private navCtrl: NavController,
        //private splashScreen: SplashScreen,  // todo: update plugin splash-screen
        private alertCtrl: AlertController,
        private userData: UserDataService,
        private SEventData: EventDataService,
        private navParams: NavParams,
        private SActiveMenuIcons: ActiveMenuIconsService
    ) {
        const url = this.route.snapshot.url
        this.isModal = !url.find((urlSegment) => (urlSegment.path == 'public_register'));
        
        if (this.isModal) {
            this.eventId = this.navParams.get('eventId');
            this.logoUrl = this.navParams.get('logoUrl');
            this.menuColor = this.navParams.get('menuColor');
            this.menuTxtColor = this.navParams.get('menuTxtColor');
            this.firstTime = (this.navParams.get('registerFirstTime') === undefined || this.navParams.get('registerFirstTime')) ? true : false;
        } else {
            this.eventId = this.route.snapshot.params['eventId'];
        }

        const edSubs = this.SEventData.getEventDataListener(this.eventId).subscribe((eventData) => {
            this.customField_up = eventData['customField_up'];
            this.customField_down = eventData['customField_down'];
            this.authLogo = eventData['authLogo']
            this.desktopBg = eventData['desktopBg']
            this.mobileBg = eventData['mobileBg']
        });
        this.subscriptions.push(edSubs);
    }

    ngOnInit() { }

    // loadUserData(callback) {
    //     this.userData.getUserDataSnapshot(this.eventId).then(() => {
    //         callback();
    //     });
    // }

    /**
     * @description redirect user to the public event, after he/she is created
     * and sign up in the event
     * @param data 
     */
    redirectToPublicEvent = (data: any): void => {
        if (!data) {
            this.msgGeral = this.translateService.instant("pages.login.generic_error");
            this.activeLoader = false;
            this.auth.logout();
            return;
        }

        Promise.all([
            this.SEventData.getEventDataSnapshot(this.eventId),
            this.userData.getUserDataSnapshot(this.eventId)
        ]).then(([eventData, userData]) => {
            localStorage.setItem('userIdentifier', userData.uid);
            this.close();
            if (eventData.firstRegistration && !userData.edited_profile) {
                this.userData.mustEditProfile = true;
                this.navCtrl.navigateRoot(`edit_profile/${this.eventId}/${userData.moduleId}/${userData.type}/${userData.uid}`);
            } else {
                this.navCtrl.navigateRoot(eventData.homePage);
            }
        }).catch((error) => {
            console.error(error);
            this.msgGeral = this.translateService.instant("pages.login.generic_error");
        }).finally(() => {
            this.activeLoader = false;
        });
    }

    /**
     * @description a callback method intendeed to be passed as an argument to 
     * the 'this.auth.verifyEmailDb()' method
     * @param user 
     */
    verifyEmailDb = (user): void => {
        this.user = user.result;
        if (user.result === 'email-not-found') {
            this.auth.createUserPublicEvent(
                this.eventId,
                this.name,
                this.email,
                this.password,
                this.SEventData.getLanguage(true),
                this.redirectToPublicEvent
            );
        } else {
            this.activeLoader = false;
            if (this.user.firstAccess === true) {
                this.firstAccessView = true;
                this.attendeeFirstAccess = true;
            }

            this.msgEmailExisted = this.translateService.instant('pages.login.first_access_txt_01');
            this.msgEmail = this.translateService.instant('pages.login.account_already_exist_with_email');
        }
    }

    createAccount() {
        this.msgEmail = null;
        this.msgName = null;
        this.msgPassword = null;
        this.msgGeral = null;

        if (!this.name || !this.name.trim()) {
            this.msgName = this.translateService.instant('pages.login.invalid_name');
            return;
        } 
        if (!this.email || !this.regex.validateEmail(this.email)) {
            this.msgEmail = this.translateService.instant('pages.login.invalid_email');
            return;
        } 
        if (!this.password  || (this.password.length < 6)) {
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
            return;
        } 
        if (!this.termCheck) {
            this.msgGeral = this.translateService.instant('pages.login.term_check_required');
            return;
        }
        
        this.activeLoader = true;
        this.auth.verifyEmailDb(this.email, this.verifyEmailDb);

        // this.auth.verifyEmailDb(this.email, (user) => {
        //     this.user = user.result;
        //     if (user.result === 'email-not-found') {
        //         this.auth.createUserPublicEvent(
        //             this.eventId,
        //             this.name,
        //             this.email,
        //             this.password,
        //             this.SEventData.getLanguage(true),
        //             (data) => {
        //                 localStorage.setItem('userIdentifier', data.uid);
        //                 Promise.all([
        //                     this.SEventData.getEventDataSnapshot(this.eventId),
        //                     this.userData.getUserDataSnapshot(this.eventId)
        //                 ]).then(([eventData, userData]) => {
        //                     if (eventData.firstRegister) {
        //                         this.navCtrl.navigateRoot(`edit-profile/${userData.moduleId}/${userData.type}/${userData.uid}`);
        //                     } else {
        //                         this.navCtrl.navigateRoot(eventData.homePage);
        //                     }
        //                 }).catch((error) => {
        //                     console.error(error);
        //                     this.navCtrl.navigateRoot("principal/:moduleId");
        //                 })

        //                 if (this.SEventData.eventLoaded()) {
        //                     const homePage = localStorage.getItem('homePage');
        //                     if (homePage) {
        //                         this.loadUserData(this.navCtrl.navigateRoot(homePage));
        //                     } else {
        //                         this.loadUserData(this.navCtrl.navigateRoot("principal/:moduleId"))
        //                     }
        //                 } else {
        //                     this.loadUserData((userData) => {
        //                         if (userData.type == TypeUser.SUPERGOD || userData.type == TypeUser.GOD) {
        //                             this.router.navigate(['/admin-events']);
        //                         } else if (userData.type == TypeUser.CLIENT || userData.type == TypeUser.EMPLOYEE) {
        //                             this.router.navigate(['/c-events', this.clientId]);
        //                         } else if (userData.type == TypeUser.SPEAKER || userData.type == TypeUser.ATTENDEE) {
        //                             this.router.navigate(['/user-events', data.uid]);
        //                         }
        //                     });
        //                 }
        //                 this.activeLoader = false;
        //             });
        //     } else {
        //         this.activeLoader = false;
        //         if (this.user.firstAccess === true) {
        //             this.firstAccessView = true;
        //             this.attendeeFirstAccess = true;
        //         }

        //         this.msgEmailExisted = this.translateService.instant('pages.login.first_access_txt_01');
        //         this.msgEmail = this.translateService.instant('pages.login.account_already_exist_with_email');
        //     }
        // });
    }

    createAccounFirstAccess() {
        this.msgPassword = null;
        console.log(this.email, this.createPass, this.user.uid, this.userType)
        if (this.createPass.length >= 6) {
            if (this.createPass == this.confirmPass) {
                if (this.termCheck) {
                    this.activeLoader = true;
                    this.auth.createAccount(this.email, this.createPass, this.user.uid, this.userType).then((success) => {
                        if (success['message'] == 'error') {
                                this.activeLoader = false;
                                this.msgEmailExisted = this.translateService.instant('pages.login.pass_error');
                                // this.msgCreateAccount = success.code;
                            }
                        else if (success['message'] == 'success') {
                            // call API sending old uid and new uid; 
                            this.password = this.createPass;
                            this.makeLogin();
                        }
                    }, (error) => {
                        this.activeLoader = false;
                        this.msgEmailExisted = this.translateService.instant('pages.login.pass_error');
                    })
                } else {
                    this.activeLoader = false;
                    this.msgPassword = this.translateService.instant('pages.login.term_check_required');
                }
            } else {
                this.activeLoader = false;
                this.msgPassword = this.translateService.instant('pages.login.password_not_match');
            }
        } else {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
        }
    }

    async openTermsOfUseAndPrivacy(type) {
        const modal = await this.modalCtrl.create({
            component: TermsNPrivacyComponent,
            componentProps: {
                eventId: this.eventId,
                type: type
            }
        });
        return await modal.present();
    }

    async openPublicLogin() {
        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        if (!eventData) { return; }

        this.close();
        const modal = await this.modalCtrl.create({
            component: PathComponent.public_login,
            showBackdrop: false,
            componentProps: {
                eventId: this.eventId,
                logoUrl: eventData.logo.url,
                menuColor: this.menuColor,
                menuTxtColor: this.menuTxtColor
            }
        });
        return await modal.present();
    }

    makeLogin() {
        this.msgPassword = null;
        this.activeLoader = true;
        this.email = this.email.toLowerCase();

        if (!this.password) {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
            return;
        }
        if (!this.termCheck) {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.term_check_required');
            return;
        }

        this.auth.login(this.email, this.password, (data) => {
            if (!data) {
                this.msgPassword = this.translateService.instant('pages.login.generic_error');
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/wrong-password') {
                this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/too-many-requests') {
                this.msgEmail = this.translateService.instant('pages.login.account_disabled_many_request');
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/argument-error') {
                this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                this.activeLoader == false;
                return;
            }

            const index = this.user['events'].map(function (e) { return e; }).indexOf(this.eventId);
            if (index > -1) {
                this.redirectToPublicEvent(data);
            } else {
                const params = {
                    eventId: this.eventId,
                    name: this.name,
                    email: data.user.email,
                    language: this.SEventData.getLanguage(true),
                    providerId: "password",
                    uid: data.user.uid
                };
                this.auth.signUpToPublicEvent(params).then(() => {
                    this.redirectToPublicEvent(data)
                }).catch((error) => { 
                    console.error(error);
                    this.msgPassword = this.translateService.instant('pages.login.generic_error');
                    this.activeLoader = false;
                    this.auth.logout();
                });

                // this.auth.verifyEmailDb(this.email, this.verifyEmailDb);
                // this.auth.verifyEmailDb(this.email, (data) => {
                //     if (data) {
                //         this.user = data;
                //     }
                // })
                // this.auth.createUserPublicEvent(this.eventId, this.user.name, this.user.email, this.password, this.user.language, (user_data) => {
                //     if (user_data.status == true) {
                //         this.events.publish('allowReloadModules');

                //         this.userData.getUserDataSnapshot(this.eventId).then(() => {
                //             this.events.publish('updateScreen');
                //             this.close();
                //         });
                //     } else {
                //         this.auth.logout();
                //         this.activeLoader = false;
                //     }
                //     this.activeLoader = false;
                // })
            }


            // else if (data.user['uid'] != null || data.user['uid'] != undefined) {
            //     localStorage.setItem('userIdentifier', data.user['uid']);
            //     if (this.eventId !== null && this.eventId !== '' && this.eventId !== undefined) {
            //         localStorage.setItem('eventId', this.eventId);
            //         let index = this.user['events'].map(function (e) { return e; }).indexOf(this.eventId);
            //         if (index > -1) {
            //             console.log(this.eventId)
            //             this.events.publish('allowReloadModules');

            //             this.userData.getUserDataSnapshot(this.eventId).then(() => {
            //                 this.events.publish('updateScreen');
            //                 this.close();
            //             });
            //         } else {
            //             console.log('Adding a new user to the event ...')//to delete
            //             this.auth.verifyEmailDb(this.email, (data) => {
            //                 if (data) {
            //                     this.user = data;
            //                 }
            //             })
            //             this.auth.createUserPublicEvent(this.eventId, this.user.name, this.user.email, this.password, this.user.language, (user_data) => {
            //                 if (user_data.status == true) {
            //                     this.events.publish('allowReloadModules');

            //                     this.userData.getUserDataSnapshot(this.eventId).then(() => {
            //                         this.events.publish('updateScreen');
            //                         this.close();
            //                     });
            //                 } else {
            //                     this.auth.logout();
            //                     this.activeLoader = false;
            //                 }
            //                 this.activeLoader = false;
            //             })
            //         }
            //     }
            // }
            // else {
            //     if (this.userType == TypeUser.SUPERGOD || this.userType == TypeUser.GOD) {
            //         this.events.publish('allowReloadModules');

            //         this.userData.getUserDataSnapshot(this.eventId).then(() => {
            //             this.events.publish('updateScreen');
            //             this.close();
            //             this.router.navigate(['/admin-events']);
            //         });
            //     } else if (this.userType == TypeUser.CLIENT || this.userType == TypeUser.EMPLOYEE) {
            //         this.events.publish('allowReloadModules');

            //         this.userData.getUserDataSnapshot(this.eventId).then(() => {
            //             this.events.publish('updateScreen');
            //             this.close();
            //             this.router.navigate(['/c-events', this.clientId]);
            //         });
            //     } else if (this.userType == TypeUser.SPEAKER || this.userType == TypeUser.ATTENDEE) {
            //         this.events.publish('allowReloadModules');

            //         this.userData.getUserDataSnapshot(this.eventId).then(() => {
            //             this.events.publish('updateScreen');
            //             this.close();
            //             this.router.navigate(['/user-events', data.user['uid']]);
            //         });
            //     } else if (data.code == 'auth/too-many-requests') {
            //         this.activeLoader == false;
            //         this.msgPassword = this.translateService.instant('pages.login.many_pass_errors');
            //     } else if (data.code == 'auth/argument-error') {
            //         this.activeLoader == false;
            //         this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
            //     } else {
            //         this.activeLoader == false;
            //         this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
            //     }
            // }
        });
    }

    switchToLogin() {
        this.password = null;
        this.msgEmail = null;
        this.switchLogin = true;
    }

    close() {
        if (this.isModal) {
            this.modalCtrl.dismiss();
        } 
        // else {
        //     if (this.SActiveMenuIcons.current != icons.modules) {
        //         this.SActiveMenuIcons.previousActiveIcon();
        //     }
        //     this.navCtrl.back()
        // }
    }

    closeWithoutReloading() {
        this.activeLoader == false;
        this.modalCtrl.dismiss();
    }

    showHidePwd() {
        this.showPwd = !this.showPwd;
    }
    async recoveryPassword() {
        const prompt = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: this.translateService.instant('pages.login.recovery_pass_txt'),
            inputs: [
                {
                    name: 'email',
                    placeholder: this.translateService.instant('pages.login.your_email_plc'),
                    value: this.email,
                    type: 'email'
                },
            ],
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.cancel'),
                    handler: data => {
                        // cancel operation
                    }
                },
                {
                    text: this.translateService.instant('pages.login.recovery_btn'),
                    handler: data => {
                        // check e-mail and recovery password
                        if (data.email !== null && data.email !== undefined && data.email !== null) {
                            this.auth.checkAuthEmail(data.email, (status) => {
                                if (status) {
                                    this.auth.recoveryPassword(data.email, (status) => {
                                        if (status == true) {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_success'))
                                        } else {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_fail'))
                                        }
                                    });
                                } else {
                                    // e-mail não encontrado
                                    this.alertRecoveryPass(this.translateService.instant('pages.login.email_not_found'))
                                }
                            })
                        } else {
                            // blank email error
                            this.alertRecoveryPass(this.translateService.instant('pages.login.blank_email'));
                        }
                    }
                }
            ]
        });
        prompt.present();
    }

    async alertRecoveryPass(message) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: message,
            buttons: [this.translateService.instant('global.buttons.ok')]
        });

        await alert.present();
    }
}
