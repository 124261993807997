import { Component, OnInit, NgZone, Input, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/shared/services';
import { ModalController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { NotificationDateService } from 'src/app/providers/date/notification-date.service';
import { 
    Firestore,
    collection,
    QueryConstraint,
    where,
    orderBy,
    query,
    collectionData, 
} from '@angular/fire/firestore';
import { DaoModulesService } from 'src/app/providers/db/dao-modules.service';
import { TypeUser } from 'src/app/models/type-user';
import { NotificationsComponent } from '../notifications/notifications.component';
import { Subscription, takeUntil } from 'rxjs';
import { EventColors } from 'src/app/models/event-colors';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-notifys-badges',
    templateUrl: './notifys-badges.component.html',
    styleUrls: ['./notifys-badges.component.scss']
})
export class NotifysBadgesComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    @Input() eventId: string = null;
    allowReloadNotifications: boolean = true;
    allowChat: boolean = false;
    notificationBadge: number = 0;
    chatBadge: number = 0;
    interval: any;
    intervalUserType: any;
    eventColors: EventColors = new EventColors();

    constructor(
        public global: GlobalService,
        private zone: NgZone,
        private notificationDate: NotificationDateService,
        private firestore: Firestore,
        private daoModules: DaoModulesService,
        private modalCtrl: ModalController,
        public events: Events,
        private Slogout: LogoutService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {

    }

    ngOnInit() {
        // this.global.loadService(() => { });
        this.events.subscribe('updateChatBadge', () => {
            let badge = this.global.notificationBadge;
            badge--;
            this.global.updateNotificationBadge(badge, 'chat');
        });

        this.events.subscribe('clearBadgePropertie', () => {
            this.notificationBadge = 0;
        })

        this.events.subscribe('menuBadge', () => {
            this.chatBadge = this.global.chatBadge;
        });
        
        this.events.subscribe('reloadNotification', () => {
            if (this.allowReloadNotifications) {
                this.allowReloadNotifications = false;
                this.notificationsUpdate();
                setTimeout(() => {
                    this.allowReloadNotifications = true;
                }, 5000);
            }
        });

        this.interval = setInterval(() => {
            this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
                if (!userData.moduleId) {
                    return;
                }
                clearInterval(this.interval);
                this.notificationsUpdate();
                this.subscriptions.push(this.daoModules.getModule(userData.moduleId).subscribe((moduleRes) => {
                    // this.intervalUserType = setTimeout(() => {
                    if (userData.type) {
                        clearInterval(this.intervalUserType);
                        if (userData.type == TypeUser.ATTENDEE) {
                            this.zone.run(() => {
                                this.allowChat = moduleRes['allow_chat'];
                                if (this.allowChat == true) {
                                    // this.chatUpdate();
                                }
                            })
                        }
                    }
                }));
            });
        }, 1000);

        this.loadColors();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    dateNow;
    notificationsUpdate() {
        this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
            this.dateNow = this.notificationDate.getTimeStampFromDateNow(new Date(), eventData.timezone);

            const ref = collection(this.firestore, `events/${this.eventId}/notifications`);
            const qc: QueryConstraint[] = [where('delivery_date', '<=', this.dateNow), orderBy('delivery_date', 'desc')];
            const refQ = query(ref, ...qc);
            
            collectionData(refQ).subscribe((data: any) => {
                this.notificationBadge = 0;
                this.getBadgeValue(data).then((response: number) => {
                    this.zone.run(() => {
                        this.notificationBadge = response;
                    });
                })
                if (this.notificationBadge >= 1) {
                    this.global.updateNotificationBadge(1, 'notify');
                }

            });
        });
    }

    getBadgeValue(data) {
        return new Promise(async (resolve, reject) => {
            const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
            if (!userData) { 
                return reject();
            }

            let auxBadge = [];
            let cont = 0;
            data.forEach(async notification => {
                if (this.SUserData.userLogged()) {
                    if (notification.send_to == 'all') {
                        if (userData.notification_last_time !== undefined) {
                            if (notification.delivery_date > userData.notification_last_time) {
                                auxBadge.push(notification);
                            }
                        } else {
                            auxBadge.push(notification);
                        }
                        cont++;
                    } else {
                        await this.global.loadGroups(this.SUserData.userId, userData.type, this.SEventData.eventId);
                        for (let index in notification.groups_ids) {
                            const pos = this.global.groupsAttendees.map(function (e) { return e.uid; }).indexOf(notification.groups_ids[index]);
                            if (pos >= 0) {
                                if (userData.notification_last_time !== undefined) {
                                    if (notification.delivery_date > userData.userNotificationTime) {
                                        auxBadge.push(notification);
                                    }
                                } else {
                                    auxBadge.push(notification);
                                }
                                cont++;
                            } else {
                                cont++;
                            }
                        }
                    }
                } else {
                    let noUserNotificationDate = localStorage.getItem('notificationTime');
                    if (notification.send_to == 'all') {
                        if (noUserNotificationDate !== undefined) {
                            if (notification.delivery_date > noUserNotificationDate) {
                                auxBadge.push(notification);
                            }
                        } else {
                            auxBadge.push(notification);
                        }
                        cont++;
                    } else {
                        cont++;
                    }
                }
                if (cont == data.length - 1) {
                    resolve(auxBadge.length);
                }
            });
        })
    }

    // chatUpdate() {
    //     const ref = collection(this.firestore, `events/${this.eventId}/chats`);
    //     const refQ = query(ref, where(`members.${this.SUserData.userId}`, '==', this.SUserData.userId));

    //     collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data: any) => {
    //         let auxBadgeChat = [];
    //         data.forEach(element => {
    //             let last_access = element['last_access'][this.SUserData.userId];
    //             let uid = element['uid'];

    //             const ref = collection(this.firestore, `events/${this.eventId}/chats/${uid}/messages`);
    //             const refQ = query(ref, orderBy('send_at', 'desc'));
                
    //             collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((dataT: any) => {
    //                 dataT.forEach(elMsg => {
    //                     let msg = elMsg;
    //                     if (msg.send_at > last_access) {
    //                         if (msg.from_user !== this.SUserData.userId) {
    //                             auxBadgeChat.push(element);
    //                         }
    //                     }
    //                 });
    //                 this.chatBadge = this.removeChatBadgeDouble(auxBadgeChat);
    //                 if (this.chatBadge >= 1 && this.global.chatBadge < 1) {
    //                     this.global.updateNotificationBadge(1, 'chat');
    //                 }
    //             });
    //         });
    //     });
    // }

    removeChatBadgeDouble(array) {
        let badgeArray = array.filter(function (el, i) {
            return array.indexOf(el) == i;
        });

        return badgeArray.length;
    }

    async openNotifications() {
        document.querySelectorAll('ion-toast').forEach(x => {
            if(x.id.length > 0)
                x.dismiss()
        })
        const modal = await this.modalCtrl.create({
            component: NotificationsComponent,
            componentProps: {
                'eventId': this.eventId
            }
        });
        return await modal.present();
    }

}