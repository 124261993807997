import { Component, NgZone } from '@angular/core';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoGamificationService } from 'src/app/providers/db/dao-gamification.service';
import { QRCode } from 'src/app/models/gaming-qrcode';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { BarcodeScannerService } from 'src/app/providers/barcode-scanner/barcode-scanner.service';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-gamification',
    templateUrl: './gamification.page.html',
    styleUrls: ['./gamification.page.scss'],
})
export class GamificationPage {
    eventId: string = null;
    moduleId: string = null;
    module = null;
    isMobile: boolean = false;

    eventColors: EventColors = new EventColors();

    // qrcodes: Array<QRCode> = [];
    totalQRCodes: number;
    scannedQRCodes: Object[];
    remainigQRCodes: number;

    // qrcodes: Array<any> = [];
    // qrs: Object = {};
    public readedQrCodes: number = 0;

    lastGames: any = [];
    allGames: Array<QRCode> = [];
    myGames: Array<QRCode> = [];
    totalPoints: string;
    loaded: boolean = false;

    subscriptions: Subscription[] = [];

    language: string;

    constructor(
        public global: GlobalService,
        private route: ActivatedRoute,
        private dbGamification: DaoGamificationService,
        private translateService: TranslateService,
        private toastCtrl: ToastController,
        private daoGeral: DaoGeralService,
        private SAnalytics: AnalyticsService,
        private router: Router,
        private zone: NgZone,
        public scan: BarcodeScannerService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        this.loadColors();
        this.loadModule();
        this.getQRCodes();
        
        this.language = this.SEventData.getLanguage();

        // save another module view access
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    loadModule() {
        this.dbGamification.getModule(this.moduleId, (module) => {
            this.module = module;
        })
    }

    /**
     * @description retrive all QR Codes related to the Gamification module
     */
    getQRCodes() {
        const sub = this.dbGamification.getCodes(this.moduleId).subscribe((data) => {
            let codes: Object = {};
            if (data.length >= 1) {
                data.forEach(doc => {
                    codes[doc.uid] = doc;
                });
            }

            // console.log(`codes1: ${JSON.stringify(codes)}`)
            this.totalQRCodes = Object.values(codes).length;
            this.userScannedQRCodes(codes);
        });
        this.subscriptions.push(sub);
    }

    /**
     * @description get the scanned QR Codes between all qr codes of Gamification module
     */
    userScannedQRCodes(qrcodes: Object) {
        const scannedQRCodesHandler = (scannedQRCodeIds: string[]) => {
            let qrs: Object = {};
            scannedQRCodeIds.forEach((scannedQRCode: string) => {
                if (qrcodes[scannedQRCode] != undefined) {
                    qrs[scannedQRCode] = qrcodes[scannedQRCode];
                }
            });
            this.scannedQRCodes = Object.values(qrs);
            this.remainigQRCodes = (this.totalQRCodes > this.scannedQRCodes.length) ? 
                this.totalQRCodes - this.scannedQRCodes.length : 
                0;
        }

        const sub = this.SUserData.getUserDataListener(this.eventId).subscribe((userData) => {
            const scannedQRCodeIds: string[] =  (userData.gaming != undefined) ? Object.keys(userData.gaming) : [];
            scannedQRCodesHandler(scannedQRCodeIds);
        });
        this.subscriptions.push(sub);
    }

    // scan qrcode to get points
    scanQr() {
        this.scan.scanSingleQRCode().then((result) => {
            this.dbGamification.checkQr(result, this.moduleId, (response) => {
                if (response.status) {
                    this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
                        const gaming = userData.gaming ? userData.gaming : {};

                        if (!gaming[result]) {
                            gaming[result] = { uid: result, checked: true };
                            const totalPoints = userData.points + response.qrcode.points;

                            this.daoGeral.updateUserGeneral(
                                { points: totalPoints, gaming: gaming }, 
                                userData.uid, 
                                this.eventId, 
                                userData.type
                            ).then(() => { this.showSuccessToast(); })
                            .catch(() => { this.showErrorToast(); });
                        } 
                        else {
                            // usuário já leu esse qrcode
                            this.showUserAlready();
                        }
                    });
                } 
                else {
                    // falhou, qrcode nao existe
                    this.showErrorToast();
                }
            });
        })
    }

    // check if already scanned this qrcode
    // checkAlreadyScanned(uid) {
    //     let gaming = userData.gaming ? userData.gaming : [];
    //     if (gaming.length >= 1) {
    //         return gaming.map(function (e) { return e.uid; }).indexOf(uid);
    //     } else {
    //         return -1;
    //     }
    // }

    // success QRCode Gaming
    async showSuccessToast() {
        const toast = await this.toastCtrl.create({
            message: this.translateService.instant('pages.gamification.success_scan'),
            duration: 4000
        });
        toast.present();
    }

    // error QRCode Gaming
    async showErrorToast() {
        const toast = await this.toastCtrl.create({
            message: this.translateService.instant('pages.gamification.error_scan'),
            duration: 4000
        });
        toast.present();
    }

    // user already scanned QRCode Gaming
    async showUserAlready() {
        const toast = await this.toastCtrl.create({
            message: this.translateService.instant('pages.gamification.already_scan'),
            duration: 4000
        });
        toast.present();
    }

    // error to read QRCode Gaming
    async showReadError() {
        const toast = await this.toastCtrl.create({
            message: this.translateService.instant('pages.gamification.scan_read_error'),
            duration: 4000
        });
        toast.present();
    }


}
