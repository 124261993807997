import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { FileService } from 'src/app/providers/file/file.service';

@Component({
    selector: 'app-lightbox-img',
    templateUrl: './lightbox-img.component.html',
    styleUrls: ['./lightbox-img.component.scss']
})
export class LightboxImgComponent implements OnInit {

    @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
    img: any;
    type = '';
    name = '';
    allowDownload = false;
    zoomer: boolean = false;
    downloading = false;
    sliderOpts = {
        zoom: {
            maxRatio: 5
        }
    };

    constructor(
        private navParams: NavParams,
        private modalController: ModalController,
        private fileService: FileService
    ) { }

    ngOnInit() {
        this.img = this.navParams.get('img');
        this.allowDownload = this.navParams.get('allowDownload');
        this.type = (this.navParams.get('type') || 'jpg');
        this.name = (this.navParams.get('name') || '');
    }

    zoom(zoomIn: boolean) {
        this.zoomer = zoomIn;
        let zoom = this.slider.nativeElement.swiper.zoom;
        if (zoomIn) {
            zoom.in();
        } else {
            zoom.out();
        }
    }

    async download() {
        if (!this.downloading) {

            this.downloading = true;

            this.fileService.createDir("EzbizEventImg").finally(() => {
                // if the directory already exits the promise is rejected, either way the
                // img is downloaded
                this.fileService.downloadFile(this.name, this.type, this.img, "EzbizEventImg");
                this.downloading = false;
            });
        }
    }

    close() {
        this.modalController.dismiss();
    }

}
