import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Timestamp } from '@angular/fire/firestore';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
    transform(timestamp: Timestamp): string {
        return DateTime.fromMillis(timestamp.toMillis()).toLocaleString(
            DateTime.DATETIME_SHORT
        );
    }
}
