import { Injectable } from '@angular/core';
import { 
    Firestore, 
    collection, 
    collectionData, 
    doc, 
    docData,
    addDoc
} from '@angular/fire/firestore';
import { take, takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
  providedIn: 'root'
})
export class DaoWordCloudService {

  constructor(
    private firestore: Firestore,
    private Slogout: LogoutService
  ) { }


  getModule(moduleId, onResolve) {
    const ref = doc(this.firestore, `modules/${moduleId}`);
    docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
        onResolve(doc);
    });
  }

  getClouds(moduleId, onResolve) {
    const ref = collection(this.firestore, `modules/${moduleId}/wordClouds`);
    collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
        onResolve(docs);
    });
  }

  getCloud(moduleId, cloudId, onResolve) {
    const ref = doc(this.firestore, `modules/${moduleId}/wordClouds/${cloudId}`);
    docData(ref).pipe(take(1)).subscribe((doc) => {
        onResolve(doc);
    });
  }

  setUserWordCloud(moduleId, cloudId, userId, answer, onResolve) {
    const ref = collection(this.firestore, `modules/${moduleId}/wordClouds/${cloudId}/words`);
    addDoc(ref, { userId: userId, answer: answer, })
        .then(() => { onResolve(true); })
        .catch(() => { onResolve(false); });
  }

}