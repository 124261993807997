import { Component, OnInit } from "@angular/core";
import { MenuController, NavController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { Container } from "../models/container";
import { DaoGeralService } from "../providers/db/dao-geral.service";
import { TypeLogin } from "../enums/type-login";
import { AppContainerService } from "../providers/app-container/app-container.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.page.html",
	styleUrls: ["./login.page.scss"]
})
export class LoginPage implements OnInit {
	loginLogoSize: string = environment.platform.loginLogoClass;
	loader: boolean = true;
	container: Container = {
		uid: null,
		clientId: null,
		appName: environment.platform.appName,
		loginPage: {
			type: TypeLogin.WITH_EMAIL_CONFIRM,
			eventId: null
		},
		loginOptions: {
			fbLoginBtn: false,
			gLoginBtn: false,
			registeBtn: false,
			shortcodeBtn: true
		},
		logo: "../../assets/images/logo.png",
		logoSize: "invent-logo",
		login1stBackground: "",
		login2ndBackground: "",
		color: null,
		valid_btn_bg_color: "",
		valid_btn_color: "",
		login_btn_bg_color: "",
		login_btn_color: "",
		text_color_1st_login: "",
		text_color_2nd_login_desktop: "",
		text_color_2nd_login_mobile: "",
		label_login_color: "",
		active_menu_item: "",
		color_blue_default: "",
		color_blue_default_contrast: "",
		color_blue_default_shade: "",
		color_blue_default_tint: ""
	};
	passwordScreen = false;
	isMobile = window.innerWidth < 768;
	constructor(
		private menu: MenuController,
		private daoGeral: DaoGeralService,
		private navCtrl: NavController,
        private SAppContainer: AppContainerService
	) {
		this.menu.enable(false);
	}

	ngOnInit() {
        this.SAppContainer.getContainerData().then((container: Container) => {
            document.body.style.setProperty(
                `--valid-btn-bg-color`,
                container.valid_btn_bg_color
            );
            document.body.style.setProperty(
                `--valid-btn-color`,
                container.valid_btn_color
            );
            document.body.style.setProperty(
                `--login-btn-bg-color`,
                container.login_btn_bg_color
            );
            document.body.style.setProperty(
                `--login-btn-color`,
                container.login_btn_color
            );
            document.body.style.setProperty(
                `--btn-bg-color-hover`,
                `${container.valid_btn_bg_color}d9`
            );
            document.body.style.setProperty(
                `--text-color-1st-login`,
                container.text_color_1st_login
            );
            document.body.style.setProperty(
                `--text-color-2nd-login-desktop`,
                container.text_color_2nd_login_desktop
            );
            document.body.style.setProperty(
                `--text-color-2nd-login-mobile`,
                container.text_color_2nd_login_mobile
            );
            document.body.style.setProperty(
                `--label-login-color`,
                container.label_login_color
            );
            // document.body.style.setProperty(`--active-menu-item`,container.active_menu_item)

            document.body.style.setProperty(
                `--ion-color-blue-default`,
                container.color_blue_default
            );
            document.body.style.setProperty(
                `--ion-color-blue-default-contrast`,
                container.color_blue_default_contrast
            );
            document.body.style.setProperty(
                `--ion-color-blue-default-shade`,
                container.color_blue_default_shade
            );
            document.body.style.setProperty(
                `--ion-color-blue-default-tint`,
                container.color_blue_default_tint
            );

            if (
                container &&
                container.loginPage &&
                container.loginPage.type == TypeLogin.PUBLIC_EVENT_HOME
            ) {
                this.container = container;
                this.daoGeral
                    .getEvent(container.loginPage.eventId)
                    .then((event: any) => {
                        this.loader = false;
                        this.navCtrl.navigateRoot([
                            `/${event.shortcode}`
                        ]);
                    }).catch((error) => {
                        console.error(error)
                        this.loader = false;
                    });
            } else if (container && container.loginPage) {
                this.container = container;
                this.loader = false;
            } else {
                this.loader = false;
            }
        });
    }

	changeScreen(msg: String) {
		if (msg === "showPasswordScreen") {
			this.passwordScreen = true;
		}
	}
}
