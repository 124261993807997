import { Injectable } from '@angular/core';
import { Training } from 'src/app/models/training';
import { Question } from 'src/app/models/training-question';
import { Answer } from 'src/app/models/training-answer';
import { TypeModule } from 'src/app/models/type-module';
import { NameModule } from 'src/app/models/path/name-module';
import { Firestore, collection, collectionData, deleteDoc, doc, docData, orderBy, query, setDoc, updateDoc, where, arrayUnion } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { firstValueFrom, take, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DaoTrainingService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) {}

    getTrainingModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.TRAINING))

        firstValueFrom(collectionData(refQ))
            .then((doc) => { onResolve(doc); });
    }

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        firstValueFrom(docData(ref))
            .then((snapshot) => { onResolve(snapshot); });
    }
    getTrainings(moduleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/trainings`);
        const refQ = query(ref, orderBy('order', 'asc'));

        let listTrainings: Array<Training> = [];

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            if (docs.length > 0) {
                docs.forEach((doc) => {
                    const training = this.instantiateTraining(doc);
                    if (training.visibility) {
                        let index = this.checkIndexExists(listTrainings, training);

                        if (index >= 0) {
                            listTrainings[index] = training;
                        } else {
                            listTrainings.push(training);
                        }
                    }
                });

                let cont = 0;
                for (let training of listTrainings) {
                    this.getQuestions(moduleId, training.uid, (questions) => {
                        training.questions = questions;
                        if (cont == listTrainings.length - 1) {
                            onResolve(listTrainings)
                        }

                        cont++;
                    })
                }
            } else {
                onResolve(listTrainings);
            }
        });
    }

    checkIndexExists(array, item) {
        return array.map(function (e) { return e.uid; }).indexOf(item.uid);
    }

    getTrainingsSession(eventId: string, moduleId: string, scheduleModuleId: string, sessionId: string, userId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/trainings`);
        const refQ = query(ref, orderBy('order', 'asc'));

        let listTrainings: Array<Training> = [];
        let listTrainingsSession: Array<Training> = [];

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe(async (data) => {
            if (data.length > 0) {
                data.forEach(element => {
                    let training = this.instantiateTraining(element);
                    if (training.visibility) {
                        let index = this.checkIndexExists(listTrainings, training);

                        if (index >= 0) {
                            listTrainings[index] = training;
                        } else {
                            listTrainings.push(training);
                        }
                    }
                });

                for (let training of listTrainings) {
                    if (training.type === 'AllSessions') {
                        listTrainingsSession.push(training);

                        onResolve(listTrainingsSession)

                    }
                    else if (training.type === 'ScheduleModule') {
                        if (scheduleModuleId === training.module_id) {
                            listTrainingsSession.push(training);
                        }

                        onResolve(listTrainingsSession)
                    }
                    else if (training.type === 'SessionTrack') {
                        // case this session be inside a track with permission, display feedback
                        await this.checkSpecificTrackTraining(training.module_id, sessionId, training.references, (tracks: Array<boolean>) => {
                            for (const track of tracks) {
                                if (track) {
                                    listTrainingsSession.push(training);
                                    break;
                                }
                            }

                            onResolve(listTrainingsSession)
                        });
                    }
                    else if (training.type === 'SpecificSession') {
                        for (let uid of training.references) {
                            if (uid === sessionId) {
                                listTrainingsSession.push(training);
                                break;
                            }
                        }

                        onResolve(listTrainingsSession)
                    }
                    else if (training.type === 'SpecificGroup') {
                        await this.listgroupsOfAttendee(eventId, userId, (listGroupsAttendee) => {
                            const references: any = training.references
                            let findGroup = false;
                            for (let uidGroup of references) {
                                for (const uidGroupUser of listGroupsAttendee) {
                                    //if the user is part of the group
                                    if (uidGroup == uidGroupUser && findGroup == false) {
                                        listTrainingsSession.push(training);
                                        findGroup = true;
                                        break;
                                    }
                                }

                            }

                            // if (cont == listTrainings.length - 1) {
                            onResolve(listTrainingsSession)
                            // }

                            // cont++;
                        })
                    } else if (training.type === 'Pattern') {
                        // if (cont == listTrainings.length - 1) {
                        onResolve(listTrainingsSession)
                        // }

                        // cont++;
                    }
                }
            } else {
                onResolve(listTrainingsSession);
            }
        })
    }

    getTraining(moduleId: string, trainingId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/trainings/${trainingId}`);
        firstValueFrom(docData(ref)).then((data) => {
            let training: Training = this.instantiateTraining(data);

            this.getQuestions(moduleId, trainingId, (questions) => {
                training.questions = questions;
                onResolve(training)
            })
        });
    }

    getQuestions(moduleId: string, trainingId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        let listQuestions: Array<Question> = [];

        firstValueFrom(collectionData(refQ)).then((data) => {
            data.forEach(element => {
                let question = this.instantiateQuestion(element);
                this.getAnswers(moduleId, trainingId, question.uid, (answers) => {
                    question.answers = answers;
                    listQuestions.push(question);
                    onResolve(listQuestions)
                })
            });
        })
    }

    getTotalQuestions(moduleId: string, trainingId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions`);
        firstValueFrom(collectionData(ref)).then((data) => {
            onResolve(data.length);
        })
    }

    getQuestionsAsync(moduleId: string, trainingId: string) {
        return new Promise((resolve, reject) => {
            let listQuestions: Array<Question> = [];

            const ref = collection(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions`);
            const refQ = query(ref, orderBy('createdAt', 'asc'));
            firstValueFrom(collectionData(refQ))
                .then((data) => {
                    data.forEach(element => {
                        let question = this.instantiateQuestion(element);
                        this.getAnswers(moduleId, trainingId, question.uid, (answers) => {
                            question.answers = answers;
                            listQuestions.push(question);
                            resolve(listQuestions)
                        })

                    });
                })
        })
    }

    getAnswers(moduleId: string, trainingId: string, questionId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions/${questionId}/answers`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        firstValueFrom(collectionData(refQ))
            .then((data) => {
                let listAnswers: Array<Answer> = [];
                data.forEach(element => {
                    let answer: Answer = this.instantiateAnswer(element);
                    listAnswers.push(answer);
                });
                onResolve(listAnswers);
            })
    }

    checkSpecificTrackTraining(trackModuleId, sessionId, tracksIds, onResolve) {
        let list = [];
        for (let trackId of tracksIds) {
            this.checkTrackTraining(trackModuleId, sessionId, trackId, (result: boolean) => {
                list.push(result);

                if (list.length == tracksIds.length) {
                    onResolve(list);
                }
            })
        }
    }

    // VERIFICA SE A TRACK EXISTE NA SESSÃO
    checkTrackTraining(moduleId, sessionId, trackId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/tracks/${trackId}/sessions/${sessionId}`);
        firstValueFrom(docData(ref))
            .then((data) => {
                if (data) {
                    onResolve(true);
                } else {
                    onResolve(false);
                }
            })
    }

    // VERIFICA SE A SESSÃO ESPECIFICA CORRESPONDE AO FEEDBACK
    // sessionOfTraining(eventId, trainingId, onResolve) {
    //     this.db.ref(this.SURVEY_CHILD).child(eventId).child(trainingId).child('references').once('value', (snapshot) => {
    //         const data = snapshot.val();
    //         let sessionIds = [];

    //         for (const uid in data) {
    //             sessionIds.push(uid);
    //         }
    //         onResolve(sessionIds);
    //     });
    // }

    listgroupsOfAttendee(eventId: string, attendeeId: string, onResolve) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        firstValueFrom(docData(ref)).then((doc) => {
                let groups = doc['groups'];
                let idsGroups: Array<String> = [];

                if (groups !== null && groups !== undefined) {
                    for (let uid in groups) {
                        idsGroups.push(uid);
                    }
                }
                onResolve(idsGroups);
            })
    }


    createResult(moduleId: string, userId: string, trainingId: string, IdQuestion: string, type: string, answer: any, timestamp: number, verifyQuestion: boolean, onResolve) {
        const questionResult = `modules/${moduleId}/trainings/${trainingId}/questions/${IdQuestion}/result`;
        
        if(userId !== null && userId !== undefined && userId !== '') {
            this.getResponseUserQuestion(moduleId, trainingId, IdQuestion, userId, (responses) => {
                this.deletePrevAnswersSelectOption(moduleId, trainingId, IdQuestion, userId, (result) => {
                    if (result == true) {
                        setDoc(
                            doc(this.firestore, `${questionResult}/${userId}`),
                            {
                                user: userId,
                                answer: answer,
                                timestamp: timestamp,
                                question: IdQuestion,
                                verifyQuestion: verifyQuestion
                            }
                        ).then(() => {
                            if (responses !== null && responses !== undefined) {
                                onResolve(false);
                            } else {
                                onResolve(true);
                            }
                        });
                    }
                })
            })
        } else {
            const refColl = collection(this.firestore, questionResult)
            const refDoc = doc(refColl);
            userId = refColl.id;

            setDoc(
                refDoc,
                {
                    user: userId,
                    answer: answer,
                    timestamp: timestamp,
                    question: IdQuestion,
                    verifyQuestion: verifyQuestion
                }
            )
            .then(() => {
                onResolve(true);
            })
        }
    }

    getResponseUsers(moduleId, trainingId, userId, onResolve) {
        this.getQuestions(moduleId, trainingId, (questions) => {
            let listResults = {};
            for (let i = 0; i < questions.length; i++) {
                this.getResponseUserQuestion(moduleId, trainingId, questions[i].uid, userId, (response) => {
                    if (response != null) {
                        listResults[response.question] = response;
                    }

                    if (i == questions.length - 1) {
                        onResolve(listResults);
                    }
                })
            }
        })
    }

    getResponseUserQuestion(moduleId, trainingId, questionId, userId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions/${questionId}/result/${userId}`);
        firstValueFrom(docData(ref))
            .then((data) => {
                if (data) {
                    onResolve(data)
                } else {
                    onResolve(null);
                }
            })
    }

    deletePrevAnswersSelectOption(moduleId, trainingId, IdQuestion, userId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/trainings/${trainingId}/questions/${IdQuestion}/result`);
        deleteDoc(ref).then(() => {
            onResolve(true)
        }).catch(() => {
            onResolve(false);
        })
    }

    instantiateTraining(data) {
        let training = new Training();
        training.title = data.title;
        training.type = data.type;
        training.uid = data.uid;
        training.visibility = data.visibility;
        training.change_answer = data.change_answer;
        training.view_answered = data.view_answered;
        training.icon = data.icon;
        training.iconFamily = data.iconFamily;

        data.active_timer != undefined ? training.active_timer = data.active_timer : training.active_timer = false;
        data.timer_questions != undefined ? training.timer_questions = data.timer_questions : training.timer_questions = null;
        data.max_responses != undefined ? training.max_responses = data.max_responses : training.max_responses = null;
        data.module_id != undefined ? training.module_id = data.module_id : training.module_id = null;
        data.references != undefined ? training.references = data.references : training.references = null;

        return training;  
    }

    instantiateQuestion(data) {
        console.log(data)
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        data.explanation != undefined ? question.explanation = data.explanation : question.explanation = new NameModule('', '', '', '', '');
        data.image != undefined ? question.image = data.image : question.image = null;
        data.maxResponses != undefined ? question.maxResponses = data.maxResponses : question.maxResponses = null;
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        answer.correct = data.correct;

        return answer;
    }

    setUserTrainingAnswered(eventId: string, attendeeId: string, surveyId: string, onResolve) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        updateDoc(
            ref,
            { answeredTrainings: arrayUnion(surveyId) }
        ).then(() => {
            onResolve(true);
        });
    }
}