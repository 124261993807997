import { Injectable } from '@angular/core';
import { 
    Firestore,
    collection, 
    query, 
    orderBy, 
    collectionData,
    doc, 
    docData
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';


@Injectable({
  providedIn: 'root'
})

export class DaoCustomPageService {
  constructor(
    private firestore: Firestore,
    private Slogout: LogoutService
  ) { }

  getModule(moduleId: string, onResolve) {
    const ref = doc(this.firestore, `modules/${moduleId}`);
    docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
        onResolve(doc);
    });
  }

  getPages(moduleId: string, onResolve) {
    const ref = collection(this.firestore, `modules/${moduleId}/pages`);
    const refQ = query(ref, orderBy('order'));

    collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
        onResolve(docs);
    });
  }
}
