import { Answer } from './training-answer';
import { NameModule } from './path/name-module';

export class Question {
    uid: string;
    title: string;
    infobooth: NameModule;
    explanation: NameModule;
    type: string;
    graphic: string;
    answers: Array<Answer> = [];
    maxResponses: number;
    image: any;
    answered = false;
    showTrue = false;
    visibility: boolean;
}