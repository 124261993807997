import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { GetVideoPlayer, UpdateStart, UpdateState } from "src/app/shared/actions/video-player.actions";
import { AppState } from "src/app/shared/reducers";
import { getBaseVideoState, getInSession, getState } from "src/app/shared/selectors/video-player.selectors";
import YTPlayer from "yt-player";
import Player from "@vimeo/player";
import { IVideoPlayer } from "src/app/shared/interfaces/video-player.interfaces";
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-video-player",
	templateUrl: "./video-player.component.html",
	styleUrls: ["./video-player.component.scss"]
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	@ViewChild("videoPlayer") videoPlayer: any;
	@Input("src") src: string;
	@Input("type") type: string;
	@Input("start") start: number = 0;
	@Input("width") width: any = 640;
	@Input("height") height: any = 360;
	@Input("expanded") expanded: boolean = false;
	@Input("floating") floating: boolean = false;
	@Input("localisation") localisation: string = "session";
	@Output() onExpanded = new EventEmitter<boolean>();
	resetVideoPlayer: boolean = false;

	youtubePlayer: YTPlayer;
	vimeoPlayer: Player;

	/**
	 * When true, the player is hidden (css)
	 * avoiding performance issues when dragging
	 */
	moving: boolean = false;
	init: boolean = false;
	position: string = "absolute";
	sessionId: string;
	videoPlayerState: IVideoPlayer;
	youtubeState: number = -1;
	inSession: boolean = false;

	apiLoaded = false;
	youtubePlayerReady: boolean = false;
    eventId: string;
    eventColors: EventColors = new EventColors();

	constructor(
        private store: Store<AppState>, 
        private platform: Platform, 
        private SEventData: EventDataService
    ) {}

	ngOnInit() {
		if (!this.apiLoaded) {
			// This code loads the IFrame Player API code asynchronously, according to the instructions at
			// https://developers.google.com/youtube/iframe_api_reference#Getting_Started
			const tag = document.createElement("script");
			tag.src = "https://www.youtube.com/iframe_api";
			document.body.appendChild(tag);
			this.apiLoaded = true;
		}

        this.eventId = (this.SEventData.eventData && this.SEventData.eventId) ? 
            this.SEventData.eventId : 
            localStorage.getItem("eventId");
        
        this.loadColors();

		this.subscriptions.push(
			this.store.select(getBaseVideoState).subscribe(async (videoPlayerState) => {
				this.videoPlayerState = videoPlayerState;
				if (videoPlayerState && videoPlayerState.src && videoPlayerState.type) {
					this.src = videoPlayerState.src ? videoPlayerState.src : "";
					this.type = videoPlayerState.type ? videoPlayerState.type : "";
					this.start = videoPlayerState.start ? videoPlayerState.start : 0;
					if (!this.sessionId && videoPlayerState && videoPlayerState.sessionId) {
						this.sessionId = videoPlayerState.sessionId;
					}

					// On youtube player seek and play video on update time or state if start > 0 (so already played)
					if (this.type === "youtube" && this.youtubePlayerReady && this.start && this.start > 0) {
						this.seekToYoutubeVideo(this.start, true);
						if (this.youtubeState === 1) {
							this.playYoutubeVideo();
						} else if (this.youtubeState === 2 || this.youtubeState === -1) {
							this.pauseWithoutUpdateYoutubeVideo();
						}
					}

					if (this.type == "vimeo" && !this.init) {
						if (!this.vimeoPlayer) {
							setTimeout(() => {
								this.initVimeoVideo();
							}, 200);
						} else {
							this.vimeoPlayer.setCurrentTime(this.start);
						}
					}
					this.init = true;
				} else {
					this.destroyPlayer();
				}
			})
		);

		// Get state of youtube player
		this.subscriptions.push(
			this.store.select(getState).subscribe((state) => {
				if (this.type && this.type === "youtube") {
					this.youtubeState = state;
				}
			})
		);

		//In session or not subscription
		this.subscriptions.push(
			this.store.select(getInSession).subscribe((inSession) => {
				this.inSession = inSession;
				if (inSession || inSession === null) {
					this.floating = false;
				} else {
					this.floating = true;
				}

				if ((!inSession && this.localisation == "session") || (inSession && this.localisation == "global")) {
					if (this.type == "youtube" && this.youtubePlayerReady) {
						if (this.sessionId === this.videoPlayerState.sessionId) {
							this.pauseYoutubeVideo();
						} else {
							this.stopYoutubeVideo();
						}
					} else if (this.type == "vimeo" && this.vimeoPlayer) {
						this.vimeoPlayer.pause();
					}
				}
			})
		);
	}

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            document.querySelectorAll("#btn-video-player").forEach((item) => {
                (<HTMLIonButtonElement>item).style.setProperty("--background", this.eventColors.link_color);
                (<HTMLIonButtonElement>item).style.setProperty("--color", this.eventColors.btn_text_color);
            });
        });
        this.subscriptions.push(subscription);
    }

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 *
	 * Youtube part
	 *
	 */

	/**
	 * On ready youtube video player
	 * @param evt
	 */
	onReady(evt) {
		if (evt.target.playerInfo.playerState === 5 || evt.target.playerInfo.playerState === -1) {
			this.youtubePlayerReady = true;

			// Pause video directly on ready / no auto play
			this.pauseWithoutUpdateYoutubeVideo();

			if (this.localisation === "global" && this.start > 0) {
				this.seekToYoutubeVideo(this.start, true);
			}
		}
	}

	/**
	 * On state change of youtube video player
	 * @param evt
	 */
	stateChange(evt) {
		// -1 = unstarted, 1 = playing, 2 = Paused, 3 = buffering, 5 = cued
		if (evt.data === -1 && this.youtubePlayerReady) {
			this.seekToYoutubeVideo(this.start, true);

			if (this.youtubeState === 2) {
				setTimeout(() => {
					this.pauseWithoutUpdateYoutubeVideo();
				}, 200);
			}
		}

		if (
			(evt.data === 1 && (this.inSession || this.localisation === "global")) ||
			(evt.data === 2 && (this.inSession || this.localisation === "global"))
		) {
			this.store.dispatch(new UpdateState(evt.data));
		}
	}

	onError(evt) {
		this.youtubePlayerReady = false;
	}

	/**
	 * Play youtube video
	 */
	playYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.playVideo();
		}
	}

	/**
	 * Pause youtube video and update time
	 */
	pauseYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.pauseVideo();
			this.updateCurrentTimeYoutubeVideo();
		}
	}

	/**
	 * Pause youtube video
	 */
	pauseWithoutUpdateYoutubeVideo() {
		this.videoPlayer.pauseVideo();
	}

	/**
	 * Stop and unload video
	 */
	stopYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.stopVideo();
		}
	}

	/**
	 * Go to number of seconds specified of the video
	 * @param seconds
	 * @param allowSeekAhead
	 */
	seekToYoutubeVideo(seconds: number, allowSeekAhead: boolean) {
		if (this.youtubePlayerReady) {
			this.videoPlayer.seekTo(seconds, allowSeekAhead);
		}
	}

	/**
	 * Get current time of youtube video
	 */
	getCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			return this.videoPlayer.getCurrentTime();
		}
	}

	/**
	 * Update current time of a youtube video on store
	 */
	updateCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			let time = this.videoPlayer.getCurrentTime();
			this.store.dispatch(new UpdateStart(time));
		}
	}

	destroyPlayer() {
		this.src = "";
		this.type = "";
		this.start = 0;
		if (this.vimeoPlayer) {
			this.vimeoPlayer.destroy();
			this.vimeoPlayer = null;
		}

		this.init = false;
	}

	/**
	 *
	 * Vimeo part
	 *
	 */

	/**
	 * Init a vimeo video
	 */
	initVimeoVideo() {
		if (this.src.includes("https")) {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				url: this.src
			});
		} else {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				id: this.src
			});
		}

		this.vimeoPlayer.setCurrentTime(this.start);
		if (this.start > 0) {
			this.vimeoPlayer.play();
		}

		this.vimeoPlayer.on("timeupdate", (currentTime) => {
			this.store.dispatch(new UpdateStart(Math.round(currentTime.seconds)));
		});
	}

	getWidth() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			if (this.localisation === "global") {
				return this.platform.width() + "px";
			} else {
				return this.platform.width() * 0.9 + "px";
			}
		} else {
			if (this.localisation === "global") {
				return "400px";
			} else {
				if (this.type === "vimeo") {
					return "768px";
				} else {
					return "100%";
				}
			}
		}
	}

	getHeight() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			return this.platform.height() * 0.4 + "px";
		} else {
			if (this.localisation === "global") {
				return "225px";
			} else {
				if (this.type === "vimeo") {
					return "432px";
				} else {
					return (this.platform.height() * 60) / 100 + "px";
				}
			}
		}
	}

	/**
	 * If true, the iframe is displayed.
	 * Otherwise, the <video></video>
	 */
	isYoutubeOrVimeo() {
		// youtu because can be youtube or youtu.be
		return ["youtu", "vimeo"].some((p) => (this.src || "").includes(p));
	}

	onExpandClick() {
		this.expanded = !this.expanded;

		this.onExpanded.emit(this.expanded);
	}

	onCloseClick() {
		this.store.dispatch(new GetVideoPlayer(null));
	}

	setFloating(newValue: boolean) {
		this.floating = newValue;
	}

	setStart(newValue: number) {
		this.start = newValue;
	}

	setSrc(newValue: string) {
		this.src = newValue;
	}

	setType(newValue: string) {
		this.type = newValue;
	}
}
