import { Injectable } from '@angular/core';
import { IMenuIcons } from '../../interfaces/menu-icons.interface';

export enum icons {
    home,
    personalPage,
    chat,
    notifications,
    modules
}

@Injectable({
    providedIn: 'root'
})
export class ActiveMenuIconsService {
    private prvsActvIcon: IMenuIcons = {
        inHomePage: true,
        inPersonalPage: false,
        inChat: false,
        inNotifications: false
    }

    private activeMenuIcon: IMenuIcons = {
        inHomePage: true,
        inPersonalPage: false,
        inChat: false,
        inNotifications: false
    }

    private _current: icons = icons.home;
    public get current(): icons {
        return this._current;
    }

    /**
     * @description 
     * @param icon accepts the following values: 'home' | 'personalPage' | 'chat' | 'notifications'
     */
    public changeActiveIcon(icon: string) {
        this.prvsActvIcon = this.activeMenuIcon;

        this.activeMenuIcon = {
            inHomePage: false,
            inPersonalPage: false,
            inChat: false,
            inNotifications: false
        }

        switch (icon) {
            case "home":
                this.activeMenuIcon.inHomePage = true;
                this._current = icons.home;
                break;
            case "personalPage":
                this.activeMenuIcon.inPersonalPage = true;
                this._current = icons.personalPage;
                break;
            case "chat":
                this.activeMenuIcon.inChat = true;
                this._current = icons.chat;
                break;
            case "notifications":
                this.activeMenuIcon.inNotifications = true;
                this._current = icons.notifications;
                break;
            case "modules":
                this._current = icons.modules;
                break;
            default:
                console.error(`Invalid value passed to 'icon' parameter`);
                return;
        }
    }

    /**
     * @description return the menu bar to its previous state, i.e. lights up 
     * the last icons to be activated and turn off all of the others.
     */
    public previousActiveIcon() {
        this.activeMenuIcon = this.prvsActvIcon;
    }

    constructor() { }
}
