import { FeedNewsPublishingPage } from './feed-news-publishing/feed-news-publishing.page';
import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	ActionSheetController,
	MenuController,
	ModalController,
	Platform,
	PopoverController,
	ToastController,
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { FeedCommentComponent } from "src/app/components/feed-comment/feed-comment.component";
import { LightboxImgComponent } from "src/app/components/lightbox-img/lightbox-img.component";
import { TypeTracking } from "src/app/enums/type-tracking";
import { Post } from "src/app/models/ceu-post";
import { TypeUser } from "src/app/models/type-user";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { StorageService } from "src/app/providers/storage/storage.service";
import { IAccessModule } from "src/app/shared/interfaces/access-module.interface";
import { AnalyticsService, GlobalService } from "src/app/shared/services";
import { DaoFeedNewsService } from "../../providers/db/dao-feed-news.service";
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { ShareService } from 'src/app/providers/share/share.service';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { stringify } from 'querystring';

@Component({
	selector: "app-feed-news",
	templateUrl: "./feed-news.page.html",
	styleUrls: ["./feed-news.page.scss"]
})
export class FeedNewsPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
    feedSub: Subscription = null;
	isMobile: boolean = false;
	public module = null;
	userLoggedId = null;

	eventId: string = null;
	moduleId: string = null;
	allowButtonsHeader: boolean = false;
	backBtn: boolean = true;

	menu_color1: string = null;
	menu_color2: string = null;
	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;

	posts: any[] = [];
    feedInitSize: number = 20;    // tamanho inicial e de expanção de posts por cada vez que o infiniteScroll é acionado
    feedSize: number = this.feedInitSize;    // tamanho total do feed

	myImg: string = null;
	textArea = "";
	loaderMyImg: boolean = false;
	loaderPost: boolean = false;

	// module
	isPublic = true;
	isNeed_moderate = true;
	isSocial_sharing = true;
	isComments = true;
	isLoading = true;

    language: string;

	constructor(
		private platform: Platform,
		private route: ActivatedRoute,
		private menuCtrl: MenuController,
		public global: GlobalService,
		private events: Events,
		private modalCtrl: ModalController,
		private modalCtrlWriteMessages: ModalController,
		private dbFeed: DaoFeedNewsService,
		private SAttendees: DaoAttendeesService,
		public actionSheetCtrl: ActionSheetController,
		private storage: StorageService,
		private actionSheetController: ActionSheetController,
		private translateService: TranslateService,
		private SAnalytics: AnalyticsService,
		private router: Router,
		public toastController: ToastController,
		public popoverController: PopoverController,
		private el: ElementRef,
        private share: ShareService,
        private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
	) {
		if (this.platform.is("ios") || this.platform.is("android")) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
		this.menuCtrl.enable(true);
        
        const params = this.route.snapshot.params;
		this.eventId = params.eventId;
		this.moduleId = params.moduleId;

		this.loadColors();
        this.language = this.SEventData.getLanguage();

		this.menuBadge = this.global.notificationBadge;
		this.events.subscribe("menuBadge", () => {
			this.menuBadge = this.global.notificationBadge;
		});

        //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	async ngOnInit() {
		if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}

		// if (this.router.url == this.SEventData.eventData.homePage) {
		// 	this.allowButtonsHeader = true;
		// }

        this.userLoggedId = this.SUserData.userId;

		// load module
		this.subscriptions.push(
			this.dbFeed.moduleNewsFeed(this.moduleId).subscribe((module: any) => {
				this.module = module;

				this.isPublic = module.public;
				this.isNeed_moderate = module.need_moderate;
				this.isSocial_sharing = module.social_sharing;
				this.isComments = module.comment;
			})
		);
		this.loadPosts();

		// Track analytics
		this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
		this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_FEED_MODULE);
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            let style: string = `.custom-fab {--background :${this.eventColors.link_color}; }`;

            this.createStyle(style);

            if (this.eventColors.menu_color.includes("linear-gradient")) {
                const colors = this.eventColors.menu_color.split(",");
                this.menu_color1 = colors[1];
                this.menu_color2 = colors[2].substr(0, colors[2].length - 1);
            } else {
                this.menu_color1 = this.menu_color2 = this.eventColors.menu_color;
            }
        });
        this.subscriptions.push(subscription);
    }
	
	createStyle(style: string): void {
		const styleElement = document.createElement('style');
		styleElement.appendChild(document.createTextNode(style));
		this.el.nativeElement.appendChild(styleElement);
	}

    /**
     * @description Include the first element that inst a match in the fetched 
     * data (database data). This is, a new post that was created. For example, 
     * if three posts were created, at every call of this function one of those 
     * posts will be included to the 'posts' property. So this function should 
     * be called the exact number of times equal to the number o created posts.
     * obs.: new posts will be close to the top (but not nessessarily at the 
     * top), thus, the data is already sorted.
     * @param data 
     */
    addNewPosts(data: any[], i?: number): void {
        i ??= data.findIndex((post, index) => {
            return (post.uid != this.posts[index].uid);
        });
        
        if (i == 0) {
            this.posts.unshift(data[i]);
            console.log(`this.posts.length 3: ${this.posts.length}`)
        } else if (i > 0) {
            this.posts.splice((i), 0, data[i]);
            console.log(`this.posts.length 4: ${this.posts.length}`)
        }
    }

    /**
     * @description remove all elements (posts) in the this.posts array that 
     * are not present in the retrieve data from the database, i.e. remove 
     * posts that were deleted from the backoffice.
     * @param dataIndex the index of where the arrays data and this.posts have 
     * different elements
     * @param data 
     */
    rmPosts(dataIndex, data: any[]) {
        console.log(`rmPosts`)
        let postIndex = dataIndex;
        for (; postIndex < this.posts.length; postIndex++) {
            if (this.posts[postIndex].uid == data[dataIndex].uid) {
                const arr = this.posts.splice(dataIndex, postIndex - dataIndex);
                // if (arr.length == 0) { break; }
                postIndex = dataIndex;
            }
        }
        if (
            (data.length > this.posts.length) && 
            (data[data.length - 1].uid != this.posts[this.posts.length - 1].uid)
        ) {
            this.posts.push(...(data.slice(this.posts.length)));
        }
    }

	/**
	 * Loading posts
	 */
	loadPosts() {
        if (this.feedSub) { this.feedSub.unsubscribe(); }

        const subs = this.dbFeed
            .getPostsNewsFeed(this.moduleId, this.feedSize)
            .subscribe((data) => {
                if (this.posts.length == 0) {
                    this.posts = data;
                } else if (data.length > this.posts.length) {
                    if (data[data.length - 1].uid != this.posts[this.posts.length - 1].uid) {
                        // add more posts as the user scrolls down
                        this.posts.push(...(data.slice(this.feedSize - this.feedInitSize)));
                    } else {
                        // new posts were created and the feed has already 
                        // loaded all posts
                        const newPosts: number = data.length - this.posts.length;
                        for (let i = 0; i < newPosts; i++) {
                            this.addNewPosts(data);
                        }
                    }
                } else {
                    const i = data.findIndex((post, index) => {
                        return (post.uid != this.posts[index].uid);
                    });

                    if (i > -1) {
                        if (data.length >= i + 1 && data[i + 1] == this.posts[i]) {
                            // new posts were created and the feed hasnt loaded all 
                            // available posts
                            this.addNewPosts(data, i);
                        } else {
                            this.rmPosts(i, data);
                        }
                    } else if (this.posts.length > data.length) {
                        // in case every single posts exists in the retrieved 
                        // data but the last one 
                        this.posts.splice(this.posts.length - 1);
                    }
                }

                this.feedSize = this.posts.length;
                this.isLoading = false;
            });

        this.feedSub = subs;
	}

	// zoom profile picture image
	openImage(url: string) {
		this.modalCtrl
			.create({
				component: LightboxImgComponent,
				componentProps: {
					img: url
				}
			})
			.then((modal) => {
				modal.present();
			});
	}

	async viewComments(uid) {
		const modal = await this.modalCtrl.create({
			component: FeedCommentComponent,
			componentProps: {
				eventId: this.eventId,
				moduleId: this.moduleId,
				postId: uid,
				userLoggedId: this.userLoggedId
			}
		});
		return await modal.present();
	}

	// account the number of likes in the post.
	contLikes(likes) {
		let cont = 0;

		for (let i in likes) {
			cont++;
		}

		return cont;
	}

	// verifies if the user liked the post
	verifiesLikedPost(post) {
		return post.likes[this.userLoggedId] ? true : false;
	}

	/**
	 * Add or remove like in a post
	 * @param post
	 */
	addOrRemoveLike(post) {
		if (this.SUserData.userLogged()) {
			if (!this.verifiesLikedPost(post)) {
				post.likes[this.userLoggedId] = true;
				post.totalLikes = post.totalLikes + 1;

				this.dbFeed.updatePost(post, this.eventId);

				// Track like analytics
				this.SAnalytics.sendOrRemoveLikeOnFeed(this.eventId, post, "send", TypeTracking.SEND_LIKE_ON_FEED);
			} else {
				delete post.likes[this.userLoggedId];
				post.totalLikes = post.totalLikes - 1;

				this.dbFeed.updatePost(post, this.eventId);

				// Track like analytics
				this.SAnalytics.sendOrRemoveLikeOnFeed(this.eventId, post, "remove", TypeTracking.REMOVE_LIKE_ON_FEED);
			}
		}
	}

	// account the number of comments in the post.
	contComments(comments) {
		let cont = 0;

		for (let i in comments) {
			cont++;
		}

		return cont;
	}

	async getImageToSend() {
		this.loaderMyImg = true;

		// const iosConfig = {
		// 	buttons: [
		// 		{
		// 			text: this.translateService.instant("global.buttons.cancel"),
		// 			role: "destructive",
		// 			icon: "close",
		// 			handler: () => {
		// 				this.loaderMyImg = false;
		// 			}
		// 		},
		// 		{
		// 			text: this.translateService.instant("global.buttons.take_picture"),
		// 			icon: "camera",
		// 			handler: () => {
		// 				this.storage.takePicture((data) => {
		// 					this.myImg = data;
		// 					if (this.myImg) {
		// 						this.loaderMyImg = false;
		// 					}
		// 				});
		// 			}
		// 		},
		// 		{
		// 			text: this.translateService.instant("global.buttons.open_gallery"),
		// 			icon: "photos",
		// 			handler: () => {
		// 				this.storage.openGallery((data) => {
		// 					this.myImg = data;
		// 					if (this.myImg) {
		// 						this.loaderMyImg = false;
		// 					}
		// 				});
		// 			}
		// 		}
		// 	]
		// };

		// const androidConfig = {
		// 	buttons: [
		// 		{
		// 			text: this.translateService.instant("global.buttons.cancel"),
		// 			role: "destructive",
		// 			icon: "close",
		// 			handler: () => {
		// 				this.loaderMyImg = false;
		// 			}
		// 		},
		// 		{
		// 			text: this.translateService.instant("global.buttons.open_gallery"),
		// 			icon: "photos",
		// 			handler: () => {
		// 				this.storage.openGallery((data) => {
		// 					this.myImg = data;
		// 					if (this.myImg) {
		// 						this.loaderMyImg = false;
		// 					}
		// 				});
		// 			}
		// 		}
		// 	]
		// };

		// const actionSheet = await this.actionSheetController.create(
		// 	this.platform.is("ios") ? iosConfig : androidConfig
		// );
		// await actionSheet.present();
        
        this.storage.takePicture((data) => {
            this.myImg = data;
            if (this.myImg) {
                this.loaderMyImg = false;
            }
        });
    }

	/**
	 * Get an image to send from web
	 * @param ev
	 */
	getImageToSendFromWeb(ev) {
		this.loaderMyImg = true;
		const fileWeb: File = ev.target.files[0];

		const reader = new FileReader();
		reader.readAsDataURL(fileWeb);
		reader.onload = () => {
			this.myImg = reader.result as string;
			if (this.myImg) {
				this.loaderMyImg = false;
			}
		};
		reader.onerror = (error) => {
			this.loaderMyImg = false;
		};
	}

	// create post
	async createPost(form) {
		// if (this.global.userLogged()) {
		this.loaderPost = true;

		// get uid post
		const postId = this.dbFeed.createIdPost();
		const fixedTop = 1;
		const description = this.textArea;

        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
        let creator = null;
        if (userData) {
            creator = {
                name: userData.name,
                img: {
                    url: userData.photoUrl,
                    uid: userData.uid
                },
                moduleId: userData.moduleId,
                type: userData.type,
                uid: userData.uid
            };   
        }

		// create object img
		let img: any = {
			name: "",
			url: ""
		};
		// create url storage
		if (this.myImg !== null && typeof this.myImg !== "undefined") {
			img["url"] = await this.storage.uploadImgNewsFeed(this.myImg, postId, this.eventId, this.moduleId);
		}

		let active;
		if (!this.isNeed_moderate) {
			active = true;
		} else {
			active = false;
		}
		console.log(postId, this.moduleId, this.eventId, img, description, creator, fixedTop, active);
		const post = new Post(postId, this.moduleId, this.eventId, img, description, creator, fixedTop, active);
        //post.date = this.dt.dbTimezone();

		this.dbFeed
			.createPost(post, this.eventId)
			.then((_) => {
				this.loaderPost = false;
				this.myImg = null;
				this.textArea = "";

				if (this.isNeed_moderate) {
					this.presentToastFeedModerate();
				}
			})
			.catch((e) => {
				this.loaderPost = false;
			});
		// }
	}

	deletePost(post) {
		// this.dbFeed.deletePost(post, this.eventId);
	}

	async getBase64ImageFromUrl(imageUrl) {
		var res = await fetch(imageUrl);
		var blob = await res.blob();

		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.addEventListener(
				"load",
				function () {
					resolve(reader.result);
				},
				false
			);

			reader.onerror = () => {
				return reject(this);
			};
			reader.readAsDataURL(blob);
		});
	}

	// displayed tost with the moderation message.
	async presentToastFeedModerate() {
		const toast = await this.toastController.create({
			message: this.translateService.instant("global.alerts.post_moderated"),
			duration: 3000
		});

		toast.present();
	}

	async moreOptions(post: Post) {
        //todo: social-sharing
        let images: string[] = new Array<string>();
        if ((typeof post.img.url) === "string") {
            images.push(post.img.url);
        }
        else {
            images = post.img.url;
        }
        this.share.share(null, post.description, null, images);
	}

	redirectProfile(creator: any) {
		if (creator.moduleId !== null && creator.moduleId !== undefined && creator.moduleId !== "") {
			this.router.navigateByUrl(
				`/event/${this.eventId}/profile/${creator.moduleId}/${creator.type}/${creator.uid}`
			);
		}
	}

	ionViewWillEnter() {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            if (userData.type != TypeUser.ATTENDEE) {
                return;
            }

            const newAccess: IAccessModule = (userData.access) ? 
                _.cloneDeep(userData.access) : 
                this.SAttendees.initAccessModule();

			newAccess.feedNews = moment().toISOString();
			this.SAttendees.updateAccessModule(this.eventId, this.userLoggedId, newAccess);
        });
	}

    // carrega mais posts no feed a medida que o usuário vai descendo a pagina do feed
    loadData(event) {
        setTimeout(() => {
            this.feedSize += this.feedInitSize;
            this.loadPosts();

            event.target.complete();

            if (this.feedSize == 1000) {
                event.target.disable = true;
            }

        }, 500);
    }

	async showModal() {
		const modal = await this.modalCtrlWriteMessages.create({
			component: FeedNewsPublishingPage,
			componentProps:{ 
				eventId: this.eventId,
				moduleId: this.moduleId
				
			}
		});
		modal.present();
	}

}
