import { UtilityActionsTypes, UtilityActionsUnion } from '../actions/utility.actions';
import { IUtilityState } from '../interfaces/utility.interfaces';

export const initialState: IUtilityState = {
    haveNetworkConnection: "PENDING"
}

export function utilityReducer(state = initialState, action: UtilityActionsUnion): IUtilityState {
    switch (action.type) {
        case UtilityActionsTypes.HaveNetworkConnection: {
            return ({
                ...state,
                haveNetworkConnection: action.payload
            })
        }

        default: {
            return (state);
        }
    }
}