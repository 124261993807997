import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DaoDocumentsService } from "src/app/providers/db/dao-document.service";
import { GlobalService, AnalyticsService } from "src/app/shared/services";
import { MenuController, Platform } from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { NetworkService } from "src/app/providers/network/network.service";
import { StorageOfflineService } from "src/app/providers/storage-offline/storage-offline.service";
import { Subscription } from "rxjs";
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
    selector: "app-documents-folder",
    templateUrl: "./documents-folder.page.html",
    styleUrls: ["./documents-folder.page.scss"],
})
export class DocumentsFolderPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    docModule: any = null;

    eventId: string = null;
    moduleId: string = null;
    folderId: string = null;
    isMobile: boolean = false;

    folder: any;

    documentsSaved: any[] = [];
    documents: any[] = [];

    loader: boolean = true;

    searchOpen: boolean = false;
    searchText: string = "";
    backBtn: boolean = true;

    eventColors: EventColors = new EventColors();

    menuBadge: number = 0;
    allowButtonsHeader: boolean = false;

    language: string;
    enableNoResult = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private SAnalytics: AnalyticsService,
        private daoDocuments: DaoDocumentsService,
        public SGlobal: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private network: NetworkService,
        private storage: StorageOfflineService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);
        this.language = this.SEventData.getLanguage();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.SGlobal.previousPage == "container") {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }

        this.route.params.subscribe((params) => {
            this.eventId = params.eventId;
            // localStorage.setItem("eventId", this.eventId);
            this.moduleId = params.moduleId;
            this.folderId = params.folderId;

            // Track analytics
            this.SAnalytics.goToDocumentsFolder(this.eventId, this.moduleId, this.folderId);

            this.loadColors();
            this.menuBadge = this.SGlobal.notificationBadge;
            this.events.subscribe("menuBadge", () => {
                this.menuBadge = this.SGlobal.notificationBadge;
            });

            // online
            if (this.network.status()) {
                // get module
                this.subscriptions.push(this.daoDocuments.getModule(this.moduleId).subscribe((module) => {
                    this.docModule = module;

                    // get folder
                    this.subscriptions.push(this.daoDocuments.getFolder(this.moduleId, this.folderId).subscribe((folder) => {
                        this.folder = folder;
                        let orderDocuments = folder.orderDocuments;
                        let orderName = `name.${this.language}`;
                        // get documents
                        this.subscriptions.push(this.daoDocuments.getDocuments(this.moduleId, this.folderId, orderDocuments, orderName).subscribe((documents) => {
                            this.documentsSaved = [];
                            this.documentsSaved = documents;
                            this.documents = [];
                            this.documents = documents;
                            this.documents =  [...this.documents].map(doc => {
                                if(doc.icon==='jpg_ico.png' || doc.icon === 'png_ico.png'){
                                    doc.icon = 'photo_ico.svg'
                                }
                                if(doc.icon.substr(doc.icon.length-3) === 'png'){
                                    doc.icon = doc.icon.substr(0, doc.icon.length - 3) +'svg'
                                }
                                return doc
                            })
                            this.loader = false;
                            // stores data on storage,
                            this.storage.setObject(this.folderId, {
                                module: this.docModule,
                                documents: this.documentsSaved,
                            });
                        }));
                    }));
                }));
            }
            // offline
            else {
                //iv. Get an storage Object:
                this.storage
                    .getObject(this.folderId)
                    .then((result) => {
                        if (result != null) {
                            this.docModule = result.module;
                            this.documentsSaved = result.documents;
                            this.documents = result.documents;
                        }

                        this.loader = false;
                    })
                    .catch((e) => {
                        this.docModule = null;
                        this.documentsSaved = [];
                        this.documents = [];
                        this.loader = false;
                    });
            }
        });
    }

    /**
     * Filter documents
     * @param event 
     */
    filterDocs(ev) {
        this.documents = this.documentsSaved;

        var val = ev.target.value.toLowerCase();
        const extensions = this.getExtensions(val)
        if (val && val.trim() != '') {
            this.documents = [...this.documentsSaved].filter((item: any) => {
                return (item.name[this.language].toLowerCase().indexOf(val.toLowerCase()) > -1) || 
                    extensions.includes(item.type)
                ;
            })
        }
        if(this.documents.length === 0) this.enableNoResult = true;
    }
    getExtensions(value: string) {
        if('word'.includes(value)) return ['doc','docx','docm'];
        else if('excel'.includes(value)) return ['xls','xlt','xml','xlsx','xlsm','xlsb','xltx','xltm'];
        else if('pdf'.includes(value)) return ['pdf','PDF'];
        else if('png'.includes(value)) return ['png','PNG'];
        else if('jpg'.includes(value)) return ['jpg','JPG'];
        else if('jpeg'.includes(value)) return ['jpeg','JPEG'];
        else if('powerpoint'.includes(value)) return ['ppt','pptx','thmx','ppsx','pps','ppsm'];
        else if('media'.includes(value)) return ['mp4','wmv','3gp','avi','mp3','wav'];
        else return []
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    resitFilter(){
        // this.documents = this.documentsSaved;
        this.documents = [...this.documentsSaved];
        this.enableNoResult = false;
    }
}
