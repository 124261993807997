import { Question } from "./question";

export class Survey {
    title: string;
    type: string;
    uid: string;
    visibility: string;
    icon: string;
    change_answer: boolean;
    view_answered: boolean;
    max_responses: number;
    module_id: string;
    references: string;
    questions: Array<Question>
    iconFamily: string;
    forceComplete: boolean;
    totalQuestions: number;
    order: number;
    groups: Array<string>;
    minPoints: number;
    disabled?: boolean;
}