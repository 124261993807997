import { Injectable } from '@angular/core';
import { 
    Firestore, 
    collection, 
    query, 
    orderBy, 
    collectionData
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})

export class DaoTrackService {
    private refGetTracksModule = null

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    // loads the module tracks.
    getTracksModule(moduleId: string, lang, onResolve) {
        const queryName = `queryName.${lang}`;
        const ref = collection(this.firestore, `modules/${moduleId}/tracks`);
        const refQ = query(ref, orderBy(queryName, 'asc'));

        this.refGetTracksModule = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    closeGetTracksModule() {
        if (this.refGetTracksModule)
            this.refGetTracksModule.unsubscribe()
    }
}