import { Injectable } from "@angular/core";
import { CeuVisio } from "src/app/models/ceu-visio";
import { DateTime } from "luxon";
//import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";  // todo: update plugin in-app-browser
//import { SafariViewController } from "@ionic-native/safari-view-controller/ngx";  // todo: update plugin safari-view-controller
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { environment } from "../../../../environments/environment";
import { first, map, switchMap, take, takeUntil } from "rxjs/operators";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { GroupDiscussionsService } from "../group-discussions/group-discussions.service";
import { CeuUser } from "src/app/models/ceu-user";
import { GlobalService } from "../global/global.service";
import { UtilityService } from "../utility/utility.service";
import { DaoChatService } from "src/app/providers/db/dao-chat.service";
import { LuxonService } from "src/app/providers/luxon/luxon.service";
import { ChatMessage } from "src/app/models/chat-message";
import { TranslateService } from "@ngx-translate/core";
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, query, setDoc, where } from "@angular/fire/firestore";
import { LogoutService } from "../logout/logout.service";
import { UserDataService } from "../user-data/user-data.service";
import { EventDataService } from "../eventData/event-data.service";

@Injectable({
	providedIn: "root"
})
export class WherebyService {
	headers: HttpHeaders;
	baseApiUrl: string = environment.platform.apiBaseUrl;
	stopVisio: Subject<boolean> = new Subject();

	constructor(
		private firestore: Firestore,
        private Slogout: LogoutService,
		//private iab: InAppBrowser,  // todo: update plugin in-app-browser
		private dbChat: DaoChatService,
		private luxon: LuxonService,
		private translate: TranslateService,
		//private svc: SafariViewController,  // todo: update plugin safari-view-controller
		private gdService: GroupDiscussionsService,
		private SGlobal: GlobalService,
		private SUtility: UtilityService,
		private http: HttpClient,
		private platform: Platform,
        private SUserData: UserDataService,
        private SEventData: EventDataService
	) {
		this.headers = new HttpHeaders({
			"Content-Type": "application/json"
		});
	}

	createMeetingOnWhereby(data: any): Promise<HttpResponse<any>> {
		const url = `${this.baseApiUrl}dbWherebyCreateMeeting`;

		return this.http
			.post(url, data, {
				headers: this.headers,
				observe: "response"
			})
			.toPromise();
	}

	isVisioEnabled(eventId: string, groupId?: string): Observable<boolean> {
		if (groupId) {
			return this.gdService.groupDiscussion(groupId).pipe(map((gd) => gd.visio));
		}

        const ref = doc(this.firestore, `events/${eventId}`);
        return docData(ref).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((e: any) => {
                return e.allow_visio && e.allow_visio_for_2;
            })
        );
	}

	getVisio(eventId: string, chatId: string): Promise<string> {
        const ref = doc(this.firestore, `events/${eventId}/visios/${chatId}`);
        return firstValueFrom(docData(ref).pipe(
            map((v: CeuVisio) => {
                const currentTime = DateTime.local().toISO();

                return v && v.endDate > currentTime ? v.url : null;
            })
        ));
	}

	async setVisio(eventId: string, visio: any): Promise<void> {
        const ref = doc(this.firestore, `events/${eventId}/visios/${visio.uid}`);
        return setDoc(ref, visio);
	}

	visioAvailableOnMobile(): boolean {
		return this.isIOS() || this.isAndroid();
	}

	isIOS() {
		return this.platform.is("ios");
	}

	isAndroid() {
		return this.platform.is("android");
	}

	visioAvailableOnSF()/*: Promise<boolean> */{
		//return this.svc.isAvailable();  // todo: update plugin safari-view-controller
	}

	openInAppBrowserVisio(url: string)/*: Observable<any> */{
		//const browser = this.iab.create(url, "_system");
		//return browser.on("loadstart");  // todo: update plugin in-app-browser
	}

	openSFVisio(url: string)/*: Observable<any> */{
		// return this.svc.show({
		// 	url,
		// 	hidden: true,
		// 	animated: true,
		// 	transition: "curl",
		// 	enterReaderModeIfAvailable: false
		// });  // todo: update plugin safari-view-controller
	}

	openDesktopVisio(url: string): void {
		window.open(url, "_system");
	}

	analyticsNewAccessToRoom(eventId: string, user, session: any) {
		if (!user) { return; }

        const ref = collection(this.firestore, `analytics/${eventId}/total-visio-access`);
        return addDoc(
            ref,
            {
				userId: user.uid,
				userIdentifier: user.identifier,
				userEmail: user.email,
				sessionId: session && session.uid ? session.uid : null,
				sessionName:
					session && session.name[this.SEventData.getLanguage()]
						? session.name[this.SEventData.getLanguage()]
						: null,
				date: DateTime.local().toISO()
			}
        );
	}

	analyticsNewRoom(eventId: string, userIds: string[]) {
        const ref = collection(this.firestore, `analytics/${eventId}/total-visio`);
        return addDoc(
            ref,
            {
                userIds: userIds,
                date: DateTime.local().toISO()
            }
         );
	}

	/**
	 * Analytics for new creation of a room for 2
	 * @param eventId
	 * @param userIds
	 */
	analyticsNewRoomFor2(eventId: string, userIds: string[]) {
        const ref = collection(this.firestore, `analytics/${eventId}/total-visio-for-2`);
        return addDoc(
            ref,
            {
                userIds: userIds,
                date: DateTime.local().toISO()
            }
        );
	}

	/**
	 * Check if room exist and is valid
	 * @param eventId
	 * @param ids
	 */
	getValidAndExistRoom(eventId: string, ids: string[]) {
        const ref = collection(this.firestore, `events/${eventId}/visios`);
        const refQ = query(ref, ...[where(`members.${ids[0]}`, "==", true), where(`members.${ids[1]}`, "==", true)]);

        return firstValueFrom(collectionData(refQ).pipe(
            switchMap(async (docs) => {
                if (docs.length > 0) {
                    let currentTime = DateTime.local().toISO();

                    let visioSaved: CeuVisio;
                    for (let i = 0; i < docs.length; i++) {
                        let visio = docs[i] as CeuVisio;
                        if (visio && visio.endDate && visio.endDate > currentTime && !visioSaved) {
                            // Visio to keep
                            visioSaved = docs[i] as CeuVisio;
                        } else {
                            // Excessive or expired visios to delete
                            const refDoc = doc(this.firestore, `events/${eventId}/visios/${docs[i].uid}`);
                            await deleteDoc(refDoc);
                        }
                    }

                    // If one visio valid return true
                    if (visioSaved) {
                        return visioSaved;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            })
        ));
	}

	/**
	 * Create a new visio room for 2
	 * @param eventId
	 * @param ids
	 */
	async createNewVisioFor2(eventId: string, ids: string[]) {
		let visioCheck = await this.getValidAndExistRoom(eventId, ids);
		if (visioCheck) {
			return visioCheck;
		} else {
			let docId = ids[0] + "-" + ids[1];
			let visio = new CeuVisio();
			visio.uid = docId;
			visio.startDate = DateTime.local().toISO();
			visio.endDate = DateTime.local().plus({ days: 1 }).toISO();

			let response = await this.createMeetingOnWhereby({
				roomNamePrefix: "/" + ids[0],
				roomMode: "normal",
				startDate: visio.startDate,
				endDate: visio.endDate
			});

			visio.url = response.body.roomUrl;

			for (let i = 0; i < ids.length; i++) {
				visio.members[ids[i]] = true;
			}

			visio = Object.assign({}, visio);
            const ref = doc(this.firestore, `events/${eventId}/visios/${docId}`);
            await setDoc(ref, visio);
			return visio;
		}
	}

	/**
	 * Send message visio on chat
	 * @param eventId
	 * @param ids
	 */
	sendMessageVisioOnChat(eventId: string, ids: string[]) {
		// Get chat linked
        const ref = collection(this.firestore, `events/${eventId}/chats`);
        const refQ = query(ref, ...[
            where(`members.${ids[0]}.uid`, "==", ids[0]), 
            where(`members.${ids[1]}.uid`, "==", ids[1])
        ]);

        return collectionData(refQ).pipe(
            take(1),
            switchMap(async (docs) => {
                try {
                    let chatId = "";
                    if (docs.length > 0) {
                        // Chat exist already so take uid directly
                        chatId = docs[0].id;
                    } else {
                        // Create chat if don't exist or get id
                        chatId = await this.gdService.getOrCreateChat(eventId, ids[0], ids[1]);
                    }

                    // Check and create visio if necessary and get payload of visio
                    let visio = await this.createNewVisioFor2(eventId, ids);

                    // Create message on chat for visio
                    let msg = new ChatMessage();
                    msg.message = this.translate.instant("global.texts.visio-launched");
                    msg.url = visio.url;
                    msg.from_user = ids[0];
                    msg.to_user = ids[1];
                    msg.send_at = this.luxon.getTimestampCurrentDate();
                    msg.message_picture = null;
                    msg.send_from_user_name = this.SUserData.userData.name;
                    msg.send_from_user_photo = this.SUserData.userData.photoUrl;
                    msg.eventId = eventId;

                    // Send message
                    this.dbChat.sendMessage(eventId, chatId, msg);

                    return true;
                } catch (error) {
                    return false;
                }
            })
        );
	}
}
