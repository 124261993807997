import { Injectable } from '@angular/core';
import { 
    Firestore,
    collection,
    collectionData,
    doc, 
    docData
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoGalleryService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    getFolder(moduleId: string, folderId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/folders/${folderId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    getFolders(moduleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/folders`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    getImages(moduleId: string, folderId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/folders/${folderId}/images`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }
}
