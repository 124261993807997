import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {
  privacy: string = null;
  constructor(private modalCtrl: ModalController, private daoGeral: DaoGeralService, private navParams: NavParams) { }

  ngOnInit() {
    if (this.navParams.get('privacy') == undefined || this.navParams.get('privacy') == null) {
      this.daoGeral.getPrivacy((privacy) => {
        this.privacy = privacy;
      });
    } else {
      this.privacy = this.navParams.get('privacy');
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
