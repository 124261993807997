export const supportedLanguagesCode = ['pt', 'en', 'es', 'fr', 'de'];
export const supportedLanguagesTag = ['pt-BR', 'en-US', 'es-ES', 'fr-FR', 'de-DE'];

// export type SupportedLanguagesCode = typeof supportedLanguagesCode[number];
// export type SupportedLanguagesTag = typeof supportedLanguagesTag[number];

export interface SupportedLanguages {
    code: string;   // 'pt', 'en', 'es', 'fr', 'de'
    tag: string;    // 'pt-BR', 'en-US', 'es-ES', 'fr-FR', 'de-DE'
}
