
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	ActionSheetController,
	MenuController,
	ModalController,
	Platform,
	ToastController
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { TypeTracking } from "src/app/enums/type-tracking";
import { Post } from "src/app/models/ceu-post";
import { StorageService } from "src/app/providers/storage/storage.service";
import { AnalyticsService, GlobalService } from "src/app/shared/services";
import { DaoFeedNewsService } from "../../../providers/db/dao-feed-news.service";
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: 'app-feed-news-publishing',
	templateUrl: './feed-news-publishing.page.html',
	styleUrls: ['./feed-news-publishing.page.scss'],
})
export class FeedNewsPublishingPage implements OnInit {

	subscriptions: Subscription[] = [];
	isMobile: boolean = false;
	public module = null;
	userLoggedId = null;

	@Input() eventId: string = null;
	@Input() moduleId: string = null;
	
    allowButtonsHeader: boolean = true;
	backBtn: boolean = true;
	
    eventColors: EventColors = new EventColors();
    
    menuBadge: number = 0;
	posts: any[] = [];
	feedInitSize: number = 20;    // tamanho inicial e de expanção de posts por cada vez que o infiniteScroll é acionado
	feedSize: number = this.feedInitSize;    // tamanho total do feed

	myImg: string = null;
	textArea = "";
	loaderMyImg: boolean = false;
	loaderPost: boolean = false;

	// module
	isPublic = true;
	isNeed_moderate = true;
	isSocial_sharing = true;
	isComments = true;
	isLoading = true;

	// scrHeight = window.innerHeight;
	// scrWidth = window.innerWidth;

	constructor(
		private platform: Platform,
		private route: ActivatedRoute,
		private menuCtrl: MenuController,
		public global: GlobalService,
		private events: Events,
		private modalCtrlWriteMessages: ModalController,
		private dbFeed: DaoFeedNewsService,
		public actionSheetCtrl: ActionSheetController,
		private storage: StorageService,
		private actionSheetController: ActionSheetController,
		private translateService: TranslateService,
		private SAnalytics: AnalyticsService,
		private router: Router,
		public toastController: ToastController,
		//public popoverController: PopoverController,
		private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
	) {
		if (this.platform.is("ios") || this.platform.is("android")) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
		this.menuCtrl.enable(true);
		this.route.params.subscribe((params) => {
			// console.log(params.eventId, params.moduleId);
			this.eventId = params.eventId;
			// localStorage.setItem("eventId", this.eventId);
			this.moduleId = params.moduleId;

			this.loadColors();

			// this.events.subscribe("loadColors", () => {  // TODO:
			// 	this.loadColors();
			// });

			this.menuBadge = this.global.notificationBadge;
			this.events.subscribe("menuBadge", () => {
				this.menuBadge = this.global.notificationBadge;
			});
		});
		//this.getScreenSize();
		//this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	async ngOnInit() {
		if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}

		// if (this.router.url == this.SEventData.eventData.homePage) {
		// 	this.allowButtonsHeader = true;
		// }
        this.userLoggedId = this.SUserData.userId;
		// // receives user uid
		// if (this.SUserData.userId !== null) {
		// 	this.userLoggedId = this.SUserData.userId;
		// } else {
		// 	this.userLoggedId = await this.global.loadUserId();
		// }

		// load module
		this.subscriptions.push(
			this.dbFeed.moduleNewsFeed(this.moduleId).subscribe((module: any) => {
				this.module = module;

				this.isPublic = module.public;
				this.isNeed_moderate = module.need_moderate;
				this.isSocial_sharing = module.social_sharing;
				this.isComments = module.comment;
			})
		);

		// Track analytics
		this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
		this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_FEED_MODULE);
	}


	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }


	async createPost() {
		//console.log("aqui"+this.scrHeight, this.scrWidth);
		// if (this.global.userLogged()) {
		this.loaderPost = true;

		console.log(this.textArea);

		// get uid post
		const postId = this.dbFeed.createIdPost();
		const fixedTop = 1;
		const description = this.textArea;

        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
        let creator = null;
        if (userData) {
            creator = {
                name: userData.name,
                img: {
                    url: userData.photoUrl,
                    uid: userData.uid
                },
                moduleId: userData.moduleId,
                type: userData.type,
                uid: userData.uid
            };
        }

		// create object img
		let img: any = {
			name: "",
			url: ""
		};
		// create url storage
		if (this.myImg !== null && typeof this.myImg !== "undefined") {
			img["url"] = await this.storage.uploadImgNewsFeed(this.myImg, postId, this.eventId, this.moduleId);
		}

		let active = (!this.isNeed_moderate) ? true : false;

		const post = new Post(postId, this.moduleId, this.eventId, img, description, creator, fixedTop, active);
		post.date = this.dt.dbTime();

		this.dbFeed
			.createPost(post, this.eventId)
			.then((_) => {
				this.loaderPost = false;
				this.myImg = null;
				this.textArea = "";

				if (this.isNeed_moderate) {
					this.presentToastFeedModerate();
				}
			})
			.catch((e) => {
				this.loaderPost = false;
			});
			this.closeModal()
	}

	setOpen()  {
		if (this.textArea === "") {
			this.presentToastFeedModerate();
			
		}else{
			this.createPost();
			
		}
	}
	
	// displayed tost with the moderation message.
	async presentToastFeedModerate() {
		const toast = await this.toastController.create({
			message: this.translateService.instant("pages.feed_news.empty_comments"),
			duration: 3000,
			position: 'middle'
		});

		toast.present();
	}

	closeModal() {
		this.modalCtrlWriteMessages.dismiss()
	}

	async getImageToSend() {
		this.loaderMyImg = true;

		this.storage.takePicture((data) => {
			this.myImg = data;
			if (this.myImg) {
				this.loaderMyImg = false;
			}
		});
	}

	/**
	 * Get an image to send from web
	 * @param ev
	 */
	getImageToSendFromWeb(ev) {
		this.loaderMyImg = true;
		const fileWeb: File = ev.target.files[0];

		const reader = new FileReader();
		reader.readAsDataURL(fileWeb);
		reader.onload = () => {
			this.myImg = reader.result as string;
			if (this.myImg) {
				this.loaderMyImg = false;
			}
		};
		reader.onerror = (error) => {
			this.loaderMyImg = false;
		};
	}

	// @HostListener('window:resize', ['$event'])
	//     getScreenSize(event?) {
	//           this.scrHeight = window.innerHeight;
	//           this.scrWidth = window.innerWidth;
	//           console.log(this.scrHeight, this.scrWidth);
	//     }

}
