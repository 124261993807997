import { Component, Input } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Router } from "@angular/router";
import { DocumentData } from "@angular/fire/firestore";
import { IMenuIcons } from "src/app/shared/interfaces/menu-icons.interface";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";
import { ActiveMenuIconsService } from "src/app/shared/services/active-menu-icons/active-menu-icons.service";

@Component({
    selector: 'back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
    buttonStatus: boolean = true;  // false: disable | true: enable
    homePage: string;
    menuPrincipal: string;

    @Input("path") path: string = null;
    @Input("activeMenuIcon") activeMenuIcon: IMenuIcons = null;

    constructor(
        private router: Router,
        private navCtrl: NavController,
        private SActiveMenuIcons: ActiveMenuIconsService,
        private SEventData: EventDataService
    ) {
        const eventId: string = localStorage.getItem("eventId");

        this.SEventData.getEventDataListener(eventId).subscribe((data: DocumentData) => {
            if (this.router.url == data.homePage) {
                this.buttonStatus = false;
            }
            
            this.homePage = data.homePage;
        });

        this.menuPrincipal = `/event/${eventId}/principal/:moduleId`;
    }

    goBack() {
        if (!this.path) {
            this.navCtrl.back();
        } else {
            const homePage = localStorage.getItem('homePage');
            let path: string;

            if (this.path == "home" && homePage) {
                path = homePage;
                this.SActiveMenuIcons.changeActiveIcon('home');
            } else {
                path = this.menuPrincipal;
                this.SActiveMenuIcons.changeActiveIcon('modules');
            }

            this.navCtrl.navigateBack(path);
        }
    }
}