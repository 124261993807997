import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { AnalyticsService, GlobalService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { CeuChat } from 'src/app/models/ceu-chat';
import { DaoAttendeesService } from 'src/app/providers/db/dao-attendees.service';
import { ChatListComponent } from 'src/app/components/chat-list/chat-list.component';
import { GroupDiscussionsService } from 'src/app/shared/services/group-discussions/group-discussions.service';
import { tap } from 'rxjs/operators';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { DaoChatService } from 'src/app/providers/db/dao-chat.service';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-chat-history',
    templateUrl: './chat-history.page.html',
    styleUrls: ['./chat-history.page.scss']
})
export class ChatHistoryPage implements OnInit, OnDestroy {
    eventId: string;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    chatBadge: number = 0;
    backBtn: boolean = true;
    q: string;
    isMobile: boolean = false;

    chats$: Observable<any[]>;
    notifications$: any = {};

    allChats: Array<CeuChat> = [];
    allChatsSearch: Array<CeuChat> = [];
    userId: string = null;
    searchOpen: boolean = false;
    subscriptions: Subscription[] = [];

    constructor(
        private menuCtrl: MenuController,
        public global: GlobalService,
        private route: ActivatedRoute,
        private modalCtrl: ModalController,
        private dbAttendees: DaoAttendeesService,
        public gdService: GroupDiscussionsService,
        private dbChat: DaoChatService,
        private SAnalytics: AnalyticsService,
        public SDateTime: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);
        this.q = '';
        this.eventId = this.route.snapshot.paramMap.get('eventId');
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        this.loadColors();

        this.chats$ = this.gdService.getChatsListener(this.eventId).pipe(
            tap((chats) => {
                chats.map((c) => {
                    if (c) {
                        this.notifications$[c.uid] = this.badgeNotifications(c.uid);
                    }
                });
            })
        );

        // Analytics chat history track
        this.SAnalytics.chatHistoryAccess(this.eventId);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    badgeNotifications(chatId: string) {
        return this.gdService.badgeNotifications(this.eventId, chatId);
    }

    getAttendee(uid: string) {
        return (this.dbAttendees.getAttendeeByEvent(this.eventId, uid).toPromise());
    }

    getLastMessage(chatId: string) {
        return new Promise((resolve) => {
            this.dbChat.getLastMessage(this.eventId, chatId, (msg) => {
                resolve(msg);
            });
        })
    }

    getChatUrl(chat: any) {
        return chat.group
            ? `/event/${this.eventId}/chat/group/${chat.uid}`
            : `/event/${this.eventId}/chat/${chat.uid}`;
    }

    async newChat() {
        const modal = await this.modalCtrl.create({
            component: ChatListComponent,
            componentProps: {
                eventId: this.eventId,
                userId: this.SUserData.userId
            }
        });

        return await modal.present();
    }

    searchBar(ev) {
        this.q = ev.target.value.toLowerCase();
    }

    resitFilter() {
        this.q = ''
    }
}
