import { Injectable } from "@angular/core";
import { 
    Firestore, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    updateDoc, 
    doc, 
    setDoc
} from '@angular/fire/firestore';
import { ChatMessage } from "src/app/models/chat-message";
import { map, takeUntil } from "rxjs/operators";
import { LogoutService } from "src/app/shared/services/logout/logout.service";

@Injectable({
	providedIn: "root"
})
export class DaoChatService {
	constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) {}

	sendMessage(eventId: string, chatId: string, message: ChatMessage): Promise<void> {
		// console.log("Send message on chat: ", message, " - ", eventId, " - ", chatId);
        const refColl = collection(this.firestore, `events/${eventId}/chats/${chatId}/messages`);
        const refDoc = doc(refColl);

        message.uid = refDoc.id;
		message = Object.assign({}, message);

        return setDoc(refDoc, message);
	}

	/**
	 * Update a message
	 * @param eventId
	 * @param chatId
	 * @param messageId
	 * @param newMessage
	 */
	updateMessage(eventId: string, chatId: string, messageId: string, newMessage) {
        const ref = doc(this.firestore, `events/${eventId}/chats/${chatId}/messages/${messageId}`);

        newMessage = Object.assign({}, newMessage);
        return updateDoc(ref, newMessage);
	}

	getLastMessage(eventId: string, chatId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/chats/${chatId}/messages`);
        const refQ = query(ref, orderBy("send_at", "desc"));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs[docs.length - 1])
        });
	}

	getMessages(eventId: string, chatId: string) {
        const ref = collection(this.firestore, `events/${eventId}/chats/${chatId}/messages`);
        const refQ = query(ref, orderBy("send_at", "asc"));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	// saveChatLastAccess(eventId: string, userId: string, chatId: string, timestamp: number) {
    //     const ref = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        
    //     let access = {};
	// 	access[userId] = timestamp;

	// 	const updates = {};
	// 	updates[`last_access.${userId}`] = timestamp;

    //     updateDoc(ref, updates);
	// }
}
