import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'app-filter-by',
    templateUrl: './filter-by.component.html',
    styleUrls: ['./filter-by.component.scss']
})
export class FilterByComponent implements OnInit, OnDestroy {

    @Input() activeChoice : string = 'Alphabétique A - Z';
    @Input() mode : string ;
    @Input() comp : string ;
    @Input() colors = {
        link_color: '',
        title_color: '',
        btn_text_color: ''
    } ;
    @Input() selectedValues : Array<any>;
    @Output() newValueEmit = new EventEmitter<any>();
    
    @Input() choices = [
        { key:'asc',value: 'Alphabétique A - Z'},
        { key:'desc',value: 'Alphabétique Z - A'},
        { key:'present',value: 'Présent'},
        { key:'away',value: 'Absent'}
    ];
    @Input() companyFilterText:  string
    @Input() postFilterText:  string

    initialSelectedValues = [];
    constructor(private modalCtrl: ModalController){

    }
    ngOnInit() {
        this.initialSelectedValues = [...this.selectedValues].map(x => {
            x = Object.assign({}, x)
            x.value = Array.from(x.value)
            return x
        })
        // this.selectedValues = [...this.choices].map(item => {
        //     return {key: item.key, value: []}
        // })
    }

    ngOnDestroy(){}

    selectChoice(event,value: string, key?: string){
        event.stopPropagation()
        if(this.comp === 'checkin') {
            this.activeChoice = value;
            if(this.mode === 'mobile'){
                this.modalCtrl.dismiss({
                    'choice': this.activeChoice
                });
            }else{
                this.newValueEmit.emit(this.activeChoice)
            }
        } else if (this.comp === 'attendees') {
            const condition = this.selectedValues.some(item => item.key === key && item.value.includes(value))
            this.selectedValues = [...this.selectedValues].map(item => {
                if(condition && item.key === key) {
                    item.value = [...item.value].filter(x => x!== value)
                    // return item
                }
                if(!condition && item.key === key) {
                    item.value.push(value)
                }
                return item
            })
        }
    }

    dismissModal(){
        if(this.mode === 'mobile'){
            if(this.comp === 'checkin'){
                this.modalCtrl.dismiss({
                    'choice': this.activeChoice
                });
            } else if(this.comp === 'attendees'){
                this.modalCtrl.dismiss({
                    'selectedValues': this.initialSelectedValues
                });
            }
        }
    }

    dontDismissModal(event){
        event.stopPropagation()
    }

    sendSelectedData(){
        this.modalCtrl.dismiss({
            'selectedValues': this.selectedValues
        });
    }

    isValueIncluded(key, value) {
        return this.selectedValues.some(x => x.key === key && x.value.includes(value))
    }
}