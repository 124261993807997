import { Injectable } from '@angular/core';
import { 
    SupportedLanguages, 
    supportedLanguagesTag, 
    // supportedLanguagesCode 
} from './supported-languages';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    public userDeviceLanguage: SupportedLanguages;

    constructor() {
        this.userDeviceLanguage = {
            code: environment.platform.oldDefaultLanguage.slice(0, 2),
            tag: environment.platform.oldDefaultLanguage,
        }

        this.getDeviceLanguageTag().then((tag) => { 
            this.userDeviceLanguage = {
                code: tag.slice(0, 2),
                tag: tag
            }
        }).catch((error) => { console.error(error)});
        // this.getDeviceLanguageCode().then(code => this.userDeviceLanguage.code = code);
    }

    async getDeviceLanguageTag() {
        const { value } = await Device.getLanguageTag();
        if (supportedLanguagesTag.includes(value)) {
            return value;
        }
        return this.userDeviceLanguage.tag;
    }

    // async getDeviceLanguageCode() {
    //     const { value } = await Device.getLanguageCode();
    //     if (supportedLanguagesCode.includes(value)) {
    //         return value;
    //     }
    //     return this.userDeviceLanguage.code;
    // }
}
