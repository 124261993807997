import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogoutService {
    public logoutSubject: Subject<void> = new Subject();

    constructor() { }

    /**
     * @description unsubscribe to all subscriptions that takes the logoutSubject property from LogoutService 
     * as parameter of takeUntil() method.
     * (e.g. \<Observable>.pipe(takeUntil(this.\<instance of LogoutService>.logoutSubject).subscribe()))
     */
    logout() {
        this.logoutSubject.next()
    }
}
