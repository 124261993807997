import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, GlobalService } from 'src/app/shared/services';
import { MenuController, AlertController, ToastController } from '@ionic/angular';
import { DaoQuizService } from 'src/app/providers/db/dao-quiz.service';
import { NotificationDateService } from 'src/app/providers/date/notification-date.service';
import { DaoAttendeesService } from 'src/app/providers/db/dao-attendees.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DaoInteractivityService } from 'src/app/providers/db/dao-interactivity.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventColors } from 'src/app/models/event-colors';
import { DocumentData } from '@angular/fire/firestore';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.page.html',
    styleUrls: ['./quiz.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuizPage implements OnInit, OnDestroy {
    quizSubscription: Subscription;
    @Input() componentMode: boolean;
    @Input() backToList: boolean = false;
    @ViewChild('mySlider') mySlider: any;

    @Input() eventId: string = null;
    @Input() moduleId: string = null;
    @Input() quizId: string = null;
    @Input() sessionId: string = null;
    @Input() userId: string = null;
    @Input() scheduleModuleId: string = null;

    @Output() stopLoaderEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() goBackEvent: EventEmitter<any> = new EventEmitter();

    quiz: DocumentData;
    currentQuestion: any;
    result: Array<any> = [];
    setResultVerify: boolean = false;
    totalQuestions: number = null;
    moduleInteractivity = null;

    includeImgToAnswers: boolean = true ;
    questionsNumberVisibility: boolean = true ;

    rate: number;
    ratingValue;

    indexSlide: number = 0;
    counter: number = 0;
    public interval: any;
    progress: number = 0;

    previousPage;
    view_only: boolean = null;

    eventColors: EventColors = new EventColors();
    todayDate = new Date();

    checkedView = false;
    totalUserAnswers: boolean = false;
    loader: boolean = true;
    allowButtonsHeader: boolean = false;
    language: string;
    quizEnded: boolean = false;
    submitting: boolean = false;
    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public SGlobal: GlobalService,
        private menuCtrl: MenuController,
        private daoInteractivity: DaoInteractivityService,
        private daoQuiz: DaoQuizService,
        private daoAttendee: DaoAttendeesService,
        public alertCtrl: AlertController,
        public alertController: AlertController,
        public toastController: ToastController,
        private notificationDate: NotificationDateService,
        private location: Location,
        private translateService: TranslateService,
        private SAnalytics: AnalyticsService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) { }

    ngOnInit() {
        this.menuCtrl.enable(true);
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
        this.userId = this.SUserData.userId;

        this.language = this.SEventData.getLanguage();
        if (!this.componentMode) {
            const params = this.route.snapshot.params;

            this.eventId = params.eventId;
                // localStorage.setItem('eventId', this.eventId);
            this.moduleId = params.moduleId;
            this.quizId = params.quizId;

            this.loadColors();
            this.trackAnalytics();

            this.route.queryParams.subscribe((params) => {
                if (this.router.getCurrentNavigation().extras.state) {
                    this.previousPage = this.router.getCurrentNavigation().extras.state.previousPage;
                    this.scheduleModuleId = this.router.getCurrentNavigation().extras.state.scheduleModuleId;
                    this.sessionId = this.router.getCurrentNavigation().extras.state.sessionId;
                }
            });
        } else {
            this.daoInteractivity.getInteractivityModule(this.eventId, (module) => {
                this.moduleInteractivity = module;
                this.getQuiz();

                this.trackAnalytics();
            })
        }
        console.log("sub",this.submitting)
    }

    /**
     * Tracks analytics
     */
    trackAnalytics() {
        this.SAnalytics.accessToSurvey(this.eventId, this.moduleId, this.quizId);
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        if (this.quizSubscription && !this.quizSubscription.closed) {
            this.quizSubscription.unsubscribe();
        }
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    ionViewDidEnter() {
        this.daoInteractivity.getInteractivityModule(this.eventId, (module) => {
            this.moduleInteractivity = module;
            this.getQuiz();
        })
    }

    ngAfterViewChecked() {
        if (!this.checkedView) {
            if (this.mySlider !== undefined) {
                this.mySlider.lockSwipes(true)
                this.checkedView = true;
            }
        }
    }

    ngOnChanges(changes) {
        if (changes.quizId) {
            this.getQuiz();
        }
    }

    ionViewWillLeave() {
        if (this.quizSubscription && !this.quizSubscription.closed) {
            this.quizSubscription.unsubscribe();
        }
        this.indexSlide = 0;
        this.counter = 0;
        this.progress = 0;
        clearInterval(this.interval);
    }

    /**
     * Go back to list surveys on component mode
     */
    goBackToList() {
        this.goBackEvent.emit(true);
    }

    progressBar() {
        let activeIndex = this.indexSlide;
        let totalSlides = this.quiz.questions.length;

        this.progress = ((activeIndex + 1) / totalSlides)
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            document.body.style.setProperty(`--link-color`, this.eventColors.link_color);
		    document.body.style.setProperty(`--btn-text-color`, this.eventColors.btn_text_color);
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Getting quiz
     */
    getQuiz() {
        if (this.SUserData.userLogged() || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
            if (this.quizSubscription && !this.quizSubscription.closed) {
                this.quizSubscription.unsubscribe();
            }
            this.quizSubscription = this.daoQuiz.getQuiz(this.moduleId, this.quizId).subscribe((quiz) => {
                this.quiz = quiz;
                this.totalQuestions = (this.quiz && this.quiz.questions && this.quiz.questions.length > 0) ? this.quiz.questions.length : 0;
                this.questionsNumberVisibility = this.quiz.questionsNumberVisibility  !== undefined ? this.quiz.questionsNumberVisibility : true;
                this.includeImgToAnswers = this.quiz.includeImgToAnswers  !== undefined ? this.quiz.includeImgToAnswers : true;

                this.daoQuiz.checkAnsweredQuiz(this.eventId, this.userId, this.quizId).pipe(take(1)).subscribe((answered) => {
                    this.quizEnded = answered;
                })
                let cont = 0;
                for (let question of this.quiz.questions) {
                    let visibility = question.visibility;

                    if (!visibility) {
                        this.quiz.questions.splice(cont, 1);
                    }
                    cont++;
                }

                if (this.quiz.questions.length > 0) {
                    this.currentQuestion = this.quiz.questions[0];
                }

                this.loader = false;

                if (!this.quiz.change_answer && this.quiz.view_answered) {
                    this.view_only = true;
                } else {
                    this.view_only = false;
                }

                this.startCountdown();
                this.progressBar();
                this.setResult();
                this.stopLoaderEvent.emit(false);
            });
        } else {
            if (this.componentMode) {
                clearInterval(this.interval);
                this.goBackToList();
            } else {
                clearInterval(this.interval);
                this.location.back();
            }
        }
    }


    setResult() {
        for (let i = 0; i < this.quiz.questions.length; i++) {
            if (this.quiz.questions[i].type == 'multipleSelect') {
                this.result[i] = new Array(this.quiz.questions[i].answers.length).fill(null);
            } else {
                this.result[i] = null;
            }
        }

        this.setResultVerify = true;

        if (this.SUserData.userLogged()) {
            this.daoQuiz.getResponseUsers(this.moduleId, this.quizId, this.userId, (responses) => {
                let cont = 0;
                for (let question of this.quiz.questions) {
                    if (responses[question.uid] == undefined) {
                        question.answered = false;
                    } else {
                        question.answered = true;

                        if (question.type == 'multipleSelect') {
                            if (!this.quiz.change_answer) {
                                question.showTrue = true;
                            }

                            let findOneCorrect = false;

                            for (let answer of this.quiz.questions[cont].answers) {
                                if (answer.correct) {
                                    findOneCorrect = true;
                                }
                            }

                            for (let answerId of responses[question.uid].answer) {
                                let contAnswer = 0;
                                for (let answer of this.quiz.questions[cont].answers) {
                                    if (answerId == answer.uid) {
                                        this.result[cont][contAnswer] = true;

                                        if (!this.quiz.change_answer && findOneCorrect && !answer.correct) {
                                            answer.showFalse = true;
                                        }
                                    }

                                    contAnswer++;
                                }
                            }
                        } else {

                            if (!this.quiz.change_answer) {
                                question.showTrue = true;
                            }

                            let findOneCorrect = false;

                            for (let answer of this.quiz.questions[cont].answers) {
                                if (answer.correct) {
                                    findOneCorrect = true;
                                }
                            }

                            for (let answer of this.quiz.questions[cont].answers) {
                                if (answer.uid == responses[question.uid].answer) {
                                    this.result[cont] = answer.uid;

                                    if (!this.quiz.change_answer && findOneCorrect && !answer.correct) {
                                        answer.showFalse = true;
                                    }
                                }
                            }
                        }
                    }

                    cont++;
                }
            })
        }
    }

    setEvaluation($event: { newValue: number }, index) {
        this.result[index] = $event.newValue;
    }

    // dateSelected($event, index) {
    //     if (this.quiz.questions[index].answered == false) {
    //         let date = new Date($event);
    //         let timestamp = this.notificationDate.getTimeStampFromDateNow(date, this.SEventData.eventData.timezone);
    //         this.result[index] = timestamp;
    //     } else {
    //         this.presentToastDate();
    //     }
    // }

    async presentToastDate() {
        const toast = await this.toastController.create({
            message: this.translateService.instant('global.alerts.date_already_selected'),
            duration: 2000
        });

        toast.present();
    }

    async presentToast(answered) {
        if (answered && this.quiz.change_answer == false) {
            const toast = await this.toastController.create({
                message: this.translateService.instant('global.alerts.not_change_answer'),
                duration: 2000
            });

            toast.present();
        }
    }

    async sendQuiz(question, index) {
        this.totalUserAnswers = true;
        this.submitting = true;
        clearInterval(this.interval);
        if (this.SUserData.userLogged() || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
            for (let i = 0; i <= this.quiz.questions.length - 1; i++) {
                if (this.quiz.questions[i].type == 'multipleSelect') {
                    let findAnswer = false;
                    for (let result of this.result[i]) {
                        if (result) {
                            findAnswer = true;
                        }
                    }

                    if (!findAnswer) {
                        this.totalUserAnswers = false;
                    }
                } else {
                    if (this.result[i] == null) {
                        this.totalUserAnswers = false;
                    }
                }
            }

            if (this.totalQuestions > this.quiz.questions.length) {
                this.totalUserAnswers = false;
            }


            let timestamp = Date.now() / 1000 | 0;

            if (question.type == 'multipleSelect') {
                let answersSelected: Array<string> = [];
                let answersSelectedWeight: Array<number> = [];

                for (let j = 0; j < this.result[index].length; j++) {
                    if (this.result[index][j] == true) {
                        answersSelected.push(question.answers[j].uid);

                        let weight = Number(question.answers[j].weight);
                        if (weight !== undefined && weight !== null) {
                            answersSelectedWeight.push(weight);
                        }

                        let findCorrect = false;
                        for (let answer of question.answers) {
                            if (answer.correct) {
                                findCorrect = true;
                            }
                        }

                        if (!question.answers[j].correct && findCorrect) {
                            question.answers[j].showFalse = true;
                        }
                    }
                }

                if (answersSelected.length > 0) {
                    await this.createResultQuestion(question.uid, question.type, answersSelected, timestamp)
                        .then((status) => {
                            question.answered = true;
                            question.showTrue = true;

                            let totalPoints = 0;
                            for (let aux of answersSelectedWeight) {
                                totalPoints = totalPoints + aux;
                            }

                            this.addUserPoints(totalPoints);
                            this.submitting = false;

                            // if (this.indexSlide >= this.quiz.questions.length - 1) {
                            //     this.setAnsweredQuiz();

                            // } else {
                            setTimeout(() => {
                                this.slideNext();
                            }, 1000);
                            // }
                        })
                }

            } else if (question.type == 'oneSelect') {
                if (this.result[index] != null && this.result[index] != undefined && this.result[index] != "") {
                    let ArrayAnswer: Array<String> = [this.result[index]];

                    let findCorrect = false;
                    for (let answer of question.answers) {
                        if (answer.correct) {
                            findCorrect = true;
                        }
                    }

                    let totalPoints = 0;
                    for (let answer of question.answers) {
                        if (answer.uid == this.result[index]) {

                            if (answer.weight !== null && answer.weight !== undefined && answer.weight != '') {
                                totalPoints = answer.weight;
                            }

                            if (!answer.correct && findCorrect) {
                                answer.showFalse = true;
                            }
                        }
                    }

                    await this.createResultQuestion(question.uid, question.type, ArrayAnswer, timestamp)
                        .then((status) => {
                            question.answered = true;
                            question.showTrue = true;
                            if (totalPoints > 0) {
                                this.addUserPoints(totalPoints);
                            }
                            this.submitting = false;
                            
                            if (this.indexSlide >= this.quiz.questions.length - 1) {
                                this.setAnsweredQuiz();
                            } else {
                                setTimeout(() => {
                                    this.slideNext();
                                }, 1000);
                            }
                        })
                }
            }
        }
    }

    createResultQuestion(questionId, typeQuestion, answeredSelected, timestamp) {
        return new Promise((resolve, reject) => {
            this.daoQuiz.createResult(this.moduleId, this.userId, this.quizId, questionId, typeQuestion, answeredSelected, timestamp, (status) => {

                // Track analytics
                this.SAnalytics.sendSurvey(this.eventId, this.moduleId, this.quizId);

                resolve(status);
            })
        })
    }

    startCountdown() {
        if (this.quiz.active_timer) {
            this.counter = this.quiz.timer_questions;
            clearInterval(this.interval);

            this.interval = setInterval(() => {
                this.counter--;

                if (this.counter < 0 && this.counter !== null) {
                    this.slideNext();
                }
            }, 1000);
        }
    }

    async slideNext() {
        if (this.currentQuestion.graphicDifusion && !this.currentQuestion.showResult) {
            this.currentQuestion.showResult = true;
        } else {
            if (this.indexSlide >= this.quiz.questions.length - 1) {
                this.currentQuestion.showResult = false;
                clearInterval(this.interval);
                if (!this.quiz.view_answered) {
                    this.setAnsweredQuiz();
                }
            } else {
                this.currentQuestion.showResult = false;
                this.indexSlide++;
                this.currentQuestion = this.quiz.questions[this.indexSlide];
                clearInterval(this.interval);
                this.mySlider.lockSwipes(false);
                this.mySlider.slideNext();
                this.mySlider.lockSwipes(true);
                this.progressBar();
                this.startCountdown();
                document.getElementById("top").scrollIntoView();
            }
        }
    }

    slidePrevious() {
        if (this.indexSlide > 0) {
            this.indexSlide--;
            this.currentQuestion.showResult = false;
            this.currentQuestion = this.quiz.questions[this.indexSlide];
            this.mySlider.lockSwipes(false);
            this.mySlider.slidePrev();
            this.mySlider.lockSwipes(true);
            document.getElementById("top").scrollIntoView();
        }
    }

    addUserPoints(points) {
        if (points > 0) {
            this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
                this.daoAttendee.addPoints(
                    this.eventId, 
                    userData.moduleId,
                    this.userId, 
                    points, 
                    (result) => { });
            });
        }
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.thankyou_answer'),
            message: this.translateService.instant('global.alerts.answer_saved_successfully'),
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        if (this.componentMode) {
                            this.goBackToList();
                        } else {
                            this.location.back();
                        }
                    }
                }
            ],
            backdropDismiss: false
        });

        await alert.present();

        clearInterval(this.interval);
    }

    setAnsweredQuiz() {
        if (this.quizEnded) {
            this.presentAlertConfirm();
        } else {
            this.daoQuiz.setUserQuizAnswered(this.eventId, this.userId, this.quizId, (data) => {
                this.presentAlertConfirm();
            });
        }
    }

    showNextButton() {
        if (
            this.quiz.questions.length <= 0
            || (this.quiz.view_answered
                && this.indexSlide == (this.quiz.questions.length - 1)
                && this.currentQuestion.showResult
            )
        ) {
            return (false);
        } else {
            return (true);
        }
    }
    removeClick(e){
        e.stopPropagation()
    }
}
