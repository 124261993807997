import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/shared/services';
import { DaoSurveyService } from 'src/app/providers/db/dao-survey.service';
import { Subscription } from 'rxjs';
import { GetSurveys } from 'src/app/shared/actions/interactivity.actions';
import { Survey } from 'src/app/models/survey';
import { AppState } from 'src/app/shared/reducers';
import { Store } from '@ngrx/store';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-surveys-list-page',
    templateUrl: './surveys-list-page.page.html',
    styleUrls: ['./surveys-list-page.page.scss'],
})
export class SurveysListPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    surveyModule: any = null;
    surveys: Survey[] = [];
    isMobile: boolean = false;

    eventId: string = null;
    moduleId: string = null;

    backBtn: boolean = true;

    allowButtonsHeader: boolean = false;
    eventColors: EventColors = new EventColors();

    language: string;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private router: Router,
        public SGlobal: GlobalService,
        private menuCtrl: MenuController,
        private daoSurvey: DaoSurveyService,
        public SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);
        if (this.SGlobal.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }

        this.language = this.SEventData.getLanguage();

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        this.loadColors();
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.store.dispatch(new GetSurveys({
            surveyModule: null,
            surveys: []
        }))
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ionViewWillEnter() {
        this.getSurveys();
        // this.daoInteractivity.getInteractivityModule(this.eventId, (moduleInteractivity) => {
        //     this.SGlobal.loadService(() => {
        //         if (this.SGlobal.user || moduleInteractivity.answerOffline) {
        //         } else {
        //             this.SGlobal.userLogged();
        //         }
        //     });
        // })
    }

    /**
     * Getting all surveys for event
     */
    getSurveys() {
        this.subscriptions.push(this.daoSurvey.getSurveyModule(this.eventId).subscribe((modules) => {
            if (modules.length > 0) {
                this.surveyModule = modules[0];

                this.subscriptions.push(this.daoSurvey.getSurveysEvent(this.moduleId).subscribe((surveys) => {
                    this.surveys = [];
                    this.surveys = surveys;

                    this.store.dispatch(new GetSurveys({
                        surveyModule: this.surveyModule,
                        surveys: this.surveys
                    }))
                }));
            }
        }))
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ionViewWillLeave() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

}
