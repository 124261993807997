import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoAskQuestionService } from 'src/app/providers/db/dao-ask-queston.service';
import { Subscription } from 'rxjs';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-ask-question-list',
    templateUrl: './ask-question-list.page.html',
    styleUrls: ['./ask-question-list.page.scss'],
})
export class AskQuestionListPage implements OnInit {
    subscriptions: Subscription[] = [];

    questionModule: any = null;

    eventId: string = null;
    moduleId: string = null;
    userId: string = null;
    isMobile: boolean = false;

    listItems: any[] = [];

    eventColors: EventColors = new EventColors();

    loader: boolean = true;

    language: string;

    constructor(private route: ActivatedRoute,
        private router: Router,
        public SGlobal: GlobalService,
        private menuCtrl: MenuController,
        private daoAskQuestion: DaoAskQuestionService,
        private SAnalytics: AnalyticsService,
        private SEventData: EventDataService, 
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        this.route.params.subscribe((params) => {
            this.eventId = params.eventId;
            // localStorage.setItem('eventId', this.eventId);
            this.moduleId = params.moduleId;
            this.loadColors();
            this.language = this.SEventData.getLanguage();

            // Analytics tracking
            this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
            this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_ASK_QUESTION_MODULE);
        })
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        this.daoAskQuestion.getQuestionsModule(this.moduleId).subscribe((questionModule) => {
            this.questionModule = questionModule;
        })

        this.getAskQuestions();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Get ask questions
     */
    getAskQuestions() {
        this.subscriptions.push(this.daoAskQuestion.getAskQuestions(this.moduleId).subscribe((listItems) => {
            this.listItems = listItems;
            this.loader = false;
        }));
    }

    /**
     * Open question module
     * @param item 
     */
    openItem(item) {
        this.router.navigate([`/event/${this.eventId}/ask-question/${this.moduleId}/${item.uid}`]);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
