import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IVideoPlayer } from "../interfaces/video-player.interfaces";

export const getVideoPlayerState = createFeatureSelector<IVideoPlayer>("videoPlayer");

export const getSrc = createSelector(getVideoPlayerState, (state: IVideoPlayer) => (state !== null ? state.src : null));
export const getType = createSelector(getVideoPlayerState, (state: IVideoPlayer) =>
	state !== null ? state.type : null
);
export const getStart = createSelector(getVideoPlayerState, (state: IVideoPlayer) =>
	state !== null ? state.start : null
);
export const getSessionId = createSelector(getVideoPlayerState, (state: IVideoPlayer) =>
	state !== null ? state.sessionId : null
);
export const getInSession = createSelector(getVideoPlayerState, (state: IVideoPlayer) =>
	state !== null ? state.inSession : null
);
export const getState = createSelector(getVideoPlayerState, (state: IVideoPlayer) =>
	state !== null ? state.state : null
);
export const getBaseVideoState = createSelector(
	getSrc,
	getType,
	getStart,
	getSessionId,
	(src: string, type: string, start: number, sessionId: string) => {
		return {
			src: src,
			type: type,
			start: start,
			sessionId: sessionId
		};
	}
);
