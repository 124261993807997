import { Injectable } from '@angular/core';
import { v1 as uuidv1 } from 'uuid';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class PublicService {
    isEventPublic: boolean = false;

    constructor(
        private platform: Platform,
        private storage: Storage
    ) {}

    /**
     * Create public id if don't already exist
     */
    async createOrGetPublicUserId(): Promise<string> {
        let userPublicId: string = "";

        // Get value of user public id for checking if not already exist
        if (this.platform.is('ios') || this.platform.is('android')) {
            userPublicId = await this.storage.get('userPublicId');
        } else {
            userPublicId = localStorage.getItem('userPublicId');
        }

        // If already exist return it
        if (userPublicId) {
            return (userPublicId);
        } else {

            // If don't exist create and return it
            let uid = uuidv1();

            if (this.platform.is('ios') || this.platform.is('android')) {
                await this.storage.set('userPublicId', uid);
            } else {
                localStorage.setItem('userPublicId', uid);
            }
            return (uid);
        }
    }

    /**
     * Clear user public id
     */
    clearUserPublicId() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.storage.remove('userPublicId');
        } else {
            localStorage.removeItem('userPublicId');
        }
    }
}
