import { Injectable } from '@angular/core';
import { Survey } from 'src/app/models/survey';
import { Question } from 'src/app/models/question';
import { Answer } from 'src/app/models/answer';
import { TypeModule } from 'src/app/models/type-module';
import { map, take, switchMap, takeUntil } from 'rxjs/operators';
import { of, Observable, combineLatest } from 'rxjs';
import { UtilityService } from 'src/app/shared/services';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, setDoc, updateDoc, where, arrayUnion } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class DaoSurveyService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private SUtility: UtilityService,
        private platform: Platform,
        private storage: Storage
    ) {
    }

    /**
     * Get survey module for specified event
     * @param eventId 
     */
    getSurveyModule(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.SURVEY));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * Get module of a survey
     * @param surveyModuleId 
     */
    getModule(surveyModuleId: string) {
        const ref = doc(this.firestore, `modules/${surveyModuleId}`);
        return docData(ref).pipe(take(1));
    }

    /**
     * Getting surveys
     * @param moduleId 
     */
    getSurveys(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/surveys`);
        const refQ = query(ref, orderBy('order', 'asc'));
        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                let listSurveys = [];
                let obs: Observable<any>[] = [];
                if (docs && docs.length > 0) {
                    for (let i = 0; i < docs.length; i++) {
                        let survey = this.instantiateSurvey(docs[i]);
                        if (survey.visibility) {
                            let index = this.SUtility.checkIndexExists(listSurveys, survey);

                            if (index >= 0) {
                                listSurveys[index] = survey;
                            } else {
                                listSurveys.push(survey);
                            }
                        }
                    }
                    listSurveys.forEach((survey) => {
                        obs.push(this.getQuestions(moduleId, survey.uid).pipe(
                            map((questions) => {
                                survey.questions = questions;
                                return (survey);
                            })
                        ));
                    })

                    return (combineLatest(obs));
                } else {
                    return (of([]));
                }
            })
        );
    }

    /**
     * Get all surveys for an event
     * @param moduleId 
     */
    getSurveysEvent(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/surveys`);
        return collectionData(ref).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((docs) => {
                return (docs.map((doc) => {
                    let survey = this.instantiateSurvey(doc);
                    return (survey);
                }))
            })
        );
    }

    /**
     * Get session of survey
     * @param eventId 
     * @param moduleId 
     * @param scheduleModuleId 
     * @param sessionId 
     * @param userId 
     * @param onResolve 
     */
    getSurveysSession(eventId: string, moduleId: string, scheduleModuleId: string, sessionId: string, userId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/surveys`);
        const refQ = query(ref, orderBy('order', 'asc'));
        
        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap(async (docs) => {
                let listSurveys: Array<Survey> = [];
                let listSurveysSession: Array<Survey> = [];

                if (docs.length > 0) {
                    docs.forEach((element) => {
                        let survey = this.instantiateSurvey(element);
                        if (survey.visibility) {
                            let index = this.SUtility.checkIndexExists(listSurveys, survey);

                            if (index >= 0) {
                                listSurveys[index] = survey;
                            } else {
                                listSurveys.push(survey);
                            }
                        }
                    })
                    for (let survey of listSurveys) {

                        if (survey.type === 'AllSessions') {
                            listSurveysSession.push(survey);
                        }
                        else if (survey.type === 'ScheduleModule') {
                            if (scheduleModuleId === survey.module_id) {
                                listSurveysSession.push(survey);
                            }
                        }
                        else if (survey.type === 'SessionTrack') {
                            // case this session be inside a track with permission, display feedback
                            await this.checkSpecificTrackSurvey(survey.module_id, sessionId, survey.references, (tracks: Array<boolean>) => {
                                for (const track of tracks) {
                                    if (track) {
                                        listSurveysSession.push(survey);
                                        break;
                                    }
                                }
                            });
                        }
                        else if (survey.type === 'SpecificSession') {
                            for (let uid of survey.references) {
                                if (uid === sessionId) {
                                    listSurveysSession.push(survey);
                                    break;
                                }
                            }
                        }
                        else if (survey.type === 'SpecificGroup') {
                            await this.listgroupsOfAttendee(eventId, userId, (listGroupsAttendee) => {
                                const references: any = survey.references
                                let findGroup = false;
                                for (let uidGroup of references) {
                                    for (const uidGroupUser of listGroupsAttendee) {
                                        //if the user is part of the group
                                        if (uidGroup == uidGroupUser && findGroup == false) {
                                            listSurveysSession.push(survey);
                                            findGroup = true;
                                            break;
                                        }
                                    }

                                }
                            })
                        }
                    }
                }
                return (listSurveysSession);
            })
        );
    }

    /**
     * Get survey
     * @param moduleId 
     * @param surveyId 
     */
    getSurvey(moduleId: string, surveyId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}/surveys/${surveyId}`);
        return docData(ref).pipe(
            take(1),
            map((doc) => {
                if (doc) {
                    const survey: Survey = this.instantiateSurvey(doc);
                    return (survey);
                } else {
                    return (null);
                }
            }),
            switchMap((survey) => {
                if (survey) {
                    return (this.getQuestions(moduleId, surveyId).pipe(
                        switchMap((questions) => {
                            survey.questions = questions;
                            return (of(survey));
                        })
                    ))
                } else {
                    return (of(null));
                }
            })
        );
    }

    /**
     * Get total of questions on a survey
     * @param surveyModuleId 
     * @param surveyId 
     */
    getTotalQuestions(surveyModuleId: string, surveyId: string) {
        const ref = collection(this.firestore, `modules/${surveyModuleId}/surveys/${surveyId}/questions`);
        return collectionData(ref).pipe(
            take(1),
            switchMap((docs) => {
                return (of(docs.length));
            })
        );
    }

    /**
     * Get questions of a survey
     * @param moduleId 
     * @param quizId 
     */
    getQuestions(surveyModuleId: string, surveyId: string): Observable<any> {
        const ref = collection(this.firestore, `modules/${surveyModuleId}/surveys/${surveyId}/questions`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        return collectionData(refQ).pipe(
            take(1),
            map((docs) => {
                return (docs.map((doc) => {
                    const question = this.instantiateQuestion(doc);
                    return (question);
                }))
            }),
            switchMap((questions) => {
                let obs: Observable<any>[] = [];
                if (questions && questions.length > 0) {
                    for (let i = 0; i < questions.length; i++) {
                        let question = questions[i];
                        obs.push(this.getAnswers(surveyModuleId, surveyId, question.uid).pipe(
                            map((answers: any) => {
                                question.answers = answers;
                                return (question);
                            })
                        ));
                    }

                    return (combineLatest(obs))
                } else {
                    return (of([]));
                }
            })
        );
    }

    /**
     * Get answer for the questions of a survey
     * @param quizModuleId 
     * @param quizId 
     * @param questionId 
     */
    getAnswers(surveyModuleId: string, surveyId: string, questionId: string) {
        const ref = collection(this.firestore, `modules/${surveyModuleId}/surveys/${surveyId}/questions/${questionId}/answers`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        return collectionData(refQ).pipe(
            take(1),
            map((docs) => {
                return (docs.map((doc) => {
                    let answer: Answer = this.instantiateAnswer(doc);
                    return (answer)
                }))
            })
        );
    }

    checkSpecificTrackSurvey(trackModuleId, sessionId, tracksIds, onResolve) {
        let list = [];
        for (let trackId of tracksIds) {
            this.checkTrackSurvey(trackModuleId, sessionId, trackId, (result: boolean) => {
                list.push(result);

                if (list.length == tracksIds.length) {
                    onResolve(list);
                }
            })
        }
    }

    // VERIFICA SE A TRACK EXISTE NA SESSÃO
    checkTrackSurvey(moduleId, sessionId, trackId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/tracks/${trackId}/sessions/${sessionId}`);
        docData(ref).pipe(take(1)).subscribe((doc) => {
            if (doc) { onResolve(true); }
            else { onResolve(false); }
        });
    }

    listgroupsOfAttendee(eventId: string, attendeeId: string, onResolve) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        docData(ref).pipe(take(1)).subscribe((doc) => {
                const groups = doc['groups'];
                let idsGroups: Array<String> = [];

                if (groups !== null && groups !== undefined) {
                    for (let uid in groups) {
                        idsGroups.push(uid);
                    }
                }

                onResolve(idsGroups);
        });
    }


    async createResult(eventId: string, moduleId: string, userId: string, surveyId: string, IdQuestion: string, type: string, answer: any, timestamp: number, documentResponse: any, onResolve) {
        const questionsResultPath = `modules/${moduleId}/surveys/${surveyId}/questions/${IdQuestion}/result`;
        const refColl = collection(this.firestore, questionsResultPath);

        // Variable for public events
        let surveysString: string = "";
        let surveysSaved: any = {};

        // Get and prepare date for saving locally if public event
        if (!userId) {
            if (this.platform.is('ios') || this.platform.is('android')) {
                surveysString = await this.storage.get('surveysAnswered');
            } else {
                surveysString = localStorage.getItem('surveysAnswered');
            }

            if (JSON.parse(surveysString) && JSON.parse(surveysString) != null) {
                surveysSaved = JSON.parse(surveysString);
            }
            if (!surveysSaved || !surveysSaved[eventId]) {
                surveysSaved[eventId] = {
                    surveys: [surveyId]
                }

            } else if (surveysSaved && surveysSaved[eventId] && surveysSaved[eventId].surveys.length == 0 || !surveysSaved[eventId].surveys.includes(surveyId)) {
                surveysSaved[eventId].surveys.push(surveyId);
            }
        }

        if (type == "oneSelect" || type == "multipleSelect") {
            if (userId !== null && userId !== undefined && userId !== '') {
                this.getResponseUserQuestion(moduleId, surveyId, IdQuestion, userId).subscribe((responses) => {
                    this.deletePrevAnswersSelectOption(moduleId, surveyId, IdQuestion, userId, (result) => {
                        if (result == true) {
                            setDoc(
                                doc(this.firestore, `${questionsResultPath}/${userId}`),
                                {
                                    user: userId,
                                    answer: answer,
                                    timestamp: timestamp,
                                    question: IdQuestion,
                                    title: (documentResponse) ? documentResponse.title : null,
                                    docType: (documentResponse) ? documentResponse.docType : null,
                                }
                            ).then(() => {
                                if (responses !== null && responses !== undefined) {
                                    onResolve(false);
                                } else {
                                    onResolve(true);
                                }
                            }).catch((e) => { console.error(e); });
                        }
                    })
                })
            } else {
                // userId = refDoc.id;
                addDoc(
                    refColl,
                    {
                        user: "",
                        answer: answer,
                        timestamp: timestamp,
                        question: IdQuestion,
                        title: (documentResponse) ? documentResponse.title : null,
                        docType: (documentResponse) ? documentResponse.docType : null,
                    }
                ).then(() => {
                    // Save localy if public event
                    if (this.platform.is('ios') || this.platform.is('android')) {
                        this.storage.set('surveysAnswered', JSON.stringify(surveysSaved));
                    } else {
                        localStorage.setItem('surveysAnswered', JSON.stringify(surveysSaved));
                    }
                    onResolve(true);
                });
            }
        } else {
            if (userId !== null && userId !== undefined && userId !== '') {
                setDoc(
                    doc(this.firestore, `${questionsResultPath}/${userId}`),
                    {
                        question: IdQuestion,
                        user: userId,
                        answer: answer,
                        timestamp: timestamp,
                        title: (documentResponse) ? documentResponse.title : null,
                        docType: (documentResponse) ? documentResponse.docType : null,
                    }
                ).then(() => { onResolve(true); });
            } else {
                // userId = document.id;
                addDoc(
                    refColl,
                    {
                        question: IdQuestion,
                        user: "",
                        answer: answer,
                        timestamp: timestamp,
                        title: (documentResponse) ? documentResponse.title : null,
                        docType: (documentResponse) ? documentResponse.docType : null,
                    }
                ).then(() => {
                    // Save localy if public event
                    if (this.platform.is('ios') || this.platform.is('android')) {
                        this.storage.set('surveysAnswered', JSON.stringify(surveysSaved));
                    } else {
                        localStorage.setItem('surveysAnswered', JSON.stringify(surveysSaved));
                    }
                    onResolve(true);
                }).catch((e) => { console.log(e); });
            }
        }
    }

    /**
     * Get user response
     * @param moduleId 
     * @param surveyId 
     * @param userId 
     * @param onResolve 
     */
    getResponseUsers(moduleId, surveyId, userId, onResolve) {
        this.getQuestions(moduleId, surveyId).subscribe((questions) => {
            let listResults = {};
            for (let i = 0; i < questions.length; i++) {
                this.getResponseUserQuestion(moduleId, surveyId, questions[i].uid, userId).subscribe((response) => {
                    if (response != null) {
                        listResults[response.question] = response;
                    }

                    if (i == questions.length - 1) {
                        onResolve(listResults);
                    }
                })
            }
        })
    }

    /**
     * Get response user question
     * @param moduleId 
     * @param surveyId 
     * @param questionId 
     * @param userId 
     * @param onResolve 
     */
    getResponseUserQuestion(moduleId, surveyId, questionId, userId) {
        const ref = doc(this.firestore, `modules/${moduleId}/surveys/${surveyId}/questions/${questionId}/result/${userId}`);
        return docData(ref).pipe(take(1));
    }

    deletePrevAnswersSelectOption(moduleId, surveyId, IdQuestion, userId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/surveys/${surveyId}/questions/${IdQuestion}/result/${userId}`);
        deleteDoc(ref)
            .then(() => { onResolve(true) })
            .catch((e) => { onResolve(false); });
    }

    instantiateSurvey(data) {
        let survey = new Survey();
        survey.title = data.title;
        survey.type = data.type;
        survey.uid = data.uid;
        survey.visibility = data.visibility;
        survey.change_answer = data.change_answer;
        survey.view_answered = data.view_answered;
        survey.icon = data.icon;
        survey.iconFamily = data.iconFamily;
        survey.totalQuestions = data.totalQuestions;
        survey.order = data.order;
        survey.groups = data.groups;
        survey.minPoints =  data.minPoints;
        survey.forceComplete = (!data.forceComplete) ? false : data.forceComplete;

        data.max_responses != undefined ? survey.max_responses = data.max_responses : survey.max_responses = null;
        data.module_id != undefined ? survey.module_id = data.module_id : survey.module_id = null;
        data.references != undefined ? survey.references = data.references : survey.references = null;

        return survey;
    }

    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.mainTitle = data.mainTitle;
        question.title = data.title;
        question.points = data.points;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = '';
        data.maxResponses != undefined ? question.maxResponses = data.maxResponses : question.maxResponses = null;
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;

        return answer;
    }

    setUserSurveyAnswered(eventId: string, attendeeId: string, surveyId: string) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        return updateDoc(
            ref,
            { answeredSurveys: arrayUnion(surveyId) }
        );
    }
}