import { VideoPlayerActionsTypes, VideoPlayerActionsUnion } from "../actions/video-player.actions";
import { IVideoPlayer } from "../interfaces/video-player.interfaces";

export const initialState: IVideoPlayer = null;

export function videoPlayerReducer(state = initialState, action: VideoPlayerActionsUnion): IVideoPlayer {
	switch (action.type) {
		case VideoPlayerActionsTypes.GetVideoPlayer: {
			return action.payload;
		}
		case VideoPlayerActionsTypes.UpdateSrc: {
			return {
				...state,
				src: action.payload
			};
		}
		case VideoPlayerActionsTypes.UpdateType: {
			return {
				...state,
				type: action.payload
			};
		}
		case VideoPlayerActionsTypes.UpdateStart: {
			return {
				...state,
				start: action.payload
			};
		}
		case VideoPlayerActionsTypes.UpdateSessionId: {
			return {
				...state,
				sessionId: action.payload
			};
		}
		case VideoPlayerActionsTypes.UpdateInSession: {
			return {
				...state,
				inSession: action.payload
			};
		}
		case VideoPlayerActionsTypes.UpdateState: {
			return {
				...state,
				state: action.payload
			};
		}

		default: {
			return state;
		}
	}
}
