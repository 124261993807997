import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavParams, ModalController, Platform } from '@ionic/angular';
// import { Events } from 'src/app/shared/services/global/events.service';
import { AnalyticsService } from 'src/app/shared/services';
import { DaoFeedNewsService } from 'src/app/providers/db/dao-feed-news.service';
import { Post } from '../../models/ceu-post'
import { LightboxImgComponent } from '../lightbox-img/lightbox-img.component';
import { Subscription } from 'rxjs';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-feed-comment',
    templateUrl: './feed-comment.component.html',
    styleUrls: ['./feed-comment.component.scss']
})


export class FeedCommentComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    eventId: string;
    moduleId: string;
    postId: string;
    userLoggedId: string
    userPhotoUrl: string = null;

    isComment: boolean
    isSocial_sharing: boolean

    eventColors: EventColors = new EventColors();

    post = null
    comments = []
    textArea = ""

    constructor(
        private navParams: NavParams,
        // private events: Events,
        private modalCtrl: ModalController,
        private dbFeed: DaoFeedNewsService,
        private SAnalytics: AnalyticsService,
        private platform: Platform,
        private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.eventId = this.navParams.get('eventId');
        this.moduleId = this.navParams.get('moduleId');
        this.postId = this.navParams.get('postId');
        this.userLoggedId = this.navParams.get('userLoggedId')
        this.isComment = this.navParams.get('isComment')

        this.loadColors();
        const subs = this.SUserData.getUserDataListener(this.eventId).subscribe((userData) => {
            this.userPhotoUrl = userData.photoUrl;
        });
        this.subscriptions.push(subs);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    mobileBrowser = false;
    ngOnInit() {
        this.getPost()
        this.getModule()
        this.mobileBrowser = this.platform.platforms().includes('mobileweb')
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getPost() {
        this.subscriptions.push(this.dbFeed.getPost(this.moduleId, this.postId).subscribe((post) => {
            if (typeof post !== 'undefined') {
                this.post = post
                // comments
                const aux = []

                for (let uid in this.post.comments) {
                    aux.push(this.post.comments[uid])
                }

                // sorting by date
                this.comments = aux.sort(function (a, b) {
                    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                });
            }
        }))
    }

    getModule() {
        this.subscriptions.push(this.dbFeed.moduleNewsFeed(this.moduleId).subscribe((module: any) => {
            this.isComment = module.comment;
            this.isSocial_sharing = module.social_sharing;
        }))
    }

    // verifies if the user liked the post
    verifiesLikedPost(post) {
        return typeof post.likes[this.SUserData.userId] !== 'undefined'
    }

    // account the number of likes in the post.
    contLikes(likes) {
        let cont = 0

        for (let i in likes) {
            cont++
        }

        return cont
    }

    // add the like in post
    addLike(post) {
        if (this.SUserData.userLogged()) {
            post.likes[this.userLoggedId] = true;
            post.totalLikes = post.totalLikes + 1;

            this.dbFeed.updatePost(post, this.eventId);


            // Track like analytics
            this.SAnalytics.sendOrRemoveLikeOnFeed(this.eventId, post, 'send', TypeTracking.SEND_LIKE_ON_FEED);
        }
    }

    // remove the like in post
    removeLike(post) {
        if (this.SUserData.userLogged()) {
            delete post.likes[this.userLoggedId];
            post.totalLikes = post.totalLikes - 1;

            this.dbFeed.updatePost(post, this.eventId);

            // Track like analytics
            this.SAnalytics.sendOrRemoveLikeOnFeed(this.eventId, post, 'remove', TypeTracking.REMOVE_LIKE_ON_FEED);
        }
    }
    addOrRemoveLike(post) {
        if (this.SUserData.userLogged()) {
            if (!this.verifiesLikedPost(post)) {
                post.likes[this.userLoggedId] = true;
                this.dbFeed.updatePost(post, this.eventId);
            } else {
                delete post.likes[this.userLoggedId];
                this.dbFeed.updatePost(post, this.eventId);
            }
        }
    }

    deletePost(post) {
        this.dbFeed.deletePost(post, this.eventId);
        this.close()
    }

    createComment() {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            if (this.textArea.length > 0) {
                const commentId = this.dbFeed.createIdPost()
                this.post.comments[commentId] = {
                    uid: commentId,
                    creator: {
                        uid: userData.userId,
                        name: userData.name,
                        img: userData.photoUrl,
                    },
                    text: this.textArea,
                    date: this.dt.dbTime()
                }
                this.post.totalComments = this.post.totalComments + 1;

                this.dbFeed.updatePost(this.post, this.eventId);
                this.textArea = "";
                const textAreaEl = document.getElementById('chatInput')
                textAreaEl.style.minHeight = '50px';
                textAreaEl.style.height = 'auto';

                // Track analytics
                this.SAnalytics.sendOrDeleteCommentOnFeed(this.eventId, {
                    id: commentId,
                    comment: this.textArea
                }, TypeTracking.SEND_COMMENT_ON_FEED);
            }
        });
    }

    deleteComment(comment) {
        delete this.post.comments[comment.uid];
        this.post.totalComments = this.post.totalComments - 1;

        this.dbFeed.updatePost(this.post, this.eventId);

        // Track analytics
        this.SAnalytics.sendOrDeleteCommentOnFeed(this.eventId, {
            id: comment.uid,
            comment: comment.text
        }, TypeTracking.DELETE_COMMENT_ON_FEED);
    }

    close() {
        this.modalCtrl.dismiss();
    }

    openImage(url: string) {
        this.modalCtrl.create({
            component: LightboxImgComponent,
            componentProps: {
                img: url
            }
        }).then(modal => {
            modal.present();
        });
    }

    async moreOptions(post: Post) {
        //todo: social-sharing
    }

    getDate(dateNumber){
        //@TODO language constraint 
        //const date = (dateNumber%1 !== 0 ) ? new Date(dateNumber * 1000) : new Date(dateNumber);
        // const date = new Date(dateNumber);

        // console.log(moment(new Date(dateNumber * 1000)).format("MM/DD/YYYY - HH:mm") + ' days',moment(new Date()).diff(moment(new Date(dateNumber * 1000)),'days'))
        // console.log(moment(new Date(dateNumber * 1000)).format("MM/DD/YYYY - HH:mm") + ' hours',moment(new Date()).diff(moment(new Date(dateNumber * 1000)),'hours'))
        // console.log(moment(new Date(dateNumber * 1000)).format("MM/DD/YYYY - HH:mm") + ' years',moment(new Date()).diff(moment(new Date(dateNumber * 1000)),'years'))

        //return this.SEventData.getLanguage() == "FrFR" ? moment(date).format("MM/DD/YYYY - HH:mm") : moment(date).format('DD/MM/YYYY - HH:mm')

        return this.dt.getTimeDate(dateNumber);
    }

    checkOverFlow(event){
        // console.log(event,event.target, event.target.style)
        const el = event.target;
        setTimeout(() => {
            el.style.cssText = `height:auto;border-color: ${this.eventColors.text_content_color}; color: ${this.eventColors.text_content_color} `;
            // el.style.cssText = 'height:' + el.scrollHeight + 'px';

            el.style.height =  el.scrollHeight + 'px';
        },0);
    }

}
