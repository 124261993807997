import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageOfflineService {

  constructor(public storage: Storage) { }


   // define uma chave / valor
   async set(key: string, value: any): Promise<any> {
    try {
      const result = await this.storage.set(key, value);
    //   console.log('set string in storage: ' + result);
      return true;
    } catch (reason) {
    //   console.log(reason);
      return false;
    }
  }
  
  // para obter um par de chave / valor
  async get(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
    //   console.log('storageGET: ' + key + ': ' + result);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
    //   console.log(reason);
      return null;
    }
  }
  
  // // define um objeto de chave / valor
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
    //   console.log('set Object in storage: ' + result);
      return true;
    } catch (reason) {
    //   console.log(reason);
      return false;
    }
  }
  
  // obtém um objeto de chave / valor
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }

  // remove um único valor de chave:
  remove(key: string) {
    this.storage.remove(key);
  }
  
  //  exclua todos os dados do seu aplicativo:
  clear() {
    this.storage.clear();
  }





}
