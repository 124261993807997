import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { 
    Firestore, 
    doc,
    docData, 
} from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetIntegrations } from '../../actions/integrations.actions';
import { IIntegration, IZoom } from '../../interfaces/integrations.interface';
import { AppState } from '../../reducers';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {
    integrationSubscription: Subscription;
    eventId: string;
    signatureEndpoint: string = environment.platform.apiBaseUrl + 'dbZoomGenerateSignature';

    constructor(
        private firestore: Firestore,
        private store: Store<AppState>,
        private httpClient: HttpClient
    ) { }

    /**
     * Get integration
     * @param eventId 
     */
    getIntegration(eventId: string) {
        if (eventId && this.eventId && this.eventId != eventId) {
            this.integrationSubscription.unsubscribe();
        }

        const ref = doc(this.firestore, `integrations/${eventId}`);
        docData(ref).pipe(map((doc) => {
            return ({ id: ref.id, ...doc as IIntegration })
        })).subscribe((integrations) => {
            this.store.dispatch(new GetIntegrations(integrations));
        })

        // this.integrationSubscription = this.afDb.collection('integrations').doc(eventId).snapshotChanges().pipe(
        //     map((doc) => {
        //         return ({
        //             id: doc.payload.id,
        //             ...doc.payload.data() as IIntegration
        //         })
        //     })
        // ).subscribe((integrations) => {
        //     this.store.dispatch(new GetIntegrations(integrations));
        // });
    }

    /**
     * Get signature for meeting
     * @param zoomIntegration 
     */
    generateSignature(meetingNumber: number, role: number, zoomIntegration: IZoom) {
        return (this.httpClient.post(this.signatureEndpoint, {
            meetingNumber: meetingNumber,
            role: role,
            apiKey: zoomIntegration.apiKey,
            secret: zoomIntegration.secret
        }, {
            observe: 'body'
        }).toPromise())
    }
}
