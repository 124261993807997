import { Component, OnInit } from '@angular/core';
import { AnalyticsService, GlobalService } from 'src/app/shared/services';
import { DaoEventsService } from '../providers/db/dao-events.service';
import { AuthService } from '../providers/authentication/auth.service';
import { NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute } from '@angular/router';
import { DateTimeService } from '../providers/date-time/date-time.service';
import { UserDataService } from '../shared/services/user-data/user-data.service';
import { DocumentData } from '@angular/fire/firestore';
import { EventDataService } from '../shared/services/eventData/event-data.service';
import { AppContainerService } from '../providers/app-container/app-container.service';

@Component({
    selector: 'app-user-events',
    templateUrl: './user-events.page.html',
    styleUrls: ['./user-events.page.scss'],
    providers: [DaoEventsService]
})
export class UserEventsPage implements OnInit {
    events;
    searchOpen = false;
    container: any;
    userId: string = null;
    loader: boolean = true;
    allow: boolean = true;

    constructor(
        public global: GlobalService,
        private daoEvents: DaoEventsService,
        private auth: AuthService,
        private route: ActivatedRoute,
        private navCtrl: NavController,
        private SAnalytics: AnalyticsService,
        private dt: DateTimeService,
        private userDataService: UserDataService,
        private SEventData: EventDataService,
        private SAppContainer: AppContainerService
    ) { }

    ngOnInit() {
        this.loader = true;
        this.route.params.subscribe((params) => {
            this.userId = params.userId;
            this.loadEvents();
            this.SAppContainer.getContainerData().then(container => {
                this.container = container
                document.body.style.setProperty(`--valid-btn-bg-color`, container.valid_btn_bg_color);
                document.body.style.setProperty(`--text-color-1st-login`,container.text_color_1st_login);
                document.body.style.setProperty(`--text-color-2nd-login-desktop`,container.text_color_2nd_login_desktop);
                document.body.style.setProperty(`--text-color-2nd-login-mobile`,container.text_color_2nd_login_mobile);
                document.body.style.setProperty(`--label-login-color`,container.label_login_color);
                // document.body.style.setProperty(`--active-menu-item`,container.active_menu_item) 
                document.body.style.setProperty(`--ion-color-blue-default`, container.color_blue_default);
                document.body.style.setProperty(`--ion-color-blue-default-contrast`, container.color_blue_default_contrast);
                document.body.style.setProperty(`--ion-color-blue-default-shade`, container.color_blue_default_shade);
                document.body.style.setProperty(`--ion-color-blue-default-tint`, container.color_blue_default_tint);
            });
        })
    }

    loadEvents() {
        this.daoEvents.getUserEvents(this.userId, (data) => {
            // makes the treatment of the startDate of the event.
            for (const event of data) {
                event.startDate = this.dt.getDate(event.startDate, this.dt.deviceTimeZone);
                
                if (event.banner == '' || event.banner == null || event.banner == undefined) {
                    event.banner = {
                        uid: '',
                        url: ''
                    }
                }
            }
            
            this.events = data;
            this.loader = false;
        });
    }

    logOut() {
        // Update connected analytics
        // this.SAnalytics.updateConnectedStatusUser(false);

        this.auth.logout();
    }

    openEvent(event: DocumentData) {
        this.loader = true;
        localStorage.setItem('eventId', event.uid);
        localStorage.setItem('homePage', event.homePage);


        this.SEventData.eventData = event;
        this.SEventData.eventId = event.uid;
        this.SEventData.getEventDataListener(event.uid).subscribe();

        // this.global.eventHomePage = event.homePage;
        this.global.previousPage = 'container';

        this.SAnalytics.updateConnectedStatusUser(event.uid, true);

        this.userDataService.getUserDataSnapshot(event.uid).then((userData) => {
            if (this.allow) {
                this.allow = false;
                if (event.required_edit_profile == false) {
                    this.navCtrl.navigateRoot([event.homePage]).then((_) => {
                        if (_ == true) {
                            // this.eventsA.publish('updateScreen');
                            this.loader = false;
                        }
                    });
                } 
                else if (event.required_edit_profile == true && userData.edited_profile == true) {
                    this.navCtrl.navigateRoot([event.homePage]).then((_) => {
                        if (_ == true) {
                            // this.eventsA.publish('updateScreen');            
                            this.loader = false;
                        }
                    });
                } 
                else if (event.required_edit_profile == true && userData.edited_profile == false) {
                    this.navCtrl
                        .navigateRoot([`/event/${event.uid}/edit-profile/${userData.moduleId}/${userData.type}/${userData.uid}`])
                        .then((_) => {
                            if (_ == true) {
                                // this.eventsA.publish('updateScreen');
                                this.loader = false;
                            }
                        });
                }
            }
        })
    }

    ionViewDidEnter() {
        const toasts = document.querySelectorAll('ion-toast')
        toasts.forEach(item => {
            item.remove()
        })
        setTimeout(() => {
            document.querySelector('ion-app').querySelectorAll('ion-toast').forEach(item => item.remove())
        }, 500)
    }
}
