import { Injectable } from '@angular/core';
import { 
    Firestore,
    collection, 
    collectionData, 
    doc, 
    docData,
    query,
    orderBy,
    where,
    writeBatch,
    updateDoc
} from '@angular/fire/firestore';
import { firstValueFrom, take, takeUntil } from 'rxjs';
import { TypeModule } from 'src/app/enums/type-module';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { DateTimeService } from '../date-time/date-time.service';


@Injectable({
    providedIn: 'root'
})
export class DaoCheckinService {
    private refLoadCheckinGlobalOrderAsc = null
    private refLoadCheckinGlobalOrderDesc = null
    private refLoadCheckinGlobalOrderPresent = null
    private refLoadCheckinGlobalOrderAway = null

    private refLoadCheckinGroupVisionOrderAsc = []
    private refLoadCheckinGroupVisionOrderDesc = []
    private refLoadCheckinGroupVisionOrderPresent = []
    private refLoadCheckinGroupVisionOrderAway = []


    private refGetCheckin = null
    private refLoadCheckinGlobalAllAttendees = null
    private refLoadCheckinGroupVisionAllAttendees = []
    private refGetModule = null

    constructor(
        private firestore: Firestore,
        private dt: DateTimeService,
        private Slogout: LogoutService
    ) { }

    /**
    * get module. 
    * @param moduleId 
    * @returns onResolve
    */

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        this.refGetModule = docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    /**
    * close getModule() function reference 
    */
    closeRefGetModule() {
        if (this.refGetModule)
            this.refGetModule.unsubscribe()
    }

    getCheckins(moduleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/checkins`);
        const refQ = query(ref, orderBy('order', 'asc'));
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    getModuleCheckinAndCheckin(checkId: string, eventId: string, onResolve) {
        const ref = collection(this.firestore, `modules`);
        const refQ = query(ref, ...[where('eventId', '==', eventId), where('type', '==', TypeModule.CHECKIN)]);
        collectionData(refQ).pipe(take(1)).subscribe((docs) => {
            docs.forEach(doc => {
                this.getCheckinPromise(checkId, doc.uid)
                    .then((res) => {
                        onResolve(res);
                    })
            });
        });
    }



    getCheckinPromise(checkinId: string, moduleId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}/checkins/${checkinId}`);
        return firstValueFrom(docData(ref));
    }

    /**
      get checkin.
    * @param checkinId 
    * @param checkinModuleId
    * @returns onResolve
    */
    getCheckin(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}`);
        this.refGetCheckin = docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    /**
     * Close closeRefLoadCheckinGlobalOrderAsc() function reference. 
     */
    closeRefGetCheckin() {
        if (this.refGetCheckin)
            this.refGetCheckin.unsubscribe()
    }


    /**
      load all attendees checkin vision global .
    * @param checkinId 
    * @param checkinModuleId
    * @returns onResolve
    */
    loadCheckinGlobalAllAttendees(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        this.refLoadCheckinGlobalAllAttendees = collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /**
    * Close refLoadCheckinGlobalAllAttendees() function reference. 
    */
    closeRefLoadCheckinGlobalAllAttendees() {
        if (this.refLoadCheckinGlobalAllAttendees)
            this.refLoadCheckinGlobalAllAttendees.unsubscribe()
    }

    /**
    * loads check in with global vision and asc order.. 
    * @param checkinId 
    * @param checkinModuleId
    * @returns onResolve
    */

    loadCheckinGlobalOrderAsc(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        const refQ = query(ref, orderBy('queryName', 'asc'));

        this.refLoadCheckinGlobalOrderAsc = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /**
    * Close closeRefLoadCheckinGlobalOrderAsc() function reference. 
    */
    closeRefLoadCheckinGlobalOrderAsc() {
        if (this.refLoadCheckinGlobalOrderAsc)
            this.refLoadCheckinGlobalOrderAsc.unsubscribe()
    }

    /**
    * loads check in with global vision and desc order.. 
    * @param checkinId 
    * @param checkinModuleId
    * @returns onResolve
    */

    loadCheckinGlobalOrderDesc(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        const refQ = query(ref, orderBy('queryName', 'desc'));

        this.refLoadCheckinGlobalOrderDesc = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }


    /**
    * Close refLoadCheckinGlobalOrderDesc() function reference. 
    */
    closeRefLoadCheckinGlobalOrderDesc() {
        if (this.refLoadCheckinGlobalOrderDesc)
            this.refLoadCheckinGlobalOrderDesc.unsubscribe()
    }

    /**
  * loads check in with global vision and present order.. 
  * @param checkinId 
  * @param checkinModuleId
  * @returns onResolve
  */

    loadCheckinGlobalOrderPresent(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        const refQ = query(ref, ...[where('checkinStatus', '==', true), orderBy('queryName', 'asc')]);

        this.refLoadCheckinGlobalOrderAway = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /**
    * Close closeRefLoadCheckinGlobalOrderAway() function reference. 
    */
    closeRefLoadCheckinGlobalOrderAway() {
        if (this.refLoadCheckinGlobalOrderAway)
            this.refLoadCheckinGlobalOrderAway.unsubscribe()
    }

    /**
  * loads check in with global vision and away order.. 
  * @param checkinId 
  * @param checkinModuleId
  * @returns onResolve
  */

    loadCheckinGlobalOrderAway(checkinId: string, checkinModuleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        const refQ = query(ref, ...[where('checkinStatus', '==', false), orderBy('queryName', 'asc')]);

        this.refLoadCheckinGlobalOrderPresent = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /**
    * Close refLoadCheckinGlobalOrderPresent() function reference. 
    */
    closeRefLoadCheckinGlobalOrderPresent() {
        if (this.refLoadCheckinGlobalOrderPresent)
            this.refLoadCheckinGlobalOrderPresent.unsubscribe()
    }

    /**
    * load all attendees checkin vision group .
    * @param checkinId 
    * @param checkinModuleId
    * @param groups
    * @returns onResolve
    */
    loadCheckinGroupVisionAllAttendees(checkinId: string, checkinModuleId: string, groups: Array<any>, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        let refQ;

        let aux = []
        let attendees = []
        let contGroup = 0

        if (groups.length <= 0)
            onResolve([])

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i]
            const group_uid = `groups.${group.uid}`;

            refQ = query(ref, orderBy(group_uid));
            this.refLoadCheckinGroupVisionAllAttendees[i] = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((snapshot) => {
                    contGroup++

                    snapshot.forEach((attendee) => {
                        // Checks if the participant is already in the array.
                        const pos = aux.map(function (e) { return e.uid; }).indexOf(attendee.uid);

                        if (pos === -1) {
                            aux.push(attendee)
                        } else {
                            aux[pos] = attendee
                        }
                    })

                    // participants from all groups were sued.
                    if (contGroup >= groups.length) {
                        attendees = aux.sort(function (a, b) {
                            if (a['queryName'] < b['queryName']) { return -1; }
                            if (a['queryName'] > b['queryName']) { return 1; }
                            return 0;
                        });

                        onResolve(attendees)
                    }
                })
        }
    }


    /**
    * Close loadCheckinGroupVisionAllAttendees() function reference. 
    */
    closeRefLoadCheckinGroupVisionAllAttendees() {
        if (this.refLoadCheckinGroupVisionAllAttendees.length > 0) {
            for (const ref of this.refLoadCheckinGroupVisionAllAttendees) {
                ref.unsubscribe()
            }
        }
    }

    /**
    * loads check-in with group view and asc order.. 
    * @param checkinId 
    * @param checkinModuleId
    * @param groups
    * @returns onResolve
    */
    loadCheckinGroupVisionOrderAsc(checkinId: string, checkinModuleId: string, groups: Array<any>, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        let refQ;

        let aux = []
        let attendees = []
        let contGroup = 0

        if (groups.length <= 0)
            onResolve([])

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            const group_uid = `groups.${group.uid}`;

            refQ = query(ref, orderBy(group_uid));
            this.refLoadCheckinGroupVisionOrderAsc[i] = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((snapshot) => {
                    contGroup++

                    snapshot.forEach((attendee) => {
                        // Checks if the participant is already in the array.
                        const pos = aux.map(function (e) { return e.uid; }).indexOf(attendee.uid);

                        if (pos === -1) {
                            aux.push(attendee)
                        } else {
                            aux[pos] = attendee
                        }
                    })

                    // participants from all groups were sued.
                    if (contGroup >= groups.length) {
                        attendees = aux.sort(function (a, b) {
                            if (a['queryName'] < b['queryName']) { return -1; }
                            if (a['queryName'] > b['queryName']) { return 1; }
                            return 0;
                        });

                        onResolve(attendees)
                    }
                })
        }
    }

    /**
  * Close loadCheckinGroupVisionOrderAsc() function reference. 
  */
    closeRefLoadCheckinGroupVisionOrderAsc() {
        if (this.refLoadCheckinGroupVisionOrderAsc.length > 0) {
            for (const ref of this.refLoadCheckinGroupVisionOrderAsc) {
                ref.unsubscribe()
            }
        }
    }

    /**
   * loads check-in with group view and desc order.. 
   * @param checkinId 
   * @param checkinModuleId
   * @param groups
   * @returns onResolve
   */
    loadCheckinGroupVisionOrderDesc(checkinId: string, checkinModuleId: string, groups: Array<any>, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        let refQ;

        let aux = []
        let attendees = []
        let contGroup = 0

        if (groups.length <= 0)
            onResolve([])

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            const group_uid = `groups.${group.uid}`;

            refQ = query(ref, orderBy(group_uid));
            this.refLoadCheckinGroupVisionOrderDesc[i] = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((snapshot) => {
                    contGroup++

                    snapshot.forEach((attendee) => {
                        // Checks if the participant is already in the array.
                        const pos = aux.map(function (e) { return e.uid; }).indexOf(attendee.uid);

                        if (pos === -1) {
                            aux.push(attendee)
                        } else {
                            aux[pos] = attendee
                        }
                    })

                    // participants from all groups were sued.
                    if (contGroup >= groups.length) {
                        attendees = aux.sort(function (a, b) {
                            if (a['queryName'] < b['queryName']) { return 1; }
                            if (a['queryName'] > b['queryName']) { return -1; }
                            return 0;
                        });

                        onResolve(attendees)
                    }
                })
        }
    }

    /**
    * Close loadCheckinGroupVisionOrderDesc() function reference. 
    */
    closeRefLoadCheckinGroupVisionOrderDesc() {
        if (this.refLoadCheckinGroupVisionOrderDesc.length > 0) {
            for (const ref of this.refLoadCheckinGroupVisionOrderDesc) {
                ref.unsubscribe()
            }
        }
    }


    /**
   * loads check-in with group view and present order.. 
   * @param checkinId 
   * @param checkinModuleId
   * @param groups
   * @returns onResolve
   */
    loadCheckinGroupVisionOrderPresent(checkinId: string, checkinModuleId: string, groups: Array<any>, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        let refQ;

        let aux = []
        let attendees = []
        let contGroup = 0

        if (groups.length <= 0)
            onResolve([])

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i]
            const group_uid = `groups.${group.uid}`

            refQ = query(ref, orderBy(group_uid));
            this.refLoadCheckinGroupVisionOrderPresent[i] = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((snapshot) => {
                    contGroup++

                    snapshot.forEach((attendee) => {
                        // Checks if the participant is already in the array.
                        const pos = aux.map(function (e) { return e.uid; }).indexOf(attendee.uid);

                        if (pos === -1 && attendee.checkinStatus) {
                            aux.push(attendee)
                        } else if (pos > -1 && attendee.checkinStatus) {
                            aux[pos] = attendee
                        } else if (pos > -1 && !attendee.checkinStatus) {
                            aux.splice(pos, 1);
                        }
                    })

                    // participants from all groups were sued.
                    if (contGroup >= groups.length) {
                        attendees = aux.sort(function (a, b) {
                            if (a['queryName'] < b['queryName']) { return -1; }
                            if (a['queryName'] > b['queryName']) { return 1; }
                            return 0;
                        });

                        onResolve(attendees)
                    }
                })
        }
    }
    /**
    * Close closeRefLoadCheckinGroupVisionOrderPresent() function reference. 
    */
    closeRefLoadCheckinGroupVisionOrderPresent() {
        if (this.refLoadCheckinGroupVisionOrderPresent.length > 0) {
            for (const ref of this.refLoadCheckinGroupVisionOrderPresent) {
                ref.unsubscribe()
            }
        }
    }


    /**
     * loads check-in with group view and away order.. 
     * @param checkinId 
     * @param checkinModuleId
     * @param groups
     * @returns onResolve
     */
    loadCheckinGroupVisionOrderAway(checkinId: string, checkinModuleId: string, groups: Array<any>, onResolve) {
        const ref = collection(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees`);
        let refQ;

        let aux = []
        let attendees = []
        let contGroup = 0

        if (groups.length <= 0)
            onResolve([])

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i]
            const group_uid = `groups.${group.uid}`

            refQ = query(ref, orderBy(group_uid));
            this.refLoadCheckinGroupVisionOrderAway[i] = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((snapshot) => {
                    contGroup++

                    snapshot.forEach((attendee) => {
                        // Checks if the participant is already in the array.
                        const pos = aux.map(function (e) { return e.uid; }).indexOf(attendee.uid);

                        if (pos === -1 && !attendee.checkinStatus) {
                            aux.push(attendee)
                        } else if (pos > -1 && !attendee.checkinStatus) {
                            aux[pos] = attendee
                        } else if (pos > -1 && attendee.checkinStatus) {
                            aux.splice(pos, 1);
                        }
                    })

                    // participants from all groups were sued.
                    if (contGroup >= groups.length) {
                        attendees = aux.sort(function (a, b) {
                            if (a['queryName'] < b['queryName']) { return -1; }
                            if (a['queryName'] > b['queryName']) { return 1; }
                            return 0;
                        });

                        onResolve(attendees)
                    }
                })
        }
    }
    /**
    * Close loadCheckinGroupVisionOrderAway() function reference. 
    */
    closeRefLoadCheckinGroupVisionOrderAway() {
        if (this.refLoadCheckinGroupVisionOrderAway.length > 0) {
            for (const ref of this.refLoadCheckinGroupVisionOrderAway) {
                ref.unsubscribe()
            }
        }
    }

    /**
     * Find Attendee Exist In Event. 
     */

    findAttendeeExistInEvent(eventId: string, uid: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/attendees`);
        const refQ = query(ref, where('uid', '==', uid));

        firstValueFrom(collectionData(refQ))
            .then((values) => {
                if (values.length >= 1) {
                    values.forEach(element => {
                        onResolve({
                            status: true,
                            user: element
                        })
                    });
                } else {
                    onResolve({
                        status: false,
                        user: null
                    });
                }
            })
            .catch((e) => { console.log(e) });
    }

    /**
     * Find Attendee Exist In Event with "identifier". 
     */
    findAttendeeExistInEventWithIdentifier(eventId: string, identifier: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/attendees`);
        const refQ = query(ref, where('identifier', '==', identifier));

        firstValueFrom(collectionData(refQ)).then((values) => {
            if (values.length > 0) {
                values.forEach((element) => {
                    onResolve({ status: true, user: element });
                });
            } else {
                onResolve({ status: false, user: null });
            }
        }).catch((e) => { console.log(e); });
    }


    /**
    * updates the participant's status at check in.
    * @param status
    * @param checkinId  
    * @param checkinModuleId
    * @param attendeeId
    * @returns onResolve
    */
    changeAttendeeStatus(status: boolean, checkinId: string, checkinModuleId: string, attendeeId: string, moduleAttendeeId: string, eventId: string, onResolve) {
        const batch = writeBatch(this.firestore);

        const ref1 = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        const ref2 = doc(this.firestore, `modules/${moduleAttendeeId}/attendees/${attendeeId}`)
        const ref3 = doc(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees/${attendeeId}`);

        batch.update(ref1, { checkinStatus: status });
        batch.update(ref2, { checkinStatus: status });
        batch.update(
            ref3,
            {
                checkinStatus: status,
                checkinMadeAt: (status) ? this.dt.dbTime() : null
            }
        );

        batch.commit().then(() => {
            onResolve(true);
        }).catch((error) => {
            console.error(error);
            onResolve(false);
        })
    }

    /**
     * Validate a checkin for an attendee
     * @param status 
     * @param checkinId 
     * @param checkinModule 
     * @param attendeeId 
     * @param moduleAttendeeId 
     * @param eventId 
     * @param checkins 
     */
    validateCheckinForAttendee(status: boolean, checkinId: string, checkinModuleId: string, attendeeId: string, moduleAttendeeId: string, eventId: string, checkins: string[], onResolve) {
        const batch = writeBatch(this.firestore);

        const ref1 = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        const ref2 = doc(this.firestore, `modules/${moduleAttendeeId}/attendees/${attendeeId}`);
        const ref3 = doc(this.firestore, `modules/${checkinModuleId}/checkins/${checkinId}/attendees/${attendeeId}`);

        batch.update(ref1, { checkinStatus: status, checkins: checkins });
        batch.update(ref2, { checkinStatus: status, checkins: checkins });
        batch.update(
            ref3,
            {
                checkinStatus: status,
                checkins: checkins,
                checkinMadeAt: (status) ? this.dt.dbTime() : null,
            }
        );

        batch.commit().then(() => {
            onResolve(true)
        }).catch((error) => {
            onResolve(false)
        })
    }

    changeOrderAttendees(moduleId, checkinId, typeOrder, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/checkins/${checkinId}`);
        updateDoc(ref, { typeOrder: typeOrder })
            .then(() => { onResolve(true); })
            .catch((e) => { onResolve(false); })
    }
}
