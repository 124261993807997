import { Component, OnInit } from '@angular/core';
import { DaoEventsService } from '../providers/db/dao-events.service';
import { MenuController, NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services';
import { CeuEvent } from '../models/ceu-event';
import { LuxonService } from '../providers/luxon/luxon.service';
import { UserDataService } from '../shared/services/user-data/user-data.service';
import { EventDataService } from '../shared/services/eventData/event-data.service';

@Component({
    selector: 'app-public-events',
    templateUrl: './public-events.page.html',
    styleUrls: ['./public-events.page.scss'],
})
export class PublicEventsPage implements OnInit {
    events;
    searchOpen = false;
    terms;
    loader: boolean = true;

    constructor(
        private daoEvents: DaoEventsService,
        private luxon: LuxonService,
        private router: Router,
        private menuCtrl: MenuController,
        public global: GlobalService,
        private eventsA: Events,
        private navCtrl: NavController,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(false);

        this.eventsA.subscribe('reloadContainerEvents', () => {
            this.loadEvents();
        });
    }

    ngOnInit() {
        this.loader = true;
        this.loadEvents();
    }

    loadEvents() {
        this.daoEvents.getPublicEvents((events: Array<CeuEvent>) => {
            // makes the treatment of the startDate of the event.
            for (const event of events) {
                if (typeof event.startDate == 'number') {
                    const sDate = this.luxon.convertTimestampToDate(event.startDate)
                    event.startDate = this.luxon.convertDateToFormatDDMM(sDate)
                }
                if (event.banner == '' || event.banner == null || event.banner == undefined) {
                    event.banner = {
                        uid: '',
                        url: ''
                    }
                }
            }

            this.events = events;
            this.loader = false;
        });
    }

    openEvent(event) {
        this.loader = true;
        this.menuCtrl.enable(true);
        localStorage.setItem('eventId', event.uid);
        this.global.previousPage = 'container';

        this.SEventData.eventData = event;
        this.SEventData.eventId = event.uid;
        this.SEventData.getEventDataListener(event.uid).subscribe();

        this.SUserData.getUserDataSnapshot(event.uid).then(() => {
            // this.router.navigate([`${event.homePage}`])
            this.navCtrl.navigateRoot([`${event.homePage}`])
                .then((_) => {
                    this.eventsA.publish('updateScreen');
                });
        });
    }

}
