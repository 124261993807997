import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { IDate } from 'src/app/shared/interfaces/date.interface';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    constructor() { }

    /* cria data com a fuso horário do timezane 
    
    return object DateTime
    */
    createDate(year: string, month: string, day: string, hour: string, minute: string, seconds: string, timezone: string) {
        const aux = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds;
        return DateTime.fromISO(aux, { zone: timezone });
    }

    // converte timestamp para DateTime
    convertTimestampToDate(timestamp: number, timezone: string) {
        const aux = DateTime.fromMillis(timestamp * 1000, { zone: timezone });
        return aux;
    }

    // converte timestamp para DateTime
    convertTimestampToDateWithoutZone(timestamp: number) {
        const aux = DateTime.fromMillis(timestamp * 1000);
        return aux;
    }

    // converte um  DateTime para uma string do formato dd/mm/aaaa
    convertDateToStringIsNotUSA(date: DateTime): any {
        return date.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric' })
    }

    // converte um DateTime para uuma string com em português do Brasil
    convertDateToStringTitlePortBrazil(date: DateTime) {
        //var f = { year: "numeric", month: "long", day: "numeric" };
        return date.setLocale('pt-BR').toLocaleString();
    }


    // converte um  DateTime para uma string do formato dd/mm/aaaa
    convertDateToStringIsUSA(date: DateTime): any {
        const year = date.year;
        const month = date.month;
        const day = date.day;

        return month + '/' + day + '/' + year;
    }

    // converte um DateTime em uma string no formato yyyy-mm-aa
    convertDateToStringYearMonthDay(date: DateTime) {
        return date.toISODate();
    }

    // retorna a hora e o minuto da data
    dateTime(date: DateTime) {
        return date.toLocaleString({ hour: 'numeric', minute: 'numeric' })
    }

    dateTimeUSA(date: DateTime): any {
        let aux = date.setLocale('en-US')
        return aux.toLocaleString(DateTime.TIME_SIMPLE)
    }

    /**
     * Create a DateTime object from IDate object
     * @param date 
     */
    createDateTime(date: IDate): DateTime {
        if (date.zone == 'local') {
            return (DateTime.local(date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond));
        } else {
            return (DateTime.fromObject(date));
        }
    }

    /**
     * Return formated date base on the format passed
     * @param date 
     * @param format 
     */
    formatDate(date: DateTime, format: string, locale: string) {
        return (date.setLocale(locale).toFormat(format));
    }
}
