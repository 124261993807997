import { Injectable } from '@angular/core';
import { TypeUser } from 'src/app/models/type-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Observable, firstValueFrom } from 'rxjs';
import { Firestore, doc, docData, updateDoc } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoGeralService {
    public headers;
    public requestOptions;
    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        public http: HttpClient,
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    loadUser(userId: string, typeUser: number, eventId: string): Observable<any> {
        let ref;

        switch (typeUser) {
            case TypeUser.SPEAKER:
                ref = doc(this.firestore, `events/${eventId}/speakers/${userId}`);
                break;
            case TypeUser.ATTENDEE:
                ref = doc(this.firestore, `events/${eventId}/attendees/${userId}`);
                break;
            default:
                ref = doc(this.firestore, `users/${userId}`);
                break;
        }

        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    userModelData(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: null,
            attendeeModules: null,
            firstAccess: null,
            uid: "",
            edited_profile: false
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        if (user.events != undefined) {
            userFormat.events = user.events;
        }

        userFormat.attendeeModules = user.attendeeModules

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }

    userModelDataSpeaker(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: null,
            speakerModules: null,
            firstAccess: null,
            uid: "",
            edited_profile: false
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        if (user.events != undefined) {
            userFormat.events = user.events;
        }

        userFormat.speakerModules = user.speakerModules

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }

    getTermsOfUse(onResolve) {
        const ref = doc(this.firestore, `general-legal-terms/terms`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc['termOfUse']);
        })
    }

    getPrivacy(onResolve) {
        const ref = doc(this.firestore, `general-legal-terms/terms`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc['privacy']);
        });
    }

    /**
     * update user information in firestore database
     * @param obj
     * @param userId 
     * @param eventId 
     * @param typeUser 
     */
    updateUserGeneral(obj, userId: string, eventId: string, typeUser: number): Promise<void> {
        let ref;

        if (typeUser == TypeUser.SPEAKER || typeUser == TypeUser.ATTENDEE) {
            const auxTypeUser: string = (typeUser == TypeUser.SPEAKER) ? "speaker" : "attendees";
            ref = doc(this.firestore, `events/${eventId}/${auxTypeUser}/${userId}`);
        }
        else {
            ref = doc(this.firestore, `users/${userId}`);
        }

        return updateDoc(ref, obj);
    }

    getEvent(eventId: string) {
        const ref = doc(this.firestore, `events/${eventId}`);
        return firstValueFrom(docData(ref));
    }

}
