export enum TypeModule {
    EXTERNAL_LINK = 35,
    GENERIC = 34, // generic modules
    MANAGER_MODULES = 33,
    LEGAL = 32,
    ROOM_PAIR = 31, // module for attendee pair hotel
    INFOBOOTH = 30,
    LOCATION = 29,
    WIDGETS = 28,
    ASK_QUESTION = 27,
    SESSION_FEEDBACK = 26,
    NETWORKING = 25, // module for networking (chat, groups, channels, etc)
    SELF_CHECKIN = 24, // auto checkin for attendees
    MANAGER_GROUP = 23, // manager group
    GAMING = 22, // gaming module
    QUIZ = 21, // quiz interactivity module
    TRAINING = 20, // training interactivity module
    SUBSCRIPTION = 19, // subscription interactivity module
    WORDCLOUD = 18, // word cloud interactivity module
    SURVEY = 17, // survey list interactivity module
    RANKING = 16, // pagina do ranking de pontos dos usuarios
    PAGE_CUSTOM_PAGE = 15, //página do infobooth
    CUSTOM_PAGE = 14,
    PERSONALSCHEDULE = 13, //agenda pessoal
    EVENT = 12,
    PROFILE = 11,
    HOME = 10,
    NEWS_FEED = 9, // FEED DE NOTÍCIAS
    CHECKIN = 8,
    NOTIFY = 7,
    MAPS = 6,
    GALLERY = 5,
    INTERACTIVITY = 4, //interatividade
    DOCUMENT = 3,
    ATTENDEE = 2,
    SPEAKER = 1,
    SCHEDULE = 0 //cronograma
}