import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoGalleryService } from 'src/app/providers/db/dao-gallery.service';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { 
    Firestore, 
    doc,
    docData,
    collection,
    query,
    orderBy,
    collectionData, 
} from '@angular/fire/firestore';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { LightboxImgComponent } from 'src/app/components/lightbox-img/lightbox-img.component';
import { FileService } from 'src/app/providers/file/file.service';
import { Subscription } from 'rxjs';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';


@Component({
    selector: 'app-gallery-folder',
    templateUrl: './gallery-folder.page.html',
    styleUrls: ['./gallery-folder.page.scss'],
})
export class GalleryFolderPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    public module = null

    @Input() eventId: string = null;
    @Input() moduleId: string = null;
    @Input() folderId: string = null;

    images: Array<any> = [];
    loader: boolean = true;
    grid: boolean = true;
    allowGrid: boolean = false;
    allowDownload: boolean = false;
    allowDownloadAll = false;
    downloadingAll = false;
    backBtn: boolean = true;
    allowButtonsHeader: boolean = false;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    doubleBack: boolean = false;
    width = window.innerWidth;
    isMobile: boolean = false;

    language: string;

    constructor(
        private route: ActivatedRoute,
        private daoGallery: DaoGalleryService,
        public global: GlobalService,
        private firestore: Firestore,
        private events: Events,
        private menuCtrl: MenuController,
        private router: Router,
        private SAnalytics: AnalyticsService,
        private modalCtrl: ModalController,
        private fileService: FileService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);


        this.eventId = this.route.snapshot.params['eventId'];
        // localStorage.setItem('eventId', this.eventId);
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.folderId = this.route.snapshot.params['folderId'];

        this.loadColors();
        this.language = this.SEventData.getLanguage();
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });

        // Track analytics
        this.SAnalytics.goToImagesFolder(this.eventId, this.moduleId, this.folderId);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
        this.getImages();
    }

    ionViewDidEnter() {
        const ref = doc(this.firestore, `modules/${this.moduleId}`);
        const subscription = docData(ref).subscribe((data) => {
            this.module = data
        });

        this.subscriptions.push(subscription);
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getImages() {
        this.daoGallery.getFolder(this.moduleId, this.folderId, (folder) => {
            if (folder.grid !== null && folder.grid !== undefined) {
                this.grid = folder.grid;
            }
            if (folder.allowGrid !== null && folder.allowGrid !== undefined) {
                this.allowGrid = folder.allowGrid;
            }



            if (folder.allowDownloadAll !== null && folder.allowDownloadAll !== undefined) {
                this.allowDownloadAll = folder.allowDownloadAll;
            }

            if (folder.allowDownload !== null && folder.allowDownload !== undefined) {
                this.allowDownload = folder.allowDownload;
            }

            const ref = collection(this.firestore, `modules/${this.moduleId}/folders/${this.folderId}/images`);
            let refQ;

            switch (folder.orderGallery) {
                case 'asc':
                    refQ = query(ref, orderBy('name', 'asc'));
                    break;
                case 'desc':
                    refQ = query(ref, orderBy('name', 'desc'));
                    break;
                case 'oldest':
                    refQ = query(ref, orderBy('createdAt', 'asc'));
                    break;
                case 'recent':
                    refQ = query(ref, orderBy('createdAt', 'desc'));
                    break;
                default:
                    refQ = ref;
                    break;
            }

            const subscription = collectionData(refQ).subscribe((data: any) => {
                this.images = [];
                this.images = data;
                this.loader = false;
            });
            this.subscriptions.push(subscription);
        })
    }

    changeGrid() {
        this.grid = !this.grid;
    }

    async downloadAll() {
        if (this.downloadingAll || this.images.length == 0) {
            return;
        }

        this.downloadingAll = true;

        for (let image of this.images) {
            if (!image.url) { continue; }

            const extension: string = (image.type || 'jpg');

            this.fileService.createDir("EzbizEventImg").finally(() => {
                // if the directory already exits the promise is rejected, either way the
                // img is downloaded
                this.fileService.downloadFile(image.name, extension, image.url, "EzbizEventImg");
                this.downloadingAll = false;
            })
        }
    }

    // zoom image
    openImage(image) {
        // Analytics access creation
        this.SAnalytics.imageAccess(this.eventId, image);

        this.modalCtrl.create({
            component: LightboxImgComponent,
            componentProps: {
                img: image.url,
                type: image.type,
                name: image.name,
                allowDownload: this.allowDownload
            }
        }).then(modal => {
            modal.present();
        });
    }


    getRows(len, parentDiv) {
        const parentWidth = Math.ceil((7 * parentDiv.clientWidth) / 10);

        if (this.grid) {
            const numberColumn = this.width < 768 ? 3 : 4;
            const height = this.width < 768 ? (this.width / 3) : Math.ceil(parentDiv.clientWidth / 4);
            const nbrLignes = Math.ceil(len / numberColumn);
            return `repeat(${nbrLignes},${height}px)`;
        } else {
            return `repeat(${len},auto)`;
        }
    }

    getColumns(parentDiv) {
        const parentWidth = Math.ceil((7 * parentDiv.clientWidth) / 10);
        // const backgroudn = document.getElementsByClassName('background')[0].width
        if (this.grid) {
            return this.width < 768 ? 'repeat(3, auto)' : `repeat(4, ${Math.ceil((parentDiv.clientWidth - 9) / 4)}px)`;
        } else {
            return this.width < 768 ? this.width + 'px' : parentDiv.clientWidth + 'px'
        }
    }

    getImageHeight(parentDiv) {
        const parentWidth = Math.ceil((7 * parentDiv.clientWidth) / 10);
        if (this.grid) {
            return this.width < 768 ? Math.ceil(this.width / 3) + 'px' : Math.ceil((parentDiv.clientWidth - 9) / 4) + 'px'
        } else {
            return this.width < 768 ? '38vh' : '65vh';
        }
    }

    backgroundSize() {
        return this.grid ? 'cover' : 'contain'
    }

}
