import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { EventDataService } from '../shared/services/eventData/event-data.service';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.page.html',
    styleUrls: ['./splash.page.scss'],
})
export class SplashPage implements OnInit {
    public event: any;

    constructor(
        private route: ActivatedRoute,
        private navCtrl: NavController,
        private SEventData: EventDataService
    ) {
        const shortcode = this.route.snapshot.params['shortcode'];
        this.SEventData.getEventByShortcode(shortcode).then((eventData) => {
            this.event = eventData;
            if (eventData.visibility) {
                localStorage.setItem('eventVisible', 'public');
                // this.navCtrl.navigateRoot([this.event.homePage]);
                this.navCtrl.navigateRoot(`public_register/${eventData.uid}`)
            } else {
                this.navCtrl.navigateRoot([`login/${eventData.uid}`]);
                // localStorage.setItem('eventVisible', 'private');
            }
        }).catch((error) => {
            console.error(error);
            this.navCtrl.navigateRoot(['/']);
        })
    }

    ngOnInit() { }

}
