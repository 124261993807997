import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { MarketService } from "src/app/providers/market/market.service";
import {
	AlertController,
	LoadingController,
	MenuController,
	ModalController,
	NavController,
	Platform
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { LightboxImgComponent } from "src/app/components/lightbox-img/lightbox-img.component";
import { TypeTracking } from "src/app/enums/type-tracking";
import { CeuAttendee } from "src/app/models/ceu-attendee";
import { DaoAppointmentsService } from "src/app/providers/db/dao-appointments.service";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { DaoEventsService } from "src/app/providers/db/dao-events.service";
import { DaoModulesService } from "src/app/providers/db/dao-modules.service";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import { AnalyticsService, GlobalService, UtilityService, WherebyService } from "src/app/shared/services";
import { GroupDiscussionsService } from "src/app/shared/services/group-discussions/group-discussions.service";
import { DocumentService } from "src/app/providers/document/document.service";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-profile",
	templateUrl: "./profile.page.html",
	styleUrls: ["./profile.page.scss"],
	providers: [DaoAttendeesService]
})
export class ProfilePage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	public typeUser: number;
	public eventId: string = null;
	public moduleId: string = null;
	public userId: string = null;
	public typeUserProfile: number = null;
	public loader: boolean = true;
	public company: string = null;
	public title: string = null;
	public userProfileId: string = null;
	public name: string = null;
	public site: string = null;
	public facebook: string = null;
	public instagram: string = null;
	public linkedin: string = null;
	public twitter: string = null;
	public phone: string = null;
	public connected: boolean = null;
	public description: string = null;
	public photoUrl: string = null;
	public email: string = null;
	public type: number = null;
	public documents: Array<Document> = [];
	public allowChat: boolean = false;
	userModuleId: string = null;
	public listCustomFields: Array<any> = [];
	public listCustomFieldOptions: Array<any> = [];
	public fieldsCustom: any;
	public listSessions = [];
	public listAttendees: CeuAttendee[] = [];
	public moduleDocVisibility: string;
	public docVisibility: string;
	public userDocVisibility: string;
	doc;
	loaderToast;
	backBtn: boolean = true;

	eventColors: EventColors = new EventColors();
	isDegrade: boolean = false;

	theUser;
	menuBadge: number = 0;
	firstAccess: boolean = true;
	// showCardCustomField: boolean = false;
	moduleName: string = "";
	allowButtonsHeader: boolean = false;
	language: string;
	module;
	event: any;
	showMore = false;
	isMobile: boolean = false;
	allSessionDates: any[] = [];
	canRequestAppointment = false;
	emailVisibilty: boolean = null;
	btnVideoCallVisibilty: boolean = null;

	get user() {
		return {
			photoUrl: this.photoUrl,
			name: this.name,
			title: this.title,
			company: this.company,
			twitter: this.twitter,
			linkedin: this.linkedin,
			facebook: this.facebook,
			instagram: this.instagram,
			language: this.language,
			website: this.site,
			connected: this.connected
		};
	}

	get colors() {
		return { // TODO: check
			text_content_color: this.eventColors.text_content_color,
			title_color: this.eventColors.title_color,
			menu_color: this.eventColors.menu_color,
			menu_text_color2: this.eventColors.menu_text_color2
		};
	}

	constructor(
		private route: ActivatedRoute,
		private dbAttendees: DaoAttendeesService,
		private dbSpeakers: DaoSpeakersService,
		private navCtrl: NavController,
		public global: GlobalService,
		private router: Router,
		private events: Events,
		private menuCtrl: MenuController,
		private gdService: GroupDiscussionsService,
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController,
		private market: MarketService,
		private translateService: TranslateService,
		private SAnalytics: AnalyticsService,
		private platform: Platform,
		private dbEvents: DaoEventsService,
		private dbModules: DaoModulesService,
		private modalCtrl: ModalController,
		private SWhereby: WherebyService,
		private SUtility: UtilityService,
		private location: Location,
		private daoAppointments: DaoAppointmentsService,
        private docService: DocumentService,
        private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
	) {
		// this.menuCtrl.enable(true);

		this.events.subscribe("menuBadge", () => {
			this.menuBadge = this.global.notificationBadge;
		});
        
        const params = this.route.snapshot.params;
		this.eventId = params.eventId;
		this.userProfileId = params.userId;
		this.typeUserProfile = params.type;
		this.moduleId = params.moduleId;

		this.loadColors();
        this.language = this.SEventData.getLanguage();
		this.menuBadge = this.global.notificationBadge;

		// Track access to profile
		this.SAnalytics.accessToProfile(
			this.eventId,
			this.userProfileId,
			this.typeUserProfile,
			TypeTracking.ACCESS_TO_PROFILE
		);

		this.route.queryParams.subscribe((params) => {
			if (this.router.getCurrentNavigation().extras.state) {
				this.theUser = this.router.getCurrentNavigation().extras.state.user;
				// this.emailVisibilty = this.router.getCurrentNavigation().extras.state.emailVisibilty;
				// this.btnVideoCallVisibilty = this.router.getCurrentNavigation().extras.state.btnVideoCallVisibilty;
			}
		});
		this.getEvent();
		this.getModule();
	}

	getEvent() {
		this.subscriptions.push(
			this.dbEvents.getEvent(this.eventId).subscribe((event) => {
				this.event = event;
			})
		);
	}

	getModule() {
		this.subscriptions.push(
			this.dbModules.getModule(this.moduleId).subscribe((module) => {
				this.module = module;
				this.moduleName = module.name;
				this.emailVisibilty = module.emailVisibilty !== undefined ? module.emailVisibilty : false;
				this.btnVideoCallVisibilty = module.showBtnVisio !== undefined ? module.showBtnVisio : false;
				this.docVisibility = module["docVisibility"] ? module["docVisibility"] : "Profil";
				console.log("Module doc visibility : ", this.docVisibility);
			})
		);
	}

	btn_color1: string = null;
	btn_color2: string = null;

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            if (this.eventColors.menu_color.includes("linear")) {
                this.isDegrade = true;
                let colors = this.separeGradientColors(this.eventColors.menu_color);
                this.btn_color1 = colors[0];
                this.btn_color2 = colors[1];
            } else {
                this.btn_color1 = this.btn_color2 = this.eventColors.menu_color;
            }
        });
        this.subscriptions.push(subscription);
    }

	separeGradientColors(str) {
		let hex = [];
		let index = str.indexOf("#");

		while (index > -1) {
			hex.push(this.getHexColors(str));
			str = str.replace("#", "");
			index = str.indexOf("#");
		}

		return hex;
	}

	getHexColors(str) {
		let position = str.indexOf("#");
		let result = str.substring(position, position + 7);

		return result;
	}

	checkType(type: string): string {
		switch (type) {
			case "doc":
			case "docx":
			case "docm":
			case "DOC":
			case "DOCX":
			case "DOCM":
				return "Word";

			case "xls":
			case "xlt":
			case "xls":
			case "xml":
			case "xlsx":
			case "xlsm":
			case "xlsb":
			case "xltx":
			case "xltm":
			case "XLS":
			case "XLT":
			case "XLS":
			case "XML":
			case "XLSX":
			case "XLSM":
			case "XLSB":
			case "XLTX":
			case "XLTM":
				return "Excel";

			case "pdf":
			case "PDF":
				return "PDF";

			case "png":
			case "PNG":
				return "PNG";

			case "jpg":
			case "JPG":
				return "JPG";
			case "jpeg":
			case "JPEG":
				return "JPEG";

			case "ppt":
			case "pptx":
			case "thmx":
			case "ppsx":
			case "pps":
			case "ppsm":
			case "PPT":
			case "PPTX":
			case "THMX":
			case "PPSX":
			case "PPS":
			case "PPSM":
				return "PowerPoint";

			case "mp4":
			case "wmv":
			case "3gp":
			case "avi":
			case "mp3":
			case "wav":
			case "MP4":
			case "WMV":
			case "3GP":
			case "AVI":
			case "MP3":
			case "WAV":
				return "Media";

			default:
				return "Generic File ";
		}
	}

	async ngOnInit() {
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
        if (userData) {
            this.userId = userData.uid;
		    this.typeUser = userData.type;
        } else {
            this.userId = this.SUserData.userId;
		    this.typeUser = 5;  // less privileged user level (i.e. attendee)
        }

		if (this.platform.is('ios') || this.platform.is('android')) {
			this.isMobile = true;
		}

		if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}

		if (this.typeUserProfile == 5) {
			this.loadAttendee();
		} else if (this.typeUserProfile == 4) {
			this.loadSpeaker();
		} else {
			// invalid view profile
			this.location.back();
		}

		// if (this.router.url == this.SEventData.eventData.homePage) {
		// 	this.allowButtonsHeader = true;
		// }
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Reload date for sessions
	 */
	// reloadDateForSessions() {
	// 	if (this.listSessions && this.listSessions.length > 0) {
	// 		this.listSessions.forEach((session) => {
	// 			if (typeof session.date === "number") {
	// 				if (this.SEventData.getLanguage() && this.SEventData.getLanguage() == "fr_FR") {
	// 					session.dateFormated = moment.unix(session.date).format("DD/MM/YYYY");
	// 				} else {
	// 					session.dateFormated = moment.unix(session.date).format("MM/DD/YYYY");
	// 				}
	// 			}
	// 		});
	// 	}
	// }

	// load attendee
	loadAttendee() {
		this.subscriptions.push(
			this.dbAttendees.getAttendeeByEvent(this.eventId, this.userProfileId).subscribe(async (attendee) => {
				this.type = attendee["type"];
				this.name = attendee["name"];
				this.email = attendee["email"];
				this.company = attendee["company"];
				this.title = attendee["title"];
				this.description = attendee["description"];
				this.photoUrl = attendee["photoUrl"];
				this.phone = attendee["phone"];
				this.site = attendee["website"];
				this.facebook = attendee["facebook"];
				this.instagram = attendee["instagram"];
				this.linkedin = attendee["linkedin"];
				this.twitter = attendee["twitter"];
				this.userModuleId = attendee["moduleId"];
				this.firstAccess = attendee["last_access"] ? true : false;
				this.userDocVisibility = attendee["userDocVisibility"] ? attendee["userDocVisibility"] : "Profil";

				console.log("Attendee doc visibility : ", this.userDocVisibility);

                this.documents = [];
				for (let uid in attendee["documents"]) {
					this.documents.push(attendee["documents"][uid]);
				}
				console.log("Documents : ", this.documents);
				console.log("User type : ", this.typeUser);
				this.getChatStatus();
				this.subscriptions.push(
					this.daoAppointments.canRequestAppointment(attendee).subscribe((canRequestAppointment) => {
						this.canRequestAppointment = canRequestAppointment;
						this.loader = false;
					})
				);
			})
		);
		// this.getFieldsCustomAttendee();
        this.getAttendeeCustomFields();
	}

	getChatStatus() {
		this.dbAttendees.getChatStatus(this.userModuleId, (data) => {
			if (this.userId == this.userProfileId) {
				this.allowChat = false;
			} else if (this.userId == null) {
				this.allowChat = false;
			} else {
				this.allowChat = data;
			}
		});
	}

	// getFieldsCustomAttendee() {
	// 	this.dbAttendees.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.fieldsCustom = fields;
	// 		this.getCustomFieldsAttendee();
	// 	});
	// }

    /**
     * get all attendee custom fields that are answered and can be seen by the 
     * current user. The retrieved data is stored in the 'listCustomFields'
     * property
     */
	async getAttendeeCustomFields() {
        this.listCustomFields = [];
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
        this.dbAttendees.getCustomFields(this.eventId, this.userProfileId)
            .then((customFields) => {
                this.listCustomFields = customFields.filter((cf) => {
                    return (    // filters by visibility
                        cf.answered &&
                        (
                            (userData && userData.type < 2) || // is admin
                            cf.exibicao == 1 || // public visibility
                            (
                                cf.exibicao == 2 && 
                                this.userProfileId == this.SUserData.userId
                            )
                        )
                    );
                });

                // for (let custom of customFields) {
                //     custom.activeView = false;
                // }
                // let displayCardCustom = false;
                // for (let aux of customFields) {
                //     let position = this.fieldsCustom[aux.uid].order;
                //     this.listCustomFields[position] = aux;
                //     if (
                //         this.fieldsCustom[aux.uid].hide_field === false &&
                //         aux.exibicao == "1" &&
                //         displayCardCustom === false
                //     ) {
                //         if (aux.type == "select") {
                //             if (
                //                 aux.value[this.language] !== null &&
                //                 aux.value[this.language] !== undefined &&
                //                 aux.value[this.language] !== "" &&
                //                 (aux.exibicao == "1" || (aux.exibicao == "2" && this.userProfileId === this.userId))
                //             ) {
                //                 displayCardCustom = true;
                //                 this.showCardCustomField = true;
                //                 console.log("if");
                //             }
                //         } else {
                //             if (
                //                 aux.textValue[this.language] !== null &&
                //                 aux.textValue[this.language] !== undefined &&
                //                 aux.textValue[this.language] !== "" &&
                //                 (aux.exibicao == "1" || (aux.exibicao == "2" && this.userProfileId === this.userId))
                //             ) {
                //                 displayCardCustom = true;
                //                 this.showCardCustomField = true;
                //             }
                //         }
                //     }
                // }
    
                // for (let i = 0; i < this.listCustomFields.length; i++) {
                //     let custom = this.listCustomFields[i];
    
                //     if (custom.type == "select") {
                //         this.dbAttendees.getCustomFieldOptions(
                //             this.moduleId,
                //             this.userProfileId,
                //             custom.uid,
                //             (listOptions) => {
                //                 for (let option of listOptions) {
                //                     if (custom.value == option.uid) {
                //                         custom.value = option.answer;
                //                     }
                //                 }
                //             }
                //         );
                //     }
    
                //     //se a exibição for somente para administrador e o tipo de usuário for supergod, god ou cliente
                //     if (
                //         (custom.exibicao == 3 || custom.exibicao == 2) &&
                //         // (this.typeUser == 0 || this.typeUser == 1 || this.typeUser == 2)
                //         (this.typeUser <= 2)
                //     ) {
                //         custom.activeView = true;
                //     }
    
                //     //se a exibição for somente para clientes e o tipo de usuário for attendee
                //     if (
                //         custom.exibicao == 2 &&
                //         !this.fieldsCustom[custom.uid].hide_field &&
                //         this.userId == this.userProfileId
                //     ) {
                //         custom.activeView = true;
                //     }
    
                //     //se o tipo de exibição for para todos
                //     if (custom.exibicao == 1 && !this.fieldsCustom[custom.uid].hide_field) {
                //         custom.activeView = true;
                //     }
                // }
            }).catch((error) => { console.error(error); });
	}

    /**
     * from a custom field of type `s` (select) returns the value of the 
     * selected answer, in the language passed as param
     * @param customField 
     */
    getCFSelectedAnswer(customField, language: string) {
        return customField.answerOptions.find((o) => {
            return o.id == customField.selectedAnswer;
        }).value[language];
    }

	// load speaker
	loadSpeaker() {
		this.subscriptions.push(
			this.dbSpeakers.getSpeakerByEvent(this.eventId, this.userProfileId).subscribe((speaker: any) => {
				this.type = speaker["type"];
				this.name = speaker["name"];
				this.company = speaker["company"];
				this.title = speaker["title"];
				this.description = speaker["description"];
				this.photoUrl = speaker["photoUrl"];
				this.phone = speaker["phone"];
				this.site = speaker["website"];
				this.facebook = speaker["facebook"];
				this.instagram = speaker["instagram"];
				this.linkedin = speaker["linkedin"];
				this.twitter = speaker["twitter"];
				// this.firstAccess = speaker["firstAccess"];
                this.firstAccess = speaker["last_access"] ? true : false;

                this.documents = [];
				for (let uid in speaker["documents"]) {
					this.documents.push(speaker["documents"][uid]);
				}

				this.loader = false;

				/**
				 * Build attendees list
				 */
				this.listAttendees = [];

				if (speaker.attendees) {
					for (const uid in speaker.attendees) {
						this.listAttendees.push(speaker.attendees[uid]);
					}

					// Ordering attendees by name.
					this.listAttendees = this.listAttendees.sort(function (a, b) {
						return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
					});
				}
			})
		);

		// this.getFieldsCustomSpeaker();
		// this.getSessionsOfSpeaker();
	}

	/**
	 * Go to attendee profile
	 * @param attendee
	 */
	profileDetails(attendee) {
		const moduleAttendee = attendee.moduleId;
		const type = attendee.type;
		const uid = attendee.uid;

		let navigationExtras: NavigationExtras = {
			state: {
				user: attendee
			}
		};
		this.router.navigate([`/event/${this.eventId}/profile/${moduleAttendee}/${type}/${uid}`], navigationExtras);
	}

	// getFieldsCustomSpeaker() {
	// 	this.dbSpeakers.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.fieldsCustom = fields;
	// 		this.getCustomFieldsSpeakers();
	// 	});
	// }

	// getCustomFieldsSpeakers() {
	// 	this.dbSpeakers.getCustomFields(this.eventId, this.userProfileId, (customFields) => {
	// 		for (let custom of customFields) {
	// 			custom.activeView = false;
	// 		}
	// 		console.log(customFields);

	// 		let displayCardCustom = false;
	// 		for (let aux of customFields) {
	// 			let position = this.fieldsCustom[aux.uid].order;
	// 			this.listCustomFields[position] = aux;

	// 			if (displayCardCustom == false) {
	// 				if (aux.type == "select") {
	// 					if (
	// 						aux.value[this.language] !== null &&
	// 						aux.value[this.language] !== undefined &&
	// 						aux.value[this.language] !== "" &&
	// 						(aux.exibicao == "1" || (aux.exibicao == "2" && this.userProfileId === this.userId))
	// 					) {
	// 						displayCardCustom = true;
	// 						this.showCardCustomField = true;
	// 					}
	// 				} else {
	// 					if (
	// 						aux.textValue[this.language] !== null &&
	// 						aux.textValue[this.language] !== undefined &&
	// 						aux.textValue[this.language] !== "" &&
	// 						(aux.exibicao == "1" || (aux.exibicao == "2" && this.userProfileId === this.userId))
	// 					) {
	// 						displayCardCustom = true;
	// 						this.showCardCustomField = true;
	// 					}
	// 				}
	// 			}
	// 		}

	// 		for (let i = 0; i < this.listCustomFields.length; i++) {
	// 			let custom = this.listCustomFields[i];

	// 			if (custom.type == "select") {
	// 				this.dbSpeakers.getCustomFieldOptions(this.moduleId, custom.uid, (listOptions) => {
	// 					for (let option of listOptions) {
	// 						if (custom.value == option.uid) {
	// 							custom.value = option.answer;
	// 						}
	// 					}
	// 				});
	// 			}

	// 			//se a exibição for somente para administrador e o tipo de usuário for supergod, god ou cliente
	// 			if (
	// 				(custom.exibicao == 3 || custom.exibicao == 2) &&
	// 				// (this.typeUser == 0 || this.typeUser == 1 || this.typeUser == 2)
    //                 (this.typeUser <= 2)
	// 			) {
	// 				custom.activeView = true;
	// 			}

	// 			//se a exibição for somente para clientes e o tipo de usuário for attendee
	// 			if (custom.exibicao == 2 && this.userId == this.userProfileId) {
	// 				custom.activeView = true;
	// 			}

	// 			//se o tipo de exibição for para todos
	// 			if (custom.exibicao == 1 && !this.fieldsCustom[custom.uid].hide_field) {
	// 				custom.activeView = true;
	// 			}
	// 		}
	// 	});
	// }

	// // lists the speaker's sessions.
	// getSessionsOfSpeaker() {
	// 	this.subscriptions.push(
	// 		this.dbSpeakers.getSessionsOfSpeaker(this.userProfileId, this.eventId).subscribe((sessions: any) => {
	// 			this.listSessions = [];
	// 			for (let session of sessions) {
    //                 const timezone = "event";
    //                 session.startTime = this.dt.getConvertedTime(session.startTime, timezone);
	// 	            session.endTime = (typeof session.endTime == "string" || session.endTime.length > 0) ?
	// 		            this.dt.getConvertedTime(session.endTime, timezone) :
	// 		            "--:--";

	// 				// locations of session
	// 				const locations = [];

	// 				for (const uid in session.locations) {
	// 					locations.push(session.locations[uid]);
	// 				}

	// 				session.locations = locations;

	// 				// tracks of session
	// 				const tracks = [];

	// 				for (const uid in session.tracks) {
	// 					tracks.push(session.tracks[uid]);
	// 				}

	// 				session.tracks = tracks;

	// 				// groups of session
	// 				const groups = [];

	// 				for (const uid in session.groups) {
	// 					groups.push(session.groups[uid]);
	// 				}

	// 				session.groups = groups;
	// 			}
	// 			this.listSessions = sessions;

    //             this.allSessionDates = this.dt.getDiffDates(sessions);
	// 		})
	// 	);
	// }

	// puts the session date in front.
	dateHeader(session, index, sessionsList) {
		if (index == 0) {
			return session.dateFormated;
		} else if (index != 0 && session.date != sessionsList[index - 1].date) {
			return session.dateFormated;
		}
	}

	// redirects to schedule-detail page with uid and session module.
	openSession(session) {
		const sessionId = session.uid;
		const moduleScheduleId = session.moduleId;

		this.router.navigate([`/event/${this.eventId}/schedule-detail/${moduleScheduleId}/${sessionId}`]);
	}

	// zoom profile picture image
	openImage(url: string) {
		// this.photoViewer.show(url, '', { share: false });
		this.modalCtrl
			.create({
				component: LightboxImgComponent,
				componentProps: {
					img: url
				}
			})
			.then((modal) => {
				modal.present();
			});
	}

	/**
     * Open a document
     * @param document
     */
    openDocument(document) {
        this.doc = document;
        this.docService.openDocument(document, this.eventId);
    }

	async presentAlertConfirm() {
		const alert = await this.alertCtrl.create({
			header: this.translateService.instant("global.alerts.not_found_title"),
			message: this.translateService.instant("global.alerts.app_open_doc_not_found_txt"),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.no"),
					role: "cancel",
					cssClass: "secondary",
					handler: (blah) => {}
				},
				{
					text: this.translateService.instant("global.buttons.yes"),
					handler: () => {
						this.openMarketApp();
					}
				}
			]
		});

		await alert.present();
	}

	openMarketApp() {
		let platforms = this.platform.platforms().join();
		if (platforms.includes("android")) {
			let androidPackage = this.getPackageNameAndroid(this.doc.type);
			this.market.open(androidPackage);
		} else if (platforms.includes("ios")) {
			let iosPackage = this.getPackageNameIos(this.doc.type);
			this.market.open(iosPackage);
		}
	}

	getPackageNameAndroid(extn) {
		let ext = extn.toLowerCase();
		let packageNames = {
			// texts
			docx: "com.google.android.apps.docs.editors.docs",
			doc: "com.google.android.apps.docs.editors.docs",
			docm: "com.google.android.apps.docs.editors.docs",
			// pdf
			pdf: "com.adobe.reader",
			// sheets
			xls: "com.google.android.apps.docs.editors.sheets",
			xlsx: "com.google.android.apps.docs.editors.sheets",
			xlsm: "com.google.android.apps.docs.editors.sheets",
			xlt: "com.google.android.apps.docs.editors.sheets",
			xlsb: "com.google.android.apps.docs.editors.sheets",
			xltx: "com.google.android.apps.docs.editors.sheets",
			xltm: "com.google.android.apps.docs.editors.sheets",
			// presentations (ppt)
			ppt: "com.google.android.apps.docs.editors.slides",
			pptx: "com.google.android.apps.docs.editors.slides",
			ppsx: "com.google.android.apps.docs.editors.slides",
			pps: "com.google.android.apps.docs.editors.slides",
			ppsm: "com.google.android.apps.docs.editors.slides"
		};
		return packageNames[ext];
	}

	getPackageNameIos(extn) {
		let ext = extn.toLowerCase();
		let packageNames = {
			// texts
			docx: "documentos-google/id842842640",
			doc: "documentos-google/id842842640",
			docm: "documentos-google/id842842640",
			// pdf
			pdf: "adobe-acrobat-reader/id469337564",
			// sheets
			xls: "planilhas-google/id842849113",
			xlsx: "planilhas-google/id842849113",
			xlsm: "planilhas-google/id842849113",
			xlt: "planilhas-google/id842849113",
			xlsb: "planilhas-google/id842849113",
			xltx: "planilhas-google/id842849113",
			xltm: "planilhas-google/id842849113",
			// presentations (ppt)
			ppt: "apresentações-google/id879478102",
			pptx: "apresentações-google/id879478102",
			ppsx: "apresentações-google/id879478102",
			pps: "apresentações-google/id879478102",
			ppsm: "apresentações-google/id879478102"
		};
		return packageNames[ext];
	}

	popPage() {
		this.navCtrl.pop();
	}

	async openChat() {
		if (!this.firstAccess) {
			await this.chatNotAvailableAlert();
		} else {
			const chatId = await this.gdService.getOrCreateChat(this.eventId, this.userId, this.userProfileId);
            if (chatId) {
                this.router.navigate([`/event/${this.eventId}/chat/${chatId}`]);
            } else {
                await this.chatNotAvailableAlert();
            }
		}
	}

	/**
	 * Create and send mess
	 */
	async createVisio() {
        const toastVisioError = async (errorMsg) => {
            this.SUtility.presentToast(
                this.translateService.instant(errorMsg),
                2500,
                "bottom",
                "danger"
            );
        }

		if (!this.firstAccess) {
			await this.chatNotAvailableAlert();
			return;
		}
		try {
			if (this.userId != this.userProfileId) {
				const sendMessageVisioOnChat$ = this.SWhereby
                    .sendMessageVisioOnChat(this.eventId, [this.userId, this.userProfileId]);
                
                firstValueFrom(sendMessageVisioOnChat$).then((status) => {
                    if (status) {
                        // Analytics created new room for 2
                        this.SWhereby.analyticsNewRoomFor2(this.eventId, [this.userId, this.userProfileId]);

                        this.SUtility.presentToast(
                            this.translateService.instant("global.toasts.visios.created"),
                            2500,
                            "bottom",
                            "primary"
                        );
                        this.openChat();
                    } else {
                        toastVisioError("global.toasts.visios.error");
                    }
                }).catch((error) => { 
                    console.error(error);
                    toastVisioError("global.toasts.visios.error");
                });
			} else {
                toastVisioError("global.toasts.visios.error-same-user");
			}
		} catch (error) {
			toastVisioError("global.toasts.visios.error");
		}
	}

	headerHeightFn(item, index) {
		return 40;
	}

	async openUserTimeslots() {
		if (!this.firstAccess) {
			await this.chatNotAvailableAlert();
		} else {
			// const module = await this.daoAppointments.getModule().pipe(first()).toPromise();
            const promise = await Promise.all([
                firstValueFrom(this.daoAppointments.getModule()),
                this.SUserData.getUserDataSnapshot(this.eventId)
            ]);
            if (promise) {
                this.router.navigate([
                    `/event/${this.eventId}/appointments/${promise[0].uid}/user-timeslots/${promise[1].type}/${this.userProfileId}`
                ]);
            } else {
                await this.chatNotAvailableAlert();
            }
		}
	}

    async chatNotAvailableAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant("pages.chat.chat"),
            message: this.translateService.instant("global.alerts.user_chat_not_available"),
            buttons: [this.translateService.instant("global.buttons.ok")]
        });
        await alert.present();
    }
}
