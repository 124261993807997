import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GalleryFolder } from '../../models/ceu-gallery-folder';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { 
    Firestore, 
    doc,
    docData,
    DocumentData,
    collection,
    query,
    orderBy,
    collectionData, 
} from '@angular/fire/firestore';
import { MenuController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { environment } from 'src/environments/environment';
import { auditTime, takeUntil } from 'rxjs/operators';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { EventColors } from 'src/app/models/event-colors';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { TypeVisionModule } from 'src/app/models/type-vision-module';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.page.html',
    styleUrls: ['./gallery.page.scss'],
})

export class GalleryPage implements OnInit {
    public module = null

    eventId: string = null;
    moduleId: string = null;
    folderId: string;
    folders: Array<GalleryFolder> = [];
    groupsFolder: Array<GalleryFolder>;
    globalFolders: Array<GalleryFolder>;
    finalFolder: Array<GalleryFolder> = [];
    isMobile: boolean = false;

    loader: boolean = true;
    backBtn: boolean = true;
    allowButtonsHeader: boolean = false;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    blockRedirect: boolean = false;

    language: string;

    subscriptions: Subscription[] = [];

    oneFolder: boolean = false;

    user: CeuAttendee;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public global: GlobalService,
        private firestore: Firestore,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private userDataService: UserDataService,
        private Slogout: LogoutService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
            
        this.moduleId = params.moduleId;
        this.loadColors();
        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });

        // Track analytics
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_IMAGES_MODULE);
    }

    ionViewDidEnter() {
        // if (this.blockRedirect == false) {
        const ref = doc(this.firestore, `modules/${this.moduleId}`);
        const sub = docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data) => {
            this.module = data
            let order;

            if (data['typeOrder'] == 'recent') { order = 'desc' } else if (data['typeOrder'] == 'oldest') { order = 'asc' } else if (data['typeOrder'] == 'custom') { order = 'custom' }

            this.startFolders(order);
        });
        this.subscriptions.push(sub);

    }

    startFolders(order) {
        const ref = collection(this.firestore, `modules/${this.moduleId}/folders`);
        let refQ;

        if (order == 'custom') { refQ = query(ref, orderBy('order')); } 
        else { refQ = query(ref, orderBy('createdAt', order)); }

        const sub = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((folders: Array<GalleryFolder>) => {
            // if (folders.length == -1) {
            //     this.router.navigateByUrl(`/event/${this.eventId}/gallery/${this.moduleId}/folder/${folders[0].uid}`)
            // } 
            // else {
            //     this.filterFolder(folders);
            //     this.oneFolder = false;
            // }
            // this.loader = false;
            this.filterFolder(folders);
            this.loader = false;
        });
        this.subscriptions.push(sub);
    }


    /**
     * Filtra os folder por TypeVisionModule
     *
     * @param {Array<GalleryFolder>} folders
     * @memberof GalleryPage
     */
    filterFolder(folders) {
        this.userDataService.getUserDataSnapshot(this.eventId).then((userData) => {
            this.finalFolder = [];
            folders.forEach((folder) => {
                if (folder.typeVision == TypeVisionModule.DIVIDED_BY_GROUPS) {
                    const i = Object.keys(folder.groups).findIndex((groupId) => { return (userData.groups[groupId] != undefined); });
                    if (i > -1) { this.finalFolder.push(folder); }
                }
                else { this.finalFolder.push(folder); }
            })
        });
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
    }

    styleIndex: number = 0;    // determina qual  o background color das default imgs
    bgColors: Array<string> = ['#FA0101', '#FAC401', '#0010AD', '#00AD31', '#FA5D00', '#6C00AD', '#0085AD'];

    // determina qual é a background color das default imgs 
    // recebe um array com codigos em hexadecimal de cores (bgColors), um index para determinar a cor no array (styleIndex) e o index das pastas 
    // retorna uma string do codigo da cor bgColors[styleIndex]
    defaultImgBgStyle(index: number) {
        if (index == 0 && this.styleIndex != 0) {
            // Essa condição permite que no caso da ultima pasta da galeria ser customImg, 
            // quando o componente recarrega/recebe novamente o valor do array folders
            // o elemento do index == 0 deve sempre corresponder a primeira cor do array bgColors
            this.styleIndex = 0;
        }

        let color = this.bgColors[this.styleIndex];

        if ((index == this.folders.length - 1) || (this.styleIndex == (this.bgColors.length - 1))) {
            // quando o valor de index corresponde ao ultimo elemento do array folders ou
            // o valor de styleIndex é igual ao ultimo index do array bgColors
            // o valor de styleIndex é resetado
            this.styleIndex = 0;
        }
        else {
            this.styleIndex++;
        }

        return color;
    }
}
