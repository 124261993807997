import { Injectable, NgZone } from '@angular/core';
import { 
    Firestore, 
    doc,
    docData
} from '@angular/fire/firestore';
import { PathApi } from '../../paths/path-api';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';  // todo: update plugin splash-screen
import { NavController, MenuController } from '@ionic/angular';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';
import { Auth, UserCredential, authState, fetchSignInMethodsForEmail, idToken, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public userToken: string = null;
    public headers;
    public requestOptions;
    public tokenId: string = null;
    constructor(
        public http: HttpClient,
        private auth: Auth,
        private firestore: Firestore,
        // private splashScreen: SplashScreen,  // todo: update plugin splash-screen
        private navCtrl: NavController,
        private menuCtrl: MenuController,
        private functions: Functions,
        private Slogout: LogoutService,
        private userData: UserDataService
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    // Verify if e-mail exists in Firestore Database
    verifyEmailDb(email: string, onResolve) {
        const call = httpsCallable(this.functions, 'dbUserGetUserByEmail');
        call({ email: email })
            .then((data: HttpsCallableResult<any>) => { 
                this.userData.userId = data.data.uid;
                onResolve(data.data);
            })
            .catch((error) => { console.error(error); })
    }

    // Verify if e-mail exists in Firebase Authentication
    verifyEmailAuth(email: string) {
        return new Promise((resolve, reject) => {
            // call http to verify if e-mail already exists
            this.http.post(PathApi.baseUrl + PathApi.authChangeUserType, email, this.requestOptions)
                .subscribe(_ => {
                    // case exists, return 'exists' to front manipulation
                    resolve('exists');
                }, e => {
                    // case not exists, return 'available' to front manipulation
                    reject('available');
                });
        })
    }

    /**
     * Make account in user first access and call Firebase Auth to set user type (access nivel)
     * @param email 
     * @param password 
     * @param userUid 
     * @param type 
     */
    createAccount(email: string, password: string, userUid: string, type) {
        const call = httpsCallable(this.functions, 'dbUserCreateUserFirstAccess');
        return new Promise((resolve, reject) => {
            call({ email: email, password: password, userId: userUid, userType: type })
                .then((data: HttpsCallableResult<any>) => { 
                    this.userData.userId = data.data.uid;
                    resolve(data.data); 
                })
                .catch((error) => { reject(error); })
        })
    }

    createUserPublicEvent(eventId: string, name: string, email: string, password: string, language: string, onResolve) {
        // const call = httpsCallable(this.functions, 'dbUserCreatePublicEventUserFirstAccess');
        // call({ eventId: eventId, name: name, email: email, password: password, language: language })
        // const call = httpsCallable(this.functions, 'dbUserSignUp');
        this.signUpToPublicEvent({ 
            eventId: eventId, 
            name: name, 
            email: email, 
            password: password, 
            language: language 
        }).then((result: HttpsCallableResult<any>) => {
            this.login(email, password, () => {
                this.userData.userId = result.data.uid;
                onResolve(result.data);
            });
        }).catch((error) => {
            console.log(error);
            onResolve(null);
        });
    }

    public signUpToPublicEvent(params: {
        eventId: string,
        name?: string,
        email: string,
        password?: string,
        language: string,
        providerId?: string,
        uid?: string
    }): Promise<HttpsCallableResult<any>> {
        // console.log(`dbUserSignUp`)
        const call = httpsCallable(this.functions, 'dbUserSignUp');
        return call(params);
    }

    logToUserPublicEvent(eventId: string, name: string, email: string, password: string, language: string, onResolve) {
        this.verifyEmailDb(email, (data) => {
            if(data){
                console.log('deja enregistré !!!')
            }
        })  
    }

    // makeUsers(attendee, user, eventId) {
    //     const ref1 = doc(this.firestore, `events/${eventId}/attendees/${user.uid}`);
    //     const ref2 = doc(this.firestore, `modules/${attendee.moduleId}/attendees/${user.uid}`)
    //     const ref3 = doc(this.firestore, `users/${user.uid}`)

    //     const batch = writeBatch(this.firestore)
            
    //     batch.set(ref1, attendee);
    //     batch.set(ref2, attendee);
    //     batch.set(ref3, user);

    //     return batch.commit()

    //     //     batch
    //     //         .commit()
    //     //         .then((result) => {
    //     //             resolve(true);
    //     //         })
    //     //         .catch((e) => {
    //     //             resolve(false);
    //     //         });
    //     // });
    // }

    // checkAttendeeInAttendees(eventId: string, uid: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     let ref = db.collection('events').doc(eventId).collection('attendees').doc(uid);

    //     ref
    //         .get()
    //         .then((snapshot) => {
    //             if (snapshot.exists) {
    //                 onResolve(true);
    //             } else {
    //                 onResolve(false);
    //             }
    //         });
    // }

    login(email: string, password: string, onResolve: (res: UserCredential | any) => void) {
        signInWithEmailAndPassword(this.auth, email, password)
            .then(async (res) => {
                this.userData.userId = res.user.uid;
                localStorage.setItem('userIdentifier', res.user.uid);
                await res.user.getIdToken(true).then((idToken: string) => {
                    localStorage.setItem("userToken", idToken);
                    this.tokenId = idToken;
                    onResolve(res);
                }).catch((err) => {
                    onResolve(err);
                });
            })
            .catch((err) => {
                onResolve(err);
            });
    }

    logout() {
        // this.splashScreen.show();  // todo: update plugin splash-screen
        // this.menuCtrl.toggle();
        this.removeStorageReferences();
        signOut(this.auth).then((_) => {
            this.navCtrl.navigateRoot(['/'])
                .then(() => {
                    window.location.reload();
                    // this.splashScreen.hide();  // todo: update plugin splash-screen
                });
        });
        this.Slogout.logout();
    }

    removeStorageReferences() {
        localStorage.removeItem("userToken");
        localStorage.removeItem("eventId");
        localStorage.removeItem("userIdentifier");
        localStorage.removeItem("homePage");
        localStorage.removeItem("timezone");
        localStorage.removeItem("eventVisible");
    }

    getIdToken() {
        return firstValueFrom(idToken(this.auth));
    }

    claimsUser(onResolve) {
        this.getIdToken()
            .then((idToken: string) => {
                // request post
                return this.http.post(PathApi.baseUrl +
                    PathApi.authClaimsUsers,
                    { idToken: idToken },
                    this.requestOptions
                ).subscribe(
                    (claims: any) => {
                        // let aux = JSON.parse(claims['_body']);
                        onResolve(claims);
                    }
                )
            });
    }


    sendCodeNumberToEmail(user, onResolve) {
        this.http.post(PathApi.baseUrl + PathApi.dbUserSendCodeNumberEmail, user, this.requestOptions).subscribe((success) => {
            onResolve(success);
        }), err => {
            onResolve(false);
        }
    }

    sendVerificationCodeToEmail(email: string, codeNumber: string, onResolve) {
        const userLanguage = navigator.language; // example: pt-BR
        // if you do not send a specific language, the e-mail will be sent in English by default;
        this.http.post(PathApi.baseUrl + PathApi.emailsSendCodeToUser, { email, codeNumber, userLanguage}, this.requestOptions).subscribe({
            next: () => {onResolve(true)},
            error: (e) => {
                onResolve(false)
                console.error(e);
            },
        })
    }

    verifyCodeNumber(code: string, userId: string, onResolve) {
        const call = httpsCallable(this.functions, 'dbUserVerifyCodeNumber');
        call({ code: code, uid: userId }).then((data) => {
            onResolve(data.data);
        }).catch((error) => { console.log(error); });

        // let db = this.aFirestore.firestore;
        // db
        //   .collection("users")
        //   .doc(userId)
        //   .get()
        //   .then((user) => {
        //     let aux = user.data();
        //     if (aux.codeNumber == code) {
        //       onResolve({
        //         code: 200,
        //         message: 'success',
        //         result: true
        //       });
        //     } else {
        //       onResolve({
        //         code: 404,
        //         message: 'error',
        //         result: false
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     onResolve({
        //       code: 404,
        //       message: 'error',
        //       result: error
        //     });
        //   });
    }

    // removeCodeNumber(userId: string) {
    //     let db = this.aFirestore.firestore;

    //     db.collection("users").doc(userId).update({
    //         codeNumber: null
    //     });
    // }

    public authenticated(): boolean {
        let storage = localStorage.getItem('userToken');
        if (this.tokenId === undefined || this.tokenId === null && storage !== null && storage !== undefined) {
            this.tokenId = storage;
        }
        return this.tokenId !== undefined && this.tokenId !== null;
    }

    isAuthenticated(): Observable<boolean> {
        return authState(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map(u => (u) ? true : false)
        );
    }

    getUser(): Observable<any> {
        return authState(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((u) => {
                if (u) {
                    const ref = doc(this.firestore, `users/${u.uid}`);
                    return docData(ref);  
                }
                else {
                    return of(null)
                }
            })
        );
    }

    public current() {
        return firstValueFrom(authState(this.auth));
    }

    checkAuthEmail(email: string, onResolve) {
        fetchSignInMethodsForEmail(this.auth, email)
            .then(() => { onResolve(true); })
            .catch((error) => { console.error(error); onResolve(false); });
    }

    recoveryPassword(email: string, onResolve) {
        sendPasswordResetEmail(this.auth, email)
            .then(() => { onResolve(true); })
            .catch((error) => { console.error(error); onResolve(false); });
    }
}
