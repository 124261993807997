import {
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment';

// Interactivity
import { IInteractivityState } from '../interfaces/interactivity.interfaces';
import { interactivityReducer } from '../reducers/interactivity.reducers';

// Integrations
import { IIntegration } from '../interfaces/integrations.interface';
import { integrationsReducer } from './integrations.reducers';

// Schedules
import { ISchedulesState } from '../interfaces/schedules.interfaces';
import { schedulesReducer } from './schedules.reducers';

// User
import { IUserState } from '../interfaces/user.interfaces';
import { userReducer } from './user.reducers';

// Utility
import { IUtilityState } from '../interfaces/utility.interfaces';
import { utilityReducer } from './utility.reducers';

// Video pllayer
import { IVideoPlayer } from '../interfaces/video-player.interfaces';
import { videoPlayerReducer } from './video-player.reducers';

export interface AppState {
    interactivity: IInteractivityState,
    integrations: IIntegration,
    schedules: ISchedulesState,
    user: IUserState,
    utility: IUtilityState,
    videoPlayer: IVideoPlayer
}

export const reducers: ActionReducerMap<AppState> = {
    interactivity: interactivityReducer,
    integrations: integrationsReducer,
    schedules: schedulesReducer,
    user: userReducer,
    utility: utilityReducer,
    videoPlayer: videoPlayerReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];