import { Injectable } from '@angular/core';
import {
    BarcodeScanner,
    BarcodeFormat,
    GoogleBarcodeScannerModuleInstallState,
} from '@capacitor-mlkit/barcode-scanning';
import { Platform } from '@ionic/angular';
import { PluginListenerHandle } from '@capacitor/core';

@Injectable({
    providedIn: 'root'
})
export class BarcodeScannerService {

    private barcodeScannedListener: PluginListenerHandle = null;
    public scannerActivated: boolean = false

    constructor(
        private platform: Platform,
    ) { }

    /**
     * @description check and request for, camera usage permission
     * @param callback 
     */
    private getPermission(): Promise<boolean> {
        return new Promise((resolve) => {
            BarcodeScanner.checkPermissions().then((permissions) => {
                if (permissions.camera === "prompt") {
                    BarcodeScanner.requestPermissions().then((permission) => {
                        if (permission.camera !== "granted") {
                            console.log(`camera permission denied`);
                            resolve(false);
                        }
                        else { resolve(true); }
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    })
                }
                else if (permissions.camera == "granted") {
                    resolve(true);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            })
        });
    }

    /**
     * @description check if the Google barcode scanner module is available for the device, if not, try to install it, then
     * return as a callback the execution status of this method (i.e. null if an error occurred or a promise with boolean value,
     * been true the status of success getting the module and false otherwise)
     * @param callback 
     */
    private getGoogleBSModule(callback = (listener: Promise<boolean> | null) => { }) {
        BarcodeScanner
            .isGoogleBarcodeScannerModuleAvailable()
            .then((result) => {
                if (!result.available) {
                    BarcodeScanner
                        .installGoogleBarcodeScannerModule()
                        .then(() => {
                            BarcodeScanner.removeAllListeners().then(() => {
                                callback(new Promise<boolean>((resolve) => {
                                    BarcodeScanner.addListener("googleBarcodeScannerModuleInstallProgress", (event) => {
                                        const state = GoogleBarcodeScannerModuleInstallState;

                                        if (event.state == state.COMPLETED) { resolve(true); }
                                        else if (event.state == state.CANCELED || event.state == state.FAILED) { resolve(false); }
                                    })
                                }))
                            })
                                .catch((error) => {
                                    console.log(error);
                                    callback(null);
                                })
                        })
                        .catch((error) => {
                            console.log(error);
                            callback(null)
                        });
                }
                else {
                    callback(new Promise<boolean>((resolve) => { resolve(true); }))
                }
            })
            .catch((error) => {
                console.log(error);
                callback(null)
            });
    }

    /**
     * @description open the QR code scanner (i.e. the BarcodeScanner scan). For android, uses the GoogleBarcodeScannerModule 
     * if available or can be installed successfully, else uses the standard scanner with the UI present in the CSS class 
     * "scanner-active"
     * @param callback this method should include code to be executed when a barcode is scanned, such as the logic 
     * for closing the scanner 
     */
    private openQRCodeScanner(callback = () => {}): Promise<string> {
        const scanFormat = { formats: [BarcodeFormat.QrCode] };

        const standartScan = () => {
            return new Promise<string>((resolve, reject) => {
                this.getPermission().then(async (result) => {
                    if (result) {
                        document.querySelector('body').classList.add('scanner-active');
                        
                        BarcodeScanner.addListener("barcodeScanned", (result) => {
                            callback();
                            resolve(result.barcode.displayValue);
                        })
                        .then((listener) => {
                            this.barcodeScannedListener = listener;
                            BarcodeScanner.startScan(scanFormat);
                        })
                        .catch((error) => { 
                            console.log(error);
                            reject();
                        });
                    }
                    else { reject(); }
                })
                .catch((error) => { 
                    console.log(error);
                    reject();
                });
            });
        }
        
        const promise: Promise<string> = new Promise<string>((resolve, reject) => {
            if (this.platform.is("android")) {
                this.getGoogleBSModule((listener) => {
                    if (listener == null) {
                        standartScan()
                            .then((barcodeValue) => { resolve(barcodeValue); })
                            .catch(() => { reject(); })
                    }
                    else {
                        listener.then((status) => {
                            if (status) {
                                BarcodeScanner.scan(scanFormat)
                                    .then((result) => { resolve(result.barcodes[0].displayValue); })
                                    .catch(() => { reject(); })
                            }
                            else {
                                standartScan()
                                    .then((barcodeValue) => { resolve(barcodeValue); })
                                    .catch(() => { reject(); })
                            }
                        })
                    }
                });
            }
            else {
                BarcodeScanner.scan(scanFormat)
                    .then((result) => { resolve(result.barcodes[0].displayValue); })
                    .catch(() => { reject(); })
            }
        });

        return promise;
    }

    /**
     * @description executes the scan of a single QR code
     */
    public scanSingleQRCode() {
        this.scannerActivated = true;

        return new Promise<string>((resolve) => {
            this.openQRCodeScanner(async () => {
                if (this.barcodeScannedListener != null) { 
                    await this.barcodeScannedListener.remove();
                }
                document.querySelector('body').classList.remove('scanner-active');
                await BarcodeScanner.stopScan();
            }).then((barcodeValue) => {
                resolve(barcodeValue);
            }).finally(() => { this.scannerActivated = false; })
        });
    }

    /**
     * @description hide the scanner UI if any exist, and stop the Barcode Scanner
     */
    public stopScanner() {
        this.scannerActivated = false;
        document.querySelector('body').classList.remove('scanner-active');
        BarcodeScanner.stopScan();
    }
}
