import { Injectable } from '@angular/core';
import { Quiz } from 'src/app/models/quiz';
import { Question } from 'src/app/models/quiz-question';
import { Answer } from 'src/app/models/quiz-answer';
import { TypeModule } from 'src/app/models/type-module';
import { NameModule } from 'src/app/models/path/name-module';
import { map, take, switchMap, auditTime, tap, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Observable, firstValueFrom } from 'rxjs';
import { UtilityService } from 'src/app/shared/services';
import * as _ from 'lodash';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Firestore, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where, arrayUnion, setDoc } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoQuizService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private SUtility: UtilityService,
        private platform: Platform,
        private storage: Storage
    ) { }

    /**
     * Get quiz module for an event
     * @param eventId 
     */
    getQuizModule(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.QUIZ));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * Get module of a quiz
     * @param quizModuleId 
     */
    getModule(quizModuleId: string) {
        const ref = doc(this.firestore, `modules/${quizModuleId}`);
        return docData(ref).pipe(take(1));
    }

    /**
     * Get quizs session
     * @param eventId 
     * @param moduleId 
     * @param scheduleModuleId 
     * @param sessionId 
     * @param userId 
     * @param onResolve 
     */
    getQuizsSession(eventId: string, moduleId: string, scheduleModuleId: string, sessionId: string, userId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/quizs`);
        const refQ = query(ref, orderBy('order', 'asc'));

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            auditTime(500),
            switchMap(async (docs) => {
                let listQuizs: Array<Quiz> = [];
                let listQuizsSession: Array<Quiz> = [];
                if (docs.length > 0) {
                    docs.forEach((element) => {
                        let quiz = this.instantiateQuiz(element);
                        if (quiz.visibility) {
                            let index = this.SUtility.checkIndexExists(listQuizs, quiz);

                            if (index >= 0) {
                                listQuizs[index] = quiz;
                            } else {
                                listQuizs.push(quiz);
                            }
                        }
                    });

                    for (let quiz of listQuizs) {

                        if (quiz.type === 'AllSessions') {
                            listQuizsSession.push(quiz);
                        }
                        else if (quiz.type === 'ScheduleModule') {
                            if (scheduleModuleId === quiz.module_id) {
                                listQuizsSession.push(quiz);
                            }
                        }
                        else if (quiz.type === 'SessionTrack') {
                            // case this session be inside a track with permission, display feedback
                            let tracks = await this.checkSpecificTrackQuiz(quiz.module_id, sessionId, quiz.references);
                            for (const track of tracks) {
                                if (track) {
                                    listQuizsSession.push(quiz);
                                    break;
                                }
                            }
                        }
                        else if (quiz.type === 'SpecificSession') {
                            for (let uid of quiz.references) {
                                if (uid === sessionId) {
                                    listQuizsSession.push(quiz);
                                    break;
                                }
                            }
                        }
                        else if (quiz.type === 'SpecificGroup') {
                            await this.listgroupsOfAttendee(eventId, userId, (listGroupsAttendee) => {
                                const references: any = quiz.references
                                let findGroup = false;
                                for (let uidGroup of references) {
                                    for (const uidGroupUser of listGroupsAttendee) {
                                        //if the user is part of the group
                                        if (uidGroup == uidGroupUser && findGroup == false) {
                                            listQuizsSession.push(quiz);
                                            findGroup = true;
                                            break;
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
                return (listQuizsSession);
            })
        );
    }

    /**
     * Get all quizs for an event
     * @param moduleId 
     */
    getQuizsEvent(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/quizs`);
        return collectionData(ref)
            .pipe(
                takeUntil(this.Slogout.logoutSubject),
                map((docs) => {
                    return (docs.map((doc) => {
                        let quiz = this.instantiateQuiz(doc);
                        return (quiz);
                    }))
                })
            );
    }

    /**
     * Get quiz
     * @param moduleId 
     * @param quizId 
     */
    getQuiz(moduleId: string, quizId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}/quizs/${quizId}`);
        return docData(ref)
            .pipe(
                takeUntil(this.Slogout.logoutSubject),
                switchMap((quiz) => {
                    if (quiz) {
                        return (this.getQuestions(moduleId, quizId).pipe(
                            switchMap((questions) => {
                                quiz.questions = questions;
                                return (of(quiz));
                            })
                        ))
                    } else {
                        return (of(null));
                    }
                })
            );
    }

    /**
     * Get questions of a quiz
     * @param moduleId 
     * @param quizId 
     */
    getQuestions(quizModuleId: string, quizId: string): Observable<any> {
        const ref = collection(this.firestore, `modules/${quizModuleId}/quizs/${quizId}/questions`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        return collectionData(refQ)
            .pipe(
                takeUntil(this.Slogout.logoutSubject),
                map((docs) => docs.map((doc) => this.instantiateQuestion(doc))),
                switchMap((questions: Question[]) => {
                    if (!questions || questions.length == 0) {
                        return (of([]));
                    }

                    let obs: Observable<any>[] = [];
                    questions.filter((question) => (question.visibility == undefined || question.visibility)).forEach((question) => {
                        obs.push(this.getAnswers(quizModuleId, quizId, question.uid).pipe(
                            map((answers) => {
                                question.answers = answers;
                                return (question);
                            })
                        ));

                    })
                    return (combineLatest(obs));
                })
            )
    }

    /**
     * Get total of questions on a quiz
     * @param quizModuleId 
     * @param quizId 
     */
    getTotalQuestions(quizModuleId: string, quizId: string) {
        const ref = collection(this.firestore, `modules/${quizModuleId}/quizs/${quizId}/questions`);
        return collectionData(ref).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                return (of(docs.length));
            })
        );
    }

    /**
     * Get answer for the questions of a quiz
     * @param quizModuleId 
     * @param quizId 
     * @param questionId 
     */
    getAnswers(quizModuleId: string, quizId: string, questionId: string) {
        const ref = collection(this.firestore, `modules/${quizModuleId}/quizs/${quizId}/questions/${questionId}/answers`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));
        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((docs) => docs.map((doc) => this.instantiateAnswer(doc)))
        );
    }

    /**
     * Check specific track on a quiz
     * @param trackModuleId 
     * @param sessionId 
     * @param tracksIds 
     */
    async checkSpecificTrackQuiz(trackModuleId, sessionId, tracksIds) {
        let list = [];
        for (let trackId of tracksIds) {
            let result = await this.checkTrackQuiz(trackModuleId, sessionId, trackId);
            list.push(result);

            if (list.length == tracksIds.length) {
                return (list);
            }
        }

    }

    /**
     * Check track on a quiz
     * @param moduleId 
     * @param sessionId 
     * @param trackId 
     * @param onResolve 
     */
    checkTrackQuiz(moduleId, sessionId, trackId) {
        const ref = doc(this.firestore, `modules/${moduleId}/tracks/${trackId}/sessions/${sessionId}`);
        return firstValueFrom(docData(ref).pipe(
            switchMap((doc) => {
                if (doc) {
                    return (of(true));
                } else {
                    return (of(false));
                }
            })
        ));
    }

    listgroupsOfAttendee(eventId: string, attendeeId: string, onResolve) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        firstValueFrom(docData(ref))
            .then((data) => {
                let groups = data['groups'];
                let idsGroups: Array<String> = [];

                if (groups !== null && groups !== undefined) {
                    for (let uid in groups) {
                        idsGroups.push(uid);
                    }
                }

                onResolve(idsGroups);
            })
    }


    createResult(moduleId: string, userId: string, quizId: string, IdQuestion: string, type: string, answer: any, timestamp: number, onResolve) {
        // const ref = collection(this.firestore, `modules/${moduleId}/quizs/${quizId}/questions/${IdQuestion}/result`);
        const refQuestionsResult = `modules/${moduleId}/quizs/${quizId}/questions/${IdQuestion}/result`;
        let ref;
        
        if (type == "oneSelect" || type == "multipleSelect") {
            if (userId !== null && userId !== undefined && userId !== '') {
                this.getResponseUserQuestion(moduleId, quizId, IdQuestion, userId, (responses) => {
                    this.deletePrevAnswersSelectOption(moduleId, quizId, IdQuestion, userId, (result) => {
                        if (result == true) {
                            ref = doc(this.firestore, `${refQuestionsResult}/${userId}`);
                            setDoc(
                                ref,
                                {
                                    user: userId,
                                    answer: answer,
                                    timestamp: timestamp,
                                    question: IdQuestion
                                }
                            ).then(() => {
                                    if (responses !== null && responses !== undefined) {
                                        onResolve(false);
                                    } else {
                                        onResolve(true);
                                    }
                                })
                        }
                    })
                })
            } else {
                ref = doc(
                    collection(this.firestore, refQuestionsResult)
                );
                userId = ref.id;
                setDoc(
                    ref,
                    {
                        user: userId,
                        answer: answer,
                        timestamp: timestamp,
                        question: IdQuestion
                    }
                ).then(() => {
                        onResolve(true);
                    })
            }
        } else {
            ref = doc(this.firestore, `${refQuestionsResult}/${userId}`);
            setDoc(
                ref,
                {
                    question: IdQuestion,
                    user: userId,
                    answer: answer,
                    timestamp: timestamp
                }
            ).then(() => {
                    onResolve(true);
                })
        }
    }

    getResponseUsers(moduleId, quizId, userId, onResolve) {
        this.getQuestions(moduleId, quizId).subscribe((questions) => {
            let listResults = {};
            for (let i = 0; i < questions.length; i++) {
                this.getResponseUserQuestion(moduleId, quizId, questions[i].uid, userId, (response) => {
                    if (response != null) {
                        listResults[response.question] = response;
                    }

                    if (i == questions.length - 1) {
                        onResolve(listResults);
                    }
                })
            }
        })
    }

    getResponseUserQuestion(moduleId, quizId, questionId, userId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/quizs/${quizId}/questions/${questionId}/result/${userId}`);
        firstValueFrom(docData(ref))
            .then((data) => {
                if (data) {
                    onResolve(data)
                } else {
                    onResolve(null);
                }
            })
    }

    deletePrevAnswersSelectOption(moduleId, quizId, IdQuestion, userId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/quizs/${quizId}/questions/${IdQuestion}/result/${userId}`);
        deleteDoc(ref).then(() => {
            onResolve(true)
        }).catch(() => {
            onResolve(false);
        })
    }

    /**
     * Instantiate quiz data
     * @param data 
     */
    instantiateQuiz(data) {
        let quiz = new Quiz();
        quiz.title = data.title;
        quiz.imgCapa = data.imgCapa;
        quiz.type = data.type;
        quiz.uid = data.uid;
        quiz.visibility = data.visibility;
        quiz.change_answer = data.change_answer;
        quiz.view_answered = data.view_answered;
        quiz.icon = data.icon;
        quiz.iconFamily = data.iconFamily;
        quiz.totalQuestions = data.totalQuestions;
        quiz.order = data.order;

        quiz.questionsNumberVisibility=  data.questionsNumberVisibility;
        quiz.includeImgToAnswers = data.includeImgToAnswers;

        data.active_timer != undefined ? quiz.active_timer = data.active_timer : quiz.active_timer = false;
        data.timer_questions != undefined ? quiz.timer_questions = data.timer_questions : quiz.timer_questions = null;
        data.max_responses != undefined ? quiz.max_responses = data.max_responses : quiz.max_responses = null;
        data.module_id != undefined ? quiz.module_id = data.module_id : quiz.module_id = null;
        data.references != undefined ? quiz.references = data.references : quiz.references = null;

        return quiz;
    }

    /**
     * Instantiate a question
     * @param data 
     */
    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.graphicDifusion != undefined ? question.graphicDifusion = data.graphicDifusion : question.graphicDifusion = false;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        data.image != undefined ? question.image = data.image : question.image = null;
        data.maxResponses != undefined ? question.maxResponses = data.maxResponses : question.maxResponses = null;
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    /**
     * Instantiate answer
     * @param data 
     */
    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        answer.correct = data.correct;
        answer.img = data.img;

        return answer;
    }

    async setUserQuizAnswered(eventId: string, attendeeId: string, quizId: string, onResolve) {
        if (attendeeId) {
            const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
            updateDoc(ref, { answeredQuizes: arrayUnion(quizId) })
                .then(() => {
                    onResolve(true);
                });
        } else {
            // Save quiz id on localstorage if event public
            let quizsString: string = "";
            let quizsSaved: any = {};
            if (this.platform.is('ios') || this.platform.is('android')) {
                quizsString = await this.storage.get('quizsAnswered');
            } else {
                quizsString = localStorage.getItem('quizsAnswered');
            }

            if (JSON.parse(quizsString) && JSON.parse(quizsString) != null) {
                quizsSaved = JSON.parse(quizsString);
            }

            if (!quizsSaved || !quizsSaved[eventId]) {
                quizsSaved[eventId] = {
                    quizs: [quizId]
                }

            } else if (quizsSaved && quizsSaved[eventId] && quizsSaved[eventId].quizs.length == 0 || !quizsSaved[eventId].quizs.includes(quizId)) {
                quizsSaved[eventId].quizs.push(quizId);
            }

            if (this.platform.is('ios') || this.platform.is('android')) {
                this.storage.set('quizsAnswered', JSON.stringify(quizsSaved));
            } else {
                localStorage.setItem('quizsAnswered', JSON.stringify(quizsSaved));
            }
            onResolve(true);
        }
    }

    /**
     * Check if a quiz is answered
     * @param eventId 
     * @param attendeeId 
     * @param quizId 
     */
    checkAnsweredQuiz(eventId: string, attendeeId: string, quizId: string) {
        if (!attendeeId) {
            return (of(false));
        } else {
            const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
            return docData(ref).pipe(
                take(1),
                switchMap((attendeeData) => {
                    if (attendeeData.answeredQuizes && attendeeData.answeredQuizes.includes(quizId)) {
                        return (of(true));
                    } else {
                        return (of(false));
                    }
                })
            )
        }
    }

    /**
     * Get results of an answer
     * @param moduleId 
     * @param questionId 
     * @param quizId 
     */
    getResultsOfAnswer(moduleId: string, questionId: string, quizId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/quizs/${quizId}/questions/${questionId}/result`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}