import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService, AnalyticsService } from "src/app/shared/services";
import { 
    Firestore, 
    doc,
    docData,
    collection,
    query,
    orderBy,
    collectionData,
    QueryConstraint,
    limit,
    startAfter, 
} from '@angular/fire/firestore';
import {
	MenuController,
	IonInfiniteScroll,
	IonVirtualScroll,
	ModalController,
	Platform
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { CeuAttendee } from "src/app/models/ceu-attendee";
import { TypeTracking } from "src/app/enums/type-tracking";
import { PathComponent } from "src/app/models/path/path-components";
import { EventColors } from "src/app/models/event-colors";
import { Subscription } from "rxjs";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-ranking",
	templateUrl: "./ranking.page.html",
	styleUrls: ["./ranking.page.scss"]
})
export class RankingPage implements OnInit, OnDestroy {
	public module = null;

	@ViewChild(IonInfiniteScroll)
	infiniteScroll: IonInfiniteScroll;
	@ViewChild(IonVirtualScroll)
	virtualScroll: IonVirtualScroll;
	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;
	eventId: string = null;
	moduleId: string = null;
	allowButtonsHeader: boolean = false;
	allAttendeesList: Array<any> = [];
	lastAttendees;
	attendees: Array<CeuAttendee> = [];
	loader: boolean = true;
	myUser = null;
	searchOpen: boolean = false;
	isMobile: boolean = false;
	showOrderList = false;
	filterby = "asc";
    subscriptions: Subscription[] = [];
    language: string;

	constructor(
		private route: ActivatedRoute,
		public global: GlobalService,
		private firestore: Firestore,
		private events: Events,
		private menuCtrl: MenuController,
		private SAnalytics: AnalyticsService,
		private router: Router,
		private modalCtrl: ModalController,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
				private platform: Platform,
	) {
		this.menuCtrl.enable(true);
        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        // localStorage.setItem("eventId", this.eventId);
        this.moduleId = params.moduleId;

        // Analytics tracking
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(
            this.eventId,
            this.moduleId,
            TypeTracking.ACCESS_TO_RANKING_MODULE
        );

        this.loadColors();
        this.language = this.SEventData.getLanguage();
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe("menuBadge", () => {
            this.menuBadge = this.global.notificationBadge;
        });

        const ref = doc(this.firestore, `modules/${this.moduleId}`);
        const subscription = docData(ref).subscribe((data: any) => {
            this.module = data;
            this.start();
        });

        this.subscriptions.push(subscription);
	}

	/**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            document.body.style.setProperty(`--background-clr`, this.eventColors.menu_color);
		    document.body.style.setProperty(`--myUser-color`, this.eventColors.menu_text_color);
        });
        this.subscriptions.push(subscription);
    }

	ngOnInit() {
		// if (this.router.url == this.SEventData.eventData.homePage) {
		// 	this.allowButtonsHeader = true;
		// }
		if (this.platform.is('ios') || this.platform.is('android')) {
			this.isMobile = true;
		}
	}

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe; });
    }

	// get attendees and points
	start() {
        const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
        const refQ = query(ref, orderBy("queryName", "asc"));

        const subscription = collectionData(refQ).subscribe((allAttendees) => {
			this.allAttendeesList = [];
			allAttendees = allAttendees.sort(function (a, b) {
				if (a.points === b.points) {
					return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
				} else {
					return a.points < b.points ? 1 : -1;
				}
			});
			for (let i = 0; i < allAttendees.length; i++) {
				let attendee = allAttendees[i];
				let position = i + 1;
				attendee.position = position;
				if (attendee.uid == this.SUserData.userId) {
					this.myUser = attendee;
				} else {
					this.allAttendeesList.push(attendee);
				}
			}
		});
        
        this.subscriptions.push(subscription);
		this.getFirstAttendees();
	}

	getFirstAttendees() {
        const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
        const qc: QueryConstraint[] = [limit(100), orderBy("points", "desc")]
        const refQ = query(ref, ...qc);

        const subscription = collectionData(refQ).subscribe((data: any) => {
			data = data.sort(function (a, b) {
				if (a.points === b.points) {
					return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
				} else {
					return a.points < b.points ? 1 : -1;
				}
				// if (a['points'] < b['points']) { return 1; }
				// if (a['points'] > b['points']) { return -1; }
				// return 0;
			});
			if (data.length > 0) {
				this.lastAttendees = data[data.length - 1];
			}
			this.attendees = [];
			for (let i = 0; i < data.length; i++) {
				let attendee = data[i];
				let position = i + 1;
				attendee.position = position;
				if (attendee.uid !== this.SUserData.userId)
					this.attendees.push(attendee);
				else this.myUser = Object.assign({}, attendee);
			}
			this.loader = false;
		});

        this.subscriptions.push(subscription);
	}

	getMoreAttendees(event) {
        const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
        const qc: QueryConstraint[] = [startAfter(this.lastAttendees.name), limit(100), orderBy("points", "desc")]
        const refQ = query(ref, ...qc);

        const subscription = collectionData(refQ).subscribe((data: any) => {
			data = data.sort(function (a, b) {
				if (a["points"] < b["points"]) {
					return 1;
				}
				if (a["points"] > b["points"]) {
					return -1;
				}
				return 0;
			});
			if (data.length > 0) {
				this.lastAttendees = data[data.length - 1];
			}

			for (let i = 0; i < data.length; i++) {
				let attendee = data[i];
				let position = i + 1;
				attendee.position = position;
				this.attendees.push(attendee);
			}
			this.loader = false;
			if (data.length > 0) {
				this.virtualScroll.checkEnd();
				event.target.complete();
			} else {
				event.target.disabled = true;
			}
		});

        this.subscriptions.push(subscription);
	}

	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			let value = ev.target.value.toLowerCase();
			this.attendees = [];
			this.allAttendeesList.filter((item) => {
				if (item.name.toLowerCase().includes(value)) {
					this.attendees.push(item);
				}
			});
		} else {
			this.getFirstAttendees();
		}
	}

	resitFilter() {
		this.attendees = [...this.allAttendeesList];
	}

	async showOrderBy() {
		if (this.isMobile) {
			const modal = await this.modalCtrl.create({
				component: PathComponent.filter_by,
				componentProps: {
					mode: this.isMobile ? "mobile" : "desktop",
					activeChoice: this.filterby
				},
				cssClass: "modal-order-by"
			});

			modal
				.onDidDismiss()
				.then((ret) => (this.filterby = ret.data.choice));
			return await modal.present();
		} else {
			this.showOrderList = !this.showOrderList;
		}
	}

	newFilterByValue(value: string) {
		if (value !== this.filterby) {
			this.filterby = value;
			this.showOrderList = false;
		}
	}
}
