import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";

import { FileService } from '../file/file.service';
import { AnalyticsService, UtilityService } from "src/app/shared/services";
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private loader: HTMLIonLoadingElement;

    constructor(
        private fileService: FileService,
        private platform: Platform,
        private SAnalytics: AnalyticsService,
        private SUtility: UtilityService,
        private SEventData: EventDataService
    ) { }

    /**
     * @param document 
     * @param eventId
     * @description Open the document provided as "document" param and on mobile devices
     * it creates a directory (EzbizEventDoc) and download the documents before open them 
     */
    public openDocument(document: any, eventId: string) {
        // Analytics access creation
        this.SAnalytics.documentAccess(eventId, document);

        let platforms = this.platform.platforms().join();
        if (platforms.includes("android") || platforms.includes("ios")) {
            if (
                this.fileService.fileFormats.includes(document.type)
            ) {
                //try {
                this.openDocMobile(document);
                // }
                // catch {
                //     this.openDoc();
                //     this.closeLoading();
                // }
            } else {
                this.openDoc(document);
            }
        }
        else {
            this.openInweb(document);
        }
    }

    /**
     * Open document in web
     * @param document
     */
    private openInweb(document) {
        window.open(document.url, "_blank");
    }

    /**
     * Download a document to the "Documents" directory and open it if possible
     * i.e. so far exel files are not possible to open
     */
    private async openDocMobile(document: any) {
        this.presentLoading();
        const language = this.SEventData.getLanguage();
        this.fileService.downloadFile(document.name[language], document.type, document.url/*, "EzbizEventDoc"*/).then((response) => {
            // if the directory already exits the promise is rejected, either way the
            // img is downloaded
            this.fileService.openFile(response.path, document.type).finally(() => {
                this.closeLoading();
            })
        }).catch((error) => {
            alert("Erro: Something went wrong. Please, try againg later.");
            this.closeLoading();
        })
    }

    /**
     * Open document of types not present on FileService.fileTypes array
     */
    private openDoc(document): void {
        let urlFinal: string = encodeURIComponent(document.url);
        window.open(document.url, "_system");
    }

    /**
     * Present loading
     */
    private async presentLoading() {
        this.loader = await this.SUtility.presentLoading("Loading...", 0, "crescent");
        await this.loader.present();
    }

    /**
     * Close loading
     */
    private async closeLoading() {
        await this.loader.dismiss();
    }
}
