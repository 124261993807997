import {sessionFeedbackQuestion} from './sessionFeedbackQuestion';

export class sessionFeedback {
    uid: string;
    module_id: string;
    title: string;
    type: string;
    references: string;
    // max_responses: number;
    change_answer: boolean;
    visibility: boolean;
    // icon: string;
    response: boolean = false;
    questions: Array<sessionFeedbackQuestion>;
    iconFamily: string;
    icon: string;

    constructor(){
        this.response = false;
        this.visibility = true;
        this.questions = [];

        this.uid = null;
        this.module_id = null;
        this.title = null;
        this.type = null;
        this.change_answer = null;
        this.references = null;
    }

    
}