export class sessionFeedbackAnswer {
    uid: string;
    answer: string;
    weight: number;
    result: number;
    createdAt: number;

    constructor(){
        this.uid = null;
        this.answer = null;
        this.weight = null;
        this.result = null;
    }

}