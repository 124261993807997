import { Injectable } from '@angular/core';
import { 
    Firestore,
    doc, 
    docData,
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoInfoboothService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    // loads the page content.
    getPage(moduleId: string, pageId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/pages/${pageId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

}
