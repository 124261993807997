import { catchError } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavParams, ModalController, AlertController, NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/providers/authentication/auth.service';
import { TypeUser } from 'src/app/models/type-user';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { GlobalService } from 'src/app/shared/services';
import { TermsNPrivacyComponent } from '../terms-n-privacy/terms-n-privacy.component';
import { PathComponent } from 'src/app/models/path/path-components';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-public-login',
    templateUrl: './public-login.component.html',
    styleUrls: ['./public-login.component.scss'],
    providers: [NavParams]
})

export class PublicLoginComponent implements OnInit, OnDestroy {
    eventId: string = null;
    logoUrl: string = null;
    insertPasswordView: boolean = false;
    activeLoader: boolean = false;
    menuColor: string = null;
    menuTxtColor: string = null;
    email: string = null;
    password: any = null;
    user;
    userType: number = null;
    clientId: string = null;
    msgEmail;
    msgPassword;
    activeLoader2: boolean = false;
    createPass: string = null;
    confirmPass: string = null;
    userUid: string = null;
    userTempId: string = null;
    codeNumber: string;
    codeNumberTyped: string = null;
    count: number;
    interval: any;
    seconds: number = 180;
    timeRemaining;
    msgTermRequired: string = null;
    msgTimeRemaining: string = null;
    msgCode: string = null;
    normalView: boolean = true;
    firstAccessView: boolean = false;
    createPassView: boolean = false;
    msgEmailAlreadyUse: string = null;
    msgCreateAccount: string = null;
    emailDisabled: boolean = false;
    termCheck: boolean;
    type: string = null;
    event: any = null;
    showPwd = false;
    splashScreen: any;

    authLogo = '';
    desktopBg = ''
    mobileBg =  ''
    isMobile = window.innerWidth < 768;
    isModal: boolean = false;
    subscriptions: Subscription[] = [];

    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private events: Events,
        private translateService: TranslateService,
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private navCtrl: NavController,
        public global: GlobalService,
        private alertCtrl: AlertController,
        private userData: UserDataService,
        private SEventData: EventDataService
    ) {
        const url = this.route.snapshot.url
        this.isModal = !url.find((urlSegment) => (urlSegment.path == 'public_login'));
        
        if (this.isModal) {
            this.eventId = this.navParams.get('eventId');
            this.logoUrl = this.navParams.get('logoUrl');
            this.menuColor = this.navParams.get('menuColor');
            this.menuTxtColor = this.navParams.get('menuTxtColor');
        } else {
            this.eventId = this.route.snapshot.params['eventId'];
        }

        const edSubs = this.SEventData.getEventDataListener(this.eventId).subscribe((eventData) => {
            this.authLogo = eventData['authLogo']
            this.desktopBg = eventData['desktopBg']
            this.mobileBg = eventData['mobileBg']
        });
        this.subscriptions.push(edSubs);
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.subscriptions.map((sub) => { sub.unsubscribe(); });
    }

    verifyEmail() {
        if (this.email !== null) {
            this.activeLoader = true;
            this.auth.verifyEmailDb(this.email, (user) => {
                if (user['code'] !== 404) {
                    this.user = user['result'];
                    this.userType = user['result']['type'];
                    this.emailDisabled = true;
                    // verify if type is client or employee to get client id and display correct events later
                    if (this.userType == TypeUser.CLIENT) {
                        this.clientId = user['result']['uid'];
                    } else if (this.userType == TypeUser.EMPLOYEE) {
                        this.clientId = user['result']['clientId'];
                    }
                    // case e-mail exists and is first access, display verify code view;
                    if (user['result'] !== 'email-not-found') {
                        if (user['message'] == 'success') {
                            if (user['result']['firstAccess'] == false) {
                                this.insertPasswordView = true;
                                // this.passwordInput.nativeElement.focus();
                                this.activeLoader = false;
                            } else {
                                // case first access, send code number and create account
                                this.normalView = false;
                                this.firstAccessView = true;
                                //this.createCodeNumber();
                                this.activeLoader = false;
                            }
                        }
                    } else {
                        this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                        this.emailDisabled = false;
                        this.activeLoader = false;
                    }
                } else {
                    this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                    this.activeLoader = false;
                }
            });
        } else {
            this.msgEmail = this.translateService.instant('pages.login.blank_email');
        }

    }

    // createCodeNumber() {
    //     // call function to create code, insert in user database and send e-mail to user;
    //     this.auth.sendCodeNumberToEmail(this.user, (data) => {

    //         if (data !== false) {
    //             this.codeNumber = data;
    //             this.userTempId = this.user['uid'];

    //             this.count = this.seconds;
    //             this.interval = setInterval(() => {
    //                 this.count--;

    //                 var date = new Date(null);
    //                 date.setSeconds(this.count); // specify value for SECONDS here
    //                 this.timeRemaining = date.toISOString().substr(11, 8);

    //                 if (this.count == 0) {
    //                     clearInterval(this.interval);
    //                     this.msgTimeRemaining = this.translateService.instant('pages.login.resend_code_number');
    //                 }
    //             }, 1000);
    //             this.activeLoader2 = false;
    //         } else {
    //             this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
    //         }
    //     })
    // }

    // /* in case of first access, the user needs to verify your identity 
    // by code number sended to e-mail and create your account */
    // verifyCodeNumber() {
    //     if (this.codeNumberTyped !== null) {
    //         this.activeLoader = true;
    //         this.auth.verifyCodeNumber(this.codeNumberTyped, this.userTempId, (data) => {
    //             if (data['result'] == true) {
    //                 clearInterval(this.interval);
    //                 this.firstAccessView = false;
    //                 this.createPassView = true;
    //                 this.activeLoader = false;
    //             } else if (data['result'] == false) {
    //                 this.msgCode = this.translateService.instant('pages.login.invalid_code');
    //                 this.activeLoader = false;
    //             } else {
    //                 // not ok
    //                 this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
    //                 this.activeLoader = false;
    //             }
    //         });
    //     } else {
    //         this.msgCode = 'O código não pode estar vazio'
    //     }
    // }

    /* to create account, the user enter your password two times, check both 
  is equal and add to Firebase Authentication and update in Firestore */
    createAccount() {
        this.msgPassword = null;
        console.log(this.email, this.createPass, this.user.uid, this.userType)
        if (this.createPass.length >= 6) {
            if (this.createPass == this.confirmPass) {
                if (this.termCheck) {
                    this.activeLoader = true;
                    this.auth.createAccount(this.email, this.createPass, this.user.uid, this.userType).then((success) => {
                        if (success['message'] == 'error') {
                            if (success['code'] == "auth/email-already-in-use") {
                                this.activeLoader = false;
                                this.msgEmailAlreadyUse = this.translateService.instant('pages.login.email_already_use');
                            } else {
                                this.activeLoader = false;
                                //this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                                this.msgCreateAccount = success['code'];
                            }
                        } else if (success['message'] == 'success') {
                            // call API sending old uid and new uid; 
                            this.password = this.createPass;
                            this.makeLogin();
                        }
                    }, (error) => {
                        this.activeLoader = false;
                        this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                    })
                } else {
                    this.activeLoader = false;
                    this.msgPassword = this.translateService.instant('pages.login.term_check_required');
                }
            } else {
                this.activeLoader = false;
                this.msgPassword = this.translateService.instant('pages.login.password_not_match');
            }
        } else {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
        }
    }

    /**
     * @description redirect user to the public event, after he/she is created
     * and sign up in the event
     * @param data 
     */
    redirectToPublicEvent = (data: any): void => {
        if (!data) {
            this.msgPassword = this.translateService.instant("pages.login.generic_error");
            this.activeLoader = false;
            this.auth.logout();
            return;
        }

        Promise.all([
            this.SEventData.getEventDataSnapshot(this.eventId),
            this.userData.getUserDataSnapshot(this.eventId)
        ]).then(([eventData, userData]) => {
            localStorage.setItem('userIdentifier', userData.uid);
            this.close();
            if (eventData.firstRegistration && !userData.edited_profile) {
                this.userData.mustEditProfile = true;
                this.navCtrl.navigateRoot(`edit_profile/${this.eventId}/${userData.moduleId}/${userData.type}/${userData.uid}`);
            } else {
                this.navCtrl.navigateRoot(eventData.homePage);
            }
        }).catch((error) => {
            console.error(error);
            this.msgPassword = this.translateService.instant("pages.login.generic_error");
        }).finally(() => {
            this.activeLoader = false;
        });
    }

    /**
     * @description a callback method intendeed to be passed as an argument to 
     * the 'this.auth.verifyEmailDb()' method
     * @param user 
     */
    verifyEmailDb = (user): void => {
        this.user = user.result;
        if (user.result === 'email-not-found') {
            this.auth.createUserPublicEvent(
                this.eventId,
                this.user.name,
                this.email,
                this.password,
                this.SEventData.getLanguage(true),
                this.redirectToPublicEvent
            );
        } else {
            this.activeLoader = false;
            if (this.user.firstAccess === true) {
                this.firstAccessView = true;
                // this.attendeeFirstAccess = true;
            }
            // this.msgEmailExisted = this.translateService.instant('pages.login.first_access_txt_01');
            // this.msgEmail = this.translateService.instant('pages.login.account_already_exist_with_email');
        }
    }

    makeLogin() {
        this.msgPassword = null;
        this.activeLoader = true;
        this.email = this.email.toLowerCase();

        if (!this.password) {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
            return;
        } else if (!this.termCheck) {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.term_check_required');
            return;
        }


        this.auth.login(this.email, this.password, (data) => {
            if (!data) {
                this.msgPassword = this.translateService.instant('pages.login.generic_error')
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/wrong-password') {
                this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/too-many-requests') {
                this.msgEmail = this.translateService.instant('pages.login.account_disabled_many_request');
                this.activeLoader = false;
                return;
            } else if (data.code == 'auth/argument-error') {
                this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                this.activeLoader == false;
                return;
            }

            let index = this.user['events'].map(function (e) { return e; }).indexOf(this.eventId);
            if (index > -1) {
                this.redirectToPublicEvent(data);
            } else {
                // this.auth.verifyEmailDb(this.email, this.verifyEmailDb);
                const params = {
                    eventId: this.eventId,
                    // name: this.name,
                    email: data.user.email,
                    language: this.SEventData.getLanguage(true),
                    providerId: "password",
                    uid: data.user.uid
                };
                this.auth.signUpToPublicEvent(params).then(() => {
                    this.redirectToPublicEvent(data)
                }).catch((error) => { 
                    console.error(error);
                    this.msgPassword = this.translateService.instant('pages.login.generic_error');
                    this.activeLoader = false;
                    this.auth.logout();
                });
            }
        })
    }

    // recoveryPassword() {
    //     if (this.email !== null && this.email !== undefined && this.email !== null) {
    //         this.auth.recoveryPassword(this.email, (status) => {
    //             if (status == true) {
    //             } else {

    //             }
    //         });
    //     } else {
    //         // blank email error
    //     }
    // }

    async recoveryPassword() {
        const prompt = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: this.translateService.instant('pages.login.recovery_pass_txt'),
            inputs: [
                {
                    name: 'email',
                    placeholder: this.translateService.instant('pages.login.your_email_plc'),
                    value: this.email,
                    type: 'email'
                },
            ],
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.cancel'),
                    handler: data => {
                        // cancel operation
                    }
                },
                {
                    text: this.translateService.instant('pages.login.recovery_btn'),
                    handler: data => {
                        // check e-mail and recovery password
                        if (data.email !== null && data.email !== undefined && data.email !== null) {
                            this.auth.checkAuthEmail(data.email, (status) => {
                                if (status) {
                                    this.auth.recoveryPassword(data.email, (status) => {
                                        if (status == true) {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_success'))
                                        } else {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_fail'))
                                        }
                                    });
                                } else {
                                    // e-mail não encontrado
                                    this.alertRecoveryPass(this.translateService.instant('pages.login.email_not_found'))
                                }
                            })
                        } else {
                            // blank email error
                            this.alertRecoveryPass(this.translateService.instant('pages.login.blank_email'));
                        }
                    }
                }
            ]
        });
        prompt.present();
    }

    async alertRecoveryPass(message) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: message,
            buttons: [this.translateService.instant('global.buttons.ok')]
        });

        await alert.present();
    }

    async openTermsOfUseAndPrivacy(type) {
        const modal = await this.modalCtrl.create({
            component: TermsNPrivacyComponent,
            componentProps: {
                eventId: this.eventId,
                type: type
            }
        });
        return await modal.present();
    }

    async openPublicRegister() {
        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        if (!eventData) { return; }

        this.close();
        const modal = await this.modalCtrl.create({
            component: PathComponent.public_register,
            componentProps: {
                eventId: this.eventId,
                logoUrl: eventData.logo.url,
                menuColor: this.menuColor,
                menuTxtColor: this.menuTxtColor
            }
        });
        return await modal.present();
    }

    close() {
        if (this.isModal) {
            this.modalCtrl.dismiss();
        } 
        // else {
        //     this.navCtrl.back()
        // }
    }

    /*closing the modal and refreshing the whole page*/
    closeWithReloading() {
        window.location.reload();
        this.modalCtrl.dismiss();
    }

    signInWithFacebook() {
        alert('Funcionalidade em desenvolvimento! ;)')
    }
    signInWithTwitter() {
        alert('Funcionalidade em desenvolvimento! ;)')
    }
    signInWithGoogle() {
        alert('Funcionalidade em desenvolvimento! ;)')
    }
    signInWithMicrosoft() {
        alert('Funcionalidade em desenvolvimento! ;)')
    }
    signInWithYahoo() {
        alert('Funcionalidade em desenvolvimento! ;)')
    }
    showHidePwd() {
        this.showPwd = !this.showPwd;
    }
}
