import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoWordCloudService } from 'src/app/providers/db/dao-word-cloud.service';
import { WordCloud } from 'src/app/models/wordCloud';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-word-cloud-list',
    templateUrl: './word-cloud-list.page.html',
    styleUrls: ['./word-cloud-list.page.scss'],
})
export class WordCloudListPage implements OnInit, OnDestroy {

    eventId: string = null;
    moduleId: string = null;
    userId: string = null;

    allowButtonsHeader: boolean = false;

    eventColors: EventColors = new EventColors();
    
    loader: boolean = true;
    moduleName: string = null;

    clouds: Array<WordCloud> = [];
    module;

    language: string;
    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public global: GlobalService,
        private menuCtrl: MenuController,
        // private events: Events,
        private daoWordCloud: DaoWordCloudService,
        private zone: NgZone,
        private SAnalytics: AnalyticsService,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        
        this.loadColors();
        this.language = this.SEventData.getLanguage();
    }

    ngOnInit() {
        this.daoWordCloud.getModule(this.moduleId, (module) => {
            this.module = module;
            this.moduleName = module.name;
        })

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.zone.run(() => {
        //         this.allowButtonsHeader = true;
        //     })
        // }

        this.getWordClouds();
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    getWordClouds() {
        this.daoWordCloud.getClouds(this.moduleId, (clouds) => {
            this.clouds = [];
            this.clouds = clouds;
        })
    }

    openItem(item) {
        this.router.navigate([`/event/${this.eventId}/word-cloud/${this.moduleId}/${item.uid}`]);
    }
}
