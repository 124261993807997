import { Injectable } from '@angular/core';
import { sessionFeedback } from 'src/app/models/sessionFeedback';
import { sessionFeedbackQuestion } from 'src/app/models/sessionFeedbackQuestion';
import { sessionFeedbackAnswer } from 'src/app/models/sessionFeedbackAnswer';
import { TypeModule } from 'src/app/models/type-module';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { of, combineLatest, firstValueFrom } from 'rxjs';
import { Firestore, collection, collectionData, deleteDoc, doc, docData, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoSessionFeedbackService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    getFeedbackModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.SESSION_FEEDBACK));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    /**
     * Get feedbacks
     * @param moduleId 
     */
    getFeedbacks(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/session-feedbacks`);
        const refQ = query(ref, orderBy('order', 'asc'));

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                let listFeedbacks: sessionFeedback[] = [];
                if (docs && docs.length > 0) {
                    for (let i = 0; i < docs.length; i++) {
                        let feedback = this.instantiateFeedback(docs[i]);
                        if (feedback.visibility) {
                            feedback.questions.sort(function (a, b) {
                                if (a.createdAt < b.createdAt) {
                                    return -1;
                                }
                                if (a.createdAt > b.createdAt) {
                                    return 1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            listFeedbacks.push(feedback);
                        }
                    }
                    return (of(listFeedbacks));
                } else {
                    return (of([]));
                }
            })
        );
    }

    getQuestions(moduleId: string, feedbackId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/session-feedbacks/${feedbackId}/questions`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));
        
        let listQuestions: Array<sessionFeedbackQuestion> = [];

        firstValueFrom(collectionData(refQ))
            .then((data) => {
                data.forEach(element => {
                    let question = this.instantiateQuestion(element);
                    this.getAnswers(moduleId, feedbackId, question.uid).subscribe((answers) => {
                        question.answers = answers;
                        listQuestions.push(question);
                        onResolve(listQuestions)
                    })

                });
            })
    }

    /**
     * Get answers
     * @param moduleId 
     * @param feedbackId 
     * @param questionId 
     */
    getAnswers(moduleId: string, feedbackId: string, questionId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/session-feedbacks/${feedbackId}/questions/${questionId}/answers`);
        const refQ = query(ref, orderBy('createdAt', 'asc'));

        return (collectionData(refQ).pipe(
            take(1),
            map((docs) => {
                return (docs.map((doc) => {
                    const answer: sessionFeedbackAnswer = this.instantiateAnswer(doc);
                    return (answer);
                }))
            })
        ));
    }

    /**
     * Check sepecific track feedback
     * @param trackModuleId 
     * @param sessionId 
     * @param tracksIds 
     * @param onResolve 
     */
    checkSpecificTrackFeedback(trackModuleId, sessionId, tracksIds, onResolve) {
        let list = [];
        for (let trackId of tracksIds) {
            this.checkTrackFeedback(trackModuleId, sessionId, trackId).subscribe((result: boolean) => {
                list.push(result);

                if (list.length == tracksIds.length) {
                    onResolve(list);
                }
            })
        }
        return (combineLatest())
    }

    /**
     * Check track feedback
     * @param moduleId 
     * @param sessionId 
     * @param trackId 
     */
    checkTrackFeedback(moduleId, sessionId, trackId) {
        const ref = doc(this.firestore, `modules/${moduleId}/tracks/${trackId}/sessions/${sessionId}`);
        return docData(ref).pipe(
            take(1),
            switchMap((doc) => {
                if (doc) {
                    return (of(true));
                } else {
                    return (of(false));
                }
            })
        );
    }

    // VERIFICA SE A SESSÃO ESPECIFICA CORRESPONDE AO FEEDBACK
    // sessionOfFeedback(eventId, quizId, onResolve) {
    //     this.db.ref(this.SURVEY_CHILD).child(eventId).child(quizId).child('references').once('value', (snapshot) => {
    //         const data = snapshot.val();
    //         let sessionIds = [];

    //         for (const uid in data) {
    //             sessionIds.push(uid);
    //         }
    //         onResolve(sessionIds);
    //     });
    // }

    listgroupsOfAttendee(eventId: string, attendeeId: string, onResolve) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        firstValueFrom(docData(ref))
            .then((attendee) => {
                let groups = attendee['groups'];
                let idsGroups: Array<String> = [];

                if (groups !== null && groups !== undefined) {
                    for (let uid in groups) {
                        idsGroups.push(uid);
                    }
                }

                onResolve(idsGroups);
            })
    }

    /**
     * Create a result
     * @param moduleId 
     * @param sessionId 
     * @param userId 
     * @param feedbackId 
     * @param IdQuestion 
     * @param type 
     * @param answer 
     * @param timestamp 
     * @param onResolve 
     */
    createResult(moduleId: string, sessionId: string, userId: string, feedbackId: string, IdQuestion: string, type: string, answer: any, timestamp: number, onResolve) {
        const questionResultPath = `modules/${moduleId}/session-feedbacks/${feedbackId}/questions/${IdQuestion}/result`;
        let ref;

        if (type == "oneSelect" || type == "multipleSelect") {
            if (userId !== null && userId !== undefined && userId !== '') {
                this.getResponseUserQuestion(moduleId, sessionId, feedbackId, IdQuestion, userId).subscribe((responses) => {
                    this.deletePrevAnswersSelectOption(moduleId, feedbackId, IdQuestion, userId, sessionId, (result) => {
                        if (result) {
                            ref = doc(this.firestore, `${questionResultPath}/${userId}/sessions/${sessionId}`);
                            setDoc(
                                ref,
                                {
                                    user: userId,
                                    answer: answer,
                                    timestamp: timestamp,
                                    session: sessionId,
                                    question: IdQuestion
                                }
                            ).then(() => {
                                ref = doc(this.firestore, `${questionResultPath}/${userId}`);
                                setDoc(ref, { uid: userId }).then(() => {
                                    if (responses === null || responses === undefined) {
                                        onResolve(true);
                                    } else {
                                        onResolve(false);
                                    }
                                });
                            });
                        } else {
                            onResolve(false);
                        }
                    })
                })
            } else {
                const refColl = collection(this.firestore, questionResultPath);
                userId = doc(refColl).id;
                ref = doc(this.firestore, `${questionResultPath}/${userId}/sessions/${sessionId}`);
                
                setDoc(
                    ref,
                    {
                        user: userId,
                        answer: answer,
                        timestamp: timestamp,
                        session: sessionId,
                        question: IdQuestion
                    }
                ).then(() => {
                    ref = doc(this.firestore, `${questionResultPath}/${userId}`);
                    setDoc(ref, { uid: userId }).then(() => {
                        onResolve(true);
                    })
                });
            }
        } else {
            if (userId !== null && userId !== undefined && userId !== '') {
                ref = doc(this.firestore, `${questionResultPath}/${userId}/sessions/${sessionId}`);
                setDoc(
                    ref,
                    {
                        question: IdQuestion,
                        session: sessionId,
                        user: userId,
                        answer: answer,
                        timestamp: timestamp
                    }
                ).then(() => {
                    ref = doc(this.firestore, `${questionResultPath}/${userId}`);
                    setDoc(ref, { uid: userId }).then(() => {
                        onResolve(true);
                    })
                });
            } else {
                const refColl = collection(this.firestore, questionResultPath);
                userId = doc(refColl).id;
                ref = doc(this.firestore, `${questionResultPath}/${userId}/sessions/${sessionId}`);
                
                setDoc(
                    ref,
                    {
                        question: IdQuestion,
                        session: sessionId,
                        user: userId,
                        answer: answer,
                        timestamp: timestamp
                    }
                ).then(() => {
                    ref = doc(this.firestore, `${questionResultPath}/${userId}`);
                    setDoc(ref, { uid: userId }).then(() => {
                        onResolve(true);
                    })
                });
            }
        }
    }

    /**
     * Get response users
     * @param moduleId 
     * @param sessionId 
     * @param feedbackId 
     * @param userId 
     * @param onResolve 
     */
    getResponseUsers(moduleId, sessionId, feedbackId, userId, onResolve) {
        this.getQuestions(moduleId, feedbackId, (questions) => {
            let listResults = {};
            for (let i = 0; i < questions.length; i++) {
                this.getResponseUserQuestion(moduleId, sessionId, feedbackId, questions[i].uid, userId).subscribe((response) => {
                    if (response != null) {
                        listResults[response.question] = response;
                    }

                    if (i == questions.length - 1) {
                        onResolve(listResults);
                    }
                })
            }
        })
    }

    /**
     * Get response user question
     * @param moduleId 
     * @param sessionId 
     * @param feedbackId 
     * @param questionId 
     * @param userId 
     */
    getResponseUserQuestion(moduleId, sessionId, feedbackId, questionId, userId) {
        const ref = doc(
            this.firestore, 
            `modules/${moduleId}/session-feedbacks/${feedbackId}/questions/${questionId}/result/${userId}/sessions/${sessionId}`
        );
        return docData(ref).pipe(take(1));
    }

    deletePrevAnswersSelectOption(moduleId, feedbackId, IdQuestion, userId, sessionId, onResolve) {
        const ref = doc(
            this.firestore,
            `modules/${moduleId}/session-feedbacks/${feedbackId}/questions/${IdQuestion}/result/${userId}/sessions/${sessionId}`
        );
        deleteDoc(ref).then(() => {
            onResolve(true)
        }).catch((e) => {
            onResolve(false);
        })
    }

    instantiateFeedback(data) {
        let feedback = new sessionFeedback();
        feedback.title = data.title;
        feedback.type = data.type;
        feedback.uid = data.uid;
        feedback.visibility = data.visibility;
        feedback.change_answer = data.change_answer;
        feedback.icon = data.icon;
        feedback.iconFamily = data.iconFamily;
        // data.max_responses != undefined ? feedback.max_responses = data.max_responses : feedback.max_responses = null;
        data.module_id != undefined ? feedback.module_id = data.module_id : feedback.module_id = null;
        data.references != undefined ? feedback.references = data.references : feedback.references = null;

        let questions: Array<sessionFeedbackQuestion> = [];

        for (let questionId in data.questions) {
            if (data.questions[questionId].type == 'oneSelect' || data.questions[questionId].type == 'multipleSelect') {
                let listAnswers = data.questions[questionId].answers;
                delete data.questions[questionId].answers;
                let question: sessionFeedbackQuestion = this.instantiateQuestion(data.questions[questionId]);

                for (let answerId in listAnswers) {
                    let answer: sessionFeedbackAnswer = this.instantiateAnswer(listAnswers[answerId])
                    question.answers.push(answer);
                }

                question.answers.sort(function (a, b) {
                    if (a.createdAt < b.createdAt) {
                        return -1;
                    }
                    if (a.createdAt > b.createdAt) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });

                questions.push(question);

            } else {
                let question: sessionFeedbackQuestion = this.instantiateQuestion(data.questions[questionId]);
                questions.push(question);
            }
        }

        feedback.questions = questions;

        return feedback;
    }

    instantiateQuestion(data) {
        let question = new sessionFeedbackQuestion();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = '';
        question.points = data.points;
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        question.createdAt = data.createdAt;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new sessionFeedbackAnswer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        answer.createdAt = data.createdAt;

        return answer;
    }
}