export class EventFieldsVisibility {
    date: boolean;
    description: boolean;
    supportEmail: boolean;
    site: boolean;
    location: boolean;

    constructor() {
        this.date = true;
        this.description = true;
        this.supportEmail = true;
        this.site = true;
        this.location = true;
    }

}