import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { AlertController, Platform, MenuController, ModalController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { DaoCheckinService } from 'src/app/providers/db/dao-checkin.service';
import { GlobalService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { TypeVisionCheckin } from 'src/app/enums/type-vision-checkin';
import { PathComponent } from 'src/app/models/path/path-components';
import { BarcodeScannerService } from 'src/app/providers/barcode-scanner/barcode-scanner.service';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-checkin-detail',
    templateUrl: './checkin-detail.page.html',
    styleUrls: ['./checkin-detail.page.scss'],
    providers: [DaoCheckinService]
})

export class CheckinDetailPage implements OnInit, OnDestroy {
    public module = null

    checkinId: string;
    moduleId: string;
    eventId: string;
    attendees: Array<CeuAttendee> = [];
    attendeesPresent: number = 0;
    attendeesAway: number = 0;
    loader: boolean = true;
    attendeeChange: CeuAttendee;
    public subscription;
    // http
    public headers;
    public requestOptions;
    backBtn: boolean = true;
    searchOpen: boolean = false;
    searchText: string = '';
    cancelTxt: string = null;
    enableChangeOrder: boolean = false // controls the changeOrder function
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    typeOrder: string = null;
    typeVisionListing: TypeVisionCheckin;
    groups = {};

    firstAccessRealtime: boolean = true;
    allAttendeesList: Array<CeuAttendee> = [];
    isMobile: boolean = false;
    showOrderList = false;
    subscriptions: Subscription[] = [];
    language: string;

    constructor(
        private route: ActivatedRoute,
        private DaoCheckin: DaoCheckinService,
        public alertController: AlertController,
        public platform: Platform,
        private router: Router,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private translateService: TranslateService,
        private zone: NgZone,
        private modalCtrl: ModalController,
        public scan: BarcodeScannerService,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(true);
        const params = this.route.snapshot.params;
        this.checkinId = params.checkinId;
        this.moduleId = params.moduleId;
        this.eventId = params.eventId;
        this.loadColors();

        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.zone.run(() => {
                this.menuBadge = this.global.notificationBadge;
            })
        });

        this.loadModule();
        this.getAttendees();
        // this.events.subscribe('languageUpdate', () => {
        //     this.zone.run(() => {
        //         this.loadModule();
        //         this.getAttendees();
        //     });
        // });

        this.cancelTxt = this.translateService.instant('global.buttons.cancel');
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }


    ngOnInit() {
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        this.loadModule();
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ionViewDidEnter() {
        this.subscription = this.platform.backButton.subscribe(() => {
            this.router.navigate([`/events/${this.eventId}/checkin-details/${this.moduleId}/${this.checkinId}`])
        });

        this.DaoCheckin.getCheckin(this.checkinId, this.moduleId, (data) => {
            this.enableChangeOrder = false
            this.typeOrder = data['typeOrder'];
            this.typeVisionListing = (data.typeVisionListing != undefined) ? data.typeVisionListing : TypeVisionCheckin.GLOBAL_VISION;
            this.groups = (data.groups != undefined) ? data.groups : {};
            this.getAttendees();
        })
    }


    /**
     *load the module.
     */
    loadModule() {
        this.DaoCheckin.getModule(this.moduleId, (module) => {
            this.module = module;
        });
    }

    /**
     * carries the participants. 
     */
    getAttendees() {
        // Checks the type of view of the checkin.
        if (this.typeVisionListing === TypeVisionCheckin.GLOBAL_VISION) {
            //a-z
            if (this.typeOrder === 'asc') {
                this.DaoCheckin.loadCheckinGlobalOrderAsc(this.checkinId, this.moduleId, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.allAttendeesList = this.attendees
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                    this.loader = false
                })
            }

            //z-a
            if (this.typeOrder === 'desc') {
                this.DaoCheckin.loadCheckinGlobalOrderDesc(this.checkinId, this.moduleId, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.allAttendeesList = this.attendees
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                    this.loader = false
                })
            }

            //present
            if (this.typeOrder === 'present') {
                this.DaoCheckin.loadCheckinGlobalOrderPresent(this.checkinId, this.moduleId, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.loader = false
                })

                this.DaoCheckin.loadCheckinGlobalAllAttendees(this.checkinId, this.moduleId, (data) => {
                    this.allAttendeesList = this.arrangesAllAttendees(data)
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true

                })
            }

            //away
            if (this.typeOrder === 'away') {
                this.DaoCheckin.loadCheckinGlobalOrderAway(this.checkinId, this.moduleId, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.loader = false
                })

                this.DaoCheckin.loadCheckinGlobalAllAttendees(this.checkinId, this.moduleId, (data) => {
                    this.allAttendeesList = this.arrangesAllAttendees(data)
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                })
            }
        } 
        else {
            let auxGroups = []
            for (const uid in this.groups) {
                auxGroups.push(this.groups[uid])
            }

            //a-z
            if (this.typeOrder === 'asc') {
                this.DaoCheckin.loadCheckinGroupVisionOrderAsc(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.allAttendeesList = this.attendees
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                    this.loader = false
                })
            }

            //z-a
            if (this.typeOrder === 'desc') {
                this.DaoCheckin.loadCheckinGroupVisionOrderDesc(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.allAttendeesList = this.attendees
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                    this.loader = false
                })
            }

            //present
            if (this.typeOrder === 'present') {
                this.DaoCheckin.loadCheckinGroupVisionOrderPresent(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.loader = false
                })

                this.DaoCheckin.loadCheckinGroupVisionAllAttendees(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.allAttendeesList = this.arrangesAllAttendees(data)
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                })
            }

            //away
            if (this.typeOrder === 'away') {
                this.DaoCheckin.loadCheckinGroupVisionOrderAway(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.attendees = this.arrangesAttendees(data)
                    this.loader = false
                })

                this.DaoCheckin.loadCheckinGroupVisionAllAttendees(this.checkinId, this.moduleId, auxGroups, (data) => {
                    this.allAttendeesList = this.arrangesAllAttendees(data)
                    this.attendeesPresent = this.countOccurrencesStatus(this.allAttendeesList, true)
                    this.attendeesAway = this.countOccurrencesStatus(this.allAttendeesList, false)
                    this.enableChangeOrder = true
                })
            }
        }
    }

    /** *
    *
    * arranges data from participants.
      @param attendees 
    * @return attendees  
    */
    arrangesAttendees(data) {
        this.attendees = []
        for (const attendee of data) {
            var letters = attendee.name.split('');
            var letter = letters[0].toUpperCase();
            attendee.letter = letter;
            attendee.principal_title = attendee.title[this.SEventData.getLanguage()];
            this.attendees.push(attendee)
        }

        return this.attendees
    }

    /** *
   *
   * arranges data from participants.
     @param attendees 
   * @return attendees  
   */
    arrangesAllAttendees(data) {
        this.allAttendeesList = []
        for (const attendee of data) {
            var letters = attendee.name.split('');
            var letter = letters[0].toUpperCase();
            attendee.letter = letter;
            attendee.principal_title = attendee.title[this.SEventData.getLanguage()];
            this.allAttendeesList.push(attendee)
        }

        return this.allAttendeesList
    }

    /** *
     counts the occurrence number of the status passed in the parameter. Used to count the number of participants present and absent.
      @param attendees 
      @param attendees 
      @return number  
    */
    countOccurrencesStatus(attendees: Array<any>, status: boolean) {
        let cont = 0

        for (const attendee of attendees) {
            if (attendee.checkinStatus === status)
                cont++
        }

        return cont
    }

    /**
     * opens a modal asked about the status change.
     **/
    async confirmAttendeeChangeStatus(attendee: CeuAttendee) {
        this.attendeeChange = attendee;
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.change_status'),
            message: this.translateService.instant('global.alerts.change_status_confirm_msg') + ' ' + attendee.name + '?',
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.changeAttendeeStatus();
                    },
                },
                {
                    text: this.translateService.instant('global.buttons.no'),
                    handler: (_) => {
                    }
                }
            ]
        });

        await alert.present();
    }

    /**
     * Change attendee status.
     */
    changeAttendeeStatus() {
        let status = this.attendeeChange.checkinStatus ? false : true

        this.DaoCheckin.changeAttendeeStatus(status, this.checkinId, this.moduleId, this.attendeeChange.uid, this.attendeeChange.moduleId, this.eventId, (result: boolean) => {
            if (!result) {
                // failed
                this.errorManualAlert(this.attendeeChange.name);
            } else {
                // success
                this.successManualAlert(this.attendeeChange.name);
            }
        });
    }

    changeOrder() {
        if (this.typeOrder !== 'asc')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderAsc()
        if (this.typeOrder !== 'desc')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderDesc()
        if (this.typeOrder !== 'present')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderPresent()
        if (this.typeOrder !== 'away')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderAway()
        if (this.typeOrder !== 'asc')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAsc()
        if (this.typeOrder !== 'desc')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderDesc()
        if (this.typeOrder !== 'present')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderPresent()
        if (this.typeOrder !== 'away')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAway()

        this.getAttendees()
    }

    /**
     * returns the first letter of the participant's name.
     */
    attendeeHeader(attendee, i, attendees) {
        if (i == 0) {
            return attendee.letter;
        } else if (i != 0 && attendee.letter != attendees[i - 1].letter) {
            return attendee.letter;
        }
    }

    /**
     * 
     */
    openQr(): void {
        this.scan.scanSingleQRCode().then((result) => {
            this.findAttendeeExistWithIdentifier(result).then((exist) => {
                if (exist['status'] === false) {
                    // user not found in this event
                    this.userNotFoundAlert();
                } else {
                    // user found, change status to true (present);
                    this.changeUserCheckinStatus(exist['user']);
                }
            });
            // this.findAttendeeExist(result)
        })
    }

    /**
     * Find Attendee Exist In Event.
    */
    findAttendeeExist(uid: string) {
        return new Promise((resolve) => {
            this.DaoCheckin.findAttendeeExistInEvent(this.eventId, uid, (exist) => {
                if (exist.status == false) {
                    resolve({
                        user: null,
                        status: false
                    });
                } else {
                    resolve({
                        user: exist.user,
                        status: true
                    });
                }
            });
        });
    }


    /**
     * Find Attendee with "identifier" Exist In Event.
    */
    findAttendeeExistWithIdentifier(identifier: string) {
        return new Promise((resolve) => {
            this.DaoCheckin.findAttendeeExistInEventWithIdentifier(this.eventId, identifier, (exist) => {
                if (exist.status == false) {
                    resolve({
                        user: null,
                        status: false
                    });
                } else {
                    resolve({
                        user: exist.user,
                        status: true
                    });
                }
            });
        });
    }

    /**
    * Change attendee status.
    */
    changeUserCheckinStatus(user) {
        this.DaoCheckin.changeAttendeeStatus(true, this.checkinId, this.moduleId, user.uid, user.moduleId, this.eventId, (status) => {
            if (!status) {
                // show error message 
                this.userNotFoundAlert();
            } else {
                // show success message
                this.successAlert(user.name);
            }
        })
    }

    /**
   * shows the success alert on changing status of participants.
   */

    async successAlert(username: string) {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.all_right'),
            message: this.translateService.instant('global.alerts.all_right') + ' ' + username + ' ' + this.translateService.instant('global.alerts.confirmed_presence'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.translateService.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                        // this.reloadAttendeesWithoutLoader();
                    }
                }
            ]
        });

        await alert.present();
    }

    /**
    * shows the success alert on changing status of participants.
    */
    async successManualAlert(username: string) {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.all_right'),
            message: this.translateService.instant('global.alerts.the_status_changed') + ' ' + username + ' ' + this.translateService.instant('global.alerts.status_changed_successfully'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (_) => {
                        //
                        // this.reloadAttendeesWithoutLoader();
                    },
                }
            ]
        });

        await alert.present();
    }


    async errorManualAlert(username: string) {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.error'),
            message: this.translateService.instant('global.alerts.not_possible_change_status') + ' ' + username + ' ' + this.translateService.instant('global.alerts.please_tryagain'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (_) => {
                        //
                    },
                }
            ]
        });

        await alert.present();
    }


    /**
    * Display the user alert not found in this event.
    */
    async userNotFoundAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.error'),
            message: this.translateService.instant('global.alerts.attendee_not_found'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.translateService.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                    }
                }
            ]
        });

        await alert.present();
    }

    async invalidQrAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.error_scanning'),
            message: this.translateService.instant('global.alerts.invalid_qrcode'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.translateService.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                    }
                }
            ]
        });

        await alert.present();
    }

    searchBar(ev) {
        if (ev.target.value.length >= 1) {
            let value = ev.target.value.toLowerCase();
            this.attendees = [];
            this.allAttendeesList.filter(item => {
                if (item.name.toLowerCase().includes(value)) {
                    this.attendees.push(item);
                }
            })
        } else {
            this.attendees = this.allAttendeesList;
        }
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe()

        this.DaoCheckin.closeRefLoadCheckinGlobalOrderAsc()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderDesc()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderPresent()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderAway()

        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAsc()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderDesc()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderPresent()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAway()

        this.DaoCheckin.closeRefGetModule()
        this.DaoCheckin.closeRefLoadCheckinGlobalAllAttendees()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionAllAttendees()
        this.DaoCheckin.closeRefGetCheckin()
    }

    headerHeightFn(item, index) {
        return (40);
    }

    itemHeightFn(item, index) {
        return (85);
    }

    async showOrderBy() {
        if (this.isMobile) {
            const modal = await this.modalCtrl.create({
                component: PathComponent.filter_by,
                componentProps: {
                    mode: this.isMobile ? 'mobile' : 'desktop',
                    activeChoice: this.typeOrder,
                    comp: 'checkin'
                },
                cssClass: 'modal-order-by'
            });

            modal.onDidDismiss().then(ret => {
                this.typeOrder = ret.data.choice
                this.changeOrder()
            })
            return await modal.present();
        } else {
            this.showOrderList = !this.showOrderList;
        }
    }

    newFilterByValue(value: string) {
        console.log(value)
        if (value !== this.typeOrder) {
            this.typeOrder = value;
            this.showOrderList = false;
            this.changeOrder()

        }
    }
}
