import { Component, OnInit, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/shared/services';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { 
    Firestore, 
    doc,
    docData
} from '@angular/fire/firestore';
import { LightboxImgComponent } from 'src/app/components/lightbox-img/lightbox-img.component';
import { EventColors } from 'src/app/models/event-colors';
import { Subscription } from 'rxjs';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-location',
    templateUrl: './location.page.html',
    styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit, OnDestroy {
    backBtn: boolean = true;

    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    eventId: string = null;
    locationId: string = null;
    location: any = null;
    allowButtonsHeader: boolean = false;
    subscriptions: Subscription[] = [];
    isMobile: boolean = false;

    constructor(
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private route: ActivatedRoute,
        private firestore: Firestore,
        private router: Router,
        private modalCtrl: ModalController,
        private zone: NgZone,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.route.params.subscribe((params) => {
            this.eventId = params.eventId;
            // this.eventId = this.route.parent.params['_value']['eventId'];
            // localStorage.setItem('eventId', this.eventId);
            this.locationId = params.locationId;
            // this.locationId = this.route.snapshot.params['locationId'];
            this.menuCtrl.enable(true);
            this.loadColors();
            // this.events.subscribe('loadColors', () => {
            //     this.loadColors();
            // });
            this.menuBadge = this.global.notificationBadge;
            this.events.subscribe('menuBadge', () => {
                this.zone.run(() => {
                    this.menuBadge = this.global.notificationBadge;
                })
            });

            this.getLocation();
        })
    }

    getLocation() {
        const ref = doc(this.firestore, `events/${this.eventId}/locations/${this.locationId}`)
        const subscription = docData(ref).subscribe((data: any) => {
            this.location = data;
        });

        this.subscriptions.push(subscription);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.zone.run(() => {
        //         this.allowButtonsHeader = true;
        //     })
        // }
        // this.dbAnalytics.moduleAccess(this.eventId, this.moduleId);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    // zoom profile picture image
    openImage(url: string) {
        // this.photoViewer.show(url, '', { share: false });
        this.modalCtrl.create({
            component: LightboxImgComponent,
            componentProps: {
                img: url
            }
        }).then(modal => {
            modal.present();
        });
    }

}
